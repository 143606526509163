<template>
  <div>
    <Dialog v-model:visible="inputVisible" modal :header="inputModel?.id ? 'Edit Branch Office' : 'New Branch Office'">
      <div class="flex flex-column">
        <label for="nameInput">Office Name</label>
        <InputText autofocus v-model="inputModel.name" id="nameInput" placeholder="Office Name" class="mb-3" />

        <Button class="mt-3" label="Save" @click="onSubmit"
          :disabled="!inputModel.name" />
      </div>
    </Dialog>

    <ConfirmDialog />

    <Toolbar>
      <template #start>
        <Button icon="pi pi-plus" class="mr-2" label="New" @click="onNewClick" />
      </template>
    </Toolbar>
    <ConDataTable class="m-3" ref="dataTableRef" :columns="columns" filterDisplay="menu" :onReload="onReload" title="Offices"
      :createActionMenu="createActionMenu" :rows="50" />
  </div>
</template>


<script setup lang="ts">
import { ConDataTable } from '@congatec/primevue-components-lib';
import { OfficesService, type OfficeListResponse_Office } from '@/apis/saturn-api';
import Dialog from 'primevue/dialog';
import Toolbar from 'primevue/toolbar';
import ConfirmDialog from 'primevue/confirmdialog';
import InputText from 'primevue/inputtext';
import { useConfirm } from "primevue/useconfirm";
import { onMounted, ref, watch, type Ref } from 'vue';
import Button from 'primevue/button';

const confirm = useConfirm();
const dataTableRef = ref();
const inputVisible = ref(false);
const inputModel: Ref<OfficeListResponse_Office> = ref({});

const columns = [
  {
    field: "name",
    header: "Name",
    dataType: "text",
    isFiltered: true,
    sortable: true,
  },
  {
    field: "actionMenu"
  }
]

const onReload = async (filters: string, sorts: string, page: number, rows: number): Promise<any> => {
  return await OfficesService.getApiV1Offices(filters, sorts, page, rows);
}

const onSubmit = async () => {
  console.debug("Submitting ", inputModel.value);

  const req = { id: inputModel.value.id, name: inputModel.value.name };
  if (!inputModel.value.id) {
    await OfficesService.postApiV1Offices(req);
  } else {
    await OfficesService.putApiV1Offices(req);
  }

  dataTableRef.value.forceReload();
  inputVisible.value = false;
}

const onNewClick = () => {
  inputModel.value = {};
  inputVisible.value = true;
}

const onEditClick = (row: any) => {
  inputModel.value = row;
  inputVisible.value = true;
}


const onDeleteClick = (row: any) => {
  confirm.require({
    message: 'Are you sure you want to delete this entry?',
    header: 'Confirmation',
    icon: 'pi pi-exclamation-triangle',
    rejectClass: 'p-button-danger',
    rejectLabel: 'Cancel',
    acceptLabel: 'Delete',
    accept: async () => {
      console.info(`Deleting branch office with id ${row.id}`);
      await OfficesService.deleteApiV1Offices(row.id);
      dataTableRef.value.forceReload();
    },
    reject: () => {
    }
  });
}

const createActionMenu = (row: any) => {
  return [
    {
      label: 'Edit',
      icon: 'pi pi-pencil',
      command: () => {
        onEditClick(row)
      }
    },
    {
      label: 'Delete',
      icon: 'pi pi-trash',
      command: () => {
        onDeleteClick(row)
      }
    },
  ];
}

</script>

