/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateCalendarEventRequest } from '../models/CreateCalendarEventRequest';
import type { CreateCalendarEventResponse } from '../models/CreateCalendarEventResponse';
import type { DeleteCalendarEventRequest } from '../models/DeleteCalendarEventRequest';
import type { DeleteCalendarEventResponse } from '../models/DeleteCalendarEventResponse';
import type { ListCalendarEventResponse } from '../models/ListCalendarEventResponse';
import type { ListPlacesRequest } from '../models/ListPlacesRequest';
import type { ListPlacesResponse } from '../models/ListPlacesResponse';
import type { UpdateCalendarEventRequest } from '../models/UpdateCalendarEventRequest';
import type { UpdateCalendarEventResponse } from '../models/UpdateCalendarEventResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MicrosoftGraphService {

    /**
     * @param requestBody 
     * @returns CreateCalendarEventResponse Success
     * @throws ApiError
     */
    public static postApiV1MicrosoftGraph(
requestBody?: CreateCalendarEventRequest,
): CancelablePromise<CreateCalendarEventResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/MicrosoftGraph',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param from 
     * @param to 
     * @param roomEmail 
     * @returns ListCalendarEventResponse Success
     * @throws ApiError
     */
    public static getApiV1MicrosoftGraph(
from?: string,
to?: string,
roomEmail?: string,
): CancelablePromise<ListCalendarEventResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/MicrosoftGraph',
            query: {
                'From': from,
                'To': to,
                'RoomEmail': roomEmail,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns UpdateCalendarEventResponse Success
     * @throws ApiError
     */
    public static putApiV1MicrosoftGraphUpdate(
requestBody?: UpdateCalendarEventRequest,
): CancelablePromise<UpdateCalendarEventResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/MicrosoftGraph/Update',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns DeleteCalendarEventResponse Success
     * @throws ApiError
     */
    public static deleteApiV1MicrosoftGraphDelete(
requestBody?: DeleteCalendarEventRequest,
): CancelablePromise<DeleteCalendarEventResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/MicrosoftGraph/Delete',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param request 
     * @returns ListPlacesResponse Success
     * @throws ApiError
     */
    public static getApiV1MicrosoftGraphPlacesList(
request?: ListPlacesRequest,
): CancelablePromise<ListPlacesResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/MicrosoftGraph/places/List',
            query: {
                'request': request,
            },
        });
    }

}
