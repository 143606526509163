/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { HardwareSampleCreateRequest } from '../models/HardwareSampleCreateRequest';
import type { HardwareSampleCreateResponse } from '../models/HardwareSampleCreateResponse';
import type { HardwareSampleDeleteResponse } from '../models/HardwareSampleDeleteResponse';
import type { HardwareSampleGetResponse } from '../models/HardwareSampleGetResponse';
import type { HardwareSampleListResponse } from '../models/HardwareSampleListResponse';
import type { HardwareSampleUpdateRequest } from '../models/HardwareSampleUpdateRequest';
import type { HardwareSampleUpdateResponse } from '../models/HardwareSampleUpdateResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class HardwareSamplesService {

    /**
     * @param requestBody 
     * @returns HardwareSampleCreateResponse Success
     * @throws ApiError
     */
    public static postApiV1HardwareSamples(
requestBody?: HardwareSampleCreateRequest,
): CancelablePromise<HardwareSampleCreateResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/HardwareSamples',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param ids 
     * @returns HardwareSampleDeleteResponse Success
     * @throws ApiError
     */
    public static deleteApiV1HardwareSamples(
ids?: Array<number>,
): CancelablePromise<HardwareSampleDeleteResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/HardwareSamples',
            query: {
                'Ids': ids,
            },
        });
    }

    /**
     * @param sieveModelFilters 
     * @param sieveModelSorts 
     * @param sieveModelPage 
     * @param sieveModelPageSize 
     * @returns HardwareSampleListResponse Success
     * @throws ApiError
     */
    public static getApiV1HardwareSamples(
sieveModelFilters?: string,
sieveModelSorts?: string,
sieveModelPage?: number,
sieveModelPageSize?: number,
): CancelablePromise<HardwareSampleListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/HardwareSamples',
            query: {
                'sieveModel.Filters': sieveModelFilters,
                'sieveModel.Sorts': sieveModelSorts,
                'sieveModel.Page': sieveModelPage,
                'sieveModel.PageSize': sieveModelPageSize,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns HardwareSampleUpdateResponse Success
     * @throws ApiError
     */
    public static putApiV1HardwareSamples(
requestBody?: HardwareSampleUpdateRequest,
): CancelablePromise<HardwareSampleUpdateResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/HardwareSamples',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id 
     * @returns HardwareSampleGetResponse Success
     * @throws ApiError
     */
    public static getApiV1HardwareSamples1(
id: number,
): CancelablePromise<HardwareSampleGetResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/HardwareSamples/{id}',
            path: {
                'id': id,
            },
        });
    }

}
