<template>
  <Dialog v-if="visible" v-model:visible="dialogVisible" modal :draggable="false" :header="headerText" position="top"
    style="max-width: 80%; min-width: 80%; max-height: 80%">
    <template #default>
      <div>
        <div class="card flex justify-content-left">
          <div class="flex flex-column gap-5 w-full">
            <div class="flex flex-row gap-5 w-full">
              <div class="w-full">
                <label for="name">Name <span>*</span></label>
                <InputText id="name" v-model="bundle.name" :value="bundle.name"
                  placeholder="Enter the name for the Bundle" class="w-full" />
              </div>
              <div class="w-full">
                <label for="name">Version <span>*</span></label>
                <InputText id="name" v-model="bundle.version" :value="bundle.version"
                  placeholder="Enter the name for the Bundle" class="w-full" />
              </div>
            </div>
          </div>
        </div>
        <div class="m-5 mt-7">
          <PickList v-model="pickListArray" dataKey="id" breakpoint="1400px">
                <template #sourceheader> Available </template>
                <template #targetheader> Selected </template>
                <template #item="slotProps">
                    <span>{{ slotProps.item.filePath }}</span>
                </template>
            </PickList>
          <!-- <label>Substitutes</label>
          <AutoComplete dropdown class="w-full" v-model="artifactQuery" :suggestions="artifactsFound"
            optionLabel="identifier" @item-select="selectArtifact" />
          <ConDataTable tableId="ArtifactList" title="Selected Artifacts" :columns="artifactTableColumns"
            filterDisplay="menu" :rowsPerPageOptions="[10, 20, 50]" :onReload="fetchArtifactDataAsync"
            :table-data="selectedArtifacts" @onSelectionChanged="handleSelectionChange"
            :createActionMenu="createBundleActionMenu" ref="bundleTableRef">
          </ConDataTable> -->
        </div>
      </div>
    </template>
    <template #footer>
      <div class="flex flex-row align-items-end justify-content-between">
        <Button class="p-button-text" :disabled="isLoading" label="Cancel" :onClick="closeDialog
          " />

        <Button class="p-button-primary" label="Save" :onClick="() => {
            if (props.guid) {
              submitBundleUpdate();
              return;
            }
            submitBundleCreation();
          }
          " />
      </div>
    </template>
  </Dialog>
</template>
<script setup lang="ts">
import { type Ref, ref, watch } from "vue";
import Dialog from "primevue/dialog";
import InputText from "primevue/inputtext";
import AutoComplete from "primevue/autocomplete";
import { delay, useDelayTimer } from "@congatec/primevue-components-lib";
import Button from "primevue/button";
import {
  ArtifactService,
  BundleService,
  type BundlesCreateRequest,
  type ArtifactsListResponseArtifact,
  type BundlesDetailsRespoonseArtifact,
} from "@/apis/aready-api";
import { ConDataTable } from "@congatec/primevue-components-lib";
import PickList from 'primevue/picklist';

const bundle: Ref<BundlesCreateRequest> = ref({});
const selectedArtifacts = ref<Array<ArtifactsListResponseArtifact>>([]);
const artifactGuids: Ref<Array<string>> = ref([]);
const bundleTableRef = ref<null | typeof ConDataTable>(null);
const artifactQuery = ref<any>("");
const artifactsFound = ref<ArtifactsListResponseArtifact[]>([]);
const artifactQueryTimer = useDelayTimer();
const isLoading = ref(false);
const pickListArray: Ref<Array<Array<any>>> = ref([[]]); 

const emit = defineEmits(['close'])

const props = defineProps<{
  visible: boolean;
  guid: string;
}>();

const dialogVisible = ref(props.visible);

const headerText = ref("Create a new Bundle");

const selectArtifact = () => {
  selectedArtifacts.value.push(artifactQuery.value);
  artifactGuids.value = [];
  selectedArtifacts.value.forEach((artifact) => {
    artifactGuids.value.push(artifact.guid ? artifact.guid : "");
  });
  console.log(selectedArtifacts.value);
  artifactQuery.value = "";
  reloadBundlesTable();
};

function reloadBundlesTable() {
  bundleTableRef.value?.forceReload();
}

function createBundleActionMenu(row: any): any {
  return [
    {
      label: "Remove",
      icon: "pi pi-times",
      command: () => {
        console.log(row.identifier);
        selectedArtifacts.value = selectedArtifacts.value.filter(
          (obj) => obj.identifier != row.identifier,
        );

        console.log(selectedArtifacts.value);
        reloadBundlesTable();
      },
    },
  ];
}

watch(artifactQuery, async () => {
  delay(artifactQueryTimer, async () => {
    if (!artifactQuery.value) {
      artifactsFound.value = [];
      return;
    }

    let query = artifactQuery.value;
    artifactsFound.value =
      (await ArtifactService.getApiV1Artifacts(`Identifier@=${query}`))
        .artifacts || [];
  });
});

async function fetchArtifactDataAsync(
  filters: string,
  sorts: string,
  page: number,
  rows: number,
): Promise<any> {
  let res = await ArtifactService.getApiV1Artifacts(filters, sorts, page, rows);
  return {
    totalCount: res.totalCount,
    data: res.artifacts,
  };
}

async function submitBundleCreation() {
  bundle.value.artifactGuids = pickListArray.value[1]?.map(x => x.guid); //artifactGuids.value;
  await BundleService.postApiV1SoftwareBundles(bundle.value);
  closeDialog();
  reloadBundlesTable();
}

async function submitBundleUpdate() {
  bundle.value.artifactGuids = pickListArray.value[1]?.map(x => x.guid); //artifactGuids.value;
  await BundleService.putApiV1SoftwareBundles({
    guid: props.guid,
    name: bundle.value.name,
    artifactGuids: bundle.value.artifactGuids,
    version: bundle.value.version,
  });
  closeDialog();
  reloadBundlesTable();
}

function handleSelectionChange(selectedRows: any) {
  selectedArtifacts.value = selectedRows;

  artifactGuids.value = [];
  console.log(selectedArtifacts.value);
  selectedArtifacts.value.forEach((artifact) => {
    artifactGuids.value.push(artifact.guid!);
  });
}

function mapArtifacts(
  artifacts: BundlesDetailsRespoonseArtifact[] | null | undefined,
): ArtifactsListResponseArtifact[] {
  var response: ArtifactsListResponseArtifact[] = [];

  if (artifacts) {
    artifacts.forEach((element) => {
      response.push({
        filePath: element.filePath,
        fileSize: element.fileSize,
        description: element.description,
        identifier: element.identifier,
        plmItem: element.plmItem,
        fileHash: element.fileHash,
        hashAlgorithm: element.hashAlgorithm,
        guid: element.guid,
      });
    });
  }

  return response;
}

function closeDialog() {
  emit("close");
}

watch(() => props.visible, async () => {
  dialogVisible.value = props.visible;
  if (dialogVisible.value && props.guid) {
    isLoading.value = true;
    await BundleService.getApiV1SoftwareBundles(props.guid).then((res) => {
      (bundle.value.name = res.name),
      (bundle.value.version = res.version),
      (selectedArtifacts.value = mapArtifacts(res.artifacts));
      headerText.value = "Edit " + bundle.value.name;
      reloadBundlesTable();
      isLoading.value = false;
    });
  }
  if (dialogVisible.value) {
    let artifactRes = await ArtifactService.getApiV1Artifacts('', '', 1, 1000);
    let existingArtifacts = artifactRes.artifacts?.filter(x => !selectedArtifacts.value.some(y => y.guid === x.guid));
    if(existingArtifacts != undefined) {
      pickListArray.value = [existingArtifacts, selectedArtifacts.value];
    }
  }
});

watch(dialogVisible, () => {
  if (!dialogVisible.value) {
    closeDialog();
  }
});

const artifactTableColumns = [
  {
    field: "filePath",
    header: "File Path",
    dataType: "text",
    isFiltered: true,
    sortable: true,
  },
  {
    field: "fileSize",
    header: "File Size",
    dataType: "text",
    isFiltered: true,
    sortable: true,
  },
  {
    field: "identifier",
    header: "Identifier",
    dataType: "text",
    isFiltered: true,
    sortable: true,
  },
  {
    field: "plmItem",
    header: "PLM Item",
    dataType: "text",
    isFiltered: true,
    sortable: true,
  },
  {
    field: "actionMenu",
  },
];
</script>
