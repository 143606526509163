<template>
  <div class="">
    <Toolbar>
      <template #start>
        <Button icon="pi pi-save" class="mr-2" @click="onSave" />
        <Button icon="pi pi-angle-down" class="mr-2" @click="toggleAddMenu" aria-haspopup="true"
          aria-controls="overlay_tmenu" />
        <TieredMenu ref="addMenu" id="overlay_tmenu" :model="addMenuItems" popup />

        <Button v-if="selectedShape" icon="pi pi-trash" class="mr-2" @click="removeSelectedCommand" />

        <label class="mr-3" v-if="selectedShape">
          X: {{ Math.round(selectedShape.x) }}
          Y: {{ Math.round(selectedShape.y)
          }}
        </label>
        <label v-if="selectedShape && selectedShape.width && selectedShape.height">
          Width: {{ Math.round(selectedShape.width * selectedShape.scaleX) }}
          Height: {{ Math.round(selectedShape.height * selectedShape.scaleY) }}
        </label>
      </template>

      <template #center>
        <div class="flex flex-row"
          v-if="selectedShape && selectedShape.command.commandType == ImageTemplateCommandType.DRAW_TEXT">
          <div class="mr-2 flex-1 flex flex-column align-items-center">
            <label for="fontStroke" class="font-bold block mb-2"> Stroke </label>
            <ColorPicker id="fontStroke" v-model="strokeColor" />
          </div>

          <div class="mr-2 flex-1 flex flex-column align-items-center">
            <label for="fontFill" class="font-bold block mb-2"> Fill </label>
            <ColorPicker id="fontFill" v-model="fillColor" />
          </div>

          <div class="flex-auto">
            <label for="font-size" class="font-bold block mb-2"> Font Size </label>
            <InputNumber v-model="fontSize" inputId="font-size" showButtons />
          </div>
        </div>
      </template>
    </Toolbar>

    <div class="flex">
      <div v-if="!imageTemplate">
        Loading...
      </div>
      <div v-else id="stage-parent">
        <v-stage ref="stage" :config="canvasConfig" @mousedown="onStageMouseDown" @touchstart="onStageMouseDown">
          <v-layer>
            <v-image :config="imageConfig" />

            <v-image v-for="(image) in imagesConfig" :config="image" @dragend="onDragEnd" :key="image.name"
              @transformend="onTransformEnd" />
            <v-rect v-for="(rect) in rectConfig" :config="rect" @dragend="onDragEnd" :key="rect.name"
              @transformend="onTransformEnd" />
            <v-text v-for="(text) in textConfig" :config="text" @dragend="onDragEnd" @transformend="onTransformEnd"
              :key="text.name" />
            <v-transformer :config="transformerConfig" ref="transformer" />
          </v-layer>
        </v-stage>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import Konva from 'konva';
import { onMounted, ref, watch } from 'vue';
import { ImageTemplateService, type ListImageTemplateResponse_ImageTemplate, type ListImageTemplateResponse_DrawCommand, ImageTemplateCommandType, type ImageEditorCapabilitiesResponse, type ImageEditorCapabilitiesResponse_Property } from '@/apis/haumea-api';
import Toolbar from 'primevue/toolbar';
import Button from 'primevue/button';
import ColorPicker from 'primevue/colorpicker';
import InputNumber from 'primevue/inputnumber';
import { useRoute } from 'vue-router';
import TieredMenu from 'primevue/tieredmenu';
import { showSuccess } from '@congatec/primevue-components-lib';
import { useToast } from 'primevue/usetoast';

const route = useRoute();
const stage = ref();
const addMenu = ref();
const transformer = ref();
const width = window.innerWidth;
const height = window.innerHeight;

const strokeColor = ref("");
const fillColor = ref("");
const fontSize = ref(0);

const toast = useToast();

const capabilities = ref<ImageEditorCapabilitiesResponse>({});
let templateId = 0;
const commands = ref<ListImageTemplateResponse_DrawCommand[]>([]);
const imageTemplate = ref<ListImageTemplateResponse_ImageTemplate | null>(null);

const addMenuItems = ref<any>([]);

const rectImages = {
  Photo: '/primeicons/user.png',
  QrCode: '/primeicons/qrcode.png',
};

const itemToImgSrc = (item: ListImageTemplateResponse_ImageTemplate) => {
  return `data:${item.mimeType};base64,${item.content}`;
}

const onInit = async () => {
  templateId = Number.parseInt(route.params.templateId.toString());
  console.info("TemplateId: ", templateId);
  capabilities.value = await ImageTemplateService.getApiV1ImageTemplateEditorCapabilities();
  console.info("Capabilities: ", capabilities.value);

  addMenuItems.value = [];
  if (capabilities.value.properties) {
    for (const prop of capabilities.value.properties) {
      addMenuItems.value.push({
        label: prop.name,
        icon: 'pi pi-plus',
        command: () => createNode(prop)
      })
    }
  }

  const template = (await ImageTemplateService.getApiV1ImageTemplate(`id==${templateId}`, "", 1, 1, true));
  if (template.totalCount != 1 || !template || !template.imageTemplates || !template.imageTemplates[0]) {
    console.error(`Tempalte with id ${templateId} was not found!\n`);
    // TODO: show user error
    return;
  }
  commands.value = template.imageTemplates[0].drawCommands || [];
  imageTemplate.value = template.imageTemplates[0];

  const image = new window.Image();
  image.src = itemToImgSrc(imageTemplate.value);
  await image.decode();

  stageWidth.value = image.naturalWidth;
  stageHeight.value = image.naturalHeight;
  canvasConfig.value.width = image.naturalWidth;
  canvasConfig.value.height = image.naturalHeight;

  imageConfig.value = { image };

  scaleStageToScreen();
  await fullRedraw();
}

const scaleStageToScreen = () => {
  // TODO: we may want to scale this in the future 
  // const container = stage.value.getStage().container();

  // only scale based on one value to keep aspect ratio
  let scaleX = 1;
  let scaleY = scaleX;

  canvasConfig.value.width = stageWidth.value * scaleX;
  canvasConfig.value.height = stageHeight.value * scaleY;
  canvasConfig.value.scale = { x: scaleX, y: scaleY };
}

const fullRedraw = async () => {
  selectedShape.value = null;
  let configs = await commandsToDrawConfig(commands.value);
  textConfig.value = configs.textConfig;
  rectConfig.value = configs.rectConfig;
  imagesConfig.value = configs.imagesConfig;
}

watch(route.params, onInit);
onMounted(() => {
  onInit();
});

const stageWidth = ref(0);
const stageHeight = ref(0);

const canvasConfig = ref({
  width,
  height,
  scale: { x: 1, y: 1 }
});

const imageConfig = ref({});

const textConfig = ref<any[]>([]);

const transformerConfig = ref({
  rotateEnabled: false,
  flipEnabled: false,
  resizeEnabled: true
})

// outline rectangle for each element
const rectConfig = ref<any[]>([]);
const imagesConfig = ref<any[]>([]);
const selectedShape = ref<any>(null);
const idCount = ref(0);

const commandsToDrawConfig = async (commands: ListImageTemplateResponse_DrawCommand[]) => {
  let textConfig: any[] = [];
  let rectConfig: any[] = [];
  let imagesConfig: any[] = [];

  for (const command of commands) {
    const nextId = idCount.value++;

    // use this as a filter for removal code later
    (command as any).name = nextId.toString();

    switch (command.commandType) {
      case ImageTemplateCommandType.DRAW_TEXT:
        textConfig.push({
          x: command.startX,
          y: command.startY,
          text: command.propertyName,
          fontSize: command.fontSize,
          stroke: `#${command.fontStrokeColor}`,
          fill: `#${command.fontFillColor}`,
          draggable: true,
          command,
          name: nextId.toString(),
        });
        break;
      case ImageTemplateCommandType.DRAW_IMAGE:
        if (command.propertyName && (rectImages as any)[command.propertyName]) {
          const image = new window.Image();
          image.src = (rectImages as any)[command.propertyName];

          // force image to load now!
          await image.decode();

          imagesConfig.push({
            x: command.startX,
            y: command.startY,
            width: (command.endX || 0) - (command.startX || 0),
            height: (command.endY || 0) - (command.startY || 0),
            draggable: true,
            command,
            name: nextId.toString(),
            image,
            scaleX: 1,
            scaleY: 1
          });
        } else {
          rectConfig.push({
            x: command.startX,
            y: command.startY,
            width: (command.endX || 0) - (command.startX || 0),
            height: (command.endY || 0) - (command.startY || 0),
            fill: Konva.Util.getRandomColor(),
            draggable: true,
            command,
            name: nextId.toString(),
            scaleX: 1,
            scaleY: 1
          });
        }
        break;
    }
  }

  return { textConfig, rectConfig, imagesConfig };
}

const updateUi = () => {
  const shape = selectedShape.value;
  if (!shape) {
    return;
  }

  strokeColor.value = shape.command.fontStrokeColor;
  fillColor.value = shape.command.fontFillColor;
  fontSize.value = shape.command.fontSize;
}

const isTextShape = () => {
  return selectedShape.value && selectedShape.value.command.commandType == ImageTemplateCommandType.DRAW_TEXT;
}

watch(strokeColor, () => {
  if (!isTextShape()) {
    return;
  }

  selectedShape.value.command.fontStrokeColor = strokeColor.value;
  selectedShape.value.stroke = `#${strokeColor.value}`;
});

watch(fillColor, () => {
  if (!isTextShape()) {
    return;
  }

  selectedShape.value.command.fontFillColor = fillColor.value;
  selectedShape.value.fill = `#${fillColor.value}`;
});

watch(fontSize, () => {
  if (!isTextShape()) {
    return;
  }

  selectedShape.value.command.fontSize = fontSize.value;
  selectedShape.value.fontSize = fontSize.value;
});

// TODO: when dragging begins draw grid "lines" around the x/y position of other 
// objects to allow snapping to grid
// snap positon when the cursor approaches the line (e.g. +/- 5 pixels total distance) 

const onDragEnd = (event: any) => {
  const shape = selectedShape.value;
  if (!shape) {
    return;
  }

  const target = event.target;

  let x = target.x();
  let y = target.y();
  let width = target.width();
  let height = target.height();
  let scaleX = target.scaleX();
  let scaleY = target.scaleY();

  if (x < 0) {
    x = 0;
  }
  if (y < 0) {
    y = 0;
  }
  if (x + width * scaleX > stage.value.getStage().width()) {
    x = stage.value.getStage().width() - width * scaleX;
  }
  if (y + height * scaleY > stage.value.getStage().height()) {
    y = stage.value.getStage().height() - height * scaleY;
  }

  // update the state
  shape.x = x;
  shape.y = y;


  shape.command.startX = shape.x;
  shape.command.startY = shape.y;
  shape.command.endX = shape.x + shape.width * scaleX;
  shape.command.endY = shape.y + shape.height  * scaleY;
}

const onStageMouseDown = (event: any) => {
  // clicked on blank area
  // clear selection
  if (event.target == event.target.getStage()) {
    selectedShape.value = null;
    updateTransformer();
    return;
  }

  // clicked on transformer - do nothing
  const clickedOnTransformer =
    event.target.getParent().className === 'Transformer';
  if (clickedOnTransformer) {
    return;
  }

  const name = event.target.name();

  // look up selection target 

  // first in rectangles 
  const rect = rectConfig.value.find((r: any) => r.name == name);
  // then in text 
  const text = textConfig.value.find((t: any) => t.name == name);
  const image = imagesConfig.value.find((t: any) => t.name == name);
  if (rect) {
    selectedShape.value = rect;
    transformerConfig.value.resizeEnabled = true;
  } else if (text) {
    selectedShape.value = text;
    // cannot resize text nodes!
    transformerConfig.value.resizeEnabled = false;
  } else if (image) {
    transformerConfig.value.resizeEnabled = true;
    selectedShape.value = image;
  } else {
    selectedShape.value = null;
  }

  updateUi();
  updateTransformer();
}

watch(stage, () => {
  stage.value.getStage().on('wheel', (e: any) => {
    const s = stage.value.getStage();
    const scaleBy = 1.01;
    e.evt.preventDefault();

    let oldScale = s.scaleX();

    let newScale =
      e.evt.deltaY > 0 ? oldScale * scaleBy : oldScale / scaleBy;

    s.scale({
      x: newScale,
      y: newScale
    });

    s.batchDraw();
  });
});

const updateTransformer = () => {
  // here we need to manually attach or detach Transformer node
  const transformerNode = transformer.value.getNode();
  const stage = transformerNode.getStage();

  if (!selectedShape.value) {
    transformerNode.nodes([]);
    return;
  }


  const selectedNode = stage.findOne('.' + selectedShape.value.name);

  if (selectedNode === transformerNode.node()) {
    return;
  }
  if (selectedNode) {
    transformerNode.nodes([selectedNode]);
  } else {
    transformerNode.nodes([]);
  }
}

const onTransformEnd = (event: any) => {
  const shape = selectedShape.value;
  if (!shape) {
    return;
  }

  const target = event.target;
  let x = target.x();
  let y = target.y();
  let width = target.width();
  let height = target.height();
  let scaleX = target.scaleX();
  let scaleY = target.scaleY();


  // update the state
  shape.x = x;
  shape.y = y;
  shape.width = width;
  shape.height = height;
  shape.scaleX = scaleX;
  shape.scaleY = scaleY;


  shape.command.startX = shape.x;
  shape.command.startY = shape.y;
  shape.command.endX = shape.x + shape.width * scaleX;
  shape.command.endY = shape.y + shape.height * scaleY;
}

const removeSelectedCommand = async () => {
  const shape = selectedShape.value;
  if (!shape) {
    return;
  }

  let index = commands.value.findIndex((x: any) => x.name == shape.command.name);
  if (index < 0) {
    console.error("PANIC: Selected command not found int command list...");
    return;
  }
  console.debug("Removing ", index);
  commands.value.splice(index, 1);

  await fullRedraw();
}

const toggleAddMenu = () => {
  addMenu.value.toggle(event);
}

const createNode = async (prop: ImageEditorCapabilitiesResponse_Property) => {
  selectedShape.value = null;
  let firstFont = null;
  if (capabilities.value.fonts) {
    firstFont = capabilities.value.fonts[0];
  }

  switch (prop.commandType) {
    case ImageTemplateCommandType.DRAW_TEXT:
      commands.value.push({
        commandType: prop.commandType,
        propertyName: prop.name,
        font: firstFont?.fontFamily,
        fontSize: 24,
        startX: 0,
        startY: 0,
        fontStrokeColor: "000000",
        fontFillColor: "000000"
      })
      break;
    case ImageTemplateCommandType.DRAW_IMAGE:
      commands.value.push({
        commandType: prop.commandType,
        propertyName: prop.name,
        startX: 0,
        startY: 0,
        endX: 100,
        endY: 100,
      })
      break;
  }
  await fullRedraw();
}

const onSave = async () => {
  await ImageTemplateService.putApiV1ImageTemplateDrawCommands({
    templateId: templateId,
    drawCommands: commands.value
  });
  showSuccess(toast, "Saved", "Image Template Saved")
}

</script>
