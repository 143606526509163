import { defineStore } from "pinia";
import { useStorage } from "@vueuse/core";
import { type Ref } from "vue";
import type { ListEmployeeCardResponse_EmployeeCard } from "@/apis/haumea-api";

const STORAGE_KEY = "Selected-EmployeeCard";

export const useEmployeeCardStore = defineStore("EmployeeCardStorage", () => {
  const employeeCard: Ref<ListEmployeeCardResponse_EmployeeCard | undefined> = useStorage(STORAGE_KEY, undefined);

  const set = (newToken: ListEmployeeCardResponse_EmployeeCard) => {
    employeeCard.value = newToken;
  };

  const clear = () => {
    employeeCard.value = undefined;
  }

  return { employeeCard, set, clear };
});
