import { type AuthenticationService } from "@congatec/authentication-lib";
import { type Ref, ref } from "vue";
import { to } from "@congatec/primevue-components-lib";
import HomeView from "@/hubspotgateway/components/home/views/HomeView.vue";
import LogsListView from "@/hubspotgateway/components/logEntries/list/views/LogsListView.vue";

const _authenticationService: Ref<AuthenticationService | undefined | null> =
  ref(null);

export function addAuthToRouter(authenticationService: AuthenticationService) {
  _authenticationService.value = authenticationService;
}

const hubspotgatewayroutes = [
  {
    path: "/hubspot-gateway/home",
    name: "home",
    component: HomeView,
    props: true,
    meta: {
      breadcrumb: [
        { label: "Hubspot Gateway", command: to("/hubspot-gateway/home") },
        { label: "Home" },
      ],
    },
  },
  {
    path: "/hubspot-gateway/logs",
    name: "logs",
    component: LogsListView,
    props: true,
    meta: {
      breadcrumb: [
        { label: "Hubspot Gateway", command: to("/hubspot-gateway/home") },
        { label: "Logs" },
      ],
    },
  },
];

export default hubspotgatewayroutes;
