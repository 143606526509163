import { to } from "@congatec/primevue-components-lib";

export const dwhDatabaseRoutes = [
  // {
  //   path: "/users",
  //   redirect: "/users/list"
  // },
  {
    path: "/uranus/dwhdatabases",
    name: "dwhdatabases",

    component: () =>
      import("@/uranus/components/dwhdatabases/list/DwhDatabasesListView.vue"),
    meta: {
      breadcrumb: [{ label: "Uranus", command:to("/uranus/home")},{ label: "DWH Databases" }],
    },
  },
  {
    path: "/uranus/dwhdatabases/:dwhdatabaseId/",
    component: () =>
      import("@/uranus/components/dwhdatabases/details/DwhDatabaseDetailsView.vue"),
    props: (route: { params: { dwhdatabaseId: any } }) => ({
      propDetailCheckId: route.params.dwhdatabaseId,
    }),
    meta: {
      breadcrumb: [
        { label: "Uranus", command:to("/uranus/home")},
        { label: "DWH Databases", command:to("/uranus/dwhdatabases") }, { label: "Details" }
      ],
    },
  },
  {
    path: "/uranus/dwhdatabases/create",
    component: () =>
      import("@/uranus/components/dwhdatabases/create/DwhDatabaseCreateView.vue"),
    meta: {
      breadcrumb: [
        { label: "Uranus", command:to("/uranus/home")},
        { label: "DWH Databases", command:to("/uranus/dwhdatabases") }, { label: "Create" }
      ],
    },
  },
  {
    path: "/uranus/dwhdatabases/:dwhdatabaseId/edit",
    component: () =>
      import("@/uranus/components/dwhdatabases/edit/DwhDatabaseEditView.vue"),
    props: (route: { params: { dwhdatabaseId: any } }) => ({
      propDetailCheckId: route.params.dwhdatabaseId,
    }),
    meta: {
      breadcrumb: [
        { label: "Uranus", command:to("/uranus/home")},
        { label: "DWH Databases", command:to("/uranus/dwhdatabases") }, { label: "Edit" }
      ],
    },
  },
  {
    path: "/uranus/dwhdatabases/:dwhdatabaseIds/subscribe/",
    component: () =>
      import(
        "@/uranus/components/dwhdatabases/subscribe/DwhDatabaseSubscribeView.vue"
      ),
    props: (route: { params: { dwhdatabaseIds: any } }) => ({
      propSubscribeCheckIds: route.params.dwhdatabaseIds,
    }),
  },
];
