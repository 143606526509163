<template>
  <div class="flex  flex-column">
    <div class="align-self-center text-center">
      <h1 class="text-7xl">Saturn</h1>
      <h2>Inventory Management Tool</h2>
    </div>
    <div class="flex grid m-3 ml-6 mr-8 gap-6">
      <Card v-if="features.flags?.manufacturedHardwareSample"
        class="hover:surface-100 col md:col-12 lg:col sm:col-12 cursor-pointer p-0"
        @click="router.push({ name: 'SaturnHardwareList' })">
        <template #header>
          <div class="home-nav"></div>
        </template>
        <template #title>Hardware Samples</template>
        <template #subtitle>
          Manage the Hardware Sample database
        </template>
      </Card>

      <Card v-if="features.flags?.department"
        class="hover:surface-100 col md:col-12 lg:col sm:col-12 cursor-pointer p-0"
        @click="router.push({ name: 'SaturnDepartmentList' })">
        <template #header>
          <div class="home-nav"></div>
        </template>
        <template #title>Departments</template>
        <template #subtitle>
          Manage Departments
        </template>
      </Card>


      <Card v-if="features.flags?.location"
        class="hover:surface-100 col md:col-12 lg:col sm:col-12 cursor-pointer p-0"
        @click="router.push({ name: 'SaturnLocationList' })">
        <template #header>
          <div class="home-nav"></div>
        </template>
        <template #title>Locations</template>
        <template #subtitle>
          Manage Hardware Sample storage locations
        </template>
      </Card>

      <Card v-if="features.flags?.location"
        class="hover:surface-100 col md:col-12 lg:col sm:col-12 cursor-pointer p-0"
        @click="router.push({ name: 'SaturnBranchOfficeList' })">
        <template #header>
          <div class="home-nav"></div>
        </template>
        <template #title>Branch Offices</template>
        <template #subtitle>
          Manage Branch Offices
        </template>
      </Card>

      <Card v-if="features.flags?.signOut" 
        class="hover:surface-100 col md:col-12 lg:col sm:col-12 cursor-pointer p-0"
        @click="router.push({ name: 'SaturnHardwareScan' })">
        <template #header>
          <div class="home-nav"></div>
        </template>
        <template #title>QR Code Scanner</template>
        <template #subtitle>
          Scan a Hardware Sample's QR Code to sign it out or return it
        </template>
      </Card>
    </div>
  </div>
</template>

<script setup lang="ts">
import { FeaturesService, type FeaturesResponse } from "@/apis/saturn-api";
import Card from "primevue/card";
import { onMounted, ref, type Ref } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();

const features: Ref<FeaturesResponse> = ref({});

onMounted(async () => {
  features.value = await FeaturesService.getApiV1Features();
  console.info("Saturn features: ", features.value);
})

</script>

<style scoped>
.home-nav {
  background-color: var(--primary-color);
  height: 5em;
}</style>
