import { to } from "@congatec/primevue-components-lib";

export const executionRoutes = [
  // {
  //   path: "/users",
  //   redirect: "/users/list"
  // },
  {
    path: "/uranus/executions",
    name: "executions",

    component: () =>
      import("@/uranus/components/executions/list/ExecutionListView.vue"),
    meta: {
      breadcrumb: [
        { label: "Uranus", command: to("/uranus/home") },
        { label: "Executions" },
      ],
    },
  },
  {
    path: "/uranus/executions/:executionId/",
    component: () =>
      import("@/uranus/components/executions/details/ExecutionDetailsView.vue"),
    props: (route: { params: { executionId: any } }) => ({
      propDetailCheckId: route.params.executionId,
    }),
    meta: {
      breadcrumb: [
        { label: "Uranus", command: to("/uranus/home") },
        { label: "Executions", command: to("/uranus/executions") },
        { label: "Details" },
      ],
    },
  },
];
