<template>
  <div class="card">
    <Card class="w-full">
      <template #title>
        <div>Performance</div>
        <div style="display: flex; justify-content: space-between">
          <div style="display: flex">
            <span>
              <Dropdown
                style="margin-right: 1rem"
                v-model="folderName"
                placeholder="Folder"
                optionValue="value"
                :options="folders"
                @change="folderchange($event)"
                optionLabel="label"
                :filter="true"
                :loading="foldersLoading"
                filterPlaceholder="Filter..."
                class="full-size"
              />
            </span>
            <span>
              <Dropdown
                v-model="etlPackage"
                placeholder="ETL-Package"
                @change="packagechange($event)"
                optionLabel="label"
                :filter="true"
                :loading="packagesLoading"
                filterPlaceholder="Filter..."
                class="full-size"
                optionValue="value"
                :options="packages"
                :disabled="folderName == ''"
              />
            </span>
            <span class="p-float-label" style="margin-left: 1rem">
              <Calendar
                inputId="FromDate"
                v-model="FromDate"
                :showIcon="true"
                :showButtonBar="true"
                dateFormat="dd.mm.yy"
              />
              <label for="Approvaldate">From Date</label>
            </span>
            <span class="p-float-label" style="margin-left: 1rem">
              <Calendar
                inputId="ToDate"
                v-model="ToDate"
                :showIcon="true"
                :showButtonBar="true"
                dateFormat="dd.mm.yy"
              />
              <label for="Approvaldate">To Date</label>
            </span>
          </div>
        </div>
      </template>
      <template #content>
        <Chart type="line" :data="chartData" />
      </template>
    </Card>
  </div>
</template>

<script setup lang="ts">
import { ref, type Ref, onMounted, watch } from "vue";
import Chart from "primevue/chart";
import Card from "primevue/card";
import Calendar from "primevue/calendar";
import Dropdown from "primevue/dropdown";
import {
  DwhDatabaseService,
  type DwhDatabasePerformanceResponse,
} from "@/apis/uranus-api";
import { DwhDbStore } from "@/uranus/components/dataqualitychecks/store/selectedDwhDb";

const dwhDbStore = DwhDbStore();
const documentStyle = getComputedStyle(document.documentElement);
const chartData = ref();
const FromDate: Ref<Date> = ref(new Date());
const ToDate: Ref<Date> = ref(new Date());
const etlPackage = ref("");
const folderName = ref("");
const folders: Ref<Array<{ label: string; value: string }>> = ref([]);
const packages: Ref<Array<{ label: string; value: string }>> = ref([]);
const foldersLoading = ref(true);
const packagesLoading = ref(true);
let labels: Ref<Array<string | undefined>> = ref([]);
let dataSets: Ref<Array<number | undefined> | null> = ref([]);
const data: Ref<DwhDatabasePerformanceResponse> = ref({});

onMounted(() => {
  if (dwhDbStore.id && dwhDbStore.id != 0) {
    loadFolders();
    const now = new Date();
    chartData.value = fetchData();
    FromDate.value = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate() - 7,
    );
  }
});

const packagechange = (event: any) => {
  etlPackage.value = event.value;
  chartData.value = fetchData();
};

const folderchange = (event: any) => {
  etlPackage.value = "";
  folderName.value = event.value;
  chartData.value = fetchData();
  loadPackages(folderName.value);
};

watch(FromDate, async () => {
  chartData.value = fetchData();
});

watch(ToDate, async () => {
  chartData.value = fetchData();
});

const fetchData = () => {
  if (folderName.value == "" || etlPackage.value == "") return;

  const documentStyle = getComputedStyle(document.documentElement);
  dataSets.value = [];
  DwhDatabaseService.getApiV1DwhDatabaseDwhDatabasePerformance(
    etlPackage.value,
    folderName.value,
    FromDate.value.toLocaleDateString("sv"),
    ToDate.value.toLocaleDateString("sv"),
    dwhDbStore.id,
  ).then((r) => {
    if (r.dwhDatabasePerformanceResponse_Objects?.map((x) => x.startTime)) {
      console.log(
        r.dwhDatabasePerformanceResponse_Objects?.map((x) => x.startTime),
      );
      (labels.value = r.dwhDatabasePerformanceResponse_Objects?.map((x) => {
        if (x.startTime) return new Date(x.startTime).toLocaleString("de-DE");
        else return "";
      })),
        (dataSets.value = r.dwhDatabasePerformanceResponse_Objects?.map(
          (x) => x.executionDurationInSeconds,
        )),
        (chartData.value = {
          labels: labels,
          datasets: [
            {
              label: "Execution Time in Seconds",
              backgroundColor: "#fc4c02",
              data: dataSets.value,
              borderColor: documentStyle.getPropertyValue("--primary-color"),
            },
          ],
        });
    }
  });
};

const loadFolders = async () => {
  foldersLoading.value = true;
  try {
    folders.value = [];
    if (dwhDbStore.id != 0) {
      let folderNames =
        (
          await DwhDatabaseService.getApiV1DwhDatabaseDwhDatabasePerformanceFolderList(
            dwhDbStore.id,
          )
        ).folderNames || [];
      folderNames.forEach((x) => folders.value.push({ label: x, value: x }));
      foldersLoading.value = false;
    }
  } catch (ex: any) {
    console.log(ex);
  }
};

const loadPackages = async (folderName: string) => {
  packagesLoading.value = true;
  try {
    packages.value = [];
    let packageNames =
      (
        await DwhDatabaseService.getApiV1DwhDatabaseDwhDatabasePerformancePackageList(
          folderName,
          dwhDbStore.id,
        )
      ).packagenames || [];
    packageNames.forEach((x) => packages.value.push({ label: x, value: x }));
    packagesLoading.value = false;
  } catch (ex: any) {
    console.log(ex);
  }
};
</script>

<style scoped>
/* .card {
  margin-left: 1rem;
  margin-right: 1rem;
} */
</style>
