import { EmployeeCardCreator, EmployeeCardList } from '@/haumea/components'
import { ImageTemplateList, ImageTemplateEditor } from '../components/ImageTemplate';
import { to } from '@congatec/primevue-components-lib';
import { TemplateList } from '../components/template';

const haumeaRoutes = [
  {
    path: '/haumea/employee-card/:readOnly',
    name: 'EmployeeCardCreator',
    component: EmployeeCardCreator,
    meta: {
      breadcrumb: [{ title: "con-IDCARD",  command:to("/haumea/employee-card-list") }, { label: "EmployeeCardCreator" }],
    },
  },
  {
    path: '/haumea/employee-card-list',
    name: 'EmployeeCardList',
    component: EmployeeCardList,
    meta: {
      breadcrumb: [{ title: "con-IDCARD" }],
    },
  },
  {
    path: '/haumea/image-template',
    name: 'ImageTemplate',
    component: ImageTemplateList,
    meta: {
      breadcrumb: [{ title: "con-IDCARD",  command:to("/haumea/employee-card-list") }, { label: "Image Template" }],
    },
  },
  {
    path: '/haumea/image-editor/:templateId',
    name: 'ImageTemplateEditor',
    component: ImageTemplateEditor,
    meta: {
      breadcrumb: [{ title: "con-IDCARD",  command:to("/haumea/employee-card-list") },{label: "Card Creator", command:to("/haumea/employee-card/false")}, { label: "Image Template" }],
    },
  },
  {
    path: '/haumea/template',
    name: 'Template',
    component: TemplateList,
    meta: {
      breadcrumb: [{ title: "con-IDCARD",  command:to("/haumea/employee-card-list") }, { label: "Template" }],
    },
  },
  
];


export default haumeaRoutes;

