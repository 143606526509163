/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ScreeningStatus {
    IN_REVIEW = 'inReview',
    LISTED = 'listed',
    NOT_LISTED = 'notListed',
}
