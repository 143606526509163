import { ConSso } from "@congatec/primevue-components-lib";
import { createRouter, createWebHistory } from "vue-router";
import neptuneRoutes from "@/neptune/router";
import JupiterLandingPage from "./jupiter/components/app-portal/JupiterLandingPage.vue";
import ConUnauthorized from "./neptune/components/misc/ConUnauthorized.vue";
import erisRoutes from "@/eris/router";
import uranusRoutes from "@/uranus/router";
import saturnRoutes from "@/saturn/router";
import mercuryRoutes from "@/Mercury/router";
import haumeaRoutes from "@/haumea/router";
import hubspotgatewayroutes from "@/hubspotgateway/router";
import ReportsPortalLandingPage from "@/jupiter/components/bi-portal/ReportsPortalLandingPage.vue";
import areadyRoutes from "./aready/router";
import lapcounterRoutes from "@/lapcounter/router";
import conAIRoutes from "@/azureai/router";
import lunaRoutes from "@/luna/router";

const routes = [
  {
    path: "/",
    name: "LandingPage",
    component: JupiterLandingPage,
    meta: {
      breadcrumb: [{ title: "app-portal" }],
    },
  },
  {
    path: "/report-portal",
    name: "Reports-Portal-LandingPage",
    component: ReportsPortalLandingPage,
    meta: {
      breadcrumb: [{ title: "reports-portal" }],
    },
  },
  {
    path: "/unauthorized",
    name: "unauthorized",
    component: ConUnauthorized,
  },
  {
    path: "/silent-sso",
    name: "silent-sso",
    component: ConSso,
  },
  ...neptuneRoutes,
  ...erisRoutes,
  ...uranusRoutes,
  ...saturnRoutes,
  ...conAIRoutes,
  ...mercuryRoutes,
  ...haumeaRoutes,
  ...hubspotgatewayroutes,
  ...areadyRoutes,
  ...lapcounterRoutes,
  ...lunaRoutes,
];

const globalRouter = createRouter({ history: createWebHistory(), routes });

export default globalRouter;
