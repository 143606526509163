import { to } from "@congatec/primevue-components-lib";

export const dataqualitycheckRoutes = [
  // {
  //   path: "/users",
  //   redirect: "/users/list"
  // },
  {
    path: "/uranus/dataqualitychecks",
    name: "dataqualitychecks",

    component: () =>
      import(
        "@/uranus/components/dataqualitychecks/list/DataqualityChecksListView.vue"
      ),
    meta: {
      breadcrumb: [
        { label: "Uranus", command: to("/uranus/home") },
        {
          label: "Data Quality Checks",
          command: to("/uranus/dataqualitychecks"),
        },
      ],
    },
  },
  {
    path: "/uranus/dataqualitychecks/:dataqualitycheckId/",
    component: () =>
      import(
        "@/uranus/components/dataqualitychecks/details/DataqualityCheckDetailsView.vue"
      ),
    props: (route: { params: { dataqualitycheckId: any } }) => ({
      propDetailCheckId: route.params.dataqualitycheckId,
    }),
    meta: {
      breadcrumb: [
        { label: "Uranus", command: to("/uranus/home") },
        {
          label: "Data Quality Checks",
          command: to("/uranus/dataqualitychecks"),
        },
        { label: "Details" },
      ],
    },
  },
  {
    path: "/uranus/dataqualitychecks/create",
    component: () =>
      import(
        "@/uranus/components/dataqualitychecks/create/DataQualityCheckCreateView.vue"
      ),
    meta: {
      breadcrumb: [
        { label: "Uranus", command: to("/uranus/home") },
        {
          label: "Data Quality Checks",
          command: to("/uranus/dataqualitychecks"),
        },
        { label: "Create" },
      ],
    },
  },
  {
    path: "/uranus/dataqualitychecks/execute",
    component: () =>
      import(
        "@/uranus/components/dataqualitychecks/execute/DataQualityCheckExecuteView.vue"
      ),
    meta: {
      breadcrumb: [
        { label: "Uranus", command: to("/uranus/home") },
        {
          label: "Data Quality Checks",
          command: to("/uranus/dataqualitychecks"),
        },
        { label: "Execute" },
      ],
    },
  },

  {
    path: "/uranus/dataqualitychecks/:dataqualitycheckId/edit/",
    component: () =>
      import(
        "@/uranus/components/dataqualitychecks/edit/DataqualityCheckEditView.vue"
      ),
    props: (route: { params: { dataqualitycheckId: any } }) => ({
      propDetailCheckId: route.params.dataqualitycheckId,
    }),
  },
];
