<template>
  <Dialog v-if="dialogVisible" v-model:visible="dialogVisible" modal :draggable="false" position="top"
    style="max-width: 80%; min-width: 80%; max-height: 80%;">
    <template #default>
      <div class="flex flex-column w-full">
        <Toolbar v-if="!isLoading" class="w-full">
          <template #start>
            <Button label="Reparse" @click="reparseRpt" :loading="reparseInProgress" />
          </template>
        </Toolbar>
        <div class="flex flex-row m-5 mt-6 align-content-start">
          <ConDetails class="mr-3 flex-grow-0" :contentItems="contentItems" title="Test Result" :subTitle="subtitle">
            <template #item="{ content }">
              <div v-for="c in (content as any)" :key="c">
                <div class="flex justify-content-between mb-3">
                  <div>
                    <span class="details-label">{{ c.title }}:</span>
                  </div>
                  <div>
                    {{ c.content }}
                  </div>
                </div>
                <Divider></Divider>
              </div>
            </template>
            <template #footer>
            </template>
          </ConDetails>
          <ConDataTable class="flex-grow-1" :columns="propMatching" :rows="25" :rowsPerPageOptions="[10, 25, 50]"
            filterDisplay="menu" :onReload="(fetchMessageDataFromServiceAsync as any)" />
        </div>
      </div>
    </template>
    <template #footer>
      <div class="w-full flex flex-row align-items-end justify-content-between">
        <Button class="p-button-text" label="Close" icon="pi pi-times" :onClick="() => {
          dialogVisible = false;
        }" />
      </div>
    </template>
  </Dialog>
</template>


<script setup lang="ts">
import { type Ref, defineComponent, ref, watch } from "vue";
import { ConDetails, ConDataTable } from "@congatec/primevue-components-lib"
import {
  TestResultsService,
  type TestDetailsResponse,
  SvnExtractionsService
} from "@/apis/eris-api";
import 'primeicons/primeicons.css';
import Divider from "primevue/divider";
import Button from "primevue/button";
import { useToast } from "primevue/usetoast";
import Toolbar from "primevue/toolbar";
import Dialog from "primevue/dialog";
let contentItems: Ref<any> = ref();
const RptTestDetails: Ref<TestDetailsResponse | null> = ref(null);
const props = defineProps<{
  id: any,
  visible: boolean,
  onHide: (onDetailsClosed: boolean) => any
}>();
let subtitle: string | undefined = "";
const testDetailId: Ref<number | null> = ref(null);
const dialogVisible = ref<boolean>(false);
// const testDetail: Ref<TestDetailsResponse | null> = ref(null);
const isLoading = ref(false);
const reparseInProgress = ref(false);
let svnRevision = 0;
const toast = useToast();

let propMatching: any[] = [
  {
    field: "category",
    header: "Category",
    dataType: "text",
    isFiltered: true,
    sortable: true,
  },
  {
    field: "message",
    header: "Messages",
    dataType: "text",
    isFiltered: true,
    sortable: true,
  },
];

if (props && props.id) {
  testDetailId.value = parseInt(
    props.id.toString()
  );
}

// onMounted(() => {
//   if (testDetailId.value) {
//     _fetchRptDetails(testDetailId.value ?? 0);
//   }
// })

function _fetchRptDetails(id: number) {
  isLoading.value = false;

  TestResultsService.getApiV1RptTestResultRptsSerialNumbers(id)
    .then(async (response: any) => {

      svnRevision = response.svnRevision;
      RptTestDetails.value = response;
      if (response) {
        contentItems.value = [
          {
            title: 'Details', content: [
              {
                title: "ProductionOrder",
                content: RptTestDetails?.value?.productionOrder ?? "",
              },
              {
                title: "Serialnumber",
                content: RptTestDetails.value?.serialNumber?.toString() ?? "",
              },
              {
                title: "Macs",
                content: RptTestDetails.value?.macs?.toString() ?? "",
              },
              {
                title: "HardwareRevision",
                content: RptTestDetails.value?.hardwareRevision ?? "",
              },
              {
                title: "ManufacturedBiosRevision",
                content: RptTestDetails.value?.manufacturedBiosRevision ?? "",
              },
              {
                title: "ManufacturedDate",
                content: RptTestDetails.value?.manufacturedDate ?? "",
              },
              {
                title: "TestDate",
                content: RptTestDetails.value?.testDate ?? "",
              },
              {
                title: "LogfileRevision",
                content: RptTestDetails.value?.logfileRevision?.toString() ?? "",
              },
              {
                title: "Result",
                content: RptTestDetails.value?.result ?? "",
              },
              {
                title: "Product",
                content: RptTestDetails.value?.product ?? "",
              }
            ]
          }
        ];

        subtitle = RptTestDetails.value?.product + " - " + RptTestDetails.value?.serialNumber
        isLoading.value = false;
      }
    });
}

async function fetchMessageDataFromServiceAsync(filters: string, sorts: string, page: number, rows: number) {
  return await TestResultsService.getApiV1RptTestResultRptsMessages(testDetailId.value || 0, filters, sorts, page, rows);
}

const reparseRpt = async () => {
  reparseInProgress.value = true;
  try {
    await SvnExtractionsService.postApiV1SvnExtractionRptFilesParseRevisions({ revisions: [svnRevision] })
    _fetchRptDetails(testDetailId.value ?? 0);
    toast.add({
      severity: 'success',
      summary: "Logfile was reparsed",
      life: 3000
    });
  } catch (e: any) {
    toast.add({
      severity: 'error',
      summary: "Error",
      detail: e,
      life: 3000
    });
  }
  reparseInProgress.value = false;
}

watch(() => dialogVisible.value, (nv) => {
  if (nv != null && !nv) {
    props.onHide(true);
  }
})

watch(() => props.id, async (nv) => {
  if (nv != undefined) {
    testDetailId.value = props.id;
    _fetchRptDetails(testDetailId.value ?? 0);
    // await _fetchManufacturedProductDetails(testDetailId.value ?? 0);
    // await _fetchTestDetailList(serialNumber.value);
    isLoading.value = false;
  }
})

watch(() => props.visible, (nv) => {
  console.log("VISIBLE: ", nv);
  if (nv) {
    dialogVisible.value = nv;
  }
}, { deep: true, immediate: true });

</script>

<script lang="ts">
export default defineComponent({
  name: "RptTestDetailsComponent",
});
</script>

<style lang="css"> .details-label {
   text-decoration: underline;

 }
</style>
