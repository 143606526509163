
<template>
    <div
    v-if="!loading">
        <h1>Scan User Token</h1>
        <qrcode-stream 
            class="d-flex align-start justify-start mb-5"
            style="min-height: 400px !important"
            :track="scanLocked == false ? drawOutline : () => {}" 
            @init="onInit"
            @decode="onDecode" 
            @detect="onDetect" 
            :camera="camera">
        </qrcode-stream>

    </div>
</template>


<script lang="ts">

export default defineComponent({
    name: "UserScan",
    components: { 
    }
});
</script>

<script setup lang="ts">
import { defineComponent, type Ref, ref } from "vue";
import {useToast} from 'primevue/usetoast';
import { inject, toRef } from "vue";

const emit = defineEmits(["onUserScan"]);

const toast = useToast();

const dialogRef: any = inject('dialogRef');

enum DecodingState {
    Unloaded,
    Idle,
    Failed, 
    Processing, 
    Validating 
}

interface DecodedUserObject {
    SubjectId: String,
    Authority: String,
}

let loading = ref(false);
let camera = ref('auto');
let scanLocked = false;
let decodingState = ref(DecodingState.Unloaded)

const onDecode = (decoded: any) => {
    if (
        decodingState.value ==
        DecodingState.Processing &&
        !scanLocked
    ) {
        scanLocked = true;
        decodingState.value = DecodingState.Validating;

        camera.value = "off";
        validate(decoded);
    }
}

const validate = async (decodedJsonString: any) => {
    console.log(decodedJsonString);
    try {
        let decodedObject: DecodedUserObject = JSON.parse(decodedJsonString);
        dialogRef.value?.close(decodedObject);
        emit("onUserScan", decodedObject);

        toast.add({severity: 'success', 
            summary: 'User Scanned', 
            life: 3000});    
    } catch (error: any) {
        console.log(error);
        toast
        .add({severity:'error', 
            summary: 'Invalid QR Code', 
            life: 3000});
    }
}

const onInit = async (promise: any) => {
    if (decodingState.value != DecodingState.Unloaded) {
        return;
    }
    loading.value = true;
    try {
      const { capabilities } = await promise;
      // successfully initialized
      decodingState.value = DecodingState.Idle;
      console.log("Camera initialized!");
    } catch (error: any) {
      decodingState.value = DecodingState.Failed;
      toast
        .add({severity:'error', 
            summary: 'Camera unavailable', 
            life: 3000});

      if (error.name === "NotAllowedError") {
        // user denied camera access permisson
        console.log(error);
      } else if (error.name === "NotFoundError") {
        // no suitable camera device installed
        console.log(error);
      } else if (error.name === "NotSupportedError") {
        // page is not served over HTTPS (or localhost)
        console.log(error);
      } else if (error.name === "NotReadableError") {
        // maybe camera is already in use
        console.log(error);
      } else if (error.name === "OverconstrainedError") {
        // did you requested the front camera although there is none?
        console.log(error);
      } else if (error.name === "StreamApiNotSupportedError") {
        // browser seems to be lacking features
        console.log(error);
      }
    } finally {
      // hide loading indicator
    }
    loading.value = false;
}

const onDetect = (stream: string) => {

}

const drawOutline = (detectedCodes: any, ctx: any) => {
    if (decodingState.value != DecodingState.Processing &&
      !scanLocked) {
      decodingState.value = DecodingState.Processing;
    }


    if (!scanLocked) {
      for (const detectedCode of detectedCodes) {
        const [firstPoint, ...otherPoints] = detectedCode.cornerPoints;

        ctx.strokeStyle = "#fc4c02";

        ctx.beginPath();
        ctx.moveTo(firstPoint.x, firstPoint.y);
        for (const { x, y } of otherPoints) {
          ctx.lineTo(x, y);
        }
        ctx.lineTo(firstPoint.x, firstPoint.y);
        ctx.lineWidth = 5;
        ctx.closePath();
        ctx.stroke();
      }
    }
}

const resetScanner = () => {
    decodingState.value = DecodingState.Idle;
    scanLocked = false;
    camera.value = "auto";
  }

</script>

<style scoped>

</style>