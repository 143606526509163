/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum VoteStatus {
    NOT_VOTED = 'notVoted',
    APPROVED = 'approved',
    REJECTED = 'rejected',
}
