<template>
  <Dialog
    v-if="dialogVisible"
    v-model:visible="dialogVisible"
    modal
    :draggable="false"
    :header="headerText"
    position="top"
    style="max-width: 50%; min-width: 50%; max-height: 80%"
  >
    <template #default>
      <div class="flex flex-column gap-5 w-full">
        <div class="flex flex-row w-full gap-2">
          <div class="flex flex-column w-6">
            <label>Make</label>
            <InputText
              type="text"
              v-model="make"
              placeholder="Make of the asset"
            />
          </div>
          <div class="flex flex-column w-6">
            <label>Asset Name</label>
            <InputText
              type="text"
              v-model="assetName"
              placeholder="Name of the Asset"
              @keyup.enter="onSave"
            />
          </div>
        </div>
        <div class="flex flex-row gap-2">
          <div class="flex flex-column w-6">
            <label>Location</label>
            <Dropdown
              v-model="location"
              placeholder="Location of asset"
              @keyup.enter="onSave"
              :options="locations"
              optionLabel="locationName"
            />
          </div>
          <div class="flex flex-column w-6">
            <label>Tags</label>
            <MultiSelect
              v-model="tags"
              placeholder="Tags for the Asset"
              filter
              display="chip"
              :options="tagoptions"
              optionLabel="name"
            />
          </div>
        </div>
        <div class="flex flex-column w-6">
          <label>Asset Type</label>
          <Dropdown
            v-model="assetType"
            placeholder="Type of asset"
            @keyup.enter="onSave"
            :options="assetTypes"
            optionLabel="name"
          />
        </div>
      </div>
    </template>
    <template #footer>
      <div class="flex flex-row align-items-end justify-content-between">
        <Button
          class="p-button-text"
          label="Cancel"
          :onClick="
            () => {
              dialogVisible = false;
            }
          "
        />
        <Button class="p-button-primary" label="Save" :onClick="onSave" />
      </div>
    </template>
  </Dialog>
</template>
<script setup lang="ts">
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import Dropdown from "primevue/dropdown";
import { useToast } from "primevue/usetoast";
import MultiSelect from "primevue/multiselect";
import { ref, watch, type Ref } from "vue";
import {
  type AssetsCreateRequest,
  AssetsService,
  LocationsService,
  TagsService,
  AssetTypesService,
  type AssetsUpdateRequest,
} from "@/apis/luna-api";

const dialogVisible = ref(false);
const toast = useToast();
const make = ref("");
const assetName = ref("");
const tags: Ref<Array<any>> = ref([]);
const tagoptions: Ref<Array<any>> = ref([]);
const location: Ref<any> = ref(null);
const locations: Ref<Array<any>> = ref([]);
const assetType: Ref<any> = ref(null);
const assetTypes: Ref<Array<any>> = ref([]);
const headerText = ref("Create new Asset");

const props = defineProps<{
  visible: boolean;
  onHide: (edited: boolean) => any;
  asset?: any;
}>();

watch(
  () => props.visible,
  async (nv: any) => {
    console.log("VISIBLE: ", nv);
    await fetchDataAsync();
    if (nv) {
      dialogVisible.value = nv;
    }

    if (nv && props.asset && props.asset.assetName) {
      console.log("ASSET: ", props.asset);
      console.log("TAGS: ", props.asset.tags);
      headerText.value = "Edit Asset";
      assetName.value = props.asset.assetName;
      tags.value = mapTags();
      make.value = props.asset.make;
      location.value = mapLocation();
      assetType.value = mapAssetType();
      console.log("LOC: ", location.value);
      console.log("TYPE: ", assetType.value);
    }
  },
);
watch(
  () => dialogVisible.value,
  (nv: any) => {
    if (nv != null && !nv) {
      console.log("TRIGGER");
      headerText.value = "Create new Asset";
      assetName.value = "";
      tags.value = [];
      make.value = "";
      assetType.value = null;
      location.value = null;
      props.onHide(true);
    }
  },
);

const onSave = async () => {
  if (props.asset && props.asset.assetName) {
    await dispatchEditAssetAsync({
      id: props.asset.id,
      make: make.value,
      assetName: assetName.value,
      tagIds: tags.value.map((el) => el.id),
      locationId: location.value.id,
      assetTypeId: assetType.value.id,
    } as AssetsUpdateRequest);
    return;
  }

  await dispatchCreateAssetAsync({
    make: make.value,
    assetName: assetName.value,
    tagIds: tags.value.map((el) => el.id),
    locationId: location.value.id,
    assetTypeId: assetType.value.id,
  } as AssetsCreateRequest);
};

async function dispatchCreateAssetAsync(
  assetsCreateRequest: AssetsCreateRequest,
) {
  await AssetsService.postApiV1Assets(assetsCreateRequest);
  toast.add({
    severity: "success",
    summary: "Asset created",
    detail: "Asset created successfully",
    life: 3000,
  });
  dialogVisible.value = false;
}

async function fetchDataAsync() {
  let res = await LocationsService.getApiV1Locations();
  console.log("IAMCALLED");
  locations.value = res.locations!;
  let assetRes = await AssetTypesService.getApiV1Assettypes();
  assetTypes.value = assetRes.assetTypes!;

  let tagRes = await TagsService.getApiV1Tags();
  tagoptions.value = tagRes.tags!;
  console.log("TAGOPTS: ", tagoptions.value);
}

async function dispatchEditAssetAsync(
  assetsUpdateRequest: AssetsUpdateRequest,
) {
  await AssetsService.putApiV1Assets(props.asset.id, assetsUpdateRequest);
  dialogVisible.value = false;
}

function mapTags() {
  let res: Array<any> = [];
  console.log(tags);
  props.asset.tags.forEach((element: any) => {
    let opt = tagoptions.value.find((y) => y.id == element.id);
    if (opt) res.push(opt);
  });
  console.log("RES", res);
  return res;
}

function mapLocation() {
  return locations.value.find((y) => y.id == props.asset.location.id);
}

function mapAssetType() {
  return assetTypes.value.find((y) => y.id == props.asset.type.id);
}
</script>
