<template>
  <div class="flex flex-column ">

    <Toolbar>
      <template #start>
        <Button class="m-1" icon="pi pi-qrcode" label="QR Code" @click="showQr"></Button>
        <Button class="m-1" v-if="item?.item?.status?.signOutInformation == null" icon="pi pi-database" label="Sign Out"
          @click="showSignOut"></Button>
        <Button v-if="item?.item?.status?.signOutInformation != null" class="m-1" icon="pi pi-database" label="Return"
          @click="returnItem"></Button>
      </template>
    </Toolbar>

    <Dialog v-model:visible="displayQr">
      <template #header>
        <Button icon="pi pi-print" label="Print QR Code" @click="printDiv()"></Button>
      </template>
      <div id="printableArea">
        <Image :src="qrUrl" alt="QR Code"></Image>
      </div>
    </Dialog>

    <div class="mt-3 grid">
      <Card class="col lg:col xl:col sm:col-12 m-1">
        <template #title>General</template>
        <template #content>
          <ConDetailsItem title="Name" icon="pi pi-id-card" :content="item?.item?.name" />
          <ConDetailsItem divider title="Serialnumber" icon="pi pi-id-card" :content="item?.serialNumber" />
          <ConDetailsItem divider title="Location" icon="pi pi-compass" :content="item?.item?.location?.name" />
          <ConDetailsItem divider title="Department" icon="pi pi-building" :content="item?.item?.department?.name" />
          <ConDetailsItem divider title="Keep sample until" icon="pi pi-clock" :content="item?.keepUntil" />
          <ConDetailsItem divider title="Quantity" icon="pi pi-chart-line" :content="item?.item?.quantity?.toString() || '0'" />
          <ConDetailsItem divider title="Remark" icon="pi pi-bars" :content="item?.item?.remark" />

          <ConDetailsItem divider title="Expected return date" icon="pi pi-bars" v-if="item?.item?.status?.isSignedOut"
            :content="item?.item?.status?.signOutInformation?.expectedReturnDate" />
          <ConDetailsItem divider title="Expected return date" icon="pi pi-calendar-times" v-else
            :content="'Item is not currently signed out'" />

          <ConDetailsItem divider title="Signout Reason" icon="pi pi-question-circle"
            v-if="item?.item?.status?.isSignedOut && item?.item?.status?.signOutInformation?.remark"
            :content="item?.item?.status?.signOutInformation?.remark" />

        </template>
      </Card>
      <Card class="col lg:col xl:col sm:col-12 m-1">
        <template #title>Hardware Details</template>
        <template #content>
          <ConDetailsItem title="Hardware Revision" icon="pi pi-box" :content="item?.hardwareSample?.hardwareRevision" />
          <ConDetailsItem divider title="Lot" icon="pi pi-box" :content="item?.lot" />
          <ConDetailsItem divider title="Customer Revision" icon="pi pi-box" :content="item?.customerRevision" />
          <ConDetailsItem divider title="Bios" icon="pi pi-box" :content="item?.bios" />
          <ConDetailsItem divider title="Cmos" icon="pi pi-box" :content="item?.cmos" />
          <ConDetailsItem divider title="Ean" icon="pi pi-box" :content="item?.hardwareSample?.ean" />
          <ConDetailsItem divider title="Part Number" icon="pi pi-box" :content="item?.hardwareSample?.partNumber" />
        </template>
      </Card>
    </div>
  </div>
</template>

<script lang="ts">
export default defineComponent({
  name: "HardwareDetails",
  components: {},
});
</script>

<script setup lang="ts">
import { onMounted } from "vue";
import {
  type ManufacturedHardwareSampleGetResponse,
  ItemsService,
  ManufacturedHardwareSampleService,
  OpenAPI,
  SignOutService,
} from "@/apis/saturn-api";
import { defineComponent, type Ref, ref } from "vue";
import { useConfirm } from "primevue/useconfirm";
import { useRouter, useRoute } from "vue-router";
import {
  useKeycloak
} from "@congatec/authentication-lib";
import { AuthenticationObserver } from "@/base/AuthenticationObserver";
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import Image from 'primevue/image';
import Toolbar from 'primevue/toolbar';
import { ConDetailsItem } from '@congatec/primevue-components-lib';
import Card from "primevue/card";
import Divider from 'primevue/divider';

let loading = ref(true);
let authenticationService = useKeycloak();
let authObserver = new AuthenticationObserver(authenticationService);
const router = useRouter();
const route = useRoute();
const confirm = useConfirm();
const id = Number.parseInt((route.params["id"] as string) || "0");

const qrUrl = ref("");
const item: Ref<ManufacturedHardwareSampleGetResponse | null> = ref(null);
const displayQr = ref(false);

onMounted(() => {
});



const deleteHardware = () => {
  confirm.require({
    message: "Are you sure you want to proceed?",
    header: "Confirmation",
    icon: "pi pi-exclamation-triangle",
    accept: async () => {
      await ItemsService.deleteApiV1Items(item.value?.item?.id);
      await ManufacturedHardwareSampleService.deleteApiV1ManufacturedHardwareSample(
        id
      );

      router.back();
    },
    reject: () => {
      //callback to execute when user rejects the action
    },
    onHide: () => {
      //Callback to execute when dialog is hidden
    },
  });
};

const printDiv = () => {
  displayQr.value = false;
  var printContents = document.getElementById("printableArea")?.innerHTML;
  if (typeof printContents != "undefined") {
    var popupWin = window.open("", "_blank");
    if (popupWin) {
      popupWin.document.open();
      popupWin.document.write(
        "<html><head><title>Print preview</title></head><body>" +
        printContents +
        "</body></html>"
      );
      popupWin.document.close();
      popupWin.onload = function () {
        if (popupWin) {
          popupWin.print();
          popupWin.close();
        }
      };
    }
  }
};

let contentItems: Ref<any[]> = ref([]); /*ref([
  { title: "Location", value: "" },
  { title: "Department", value: "" },
  { title: "Keep until", value: "" },
  { title: "Remark", value: "" },
  { title: "Serial Number", value: "" },
  { title: "Hardware Revision", value: "" },
  { title: "Lot", content: "" },
  { title: "Customer Revision", content: "" },
  { title: "Bios", content: "" },
  { title: "Cmos", content: "" },
  { title: "Ean", content: "" },
  { title: "Part Number", content: "" },
  {
    title: "Expected Return Date",
    content: "Item is not signed out",
  },
  { title: "Remark", content: "" },
]);*/

const pushContent = (item: any) => {
  contentItems.value.push(item);
}

const loadItem = async () => {
  loading.value = true;
  item.value =
    await ManufacturedHardwareSampleService.getApiV1ManufacturedHardwareSample1(
      id
    );

  contentItems.value = [];
  pushContent({ key: 'location', label: 'Location', value: item.value.item?.location?.name, type: 'details' });


  qrUrl.value = `${OpenAPI.BASE}/api/v1/Items/${item.value?.item?.id}/GetQr`;

  console.log(item.value);
  loading.value = false;
};

authObserver.onLoggedIn(loadItem);

const showQr = () => {
  displayQr.value = true;
};

const showSignOut = () => {
  router.push(`/saturn/hw-signout/${item.value?.item?.guid}`);
};

const returnItem = async () => {
  await SignOutService.putApiV1SignOutReturn({
    guid: item.value?.item?.guid,
  });
  await loadItem();
};
</script>

<style scoped></style>
