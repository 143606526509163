<template>
  <div class="container">
    <LogsDetailsView
      :visible="showDetailsDialog"
      :id="selectedId"
      :onHide="
        (onDetailsclosed: any) => {
          showDetailsDialog = false;
        }
      "
      style="overflow: hidden"
    />
    <ConDataTable
      class="w-full"
      :columns="propMatching"
      filterDisplay="menu"
      :rowsPerPageOptions="[10, 25, 50]"
      :rows="25"
      :router="router"
      :route="route"
      title="Logs"
      :onReload="fetchDataFromServiceAsync"
      @onRowClick="onRowClick"
    >
    </ConDataTable>
  </div>
</template>

<script setup lang="ts">
import { ConDataTable } from "@congatec/primevue-components-lib";
import { useRoute, useRouter } from "vue-router";
import { LogEntryService } from "@/apis/hubspotgateway-api";
import { ref } from "vue";
import LogsDetailsView from "../../details/views/LogsDetailsView.vue";

const router = useRouter();
const route = useRoute();
const showDetailsDialog = ref<boolean>(false);
const selectedId = ref();

let propMatching = [
  {
    field: "content",
    header: "Content",
    dataType: "text",
    isFiltered: true,
    sortable: true,
    filterMeta: {
      operator: "and",
      constraints: [{ value: [], matchMode: "contains" }],
    },
  },
  {
    field: "isResolved",
    header: "Resolved",
    dataType: "boolean",
    isFiltered: true,
    sortable: true,
  },
  {
    field: "resolvedBy",
    header: "Resolved By",
    dataType: "numeric",
    isFiltered: true,
    sortable: true,
  },
];

async function fetchDataFromServiceAsync(
  filters: string,
  sorts: string,
  page: number,
  rows: number,
): Promise<any> {
  let res = await LogEntryService.getApiV1LogEntryLogEntriesList(
    filters,
    sorts,
    page,
    rows,
  );
  return {
    totalCount: res.totalCount,
    data: res.logEntries,
  };
}

async function onRowClick(data: any) {
  console.log("dataId", data.id);
  selectedId.value = data.id;
  console.log("selectedId", selectedId.value);
  showDetailsDialog.value = true;
  //router.push(`/eris/manufactured-product-details/${data.id}`);
}
</script>

<style scoped lang="scss">
* {
  margin: 0;
  padding: 0;
}
</style>
