import FileUpload from "../components/FileUpload.vue";
import DirectoryList from "../components/DirectoryList.vue";
import ArtifactList from "../components/artifact/ArtifactList.vue";
import BundlesList from "../components/bundle/BundlesList.vue";
import ReleaseRulesList from "../components/ReleaseRulesList.vue";
import FileList from "../components/FileList.vue";
import BundlesDetails from "../components/bundle/BundlesDetails.vue";
import Review from "../components/ReleaseReview.vue";
import ReviewArtifact from "../components/ReleaseReviewArtifact.vue";
import ReviewBundle from "../components/ReleaseReviewBundle.vue";
import { to } from "@congatec/primevue-components-lib";

const title = "con-AREADY"

const areadyRoutes = [
  {
    path: "/oberon/artifact/upload",
    name: "FileUpload",
    component: FileUpload,
    meta: {
      breadcrumb: [{ title: title }, { label: "File Upload" }],
    },
  },
  {
    path: "/oberon/bundle-list",
    name: "BundlesList",
    component: BundlesList,
    meta: {
      breadcrumb: [{ title: title }, { label: "Bundles" }],
    },
  },
  // {
  //   path: "/oberon/file-list",
  //   name: "FileList",
  //   component: FileList,
  //   meta: {
  //     breadcrumb: [{ title: title }, { label: "Directories" }],
  //   },
  // },

  {
    path: "/oberon/artifact-list",
    name: "ArtifactList",
    component: ArtifactList,
    meta: {
      breadcrumb: [{ title: title }, { label: "Artifacts" }],
    },
  },
  {
    path: "/oberon/releaserules-list",
    name: "ReleaseRulesList",
    component: ReleaseRulesList,
    meta: {
      breadcrumb: [{ title: title }, { label: "Release Rules" }],
    },
  },
  {
    path: "/oberon/bundle/:guid",
    name: "BundlesDetails",
    component: BundlesDetails,
    props: (route: any) => ({
      guid: route.params.guid,
    }),
    meta: {
      breadcrumb: [
        { title: title, command: to("/oberon/home") },
        { label: "Bundles List", command: to("/oberon/bundles") },
        { label: "Bundles Details" },
      ],
    },
  },
  {
    path: "/aready/artifact/review/:guid",
    name: "ReviewArtifact",
    component: ReviewArtifact,
    props: (route: any) => ({
      guid: route.params.guid,
    }),
    meta: {
      breadcrumb: [
        { title: "aReady", command: to("/aready/home") },
      ],
    },
  },
  {
    path: "/aready/bundle/review/:guid",
    name: "ReviewBundle",
    component: ReviewBundle,
    props: (route: any) => ({
      guid: route.params.guid,
    }),
    meta: {
      breadcrumb: [
        { title: "aReady", command: to("/aready/home") },
      ],
    },
  },
];

export default areadyRoutes;
