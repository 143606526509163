<template>
  <div>
    <Dialog v-model:visible="inputVisible" modal :header="inputModel?.id ? 'Edit Department' : 'New Department'">
      <div class="flex flex-column">
        <label for="nameInput">Department Name</label>
        <InputText autofocus v-model="inputModel.name" id="nameInput" placeholder="Department Name" class="mb-3" />
        <Dropdown v-model="selectedBranchOffice" :options="branchOffices" optionLabel="name"
          placeholder="Select a Branch Office" class="w-full md:w-14rem" />

        <Button class="mt-3" label="Save" @click="onSubmit"
          :disabled="!inputModel.name || !inputModel.officeId" />
      </div>
    </Dialog>

    <ConfirmDialog />

    <Toolbar>
      <template #start>
        <Button icon="pi pi-plus" class="mr-2" label="New" @click="onNewClick" />
      </template>
    </Toolbar>
    <ConDataTable class="m-3" ref="dataTableRef" :columns="columns" filterDisplay="menu" :onReload="onReload" title="Departments"
      :createActionMenu="createActionMenu" :rows="50" />
  </div>
</template>


<script setup lang="ts">
import { ConDataTable } from '@congatec/primevue-components-lib';
import { DepartmentsService, type DepartmentListResponse_Department, OfficesService, type OfficeListResponse_Office } from '@/apis/saturn-api';
import Dialog from 'primevue/dialog';
import Toolbar from 'primevue/toolbar';
import ConfirmDialog from 'primevue/confirmdialog';
import InputText from 'primevue/inputtext';
import { useConfirm } from "primevue/useconfirm";
import { onMounted, ref, watch, type Ref } from 'vue';
import Button from 'primevue/button';
import Dropdown from 'primevue/dropdown';

const confirm = useConfirm();
const dataTableRef = ref();
const inputVisible = ref(false);
const inputModel: Ref<DepartmentListResponse_Department> = ref({});
const selectedBranchOffice: Ref<OfficeListResponse_Office | null> = ref(null);
const branchOffices: Ref<OfficeListResponse_Office[]> = ref([]);

const columns = [
  {
    field: "name",
    header: "Name",
    dataType: "text",
    isFiltered: true,
    sortable: true,
  },
  {
    field: "officeName",
    header: "Branch Office",
    dataType: "text",
    isFiltered: true,
    sortable: true,
  },
  {
    field: "actionMenu"
  }
]

onMounted(async () => {
  branchOffices.value = (await OfficesService.getApiV1Offices("", "", 1, 1000)).data || [];
});

watch(selectedBranchOffice, () => {
  if (!inputModel.value) {
    return;
  }
  inputModel.value.officeId = selectedBranchOffice.value?.id || 0;
});

const onReload = async (filters: string, sorts: string, page: number, rows: number): Promise<any> => {
  return await DepartmentsService.getApiV1Departments(filters, sorts, page, rows);
}

const onSubmit = async () => {
  console.debug("Submitting ", inputModel.value);

  const req = { id: inputModel.value.id, officeId: inputModel.value.officeId, name: inputModel.value.name };
  if (!inputModel.value.id) {
    await DepartmentsService.postApiV1Departments(req);
  } else {
    await DepartmentsService.putApiV1Departments(req);
  }

  dataTableRef.value.forceReload();
  inputVisible.value = false;
}

const onNewClick = () => {
  selectedBranchOffice.value = null;
  inputModel.value = {};
  inputVisible.value = true;
}

const onEditClick = (row: any) => {
  selectedBranchOffice.value = null;
  inputModel.value = row;
  inputVisible.value = true;

  // pre-select branch office
  const biIndex = branchOffices.value.findIndex((x: any) => row.officeId == x.id);
  if (biIndex == -1) {
    throw "Branch office not found!";
  }

  selectedBranchOffice.value = branchOffices.value[biIndex];
}


const onDeleteClick = (row: any) => {
  confirm.require({
    message: 'Are you sure you want to delete this entry?',
    header: 'Confirmation',
    icon: 'pi pi-exclamation-triangle',
    rejectClass: 'p-button-danger',
    rejectLabel: 'Cancel',
    acceptLabel: 'Delete',
    accept: async () => {
      console.info(`Deleting department with id ${row.id}`);
      await DepartmentsService.deleteApiV1Departments(row.id);
      dataTableRef.value.forceReload();
   },
    reject: () => {
    }
  });
}

const createActionMenu = (row: any) => {
  return [
    {
      label: 'Edit',
      icon: 'pi pi-pencil',
      command: () => {
        onEditClick(row)
      }
    },
    {
      label: 'Delete',
      icon: 'pi pi-trash',
      command: () => {
        onDeleteClick(row)
      }
    },
  ];
}

</script>

