/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TagsCreateRequest } from '../models/TagsCreateRequest';
import type { TagsCreateResponse } from '../models/TagsCreateResponse';
import type { TagsDeleteResponse } from '../models/TagsDeleteResponse';
import type { TagsDetailsResponse } from '../models/TagsDetailsResponse';
import type { TagsListResponse } from '../models/TagsListResponse';
import type { TagsUpdateRequest } from '../models/TagsUpdateRequest';
import type { TagsUpdateResponse } from '../models/TagsUpdateResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TagsService {

    /**
     * @param requestBody 
     * @returns TagsCreateResponse Success
     * @throws ApiError
     */
    public static postApiV1Tags(
requestBody?: TagsCreateRequest,
): CancelablePromise<TagsCreateResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/tags',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param sieveModelFilters 
     * @param sieveModelSorts 
     * @param sieveModelPage 
     * @param sieveModelPageSize 
     * @returns TagsListResponse Success
     * @throws ApiError
     */
    public static getApiV1Tags(
sieveModelFilters?: string,
sieveModelSorts?: string,
sieveModelPage?: number,
sieveModelPageSize?: number,
): CancelablePromise<TagsListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/tags',
            query: {
                'SieveModel.Filters': sieveModelFilters,
                'SieveModel.Sorts': sieveModelSorts,
                'SieveModel.Page': sieveModelPage,
                'SieveModel.PageSize': sieveModelPageSize,
            },
        });
    }

    /**
     * @param id 
     * @returns TagsDetailsResponse Success
     * @throws ApiError
     */
    public static getApiV1Tags1(
id: number,
): CancelablePromise<TagsDetailsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/tags/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns TagsUpdateResponse Success
     * @throws ApiError
     */
    public static putApiV1Tags(
id: number,
requestBody?: TagsUpdateRequest,
): CancelablePromise<TagsUpdateResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/tags/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id 
     * @returns TagsDeleteResponse Success
     * @throws ApiError
     */
    public static deleteApiV1Tags(
id: number,
): CancelablePromise<TagsDeleteResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/tags/{id}',
            path: {
                'id': id,
            },
        });
    }

}
