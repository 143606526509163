<template>
  <Toast position="top-right" />
  <Dialog v-if="previewVisible" v-model:visible="previewVisible" modal header="Preview">
    <EmployeeCardPreview :state="userInfo" :selectedTemplateId="selectedTemplateId"></EmployeeCardPreview>
  </Dialog>
  <ConfirmDialog />
  <Toolbar>
    <template #start>
      <div class="flex flex-row gap-3">

        <Button icon="pi pi-calendar-minus" label="EmployeeCards" class="p-button-sm "
          @click="(e) => { toggleEmployeeCardsMenu(e); }" aria-haspopup="true" aria-controls="overlay_menu" />
        <TieredMenu ref="employeeCardsMenu" id="overlay_menu" :model="employeeCardsMenuItems" :loading="printing"
          :popup="true" />
      </div>

    </template>
  </Toolbar>

  <div class="m-5 mt-7">
    <ConDataTable tableId="EmployeeCardList" title="Employee Card List" :columns="employeeCardTableColumns"
      filterDisplay="menu" :rowsPerPageOptions="[10, 20, 50]" :selection="selectedEmployeeCards"
      :onReload="fetchEmployeeCardDataAsync" @onSelectionChanged="handleEmployeeCardSelectionChange"
      ref="employeeCardTableRef" :createActionMenu="createEmployeeCardActionMenu">
      <template #invalid="col">
        <Column :field="col.field" :header="col.header" dataType="boolean">
          <template #body="field">
            <i class="pi pi-check" style=" color: rgb(23, 201, 100)" v-if="field.data.invalid == false"></i>
            <i class="pi pi-times" style="color: rgb(218, 50, 8)" v-if="field.data.invalid == true"></i>
          </template>
          <template #filter="{ filterModel }">
            <TriStateCheckbox v-model="filterModel.value" />
          </template>
        </Column>
      </template>
    </ConDataTable>
  </div>
</template>
<script setup lang="ts">
import { ConDataTable, showError } from '@congatec/primevue-components-lib';
import Button from 'primevue/button';
import Toolbar from 'primevue/toolbar';
import TieredMenu from 'primevue/tieredmenu';
import { ref, watch } from 'vue';
import ConfirmDialog from 'primevue/confirmdialog';
import { useConfirm } from "primevue/useconfirm";
import Toast from 'primevue/toast';
import { useToast } from 'primevue/usetoast';
import { EmployeeCardService, ImageTemplateService, type ListEmployeeCardResponse_EmployeeCard } from '@/apis/haumea-api';
import { useRouter } from "vue-router";
import { useEmployeeCardStore } from "./store/EmployeeCardStore"
import Column from 'primevue/column';
import { useFileDownloadStatusStore, FileStatusStoreStatusType } from "@congatec/primevue-components-lib";
import { printBlob } from '@/shared/print';
import EmployeeCardPreview from '@/haumea/components/employeeCard/EmployeeCardPreview.vue'
import type { UserState } from '@/haumea/components/employeeCard/EmployeeCardPreview.vue';
import Dialog from 'primevue/dialog';
const store = useFileDownloadStatusStore();
const ecStore = useEmployeeCardStore();
const selectionEmpty = ref<boolean>(true);
const employeeCardsMenu = ref();
const router = useRouter();
const toast = useToast();
const selectedEmployeeCards = ref<Array<ListEmployeeCardResponse_EmployeeCard>>([]);
const employeeCardTableRef = ref<null | typeof ConDataTable>(null);
const printing = ref(false);
const selectedTemplateId = ref<any>("");
const userInfo = ref<UserState>({
  Id: undefined,
  Firstname: '',
  Lastname: '',
  Email: '',
  BranchOffice: '',
  ValidUntil: new Date('9999-12-31'),
  SelectedTemplateId: undefined,
  EmployeeImageBase64: null,
  CardType: undefined
});
const previewVisible = ref(false);
const employeeCardsMenuItems = ref([
  {
    label: 'Create',
    icon: 'pi pi-plus',
    command: () => {
      router.push({
        name: 'EmployeeCardCreator', params: { readOnly: "false" }
      })

    }
  },
  {
    label: 'Clone',
    icon: 'pi pi-copy',
    disabled: selectionEmpty,
    command: () => {
      const selectedIds = selectedEmployeeCards.value.map(ec => ec.id!);
      confirmClone(selectedIds);
    }
  },
  {
    label: 'Invalidate',
    icon: 'pi pi-times',
    disabled: selectionEmpty,
    command: () => {
      const selectedIds = selectedEmployeeCards.value.map(ec => ec.id!);
      confirmInvalidate(selectedIds);
    }
  },
  {
    label: 'Validate',
    icon: 'pi pi-check',
    disabled: selectionEmpty,
    command: () => {
      const selectedIds = selectedEmployeeCards.value.map(ec => ec.id!);
      confirmValidate(selectedIds);
    }
  },
  {
    label: 'Delete',
    icon: 'pi pi-trash',
    disabled: selectionEmpty,
    command: () => {
      const selectedIds = selectedEmployeeCards.value.map(ec => ec.id!);
      confirmDelete(selectedIds);
    }
  },
  {
    label: 'Create PDF',
    icon: 'pi pi-file-pdf',
    disabled: selectionEmpty,
    command: () => {
      onGeneratePdf(selectedEmployeeCards.value);
    }
  },
  {
    label: 'Print PDF',
    icon: 'pi pi-file-pdf',
    disabled: selectionEmpty,
    command: () => {
      onPrintPdf(selectedEmployeeCards.value);
    }
  }
]);

function toggleEmployeeCardsMenu(e: any) {
  ecStore.clear();
  employeeCardsMenu.value.toggle(e);
}

const employeeCardTableColumns = [
  {
    selectionMode: 'multiple',
    field: 'any'
  },
  {
    field: "guid",
    header: "Guid",
    dataType: "text",
    isFiltered: true,
    sortable: true,
  },
  {
    field: "firstName",
    header: "First Name",
    dataType: "text",
    isFiltered: true,
    sortable: true,
  },
  {
    field: "lastName",
    header: "Last Name",
    dataType: "text",
    isFiltered: true,
    sortable: true,
  },
  {
    field: "eMail",
    header: "E-Mail",
    dataType: "text",
    isFiltered: true,
    sortable: true,
  },
  {
    field: "branchOffice",
    header: "Branch Office",
    dataType: "text",
    isFiltered: true,
    sortable: true,
  },
  {
    field: "invalid",
    header: "Valid",
    dataType: "boolean",
    isFiltered: true,
    sortable: true,
  },
  {
    field: "validUntil",
    header: "Valid Until",
    dataType: "date",
    isFiltered: true,
    sortable: true,
  },
  {
    field: "lastPrintDate",
    header: "Last Print",
    dataType: "date",
    isFiltered: true,
    sortable: true,
  },
  {
    field: "actionMenu",
  }
]

async function fetchEmployeeCardDataAsync(filters: string, sorts: string, page: number, rows: number): Promise<any> {

  let res = await EmployeeCardService.getApiV1EmployeeCard(filters, sorts, page, rows);
  console.log("Resolution: ", res)
  return {
    totalCount: res.totalCount,
    data: res.employeeCards,
  }
}

function handleEmployeeCardSelectionChange(selectedRows: any) {
  selectedEmployeeCards.value = selectedRows;
  console.log("selectedEmployeeCards: ", selectedEmployeeCards.value);
}




watch(() => selectedEmployeeCards.value, (nv) => {
  //console.log("selectedEmployeeCards:", nv.length)
  if (nv.length > 0) {
    selectionEmpty.value = false;
  } else {
    selectionEmpty.value = true;
  }
}, { deep: true })

function createEmployeeCardActionMenu(row: any): any {
  return [
    {
      label: 'Edit',
      icon: 'pi pi-pencil',
      //visible: row.printCount == 0,
      command: () => {
        selectedEmployeeCards.value = [];
        selectedEmployeeCards.value.push(row);
        ecStore.set(selectedEmployeeCards.value[0]);
        router.push({
          name: 'EmployeeCardCreator', params: { readOnly: "false" }
        })
      }
    },
    {
      label: 'View',
      icon: 'pi pi-eye',
      command: () => {
        selectedEmployeeCards.value = [];
        selectedEmployeeCards.value.push(row);
        userInfo.value.Firstname = selectedEmployeeCards.value[0].firstName || '';
        userInfo.value.Lastname = selectedEmployeeCards.value[0].lastName  || '';
        userInfo.value.BranchOffice = selectedEmployeeCards.value[0].branchOffice  || '';
        userInfo.value.EmployeeImageBase64 = selectedEmployeeCards.value[0].employeeImage  || '';
        userInfo.value.CardType = selectedEmployeeCards.value[0].cardType || '';
        selectedTemplateId.value = selectedEmployeeCards.value[0].frontImageTemplateId;
        previewVisible.value = true;
      }
    },
    {
      label: 'Clone',
      icon: 'pi pi-copy',
      visible: row.printCount > 0,
      command: () => confirmClone([row.id])
    },
    {
      label: 'Invalidate',
      icon: 'pi pi-times',
      visible: row.invalid == false,
      command: () => confirmInvalidate([row.id])
    },
    {
      label: 'Validate',
      icon: 'pi pi-check',
      visible: row.invalid == true,
      command: () => confirmValidate([row.id])
    },
    {
      label: 'Delete',
      icon: 'pi pi-trash',
      command: () => confirmDelete([row.id])
    },
    {
      label: 'Create PDF',
      icon: 'pi pi-file-pdf',
      command: () => onGeneratePdf([row])
    },
    {
      label: 'Print PDF',
      icon: 'pi pi-file-pdf',
      command: () => onPrintPdf([row])
    },
  ];
}

const confirm = useConfirm();

const confirmClone = (ids: number[]) => {
  confirm.require({
    header: 'Clone Confirmation',
    icon: 'pi pi-info-circle',
    acceptClass: 'p-button-info',
    message: 'Are you sure you want to clone this Item(s)',
    accept: async () => {
      try {
        if (ids && ids.length > 0) {
          // clone API call await VisitorService.deleteApiV1VisitorDelete({ visitorIdsList: [row.id] });
          await EmployeeCardService.postApiV1EmployeeCardClone({ employeeIds: ids })
          toast.add({ severity: 'success', summary: 'Clone', detail: 'Item(s) cloned', life: 3000 });
          //selectedEmployeeCards.value = [];
          reloadEmployeeCardTableData();
        }
      } catch (ex: any) {
        toast.add({ severity: 'error', summary: 'Error!', detail: 'An error occured while cloning: ' + ex.message, life: 3000 });
      }
    }
  })
}

const confirmInvalidate = (ids: number[]) => {
  confirm.require({
    header: 'Invalidate Confirmation',
    icon: 'pi pi-info-circle',
    acceptClass: 'p-button-info',
    message: 'Are you sure you want to invalidate this Item(s)',
    accept: async () => {
      try {
        if (ids && ids.length > 0) {
          await EmployeeCardService.putApiV1EmployeeCardInvalidate({ ids: ids, value: true })
          toast.add({ severity: 'success', summary: 'Invalidate', detail: 'Item(s) invalidated', life: 3000 });
          reloadEmployeeCardTableData();
        }
      } catch (ex: any) {
        toast.add({ severity: 'error', summary: 'Error!', detail: 'An error occured while invalidating: ' + ex.message, life: 3000 });
      }
    }
  })
}

const confirmValidate = (ids: number[]) => {
  confirm.require({
    header: 'Validate Confirmation',
    icon: 'pi pi-info-circle',
    acceptClass: 'p-button-info',
    message: 'Are you sure you want to validate this Item(s)',
    accept: async () => {
      try {
        if (ids && ids.length > 0) {
          await EmployeeCardService.putApiV1EmployeeCardInvalidate({ ids: ids, value: false })
          toast.add({ severity: 'success', summary: 'Validate', detail: 'Item(s) validated', life: 3000 });
          reloadEmployeeCardTableData();
        }
      } catch (ex: any) {
        toast.add({ severity: 'error', summary: 'Error!', detail: 'An error occured while validating: ' + ex.message, life: 3000 });
      }
    }
  })
}

const confirmDelete = (ids: number[]) => {
  confirm.require({
    header: 'Delete Confirmation',
    icon: 'pi pi-info-circle',
    acceptClass: 'p-button-info',
    //TODO: implement option to see a list of items that will be deleted
    message: 'Are you sure you want to delete this Item(s)',
    accept: async () => {
      try {
        if (ids && ids.length > 0) {
          // delete API call await VisitorService.deleteApiV1VisitorDelete({ visitorIdsList: [row.id] });
          await EmployeeCardService.deleteApiV1EmployeeCardDelete({ ids: ids })
          toast.add({ severity: 'success', summary: 'Delete', detail: 'Item(s) deleted', life: 3000 });
          selectedEmployeeCards.value.length = 0;
          reloadEmployeeCardTableData();
        }
      } catch (ex: any) {
        toast.add({ severity: 'error', summary: 'Error!', detail: 'An error occured while deleting: ' + ex.message, life: 3000 });
      }
    }
  })
}

function reloadEmployeeCardTableData() {
  employeeCardTableRef.value?.forceReload();
}

const onGeneratePdf = async (selectedRows: any[]) => {
  if (selectedRows.includes(null)) {
    return;
  }
  const id = Date.now().toString();
  const ids: number[] = selectedRows.map(row => row.id)
  store.add({
    id,
    name: selectedRows.length > 1 ? `EmployeeCards_${Date.now()}.pdf` : `EmployeeCard_${selectedRows[0].firstName}_${selectedRows[0].lastName}`,
    url: "",
    status: FileStatusStoreStatusType.DOWNLOADING,
  });

  try {
    const res = await ImageTemplateService.postApiV1ImageTemplateMakeId({ employeeCardIds: ids });
    const data =
      Uint8Array.from(atob(res.content || ""), (c) => c.charCodeAt(0));
    const url = window.URL.createObjectURL(
      new Blob([data], {
        endings: "transparent",
        type: res.mimeType || "",
      })
    );

    store.update({
      id: id,
      changes: {
        url: url,
        status: FileStatusStoreStatusType.DOWNLOADED,
      },
    });
  } catch (ex: any) {
    console.error(ex);
    showError(toast, "Error", ex);
    store.update({
      id,
      changes: {
        url: "",
        status: FileStatusStoreStatusType.ERROR,
      },
    });

  }
  reloadEmployeeCardTableData();
}

const onPrintPdf = async (selectedRows: any[]) => {
  printing.value = true;
  try {
    const ids: number[] = selectedRows.map(row => row.id)
    const res = await ImageTemplateService.postApiV1ImageTemplateMakeId({ employeeCardIds: ids });
    const data =
      Uint8Array.from(atob(res.content || ""), (c) => c.charCodeAt(0));

    printBlob(new Blob([data], {
      endings: "transparent",
      type: res.mimeType || "",
    }));
  } catch (exception: any) {
    console.error(exception);
  } finally {
    printing.value = false;
    reloadEmployeeCardTableData();
  }

}

</script>
