/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OfficeCreateRequest } from '../models/OfficeCreateRequest';
import type { OfficeCreateResponse } from '../models/OfficeCreateResponse';
import type { OfficeDeleteResponse } from '../models/OfficeDeleteResponse';
import type { OfficeGetResponse } from '../models/OfficeGetResponse';
import type { OfficeListResponse } from '../models/OfficeListResponse';
import type { OfficeUpdateRequest } from '../models/OfficeUpdateRequest';
import type { OfficeUpdateResponse } from '../models/OfficeUpdateResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class OfficesService {

    /**
     * @param requestBody 
     * @returns OfficeCreateResponse Success
     * @throws ApiError
     */
    public static postApiV1Offices(
requestBody?: OfficeCreateRequest,
): CancelablePromise<OfficeCreateResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/Offices',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param ids 
     * @returns OfficeDeleteResponse Success
     * @throws ApiError
     */
    public static deleteApiV1Offices(
ids?: Array<number>,
): CancelablePromise<OfficeDeleteResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/Offices',
            query: {
                'Ids': ids,
            },
        });
    }

    /**
     * @param sieveModelFilters 
     * @param sieveModelSorts 
     * @param sieveModelPage 
     * @param sieveModelPageSize 
     * @returns OfficeListResponse Success
     * @throws ApiError
     */
    public static getApiV1Offices(
sieveModelFilters?: string,
sieveModelSorts?: string,
sieveModelPage?: number,
sieveModelPageSize?: number,
): CancelablePromise<OfficeListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/Offices',
            query: {
                'SieveModel.Filters': sieveModelFilters,
                'SieveModel.Sorts': sieveModelSorts,
                'SieveModel.Page': sieveModelPage,
                'SieveModel.PageSize': sieveModelPageSize,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns OfficeUpdateResponse Success
     * @throws ApiError
     */
    public static putApiV1Offices(
requestBody?: OfficeUpdateRequest,
): CancelablePromise<OfficeUpdateResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/Offices',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id 
     * @returns OfficeGetResponse Success
     * @throws ApiError
     */
    public static getApiV1Offices1(
id: number,
): CancelablePromise<OfficeGetResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/Offices/{id}',
            path: {
                'id': id,
            },
        });
    }

}
