<template>
  <div class="w-full m-5 mt-7">
    <HardwareDetailsComponent
      :visible="showDetailsDialog"
      :id="selectedId"
      :onHide="onDetailsClosed"
    />
    <ConDataTable
      class="w-full"
      :columns="propMatching"
      filterDisplay="menu"
      :rowsPerPageOptions="[10, 25, 50]"
      :rows="25"
      :router="router"
      :route="route"
      title="Manufactured Products"
      :onReload="fetchDataFromServiceAsync"
      @onRowClick="onRowClick"
    >
    </ConDataTable>
  </div>
</template>

<script setup lang="ts">
import { defineComponent, ref } from "vue";
import { ManufacturedProductsService } from "@/apis/eris-api";
import { ConDataTable } from "@congatec/primevue-components-lib";
//import mapSieveFilterOperators from "@congatec/primevue-components-lib";
import { useRoute, useRouter } from "vue-router";
import { HardwareDetailsComponent } from "@/eris/components/hardware-detail-component";

const router = useRouter();
const route = useRoute();
const showDetailsDialog = ref<boolean>(false);
const selectedId = ref();
let propMatching = [
  {
    field: "serialnumber",
    header: "Serial Number",
    dataType: "numeric",
    isFiltered: true,
    sortable: true,
  },
  {
    field: "lot",
    header: "Lot",
    isFiltered: true,
    sortable: true,
  },
  {
    field: "packageRevision",
    header: "Package Revision",
    dataType: "numeric",
    isFiltered: true,
    sortable: true,
  },
  {
    field: "customerRevision",
    header: "Customer Revision",
    dataType: "numeric",
    isFiltered: true,
    sortable: true,
  },
  {
    field: "productionOrder",
    header: "PO",
    dataType: "text",
    isFiltered: true,
    sortable: true,
    filterMeta: {
      operator: "and",
      constraints: [{ value: [], matchMode: "contains" }],
    },
  },
  {
    field: "biosFile",
    header: "Bios",
    isFiltered: true,
    sortable: true,
  },
  {
    field: "cmos",
    header: "Cmos",
    isFiltered: true,
    sortable: true,
  },
  {
    field: "cgBcFwFile",
    header: "CgBcFwFile",
    dataType: "text",
    isFiltered: true,
    sortable: true,
    filterMeta: {
      operator: "and",
      constraints: [{ value: [], matchMode: "contains" }],
    },
  },
];

async function fetchDataFromServiceAsync(
  filters: string,
  sorts: string,
  page: number,
  rows: number,
): Promise<any> {
  let res = await ManufacturedProductsService.getApiV1ManufacturedProducts1(
    filters,
    sorts,
    page,
    rows,
  );
  return {
    totalCount: res.totalCount,
    data: res.manufacturedProducts,
  };
}

async function onRowClick(data: any) {
  console.log("dataId", data.id);
  selectedId.value = data.id;
  console.log("selectedId", selectedId.value);
  showDetailsDialog.value = true;
  //router.push(`/eris/manufactured-product-details/${data.id}`);
}

async function onDetailsClosed() {
  selectedId.value = null;
  showDetailsDialog.value = false;
}
</script>

<script lang="ts">
export default defineComponent({
  name: "HardwareListComponent",
});
</script>
