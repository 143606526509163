<template>
  <Dialog v-if="dialogVisible" v-model:visible="dialogVisible" modal :draggable="false" :header="headerText"
    position="top" style="max-width: 80%; min-width: 80%; max-height: 80%;">
    <template #default>
      <div class="flex flex-column">
        <div class="flex flex-row justify-content-center gap-6 w-full">
          <div class="flex flex-column mt-6 gap-2">

            <div class="flex flex-column gap-2">
              <div class="w-full flex flex-column">
                <label v-if="props.visit == null" for="visitorBox">There will be a visit created for the following
                  Visitors: </label>
                <label v-else for="visitorBox">There will be a visit edited for the following Visitors: </label>
                <ListBox class="w-auto text-sm" id="visitorBox" :options="visitorData" disabled optionLabel="data">
                </ListBox>
              </div>
            </div>

            <div class="flex flex-row gap-2">
              <div class="w-full flex flex-column">
                <label for="branchOffice">Branch Office</label>
                <Dropdown id="branchOffice" v-model="branchOffice" :options="branchOffices" optionValue="value" @change="filterMeetingRooms"
                  optionLabel="name" placeholder="Select Branch Office" class="w-exact" />
              </div>
            </div>

            <div class="w-full flex flex-column">
              <label class="w-exact" for="room">Meeting Room</label>
              <div class="flex flex-row gap-4 w-exact">
                <Dropdown @change="() => { showReservedMessage = false }" class="w-9" id="room" v-model="selectedRoom"
                  :options="rooms!" optionValue="emailAdress" optionLabel="displayName" showClear
                  placeholder="Select a Meeting Room" />
                <Button class="w-3 text-sm" label="Check availability" @click="() => {
                  checkMeetingRoomAvailability();
                }" />
              </div>
            </div>

            <div class="flex flex-row">
              <Message class="mb-0" v-if="showReservedMessage" :severity="reservedMessageSeverity">{{ reservedMessageText }}
              </Message>
            </div>

            <div class="w-exact">
              <label>Responsible Employee</label>
              <AutoComplete dropdown class="w-full" v-model="responsibleQuery" :suggestions="usersFound"
                optionLabel="identifier" @item-select="selectResponsible" @clear="clearResponsible" />
            </div>
            <div class="w-exact">
              <label>Additional Attendees</label>
              <AutoComplete dropdown class="w-full" v-model="userQuery" :suggestions="usersFound" optionLabel="identifier"
                @item-select="selectUser" />
              <MultiSelect v-model="additionalAttendees" :options="additionalAttendees" display="chip"
                placeholder="Select Attendees" optionLabel="email" class="w-full mt-3" />
            </div>


            
          </div>

          <div class="flex flex-column">
            <div v-if="props.visit == null"
              class="flex flex-column gap-2 align-items-center justify-content-center gap-3">
              <TabMenu :model="tabMenuItems" activeItem="{{  active Tab }}" @chang="onTabChange"></TabMenu>
              <div v-if="activeTab === 'singleDay'">
                <Calendar v-model="state.scheduledCheckInOutDates" placeholder="Select Single/Multiple Visit Day(s)"
                  dateFormat="dd.M.yy" hourFormat="12" inline selectionMode="multiple" />
              </div>
              <div v-if="activeTab === 'multipleDays'">
                <Calendar v-model="scheduledStartEndDates" placeholder="Select Range of Visit Days" dateFormat="dd.M.yy"
                  hourFormat="12" inline selectionMode="range" />
              </div>
              <div v-for="(x, index) in state.scheduledCheckInOutDates" :key="x.toString()"
                class="flex flex-row gap-4 w-exact">
                <label class="flex align-items-center w-3" :for="'calendar-CheckIn' + index">{{
                  state.scheduledCheckInOutDates[index].toLocaleDateString() }}</label>
                <Calendar :id="'calendar-CheckIn' + index" placeholder="Check In Time"
                  v-model="state.scheduledCheckInTime[index]" timeOnly hourFormat="12" />
                <Calendar :id="'calendar-CheckOut' + index" placeholder="Check Out Time"
                  v-model="state.scheduledCheckOutTime[index]" timeOnly hourFormat="12" />
              </div>
            </div>
            <div v-if="props.visit != null" class="flex flex-column gap-2 mt-6 align-items-center justify-content-center gap-3">
              <Calendar v-model="scheduledStartEndDate" placeholder="Select Single Visit Day(s)" dateFormat="dd.M.yy"
                hourFormat="12" inline />
                <div v-if="props.visit != null" class="flex flex-row gap-4 w-exact">
                  <Calendar id="calendar-CheckIn" placeholder="Scheduled Check In Time" v-model="state.scheduledCheckInTime[0]"
                  timeOnly hourFormat="12" />
                  <Calendar id="calendar-CheckOut" placeholder="Scheduled Check Out Time" v-model="state.scheduledCheckOutTime[0]"
                  timeOnly hourFormat="12" />
                </div>
              </div>
          </div>

        </div>
      </div>



      <!-- <div class="flex flex-column w-4 align-items-start">
        <div class="flex flex-row">
          <Message class="mb-0" v-if="showReservedMessage" :severity="reservedMessageSeverity">{{ reservedMessageText }}
          </Message>

        </div>
      </div> -->

    </template>
    <template #footer>
      <div class="flex flex-1 align-items-end justify-content-between">
        <Button class="p-button-text" label="Cancel" @click="() => {
          dialogVisible = false;


        }" />

        <ConMessages class="" v-if="v$.scheduledCheckInOutDates.$invalid" severity="error">
          <template #messageContent>
            {{ "Check-In Date " + v$.$errors[0].$message }}
          </template>
        </ConMessages>
        <ConMessages class="" v-else-if="v$.scheduledCheckInTime.$invalid" severity="error">
          <template #messageContent>
            {{ "Check-In Time " + v$.$errors[0].$message }}
          </template>
        </ConMessages>
        <ConMessages class="" v-else-if="v$.scheduledCheckOutTime.$invalid" severity="error">
          <template #messageContent>
            {{ "Check-Out Time " + v$.$errors[0].$message }}
          </template>
        </ConMessages>

        <Button class="p-button-primary" label="Save" :onClick="async () => {
          if (props.visit) {
            scheduledStartEndDate.setHours(state.scheduledCheckInTime[0].getHours(), state.scheduledCheckInTime[0].getMinutes(), 0);
            await dispatchEditVisitAsync({
              visitId: props.visit.id,
              //scheduledCheckIn: new Date(Date.UTC(scheduledStartEndDate.getFullYear(), scheduledStartEndDate?.getMonth(), scheduledStartEndDate?.getDate(), scheduledStartEndDate?.getHours(), scheduledStartEndDate?.getMinutes(), scheduledStartEndDate?.getSeconds(), scheduledStartEndDate?.getMilliseconds())).toISOString(),
              scheduledCheckIn: scheduledStartEndDate.toString(),
              scheduledCheckOut: new Date(Date.UTC(scheduledStartEndDate.getFullYear(), scheduledStartEndDate?.getMonth(), scheduledStartEndDate?.getDate(), scheduledStartEndDate?.getHours(), scheduledStartEndDate?.getMinutes(), scheduledStartEndDate?.getSeconds(), scheduledStartEndDate?.getMilliseconds())).toISOString(),
              scheduledCheckInTime: convertToUTCDateString(state.scheduledCheckInTime[0].toString()),
              scheduledCheckOutTime: convertToUTCDateString(state.scheduledCheckOutTime[0].toString()),
              substituteIds: [],
              meetingRoom: rooms!.filter(room => {
                return room.emailAdress === selectedRoom
              })[0],
              branchOffice: branchOffice,
              editForAllAttendees: editForAllAttendees,
              visitAttendeeIds: additionalAttendees.map((x: any) => x.id),
              responsibleEmployeeId: responsibleEmployee?.id
            })
            return;
          }

          await dispatchCreateVisitAsync({
            scheduledCheckInOutDates: state.scheduledCheckInOutDates.map((x, index) => { x.setHours(state.scheduledCheckInTime[index].getHours(), state.scheduledCheckInTime[index].getMinutes(), 0); return x.toString() }),
            // scheduledCheckInOutDates: state.scheduledCheckInOutDates.map((x) => {
            //   var date = new Date(x);
            //   return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds())).toISOString()
            // }),
            scheduledCheckInTime: state.scheduledCheckInTime.map((x) => { return convertToUTCDateString(x.toString()) }),
            scheduledCheckOutTime: state.scheduledCheckOutTime.map((x) => { return convertToUTCDateString(x.toString()) }),
            meetingRoom: rooms!.filter(room => {
              return room.emailAdress === selectedRoom
            })[0],
            branchOffice: branchOffice,
            visitor: visitorIdList.map((x: number) => {
              return {
                substituteIds: [],
                visitorId: x,
              }
            }),
            visitAttendeeIds: additionalAttendees.map((x: any) => x.id),
            responsibleEmployeeId: responsibleEmployee?.id
          })
        }" />
      </div>
    </template>
  </Dialog>
</template>
<script setup lang="ts">
import Dialog from 'primevue/dialog'
import { type Ref, ref, watch, onMounted } from 'vue';
import Button from 'primevue/button';
import { VisitService, VisitorService, type CreateVisitRequest, type ListVisitorResponse_Visitors, MicrosoftGraphService, type EditVisitRequest, type ListVisitResponse_Visits, UserService, type Room, type ListPlacesResponse } from '@/apis/neptune-api';
import ListBox from 'primevue/listbox';
import Calendar from 'primevue/calendar';
import TabMenu from 'primevue/tabmenu'
import Dropdown from 'primevue/dropdown';
import Message from 'primevue/message';
import { ConDateTimeHelper, ConMessages, delay, useDelayTimer } from '@congatec/primevue-components-lib';
import { useVuelidate } from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators'
import MultiSelect from 'primevue/multiselect'
import AutoComplete from 'primevue/autocomplete'

// Dataelements (constants / variables) area

const state = ref({
  scheduledCheckInOutDates: Array<Date>(),
  scheduledCheckInTime: Array<Date>(), //Array<Date>(new Date()),
  scheduledCheckOutTime: Array<Date>(), //Array<Date>(new Date(Date.now() + 1 * (60 * 60 * 1000)))
});

const dialogVisible = ref(false);
const visitorData = ref([{}]);
const visitorIdList = ref<number[]>([]);
const activeTab = ref('singleDay');
// const scheduledCheckInTime = ref();
// const scheduledCheckOutTime = ref();
// const scheduledCheckInOutDates = ref<string[]>([]);
const scheduledStartEndDates = ref<Date[]>([]);
const scheduledStartEndDate = ref<Date>(new Date());
const branchOffice = ref();
//const substituteIds = ref();
const visitorIds = ref();
const editForAllAttendees = ref(true);
const tabMenuItems = [
  { label: 'Single/ Multiple Days', icon: 'pi pi-calendar', command: () => setActiveTab('singleDay') },
  { label: 'Range of Days', icon: 'pi pi-calendar-plus', command: () => setActiveTab('multipleDays') },
]
//props definition
const props = defineProps<{
  visible: boolean,
  visitors?: ListVisitorResponse_Visitors[],
  visit?: ListVisitResponse_Visits,
  onHide: (created: boolean) => any
}>();

let headerText = "Create a new Visit";


const selectedRoom = ref();
// DONE get rooms from GraphAPI
let rooms: Ref<Room[] | null | undefined> = ref([]);
// const rooms = ref([
//   { id: 0, name: "No meeting room required", value: null },
//   { id: 1, name: "Elbe", value: "Elbe" },
//   { id: 2, name: "Donau", value: "Donau" },
//   { id: 3, name: "Isar", value: "Isar" },
//   { id: 4, name: "Main", value: "Main" },
//   { id: 5, name: "Lech", value: "Lech" },
//   { id: 6, name: "Regen", value: "Regen" },
//   { id: 7, name: "Vils", value: "Vils" },
//   { id: 8, name: "Alz", value: "Alz" },
//   { id: 9, name: "Inn", value: "Inn" },
//   { id: 10, name: "Laber", value: "Laber" },
//   { id: 11, name: "Alishan", value: "Alishan" },
//   { id: 12, name: "Hehuanshan", value: "Hehuanshan" },
//   { id: 13, name: "OldTown", value: "OldTown" },
//   { id: 14, name: "Yangmingshan", value: "Yangmingshan" },
//   { id: 15, name: "Yushan", value: "Yushan" },
// ]);

onMounted(() => {
  fetchRooms();
});

const branchOffices = ref([
  { id: 1, name: "Deggendorf, CDE", value: "Deggendorf" },
  { id: 2, name: "Plzen, CCZ", value: "Plzen" },
  { id: 3, name: "Brno, CCZ", value: "Brno" },
  { id: 4, name: "Taipei City, CTW", value: "Taipei City" },
  { id: 5, name: "San Diego, CUS", value: "San Diego" },
  { id: 6, name: "West Burleigh, CAU", value: "West Burleigh" },
  { id: 7, name: "Tokyo, CJP", value: "Tokyo" },
  { id: 8, name: "Shanghai, CCN", value: "Shanghai" },
  { id: 9, name: "Seongnam, CKR", value: "Seongnam" },
  { id: 10, name: "London, CUK", value: "London" },
  { id: 11, name: "Paris, CFR", value: "Paris" },
  { id: 12, name: "Ravensburg, RTS", value: "Ravensburg" },
  { id: 13, name: "Wittlich, RTS", value: "Wittlich" }
]);

const meetinRoomReservedDate: Ref<string | undefined> = ref();
const showReservedMessage = ref(false);
const reservedMessageSeverity = ref("success");
const reservedMessageText = ref("Meetingroom is available")
const additionalAttendees = ref<any>([]);
const userQuery = ref<any>("");
const responsibleQuery = ref<any>("");
const usersFound = ref<any[]>([]);
const userQueryTimer = useDelayTimer();
const responsibleEmployee = ref<any>("");

const emits = defineEmits(['visitCreated'])

const gteCheckInTime = (checkout: Array<string>) => checkout.every((x, index) => { return new Date(x) >= new Date(state.value.scheduledCheckInTime[index]); });

const rules = {
  scheduledCheckInOutDates: { required },
  scheduledCheckInTime: { required },
  scheduledCheckOutTime: { required, gteCheckInTime: helpers.withMessage(() => ` must be greater than or equal to Checkin-Time`, gteCheckInTime), $lazy: true },
  //description: { required },
}
const v$ = useVuelidate(rules, state, { $lazy: true });

//watch area
watch(() => props.visible, async (nv: any) => {
  console.log("VISIBLE: ", nv);
  if (nv != undefined) {
    dialogVisible.value = nv;
  }
  if (props.visit) {
    visitorData.value = [];
    headerText = "Edit Visit";
    if (props.visit?.scheduledCheckIn) {
      const date = new Date(props.visit.scheduledCheckIn);
      state.value.scheduledCheckInTime[0] = ConDateTimeHelper.utcToLocaleDate(date) as any;
      scheduledStartEndDate.value = ConDateTimeHelper.utcToLocaleDate(date);
    }
    if (props.visit?.scheduledCheckOut) {
      const date = new Date(props.visit.scheduledCheckOut);
      state.value.scheduledCheckOutTime[0] = ConDateTimeHelper.utcToLocaleDate(date) as any;
    }
    if (props.visit.eventId) {
      let visitors = await VisitorService.getApiV1VisitorFindByEventId(props.visit.eventId);
      visitors.visitors?.forEach((visitor) => {
        if(typeof visitor.email === 'undefined') {
          visitor.email = "";
        }
        visitorData.value.push({ data: "First Name: " + visitor.firstName + ", Last Name: " + visitor.lastName + ", E-Mail: " + visitor.email });
      })
    }
    else {
      let email: string|null = "";
      if(typeof props.visit.visitorEmail !== 'undefined') {
          email = props.visit.visitorEmail;
        }
      visitorData.value.push({ data: "First Name: " + props.visit.visitorFirstName + ", Last Name: " + props.visit.visitorLastName + ", E-Mail: " + email });
    }
    branchOffice.value = props.visit.branchOffice;
    let room = rooms.value?.filter((room: Room) => {
      return room.displayName === props.visit?.meetingRoom
    })[0];
    selectedRoom.value = room?.emailAdress;
    //substituteIds.value = props.visit.substitutes;
    additionalAttendees.value = props.visit.visitAttendees?.filter((attendee) => attendee.responsible == false);
    responsibleEmployee.value = props.visit.visitAttendees?.find((attendee) => attendee.responsible == true);
    if(responsibleEmployee.value) {
      if(typeof responsibleEmployee.value.email === 'undefined') {
        responsibleQuery.value = null;
      }
      else {
        responsibleQuery.value = responsibleEmployee.value.email;
      }
    }
    visitorIds.value = props.visit.visitorId;
  }
}, { deep: true, immediate: true });

watch(() => dialogVisible.value, (nv) => {
  if (nv != null && !nv) {
    showReservedMessage.value = false;
    //selectedRoom.value = null;
    v$.value.$reset();
    state.value.scheduledCheckInOutDates = Array<Date>();
    state.value.scheduledCheckInTime = Array<Date>(new Date());
    state.value.scheduledCheckOutTime = Array<Date>(new Date(Date.now() + 1 * (60 * 60 * 1000)));
    responsibleQuery.value = null;
    userQuery.value = null;
    branchOffice.value = null;
    selectedRoom.value = null;
    props.onHide(true);
  }
})

watch(() => props.visitors, (nv) => {
  visitorData.value = [];
  visitorIdList.value = [];
  console.log("Visitors:", nv)
  if (nv != undefined) {
    nv.forEach((visitor) => {
      visitorData.value.push({ data: "First Name: " + visitor.firstName + ", Last Name: " + visitor.lastName + ", E-Mail: " + visitor.email })
      if (visitor.id !== undefined) {
        visitorIdList.value.push(visitor.id)
      }
    });
  }
})

watch(() => scheduledStartEndDates.value, () => {
  state.value.scheduledCheckInOutDates = [];
  for (let date = new Date(scheduledStartEndDates.value[0]); date <= scheduledStartEndDates.value[1]; date.setDate(date.getDate() + 1)) {
    const cloneDate = new Date(date);
    state.value.scheduledCheckInOutDates.push(cloneDate);
  }
})

watch(() => scheduledStartEndDate.value, () => {
  state.value.scheduledCheckInOutDates = [];
  state.value.scheduledCheckInOutDates.push(scheduledStartEndDate.value);
})

let scheduledCheckInOutDatesCopy = state.value.scheduledCheckInOutDates.slice();
watch(() => state.value.scheduledCheckInOutDates, () => {
  //console.log("watch scheduledCheckInOutDates ", state.value.scheduledCheckInOutDates);
  if(props.visit == null) {
    let missingIndexes = null;
    if (scheduledCheckInOutDatesCopy.length > state.value.scheduledCheckInOutDates.length) {
      // date was removed
      missingIndexes = findMissingIndexes(state.value.scheduledCheckInOutDates, scheduledCheckInOutDatesCopy);
      if (missingIndexes) {
        missingIndexes.forEach((index) => {
          state.value.scheduledCheckInTime.splice(index, 1);
          state.value.scheduledCheckOutTime.splice(index, 1);
          console.log("removed index ", index);
        });
      }
    }
    else if (scheduledCheckInOutDatesCopy.length < state.value.scheduledCheckInOutDates.length) {
      // date was added
      missingIndexes = findMissingIndexes(scheduledCheckInOutDatesCopy, state.value.scheduledCheckInOutDates);
      if (missingIndexes) {
        missingIndexes.forEach(() => {
          state.value.scheduledCheckInTime.push(new Date(state.value.scheduledCheckInTime[0] || new Date()));
          state.value.scheduledCheckOutTime.push(new Date(state.value.scheduledCheckOutTime[0] || new Date(Date.now() + 1 * (60 * 60 * 1000))));
        });
      }
    }
    console.log("state.value.scheduledCheckInTime: ", state.value.scheduledCheckInTime);
    scheduledCheckInOutDatesCopy = state.value.scheduledCheckInOutDates.slice();
  }
});

//functions area
async function dispatchCreateVisitAsync(CreateVisitRequest: CreateVisitRequest) {
  console.log("Visit Create: ", CreateVisitRequest);
  console.log("Time:", state.value.scheduledCheckInOutDates ? [0] : 0)
  await v$.value.$reset();
  await v$.value.$validate(); // checks all inputs
  if (v$.value.$error) {
    return;
  }
  await VisitService.postApiV1Visit(CreateVisitRequest);
  dialogVisible.value = false;
}

async function dispatchEditVisitAsync(EditVisitRequest: EditVisitRequest) {
  await v$.value.$reset();
  await v$.value.$validate(); // checks all inputs
  if (v$.value.$error) {
    return;
  }
  await VisitService.putApiV1VisitEdit(EditVisitRequest);
  dialogVisible.value = false;
}

function setActiveTab(tabName: string) {
  state.value.scheduledCheckInOutDates = [];
  scheduledStartEndDates.value = [];
  activeTab.value = tabName;
}

function onTabChange(e: any) {
  setActiveTab(e.item.label)
}


async function checkMeetingRoomAvailability() {
  if (props.visit != null) {
    state.value.scheduledCheckInOutDates = [];
    const date = scheduledStartEndDate.value;
    //console.log("scheduledStartEndDate", scheduledStartEndDate.value);
    state.value.scheduledCheckInOutDates.push(new Date(Date.UTC(date.getFullYear(), date?.getMonth(), date?.getDate(), date?.getHours(), date?.getMinutes(), date?.getSeconds(), date?.getMilliseconds())));
  }
  if (state.value.scheduledCheckInOutDates && state.value.scheduledCheckInOutDates.length > 0 && selectedRoom.value) {
    let isFree = true;
    showReservedMessage.value = false;
    for (var i = 0; i < state.value.scheduledCheckInOutDates.length; i++) {
      var date = state.value.scheduledCheckInOutDates[i]
      let fromTime = new Date(state.value.scheduledCheckInTime[i]);
      let toTime = new Date(state.value.scheduledCheckOutTime[i]);
      let tmpDate = new Date(date);
      let tmpFromDateTime = new Date(tmpDate.getFullYear(), tmpDate.getMonth(), tmpDate.getDate(), fromTime.getHours(), fromTime.getMinutes())
      let tmpToDateTime = new Date(tmpDate.getFullYear(), tmpDate.getMonth(), tmpDate.getDate(), toTime.getHours(), toTime.getMinutes())
      console.log("tmpFromDateTime: ", tmpFromDateTime);

      let response = await MicrosoftGraphService.getApiV1MicrosoftGraph(tmpFromDateTime.toISOString(), tmpToDateTime.toISOString(), selectedRoom.value)
      if (response.events && response.events[0]?.start) {
        isFree = false;
        meetinRoomReservedDate.value = response.events[0].start;
        break;
      }
    }

    if (isFree) {
      reservedMessageSeverity.value = "success";
      showReservedMessage.value = true;
      reservedMessageText.value = "Meeting Room is available";
    } else {
      reservedMessageSeverity.value = "error";
      showReservedMessage.value = true;
      var utc = new Date(meetinRoomReservedDate.value || "");
      // var offset = utc.getTimezoneOffset();
      // var local = new Date(utc.getTime() - offset * 60000);
      reservedMessageText.value = "Meeting Room is reserved on " + utc.toLocaleString();
    }
  }
}

watch(userQuery, async () => {
  delay(userQueryTimer, async () => {
    if (!userQuery.value) {
      usersFound.value = [];
      return;
    }

    let query = userQuery.value;

    usersFound.value = (await UserService.postApiV1UserFindUser(query)).users || [];

    for (let user of usersFound.value) {
      if (user.email) {
        user.identifier = user.email;
      } else {
        user.identifier = `${user.firstName} ${user.lastName}`
      }
    }
  });

});

const selectUser = () => {
  additionalAttendees.value.push(userQuery.value);
  userQuery.value = '';
}

watch(responsibleQuery, async () => {
  delay(userQueryTimer, async () => {
    if (!responsibleQuery.value) {
      usersFound.value = [];
      return;
    }

    let query = responsibleQuery.value;

    usersFound.value = (await UserService.postApiV1UserFindUser(query)).users || [];

    for (let user of usersFound.value) {
      if (user.email) {
        user.identifier = user.email;
      } else {
        user.identifier = `${user.firstName} ${user.lastName}`
      }
    }
  });

});

const selectResponsible = () => {
  responsibleEmployee.value = responsibleQuery.value;
  //userQuery.value = '';
}

const clearResponsible = () => {
  responsibleEmployee.value = null;
}

let initialRooms: ListPlacesResponse = {rooms:[]};
async function fetchRooms() {
  initialRooms = await MicrosoftGraphService.getApiV1MicrosoftGraphPlacesList();
  rooms.value = initialRooms.rooms;
}

const convertToUTCDateString = (strDate: string) => {
  var date = new Date(strDate);
  return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), 0, 0)).toISOString();
}

const addCheckInTime = () => {
  state.value.scheduledCheckInTime.push(new Date());
  console.log("addCheckInTime ", state.value.scheduledCheckInTime);
}

const removeCheckInTime = (index: number) => {
  state.value.scheduledCheckInTime.splice(index, 1);
  console.log("removeCheckInTime ", state.value.scheduledCheckInTime);
}

function findMissingIndexes(arr: any[], fullArr: any[]): number[] {
  const missingIndexes: number[] = [];
  fullArr.forEach((item, index) => {
    if (!arr.includes(item)) {
      missingIndexes.push(index);
    }
  });
  missingIndexes.sort((a, b) => b - a);
  return missingIndexes;
}

const filterMeetingRooms = (event: any) => {
  rooms.value = initialRooms?.rooms?.filter((room: Room) => room.address === null || room.address?.city == null || room.address?.city === event.value);
  // if(rooms.value?.length == 0) {
  //   rooms.value = initialRooms?.rooms?.filter((room: Room) => room.address === null || room.address?.city == null);
  // }
}

</script>
  
<style>
.w-exact {
  width: 359px;
}
</style>
