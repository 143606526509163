/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ProductDetailsResponse } from '../models/ProductDetailsResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ProductsService {

    /**
     * @param id 
     * @returns ProductDetailsResponse Success
     * @throws ApiError
     */
    public static getApiV1Products(
id: number,
): CancelablePromise<ProductDetailsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/products/{id}',
            path: {
                'id': id,
            },
        });
    }

}
