/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { HardwareSampleImportResponse } from '../models/HardwareSampleImportResponse';
import type { ManufacturedHardwareSampleCreateRequest } from '../models/ManufacturedHardwareSampleCreateRequest';
import type { ManufacturedHardwareSampleCreateResponse } from '../models/ManufacturedHardwareSampleCreateResponse';
import type { ManufacturedHardwareSampleDeleteResponse } from '../models/ManufacturedHardwareSampleDeleteResponse';
import type { ManufacturedHardwareSampleGetResponse } from '../models/ManufacturedHardwareSampleGetResponse';
import type { ManufacturedHardwareSampleListResponse } from '../models/ManufacturedHardwareSampleListResponse';
import type { ManufacturedHardwareSampleUpdateRequest } from '../models/ManufacturedHardwareSampleUpdateRequest';
import type { ManufacturedHardwareSampleUpdateResponse } from '../models/ManufacturedHardwareSampleUpdateResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ManufacturedHardwareSampleService {

    /**
     * @param requestBody 
     * @returns ManufacturedHardwareSampleCreateResponse Success
     * @throws ApiError
     */
    public static postApiV1ManufacturedHardwareSample(
requestBody?: ManufacturedHardwareSampleCreateRequest,
): CancelablePromise<ManufacturedHardwareSampleCreateResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/ManufacturedHardwareSample',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id 
     * @returns ManufacturedHardwareSampleDeleteResponse Success
     * @throws ApiError
     */
    public static deleteApiV1ManufacturedHardwareSample(
id?: number,
): CancelablePromise<ManufacturedHardwareSampleDeleteResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/ManufacturedHardwareSample',
            query: {
                'Id': id,
            },
        });
    }

    /**
     * @param sieveModelFilters 
     * @param sieveModelSorts 
     * @param sieveModelPage 
     * @param sieveModelPageSize 
     * @returns ManufacturedHardwareSampleListResponse Success
     * @throws ApiError
     */
    public static getApiV1ManufacturedHardwareSample(
sieveModelFilters?: string,
sieveModelSorts?: string,
sieveModelPage?: number,
sieveModelPageSize?: number,
): CancelablePromise<ManufacturedHardwareSampleListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/ManufacturedHardwareSample',
            query: {
                'sieveModel.Filters': sieveModelFilters,
                'sieveModel.Sorts': sieveModelSorts,
                'sieveModel.Page': sieveModelPage,
                'sieveModel.PageSize': sieveModelPageSize,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns ManufacturedHardwareSampleUpdateResponse Success
     * @throws ApiError
     */
    public static putApiV1ManufacturedHardwareSample(
requestBody?: ManufacturedHardwareSampleUpdateRequest,
): CancelablePromise<ManufacturedHardwareSampleUpdateResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/ManufacturedHardwareSample',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id 
     * @returns ManufacturedHardwareSampleGetResponse Success
     * @throws ApiError
     */
    public static getApiV1ManufacturedHardwareSample1(
id: number,
): CancelablePromise<ManufacturedHardwareSampleGetResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/ManufacturedHardwareSample/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param serialNumber 
     * @returns HardwareSampleImportResponse Success
     * @throws ApiError
     */
    public static getApiV1ManufacturedHardwareSampleImport(
serialNumber?: string,
): CancelablePromise<HardwareSampleImportResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/ManufacturedHardwareSample/import',
            query: {
                'SerialNumber': serialNumber,
            },
        });
    }

}
