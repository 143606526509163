import {
  HardwareDetailsComponent, HardwareListComponent, ManufacturedProductExportComponent
} from "@/eris/components";
// import { Sample } from "@/components/sample-component";
import { RptTestDetailListComponent } from "@/eris/components/rpt-testdetail-list-component";
import { RptTestDetailsComponent } from "@/eris/components/rpt-testdetail-component";
import { ErisHomeView } from "@/eris/components/home"
import { AdminRevisionView } from "@/eris/components/admin-svn-revision-component";
import { to } from "@congatec/primevue-components-lib";

const erisRoutes = [
  {
    path: '/eris/home',
    name: 'erisHome',
    component: ErisHomeView,
    meta: {
      breadcrumb: [
        { title: "Eris" },{label: "Home"}
      ]
    }
  },
  // {
  //   path: "/",
  //   name: "Dashboard",
  //   component: Sample,
  // },
  // {
  //   path: "/:catchAll(.*)",
  //   name: "Dashboard",
  //   component: Sample,
  // },
  {
    path: "/eris/rpt-testresult-list",
    name: "RptTestList",
    component: RptTestDetailListComponent,
    meta: {
      breadcrumb: [
        {title:"Eris",command:to("/eris/home")},
        { label: "RPT Test List" }
      ]
    }
  },
  {
    path: "/eris/rpt-test-details/:id",
    name: "RptTestDetails",
    component: RptTestDetailsComponent,
    props: (route: any) => ({
      params: route.params,
    }),
    meta: {
      breadcrumb: [
        {title: "Eris",command:to("/eris/home")},
        { label: "RPT Test List",command:to("/eris/rpt-testresult-list") },
        { label: "RPT Test Details" }
      ]
    }
  },
  {
    path: "/eris/admin-svn-revision",
    name: "SvnRevision",
    component: AdminRevisionView,
    meta: {
      breadcrumb: [
        {title: "Eris", command:to("/eris/home")},
        { label: "SVN Revisions Extraction" }
      ]
    }
  },
  {
    path: "/eris/manufactured-product-list",
    name: "ErisHardwareList",
    component: HardwareListComponent,
    meta: {
      breadcrumb: [
        { title: "Eris", command:to("/eris/home") },
        { label: "Hardware List" }
      ]
    }
  },
  {
    path: "/eris/product-export",
    name: "ManufacturedProductExport",
    component: ManufacturedProductExportComponent,
    meta: {
      breadcrumb: [
        { title: "Eris", command:to("/eris/home") },
        { label: "Manufactured Product Export" }
      ]
    }
  },
  {
    path: "/eris/manufactured-product-details/:id",
    name: "HardwareDetails",
    component: HardwareDetailsComponent,
    props: (route: any) => ({
      params: route.params,
    }),
    meta: {
      breadcrumb: [
        { title: "Eris", command:to("/eris/home")},
        { label: "Hardware List", command:to("/eris/manufactured-product-list") },
        { label: "Hardware Details" }
      ]
    }
  },
  
];

export default erisRoutes;
