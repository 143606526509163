/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TestDetailsResponse } from '../models/TestDetailsResponse';
import type { TestResultMessagesRptListResponse } from '../models/TestResultMessagesRptListResponse';
import type { TestResultRptCountResponse } from '../models/TestResultRptCountResponse';
import type { TestResultRptListResponse } from '../models/TestResultRptListResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TestResultsService {

    /**
     * @param id 
     * @returns TestDetailsResponse Success
     * @throws ApiError
     */
    public static getApiV1RptTestResultRptsSerialNumbers(
id: number,
): CancelablePromise<TestDetailsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/rpt-test-result/rpts/serial-numbers/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param serialNumbers 
     * @param sieveModelFilters 
     * @param sieveModelSorts 
     * @param sieveModelPage 
     * @param sieveModelPageSize 
     * @returns TestResultRptListResponse Success
     * @throws ApiError
     */
    public static getApiV1RptTestResultRpts(
serialNumbers?: Array<number>,
sieveModelFilters?: string,
sieveModelSorts?: string,
sieveModelPage?: number,
sieveModelPageSize?: number,
): CancelablePromise<TestResultRptListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/rpt-test-result/rpts',
            query: {
                'SerialNumbers': serialNumbers,
                'sieveModel.Filters': sieveModelFilters,
                'sieveModel.Sorts': sieveModelSorts,
                'sieveModel.Page': sieveModelPage,
                'sieveModel.PageSize': sieveModelPageSize,
            },
        });
    }

    /**
     * @param id 
     * @param sieveModelFilters 
     * @param sieveModelSorts 
     * @param sieveModelPage 
     * @param sieveModelPageSize 
     * @returns TestResultMessagesRptListResponse Success
     * @throws ApiError
     */
    public static getApiV1RptTestResultRptsMessages(
id: number,
sieveModelFilters?: string,
sieveModelSorts?: string,
sieveModelPage?: number,
sieveModelPageSize?: number,
): CancelablePromise<TestResultMessagesRptListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/rpt-test-result/rpts/{id}/messages',
            path: {
                'id': id,
            },
            query: {
                'SieveModel.Filters': sieveModelFilters,
                'SieveModel.Sorts': sieveModelSorts,
                'SieveModel.Page': sieveModelPage,
                'SieveModel.PageSize': sieveModelPageSize,
            },
        });
    }

    /**
     * @param fromDate 
     * @param toDate 
     * @returns TestResultRptCountResponse Success
     * @throws ApiError
     */
    public static getApiV1RptTestResultStatus(
fromDate?: string,
toDate?: string,
): CancelablePromise<TestResultRptCountResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/rpt-test-result/Status',
            query: {
                'FromDate': fromDate,
                'ToDate': toDate,
            },
        });
    }

}
