import { type AuthenticationService } from "@congatec/authentication-lib"
import { type RouteRecordRaw , type NavigationGuardNext } from "vue-router";
import { type Ref, ref } from "vue";

const _authenticationService: Ref<AuthenticationService | null | undefined> = ref(undefined);

export function addAuthToGuards(authenticationService: AuthenticationService) {
  _authenticationService.value = authenticationService;
}

export const checkRoles = (
  to: RouteRecordRaw ,
  from: RouteRecordRaw ,
  next: NavigationGuardNext,
  roles: string[]
) => {
  const loggedIn = _authenticationService.value?.getLoggedIn();
  if (loggedIn) {
    if (
      loggedIn.role &&
      roles.find(r => r.toLowerCase() == loggedIn.role?.toLowerCase())
    ) {
      next();
    } else {
      next(false);
    }
  } else {
    next(false);
  }
};
