<template>
  <Toast position="top-right" />
  <ConfirmDialog />
  <!-- <Dialog  v-model:visible="previewVisible" modal header="Preview">
    <EmployeeCardPreview :state="state" :selectedTemplate="selectedTemplate[0]."></EmployeeCardPreview>
  </Dialog> -->
  
    
  <div class="flex flex-row">
    <div class="flex flex-column gap-5 m-5 w-full">
      <h2>Employee Card Creator</h2>
      <div class="w-4 flex flex-column">
        <label for="cardTemplate">Card Template <span>*</span></label>
        <Dropdown id="cardTemplate" :disabled="readOnlyMode" v-model="state.SelectedTemplateId" :options="cardTemplates"
          optionValue="id" @change="onTemplateChange" optionLabel="name" placeholder="Select Card Template" 
          :class="{ 'input-invalid': v$.SelectedTemplateId.$invalid && v$.SelectedTemplateId.$error, 'h-3rem': true }" />
          <span v-if="v$.SelectedTemplateId.$error">{{
            v$.SelectedTemplateId.$errors[0].$message }} </span>
      </div>

      <div class="flex flex-row gap-5 w-full">
        <div class="w-full">
          <label for="firstname">Firstname <span>*</span></label>
          <!--<InputText id="firstname" v-model="state.Firstname" :value="state.Firstname"
                        placeholder="Enter employee firstname" class="w-full" /> -->
          <AutoComplete :disabled="readOnlyMode" id="firstname" class="w-full" placeholder="Enter employee firstname"
            v-model="state.Firstname" optionLabel="identifier" :suggestions="usersFound" @item-select="selectUser"
            :class="{ 'input-invalid': v$.Firstname.$invalid && v$.Firstname.$error, 'h-3rem': true }" />
          <span v-if="v$.Firstname.$error">{{
            v$.Firstname.$errors[0].$message }} </span>
        </div>
        <div class="w-full">
          <label for="lastname">Lastname <span>*</span></label>
          <!-- <InputText id="lastname" v-model="state.Lastname" placeholder="Enter employee lastname"
                        class="w-full" /> -->
          <AutoComplete :disabled="readOnlyMode" id="lastname" class="w-full" placeholder="Enter employee lastname"
            v-model="state.Lastname" optionLabel="identifier" :suggestions="usersFound" @item-select="selectUser"
            :class="{ 'input-invalid': v$.Lastname.$invalid && v$.Lastname.$error, 'h-3rem': true }" />
          <span v-if="v$.Lastname.$error">{{
            v$.Lastname.$errors[0].$message }} </span>
        </div>
      </div>
      <div class="flex flex-row gap-5 w-full">
        <div class="w-full">
          <label for="email">Email <span>*</span></label>
          <!-- <InputText id="email" v-model="state.Email" placeholder="Enter employee email" class="w-full" /> -->
          <AutoComplete :disabled="readOnlyMode" id="email" class="w-full" placeholder="Enter employee email"
            v-model="state.Email" optionLabel="identifier" :suggestions="usersFound" @item-select="selectUser"
            :class="{ 'input-invalid': v$.Email.$invalid && v$.Email.$error, 'h-3rem': true }" />
          <span v-if="v$.Email.$error">{{
            v$.Email.$errors[0].$message }} </span>
        </div>
        <div class="w-full">

        </div>
      </div>
      <div class="flex flex-column gap-5 w-full">
        <FileUpload :disabled="readOnlyMode" name="demo[]" ref="imageUpload" @upload="onImageUpload"
          :showUploadButton="false" :show-cancel-button="false" @select="onImageSelect" :multiple="false" accept="image/*" :maxFileSize="1000000"
          :class="{ 'input-invalid': v$.EmployeeImageBase64.$invalid && v$.EmployeeImageBase64.$error, 'h-3rem': true }">
          <template #empty>
            <p>Drag and drop files here to upload. <span>*</span></p>
          </template>
        </FileUpload>
        <span v-if="v$.EmployeeImageBase64.$error">{{
            v$.EmployeeImageBase64.$errors[0].$message }} </span>
      </div>
      <div class="flex flex-row gap-5 w-full">
        <div class=" flex flex-column w-full">
          <label for="branchOffice">Branch Office</label>
          <Dropdown id="branchOffice" :disabled="readOnlyMode" v-model="state.BranchOffice" :options="branchOffices"
            optionValue="name" optionLabel="name" placeholder="Select Branch Office" />
        </div>
        <div class=" flex flex-column w-full">
          <label for="validUntil">Valid until <span>*</span></label>
          <Calendar id="validUntil" :disabled="readOnlyMode" v-model="state.ValidUntil" placeholder="Select date"
            dateFormat="dd.M.yy" selectionMode="single"
            :class="{ 'input-invalid': v$.ValidUntil.$invalid && v$.ValidUntil.$error, 'h-3rem': true }" />
          <span v-if="v$.ValidUntil.$error">{{
            v$.ValidUntil.$errors[0].$message }} </span>
        </div>
      </div>
      <!-- <div class="flex flex-row gap-2 w-full">
            <div class=" flex flex-column w-2">
                <label for="validUntil">Valid until</label>
                <Calendar id="validUntil" v-model="state.validUntil" placeholder="Select date" dateFormat="dd.M.yy"
                    hourFormat="12" selectionMode="multiple"  />
            </div>
        </div> -->
      <div class=" flex flex-column-reverse w-full">
        <div class="flex flex-row gap-5 w-full">
          <!-- <Button class="p-button-sm" icon="pi pi-image" :disabled="!canGenerate()" label="Preview"
            :onClick="onPreview" /> -->
          <Button class="p-button-sm" icon="pi pi-save" label="Save" :disabled="readOnlyMode" :onClick="onSaveClick" />

          <Button class="p-button-sm" icon="pi pi-pencil" label="Edit Layout" :disabled="readOnlyMode || !state.Id"
            :onClick="onEditLayout" />
          <Button v-if="selectedTemplate && selectedTemplate[0].parentId != null" class="p-button-sm" icon="pi pi-trash"
            :disabled="readOnlyMode" label="Reset Layout" :onClick="onResetLayout" />

          <Button class="p-button-sm" icon="pi pi-file-pdf" :disabled="!canGenerate() || state.Id == null"
            label="Create PDF" :onClick="onGeneratePdf" />

          <Button class="p-button-sm" icon="pi pi-file-pdf" :disabled="!canGenerate() || state.Id == null"
            label="Print PDF" :onClick="onPrintPdf" :loading="printing" />
        </div>
      </div>
    </div>
    <div class="flex flex-column gap-5 m-5 w-full">
      <div class="flex flex-row gap-5 w-full">
        <!-- <Image v-if="state.SelectedTemplateId" :src="templateSrc" id="image" alt="Image"
          imageStyle="border: 1px solid; border-color: lightgrey" width="327px" height="504px" />
        <Image v-if="imageUrl" :src="imageUrl" id="image" alt="Image"
          imageStyle="border: 1px solid; border-color: lightgrey" width="252" /> -->
          <EmployeeCardPreview v-if="directPreviewVisible" v-model:visible="directPreviewVisible" :state="state" :selectedTemplateId="selectedTemplate[0].frontImageTemplate.id"></EmployeeCardPreview>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { type Ref, ref, watch, onMounted } from 'vue';
import Button from 'primevue/button';
import Calendar from 'primevue/calendar';
import Dropdown from 'primevue/dropdown';
import FileUpload from 'primevue/fileupload';
import Toast from 'primevue/toast';
import { useToast } from 'primevue/usetoast';
import { TemplateService, ImageTemplateService, EmployeeCardService, type CreateEmployeeCardRequest, UserService as HaumeaUserService, ImageFormat } from '@/apis/haumea-api';
import { useRoute, useRouter } from "vue-router";
import { useEmployeeCardStore } from "./store/EmployeeCardStore"
import { showError } from '@congatec/primevue-components-lib';
import AutoComplete from 'primevue/autocomplete';
import { useDelayTimer, delay } from '@congatec/primevue-components-lib';
import { useFileDownloadStatusStore, FileStatusStoreStatusType } from "@congatec/primevue-components-lib";
import { printBlob } from '@/shared/print';
import { useVuelidate } from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import ConfirmDialog from 'primevue/confirmdialog';
import { useConfirm } from "primevue/useconfirm";
import EmployeeCardPreview from '@/haumea/components/employeeCard/EmployeeCardPreview.vue';
import type { UserState } from '@/haumea/components/employeeCard/EmployeeCardPreview.vue';
import axios from 'axios';

const confirm = useConfirm();
const store = useFileDownloadStatusStore();
const router = useRouter();
const ecStore = useEmployeeCardStore();
const toast = useToast();
const route = useRoute();
//const selectedTemplateId: Ref<any> = ref();
const selectedTemplate: Ref<any> = ref("");
const cardTemplates: Ref<any> = ref([]);
const templateSrc = ref();
//const employeeImageBase64 = ref();
const readOnlyMode = ref(false)

const imageUrl = ref();
const imageUpload = ref();
const directPreviewVisible = ref(false);
const usersFound = ref<any[]>([]);
const userQueryTimer = useDelayTimer();

const printing = ref(false);

const options: any = {
  //weekday: 'short',
  year: 'numeric',
  month: 'short',
  day: '2-digit',
};

const state = ref<UserState>({
  Id: undefined,
  Firstname: '',
  Lastname: '',
  Email: '',
  BranchOffice: '',
  ValidUntil: new Date('9999-12-31'), //.toLocaleDateString(undefined, options).replace(/\s/g, ''),
  SelectedTemplateId: undefined,
  EmployeeImageBase64: null,
  CardType: undefined
});

//vuelidate 
const rules = {
  Firstname: { required },
  Lastname: { required },
  Email: { required, email },
  ValidUntil: { required },
  SelectedTemplateId: {required},
  EmployeeImageBase64: {required}
}
const v$ = useVuelidate(rules, state);



const onInit = async () => {
  const result = await TemplateService.getApiV1Template("isDisabled==false", "", 1, 1000, state.value.SelectedTemplateId || ecStore.employeeCard?.templateId);
  cardTemplates.value = result.templates;
  if (ecStore.employeeCard && ecStore.employeeCard.firstName) {
    state.value.Id = ecStore.employeeCard.id || undefined;
    console.debug(`State id: ${state.value.Id}`);
    state.value.Firstname = ecStore.employeeCard.firstName || "";
    state.value.Lastname = ecStore.employeeCard.lastName || "";
    state.value.Email = ecStore.employeeCard.eMail || "";
    state.value.BranchOffice = ecStore.employeeCard.branchOffice || "";
    state.value.CardType = ecStore.employeeCard.cardType || "";
    state.value.ValidUntil = new Date(ecStore.employeeCard.validUntil || '9999-12-31');//.toLocaleDateString(undefined, options).replace(/\s/g, '') || "";
    state.value.EmployeeImageBase64 = ecStore.employeeCard.employeeImage;
    state.value.SelectedTemplateId = ecStore.employeeCard.templateId;
    directPreviewVisible.value = true;
  }

  const url: string = 'https://fnc-prod-01.azurewebsites.net/api/httptriggerbranchoffices';

  try {
      const bo = await fetch(url);
      branchOffices.value = await bo.json();
      console.log("AzureFunctionResponseFetch", branchOffices.value);
      // const response = await axios.get(url);
      // console.log("AzureFunctionResponseAxios", response);
  } catch (exception: any) {
    toast.add({ severity: 'error', summary: 'Error', detail: 'An error occured: ' + exception.body.Errors[0].ErrorMessage, life: 3000 });
  }

  // if(ecStore.readOnly) {
  //   readOnlyMode.value = true;
  // }
  displaySelectedTemplateId();
  readOnlyMode.value = route.params.readOnly === 'true';
  
}

const displaySelectedTemplateId = () => {
  if (state.value.SelectedTemplateId) {
    selectedTemplate.value = cardTemplates.value.filter((tmp: any) => tmp.id === state.value.SelectedTemplateId);
    console.info("TemplateId: ", state.value.SelectedTemplateId, selectedTemplate.value);
    //imageUpload.value.uploadedFiles = 
    imageUrl.value = state.value.EmployeeImageBase64;
    templateSrc.value = "data:image/bmp;base64," + selectedTemplate.value[0].frontImageTemplate.content;

  }
}

onMounted(onInit);

// type UserState = {
//   Id: number | undefined,
//   Firstname: string,
//   Lastname: string,
//   Email: string,
//   BranchOffice: string,
//   ValidUntil: Date
//   SelectedTemplateId: any,
//   EmployeeImageBase64: any
// }

const branchOffices = ref();
// const branchOffices = ref([
//   { id: 1, name: "Deggendorf, CDE", value: "Deggendorf" },
//   { id: 2, name: "Plzen, CCZ", value: "Plzen" },
//   { id: 3, name: "Brno, CCZ", value: "Brno" },
//   { id: 4, name: "Taipei City, CTW", value: "Taipei City" },
//   { id: 5, name: "San Diego, CUS", value: "San Diego" },
//   { id: 6, name: "West Burleigh, CAU", value: "West Burleigh" },
//   { id: 7, name: "Tokyo, CJP", value: "Tokyo" },
//   { id: 8, name: "Shanghai, CCN", value: "Shanghai" },
//   { id: 9, name: "Seongnam, CKR", value: "Seongnam" },
//   { id: 10, name: "London, CUK", value: "London" },
//   { id: 11, name: "Paris, CFR", value: "Paris" },
//   { id: 12, name: "Ravensburg, RTS", value: "Ravensburg" },
//   { id: 13, name: "Wittlich, RTS", value: "Wittlich" }
// ]);


const onImageUpload = (event: any) => {
  toast.add({ severity: 'info', summary: 'Success', detail: 'File Uploaded', life: 3000 });
};

const onImageSelect = (event: any) => {
  console.log("selectEvent: ", event)
  imageUrl.value = event.files[0].objectURL;
  // set seleced files as uploadedFiles so that the status badge shows as completed
  imageUpload.value.uploadedFiles = imageUpload.value.files;
  imageUpload.value.files = [];
  toast.add({ severity: 'info', summary: 'Success', detail: 'File Uploaded', life: 3000 });
  convertImageToBase64(event.files[0]);
};

const onTemplateChange = (event: any) => {
  console.log("newTemplate: ", event.value);
  console.log("selectedTemplate.value: ", selectedTemplate.value)
  selectedTemplate.value = cardTemplates.value.filter((temp: { id: any; }) => { return temp.id === event.value })
  templateSrc.value = "data:image/bmp;base64," + selectedTemplate.value[0].frontImageTemplate.content;
  if(ecStore.employeeCard){
    //ecStore.employeeCard.cardType = selectedTemplate.value[0].cardType || "";
    state.value.CardType = selectedTemplate.value[0].cardType || "";
  }
  

}

const onSaveClick = async (event?:any,flag?:boolean) => {
  if(!flag){
    await v$.value.$reset();
    await v$.value.$validate(); // checks all inputs
    if (v$.value.$error) {
      throw 'Vuelidate failed!';
    }
  }
  
  const request: CreateEmployeeCardRequest = {
    firstName: state.value.Firstname,
    lastName: state.value.Lastname,
    eMail: state.value.Email,
    branchOffice: state.value.BranchOffice,

    validUntil: convertToUTCDateString(state.value.ValidUntil?.toString()),
    employeeImage: state.value.EmployeeImageBase64,
    templateId: state.value.SelectedTemplateId
  }
  try {
    let getResponse = await EmployeeCardService.getApiV1EmployeeCard(`eMail==${request.eMail},printCount==0`)
    if(state.value.Id == undefined && getResponse.totalCount &&  getResponse.totalCount > 0) {
      confirm.require({
        header: 'Update Confirmation',
        message: 'There already exists an employee card for this E-mail address! The card will be updated.',
        icon: 'pi pi-info-circle',
        rejectClass: 'p-button-secondary p-button-outlined p-button-sm',
        acceptClass: 'p-p-button-info p-button-sm',
        rejectLabel: 'Cancel',
        acceptLabel: 'Update',
        accept: async () => {
          await saveEmployeeCard(request);
        },
      });
    }
    else {
      await saveEmployeeCard(request);
    }
  }
  catch (ex: any) {
    toast.add({ severity: 'error', summary: 'Error', detail: 'An error occured: ' + ex.body.Errors[0].ErrorMessage, life: 3000 });
  }

  
}

const saveEmployeeCard = async (request: CreateEmployeeCardRequest) => {
  let res = await EmployeeCardService.postApiV1EmployeeCardCreate(request);
  state.value.Id = res.id || undefined;
  console.debug(`New id: ${state.value.Id}`);
  toast.add({ severity: 'success', summary: 'Success', detail: 'Employee Card saved.', life: 3000 });
  ecStore.set({employeeImage:state.value.EmployeeImageBase64,templateId:state.value.SelectedTemplateId,
    id: state.value.Id,firstName: state.value.Firstname,lastName: state.value.Lastname,
    branchOffice:state.value.BranchOffice,validUntil:state.value.ValidUntil.toString(),eMail: state.value.Email});
  console.log("yeah!", ecStore)
}

const convertToUTCDateString = (strDate: string) => {
  if (strDate == null) {
    strDate = '9999-12-31';
  }
  var date = new Date(strDate);
  return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds())).toISOString();
}

const convertImageToBase64 = async (imageFile: any) => {
  let blob = await fetch(imageFile.objectURL).then((r) => r.blob()); //blob:url
  const reader = new FileReader();
  reader.readAsDataURL(blob);

  reader.onloadend = async function () {
    state.value.EmployeeImageBase64 = reader.result as any as string;
  }
}

// const customBase64Uploader = async (event: any) => {
//     const file = event.files[0];
//     const reader = new FileReader();
//     let blob = await fetch(file.objectURL).then((r) => r.blob()); //blob:url

//     reader.readAsDataURL(blob);

//     reader.onloadend = function () {
//         const base64data = reader.result;
//     };
// };

watch(state.value, async () => {
  delay(userQueryTimer, async () => {
    if (!state.value) {
      usersFound.value = [];
      return;
    }
    let query = "";
    if (state.value.Firstname) {
      query = state.value.Firstname
    } else if (state.value.Lastname) {
      query = state.value.Lastname
    } else if (state.value.Email) {
      query = state.value.Email
    }

    usersFound.value = (await HaumeaUserService.postApiV1UserFindUser(query)).users || [];

    for (let user of usersFound.value) {
      if (user.email) {
        user.identifier = user.email;
      } else {
        user.identifier = `${user.firstName} ${user.lastName}`
      }
    }
  });

});

const selectUser = (item: any) => {
  console.log("Item:", item)
  state.value.Firstname = item.value.firstName,
    state.value.Lastname = item.value.lastName,
    state.value.Email = item.value.email
  let branchOfficeIndex = branchOffices.value.findIndex((e: any) => e.value === item.value.branchOffice)
  state.value.BranchOffice = branchOffices.value[branchOfficeIndex].name
}


const onGeneratePdf = async () => {
  if (state.value.Id == null) {
    return;
  }
  const id = Date.now().toString();
  store.add({
    id,
    name: `EmployeeCard_${state.value.Firstname}_${state.value.Lastname}_${Date.now()}.pdf`,
    url: "",
    status: FileStatusStoreStatusType.DOWNLOADING,
  });

  try {
    const res = await ImageTemplateService.postApiV1ImageTemplateMakeId({ employeeCardIds: [state.value.Id] });
    const data =
      Uint8Array.from(atob(res.content || ""), (c) => c.charCodeAt(0));
    const url = window.URL.createObjectURL(
      new Blob([data], {
        endings: "transparent",
        type: res.mimeType || "",
      })
    );

    store.update({
      id: id,
      changes: {
        url: url,
        status: FileStatusStoreStatusType.DOWNLOADED,
      },
    });
  } catch (ex: any) {
    console.error(ex);
    showError(toast, "Error", ex);
    store.update({
      id,
      changes: {
        url: "",
        status: FileStatusStoreStatusType.ERROR,
      },
    });

  }

}

const onPrintPdf = async () => {
  printing.value = true;


  try {
    const res = await ImageTemplateService.postApiV1ImageTemplateMakeId({ employeeCardIds: [state.value.Id || 0] });
    const data =
      Uint8Array.from(atob(res.content || ""), (c) => c.charCodeAt(0));
    printBlob(new Blob([data], {
      endings: "transparent",
      type: res.mimeType || "",
    }));
  } catch (exception: any) {
    console.error(exception);
  } finally {
    printing.value = false;
  }
}

const canGenerate = () => {
  return state.value.EmployeeImageBase64 && state.value.SelectedTemplateId
}

const onEditLayout = async () => {
  if (!state.value.Id) {
    return;
  }

  if (!selectedTemplate.value[0].parentId) {
    // if it is not already a duplicate, clone it 
    const res = await ImageTemplateService.putApiV1ImageTemplateClone({ employeeCardId: state.value.Id });
    if (ecStore.employeeCard) {
      ecStore.employeeCard.templateId = res.templateId;
    }
    state.value.SelectedTemplateId = res.templateId;

    await onInit();
    await onSaveClick(undefined,true);
    
  }
  router.push({ name: "ImageTemplateEditor", params: { templateId: selectedTemplate.value[0].frontImageTemplate.id || 0 } });
  
}

const onResetLayout = async () => {
  if (selectedTemplate.value[0].parentId == null) {
    return;
  }

  await TemplateService.deleteApiV1Template(selectedTemplate.value[0].id);
  if (ecStore.employeeCard) {
    ecStore.employeeCard.templateId = selectedTemplate.value[0].parentId;
  }
  state.value.SelectedTemplateId = selectedTemplate.value[0].parentId;

  displaySelectedTemplateId();
  await onSaveClick();
}

</script>

<style>
.p-autocomplete-input {
  width: 100%;
}
</style>
<style scoped>
.input-invalid {
    border: 1px solid var(--primary-color);
}

.input-invalid:hover {
    border: 1px solid var(--primary-color) !important;
}
span {
    color: var(--primary-color);
    font-weight: 600;
}
</style>

