<template>
    <ConDataTable :tableId="props.tableId" :title="props.title" :columns="visitorTableColumns" filterDisplay="menu"
      :rowsPerPageOptions="[10, 20, 50]" :onReload="props.fetchVisitorDataAsync"
      @onSelectionChanged="props.handleVisitorSelectionChange" ref="visitorTableRef" :createActionMenu="props.createVisitorActionMenu" globalFilter="firstName|lastName|email|company">
      <template #screeningStatus="col">
        <Column :field="col.field" :header="col.header">

          <template #body="field">
            <div class="flex justify-content-center">
              <i v-if="field.data[col.field] == 'notListed'" v-tooltip="'This visitor is not listed'"
                class="pi pi-thumbs-up-fill " style="color: rgb(23, 201, 100)" aria-label="screen success"></i>
              <i v-if="field.data[col.field] == 'listed'" v-tooltip="'This visitor needs to be checked by ECC department'" 
                class=" pi pi-thumbs-down-fill" style="color: rgb(242, 19, 93)" aria-label="screen failed"></i>
              <i v-if="field.data[col.field] == 'inReview'" v-tooltip="'Review pending'" class="pi pi-search"
                style="color: rgb(255, 130, 0)" aria-label="screen pending"></i>
            </div>
          </template>
        </Column>

      </template>
    </ConDataTable>
</template>
<script setup lang="ts">
import { ConDataTable } from '@congatec/primevue-components-lib';
import Column from 'primevue/column';
import { ref } from 'vue';

const visitorTableRef = ref<null | typeof ConDataTable>(null);

const props = defineProps<{
    //visitorTableColumns: any,
    tableId: string,
    title?: string,
    fetchVisitorDataAsync: (filters: string, sorts: string, page: number, rows: number) => any,
    handleVisitorSelectionChange: (selectedRows: any) => any,
    createVisitorActionMenu: (row: any) => any,
}>();

const visitorTableColumns = [
    {
        selectionMode: 'multiple',
        field: 'any'
    },
    {
        field: "screeningStatus",
        header: "ScreeningStatus",
        dataType: "text",
        isFiltered: true,
        sortable: true,
    },
    // {
    //   field: "Id",
    //   header: "Visitor Id",
    //   dataType: "text",
    //   isFiltered: true,
    //   sortable: true,
    // },
    {
        field: "firstName",
        header: "First Name",
        dataType: "text",
        isFiltered: true,
        sortable: true,
    },
    {
        field: "lastName",
        header: "Last Name",
        dataType: "text",
        isFiltered: true,
        sortable: true,
    },
    {
        field: "email",
        header: "E-Mail",
        dataType: "text",
        isFiltered: true,
        sortable: true,
    },
    // {
    //   field: "visitorstatus",
    //   header: "Visitor Status",
    //   dataType: "text",
    //   isFiltered: true,
    //   sortable: true,
    // },
    {
        field: "company",
        header: "Company",
        dataType: "text",
        isFiltered: true,
        sortable: true,
    },
    {
        field: "function",
        header: "Function",
        dataType: "text",
        isFiltered: true,
        sortable: true,
    },
    {
        field: "contactPerson",
        header: "Contactperson",
        dataType: "text",
        isFiltered: true,
        sortable: true,
    },
    {
        field: "postalCode",
        header: "Postalcode",
        dataType: "text",
        isFiltered: true,
        sortable: true,
    },
    {
        field: "city",
        header: "City",
        dataType: "text",
        isFiltered: true,
        sortable: true,
    },
    {
        field: "street",
        header: "Street",
        dataType: "text",
        isFiltered: true,
        sortable: true,
    },
    {
        field: "country",
        header: "Country",
        dataType: "text",
        isFiltered: true,
        sortable: true,
    },
    {
        field: "actionMenu",
    }
]

const forceReload = async (page: number | undefined = undefined, resetSelection: boolean | undefined = undefined) => {
    visitorTableRef.value?.forceReload(page, resetSelection);
}

defineExpose({ forceReload });

</script>