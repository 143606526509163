<template>
  <div class="flex flex-column">
    <div class="mt-3 grid">
      <Card class="col lg:col xl:col sm:col-12 m-3">
        <template #title>General Information</template>
        <template #content>
          <div>
            <div>
              <div class="container">
                <ConDetailsItem
                  title="Name"
                  icon="pi pi-info-circle"
                  :content="`${location.locationName}`"
                />
                <ConDetailsItem
                  title="Street Adress"
                  icon="pi pi-home"
                  :content="`${location.streetAdress}`"
                />
                <ConDetailsItem
                  title="Postal Code"
                  icon="pi pi-envelope"
                  :content="`${location.postalCode}`"
                />
                <ConDetailsItem
                  title="City"
                  icon="pi pi-map"
                  :content="`${location.city}`"
                />
                <ConDetailsItem
                  title="Country"
                  icon="pi pi-flag"
                  :content="`${location.country}`"
                />
                <ConDetailsItem
                  title="Region"
                  icon="pi pi-globe"
                  :content="`${location.region}`"
                />
              </div>
            </div>
          </div>
        </template>
      </Card>
    </div>
  </div>
</template>

<script setup lang="ts">
import Card from "primevue/card";
import { ConDetailsItem } from "@congatec/primevue-components-lib";
import { ref, onMounted, watch } from "vue";
import { LocationsService } from "@/apis/luna-api";
import Divider from "primevue/divider";
import Chip from "primevue/chip";

onMounted(async () => {
  fetchDataAsync();
});

var props = defineProps({
  id: Number,
});

watch(
  () => props.id,
  (nv) => {
    if (nv) {
      fetchDataAsync();
    }
  },
);

const location = ref({
  locationName: "",
  city: "",
  streetAdress: "",
  region: "",
  postalCode: "",
  country: "",
});

async function fetchDataAsync() {
  console.log("ID: ", props.id);
  var res = await LocationsService.getApiV1Locations1(props.id!);
  location.value.locationName = res.locationName ? res.locationName : "";
  location.value.city = res.city ? res.city : "";
  location.value.country = res.country ? res.country : "";
  location.value.streetAdress = res.streetAdress ? res.streetAdress : "";
  location.value.region = res.region ? res.region : "";
  location.value.postalCode = res.postalCode ? res.postalCode : "";
}
</script>

<style scoped lang="scss">
.container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
}
</style>
