<template>
  <div>
    <ProgressSpinner v-if="loading" />
    <div v-else class="m-3 flex flex-column flex-1 justify-content-between">
      <div>
        <span class="p-float-label mt-2 w-full">
          <Input id="templateName" v-model="data.name"
            :class="{ 'p-invalid': v$.name.$invalid && v$.name.$error, 'w-full': true }" />
          <label for="templateName">Template Name</label>
        </span>
      </div>

      <div>
        <span class="p-float-label mt-2 w-full">
          <Input id="subject" v-model="data.subject"
            :class="{ 'p-invalid': v$.subject.$invalid && v$.subject.$error, 'w-full': true }" />
          <label for="subject">Subject</label>
        </span>
      </div>

      <div class="p-inputgroup flex-1 flex-column mt-3">
        <span v-if="preview">
          <Editor v-if="preview" v-model="previewText" editorStyle="height: 17rem" readonly />
          <Button class="p-button-text" label="Edit" :onClick="() => preview = false" />
        </span>
        <span v-else>
          <Editor @load="onEditorLoad" v-model="data.emailText" editorStyle="height: 17rem" />
          <Button class="p-button-text" label="Preview" :onClick="showPreview" />
          <Dropdown :loading="variablesLoading" v-model="variable" :options="allVariables" placeholder="Select a Variable"
            class="w-full mt-3" />
        </span>
      </div>

      <div class="flex flex-row gap-2 mt-3">
        <div class="w-full flex flex-column">
          <label for="branchOffice">Branch Office</label>
          <Dropdown id="branchOffice" v-model="data.branchOffice" :options="branchOffices" optionValue="name"
            optionLabel="name" placeholder="Select Branch Office" class="w-exact" />
        </div>

      </div>
      <div class="flex mt-3">
        <Checkbox v-model="data.defaultTemplate" inputId="defaultTemplate" :binary="true" />
        <label for="defaultTemplate" class="ml-2">Set as the default template</label>
      </div>

      <div class="mt-3 mb-3">
        <h3 class="mb-3" v-if="data.sendNotification">Automatically send email</h3>
        <h3 class="mb-3" v-else>Do not automatically send email</h3>
        <InputSwitch class="mr-3" v-model="data.sendNotification" />
        <InputNumber v-model="notifyDaysBeforeVisit" :disabled="!data.sendNotification" mode="decimal" showButtons
          :min="1" :max="30" suffix=" days" />
        <h3 class="mt-3" v-if="data.sendNotification">prior to visit</h3>
      </div>

      <MultiSelect :loading="attachmentsLoading" v-model="data.attachments" display="chip" :options="allAttachments"
        optionLabel="fileName" placeholder="Select Attachments" class="w-full mt-3" />


      <div class="flex flex-row mt-5 justify-content-between">
        <Button class="p-button-text" label="Cancel" :onClick="onCancel" />
        <Button :disabled="v$.$invalid" class="p-button-primary" :label="submitLabel" :onClick="onSubmit" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { AttachmentType, EmailService } from '@/apis/neptune-api';
import { reactive, ref, watch, type Ref } from 'vue';
import Button from 'primevue/button';
import Input from 'primevue/inputtext';
import ProgressSpinner from 'primevue/progressspinner';
import { useToast } from 'primevue/usetoast';
import { canThrowAsyncShowToast } from '@congatec/primevue-components-lib';
import { useVuelidate, type Validation } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import MultiSelect from 'primevue/multiselect';
import { onKeycloakToken } from '@congatec/authentication-lib'
import Editor from 'primevue/editor'
import Dropdown from 'primevue/dropdown';
import InputSwitch from 'primevue/inputswitch';
import InputNumber from 'primevue/inputnumber';
import Checkbox from 'primevue/checkbox';

interface Attachment {
  id?: number;
  mimeType?: string;
  type?: AttachmentType;
  fileName?: string;
}

interface Template {
  id?: number,
  attachments?: Attachment[],
  emailText?: string,
  subject?: string,
  name?: string,
  notifySecondsBeforeVisit?: number,
  sendNotification?: boolean,
  branchOffice?: string,
  defaultTemplate?: boolean
}

const toast = useToast();
const props = defineProps<{
  template?: Template
}>()
const emit = defineEmits<{ (e: 'onSubmitted', attachment: Template): void, (e: 'onCancel'): void }>();
const loading = ref(false);
const variablesLoading = ref(false);
const attachmentsLoading = ref(false);
const preview = ref(false);
const previewText = ref("");
const variable = ref();
const allVariables = ref([]);
const quillEditor = ref();

const branchOffices = ref([
  { id: 1, name: "Deggendorf, CDE", value: "Deggendorf" },
  { id: 2, name: "Plzen, CCZ", value: "Plzen" },
  { id: 3, name: "Brno, CCZ", value: "Brno" },
  { id: 4, name: "Taipei City, CTW", value: "Taipei City" },
  { id: 5, name: "San Diego, CUS", value: "San Diego" },
  { id: 6, name: "West Burleigh, CAU", value: "West Burleigh" },
  { id: 7, name: "Tokyo, CJP", value: "Tokyo" },
  { id: 8, name: "Shanghai, CCN", value: "Shanghai" },
  { id: 9, name: "Seongnam, CKR", value: "Seongnam" },
  { id: 10, name: "London, CUK", value: "London" },
  { id: 11, name: "Paris, CFR", value: "Paris" },
  { id: 12, name: "Ravensburg, RTS", value: "Ravensburg" },
  { id: 13, name: "Wittlich, RTS", value: "Wittlich" }
]);

// TODO: We should really make this lazy-loaded
const allAttachments = ref([]);
let data = reactive<Template>({});

const validations = {
  name: { required },
  emailText: { required },
  subject: { required },
}


let submitLabel = ref('Create');

if (props.template) {
  data = props.template;
  submitLabel.value = 'Update';
}

if (!data.notifySecondsBeforeVisit) {
  data.notifySecondsBeforeVisit = 86400;
}

const notifyDaysBeforeVisit = ref(data.notifySecondsBeforeVisit / 86400);

let v$: Ref<Validation> = useVuelidate(validations, data as any);

watch(variable, () => {
  if (variable.value) {
    insertVariable(`{{${variable.value}}}`);
  }
  variable.value = undefined;
});

onKeycloakToken(async () => {
  attachmentsLoading.value = true;

  // FIXME: I know this request is bad... but I really just want *all* attachments at this point 
  // due to a lack of lazy loading. If there are at any point more than 10000 attachments then the reason why people cant find 
  // some attachments is here and I give you permission to hunt me down for the stupid bug I caused :^)
  let resAttachments = await canThrowAsyncShowToast(toast, async () => await EmailService.getApiV1EmailListAttachment("", "", 1, 10000));
  allAttachments.value = resAttachments.attachments;
  attachmentsLoading.value = false;

  // find existing attachments 
  let existing = data.attachments;
  if (data.attachments) {
    let ids = data.attachments.map((x: Attachment) => x.id || 0);
    data.attachments = allAttachments.value.filter((x: Attachment) => ids.includes(x.id || 0))
  }

  variablesLoading.value = true;
  let resVariables = await canThrowAsyncShowToast(toast, async () => await EmailService.getApiV1EmailTemplateVariablesList());
  allVariables.value = resVariables.variables;
  variablesLoading.value = false;
});

const showPreview = async () => {
  if (data.emailText) {
    let res = await canThrowAsyncShowToast(toast, async () => await EmailService.getApiV1EmailFormatEmail(data.emailText, 0, true));
    previewText.value = res.formatted;
  }
  preview.value = true;
}

const onCancel = () => {
  emit('onCancel');
}

const onSubmit = async () => {
  if (props.template) {
    await submitUpdate();
  } else {
    await submitCreate();
  }
  emit('onSubmitted', data);
}

const onEditorLoad = (event: any) => {
  quillEditor.value = event.instance;
}

const insertVariable = (v: string) => {
  let selection = quillEditor.value.getSelection(true);
  // we need to pretend this is inserted from the user's source 
  // because otherwise the primevue editor does not update the actual model 
  quillEditor.value.insertText(selection.index, v, "user");
}


const submitUpdate = async () => {
  let formCorrect = await v$.value.$validate();
  if (!formCorrect) {
    return;
  }
  await canThrowAsyncShowToast(toast, async () => await EmailService.putApiV1EmailUpdateTemplate({
    emailTemplateId: data.id,
    subject: data.subject,
    name: data.name,
    emailText: data.emailText,
    attachmentIds: data.attachments?.map((x: Attachment) => x.id || 0) || [],
    sendNotification: data.sendNotification,
    notifySecondsBeforeVisit: notifyDaysBeforeVisit.value * 86400,
    branchoffice: data.branchOffice,
    defaultTemplate: data.defaultTemplate
  }));
}

const submitCreate = async () => {
  let formCorrect = await v$.value.$validate();
  if (!formCorrect) {
    return;
  }

  await canThrowAsyncShowToast(toast, async () => await EmailService.postApiV1EmailCreateTemplate({
    subject: data.subject,
    name: data.name,
    emailText: data.emailText,
    attachmentIds: data.attachments?.map((x: Attachment) => x.id || 0) || [],
    sendNotification: data.sendNotification,
    notifySecondsBeforeVisit: notifyDaysBeforeVisit.value * 86400,
    branchoffice: data.branchOffice,
    defaultTemplate: data.defaultTemplate
  }));
}

</script>
