<template>
  <div class="flex  flex-column gap-2 align-items-center">
    <div class="align-self-center text-center ">
      <h1 class="text-5xl m-0 mt-5">Logfile Extractor</h1>
    </div>
    <div class="flex w-auto lg:w-8 m-3 ml-6 mr-6 gap-5 align-items-center justify-content-between hide-card-content">
      <Card v-if="isAdmin" class="hover:surface-100 col md:col-12 lg:col sm:col-12 cursor-pointer p-0  max-w-20rem h-15rem lg:h-9rem " @click="router.push({ path: '/eris/admin-svn-revision' })">
        <template #header>
          <div class="home-nav"></div>
        </template>
        <template #title><div class="w-full text-center">SVN Revisions Extraction</div></template>
        <template #subtitle>
          <div class="w-full text-center">
            Navigate to the SVN Revision Extractions
          </div>
          
        </template>
      </Card>

      <Card class="hover:surface-100 col md:col-12 lg:col sm:col-12 cursor-pointer p-0 max-w-20rem h-15rem lg:h-9rem" @click="router.push({ path: '/eris/rpt-testresult-list' })">
        <template #header>
          <div class="home-nav"></div>
        </template>
        <template #title><div class="w-full text-center">Test Results</div></template>
        <template #subtitle>
          <div class="w-full text-center">

            Navigate to the Test Results
          </div>
        </template>
      </Card>

      <Card class="hover:surface-100 col md:col-12 lg:col sm:col-12 cursor-pointer p-0 max-w-20rem h-15rem lg:h-9rem" @click="router.push({ path: '/eris/manufactured-product-list' })">
        <template #header>
          <div class="home-nav"></div>
        </template>
        <template #title><div class="w-full text-center">Manufactured Products</div></template>
        <template #subtitle>
          <div class="w-full text-center">
            Navigate to the list of manufactured products

          </div>
        </template>
      </Card>

      <Card class="hover:surface-100 col md:col-12 lg:col sm:col-12 cursor-pointer p-0 max-w-20rem h-15rem lg:h-9rem" @click="router.push({ path: '/eris/product-export' })">
        <template #header>
          <div class="home-nav"></div>
        </template>
        <template #title><div class="w-full text-center">Product Export</div></template>
        <template #subtitle>
          <div class="w-full text-center">

            Navigate to the product export
          </div>
        </template>
      </Card>
    </div>
    <div class="flex flex-column justify-content-start align-items-center ml-auto mr-auto w-6 lg:w-8">
      <!-- <Card class="w-full m-auto flex justify-content-center align-items-center chart-card" style="max-width:100%;">
      <template #title>
        <div class="w-full text-center">Test Result Count</div>
      </template>
      <template #content>
      </template>
    </Card> -->
    <h3 class="m-0">Test Result Content</h3>
    <div class="flex flex-row justify-content-center">
      <div class="m-3"><Calendar v-model="fromDate" showIcon dateFormat="dd/mm/yy" /></div>
      <div class="m-3"><Calendar v-model="toDate" showIcon dateFormat="dd/mm/yy" /></div>
    </div>
    <Chart class="w-full" type="bar" :data="chartData" />
    </div>
    
  </div>
</template>
<script setup lang="ts">
import { TestResultsService } from "@/apis/eris-api";
import Card from "primevue/card";
import { type Ref, ref, onMounted, watch } from "vue"
import Chart from "primevue/chart";
import Calendar from 'primevue/calendar';
import { onKeycloakToken } from "@congatec/authentication-lib";
import type { KeycloakService } from "@congatec/authentication-lib";
import { useRouter } from "vue-router";

const now = new Date();
const fromDate = ref( new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7) );
const toDate = ref( now );
const router = useRouter();
//let chartData: Ref<Record<string, number | null> | null> = ref({ labels: [], dataSets: [] });
let labels: Ref<Array<string>> = ref([]);
//let dataSets: Ref<Array<Array<Object>>> = ref([]);
let dataSet: Ref<Array<number | null> | null> = ref([]);
// const chartData = ref({ labels: labels.value, datasets: { label: "", backgroundColor: "#fc4c02", data: dataSet.value } });
const chartData = ref(); 

onMounted(() => {
  fetchDataFromServiceAsync();
});

const isAdmin = ref(false);
onKeycloakToken(async (authenticationService: KeycloakService ) => {
  isAdmin.value = authenticationService.hasRoles(["Admin", "SystemAdmin"],import.meta.env.VITE_KEYCLOAK_JUPITER_CLIENTID);
  
});


async function fetchDataFromServiceAsync() {
  console.log("fromDate: ", fromDate.value.toLocaleDateString("sv"));
  console.log("toDate: ", toDate.value.toLocaleDateString("sv"));
  TestResultsService.getApiV1RptTestResultStatus(fromDate.value.toLocaleDateString("sv"), toDate.value.toLocaleDateString("sv")).then((r) => {
    dataSet.value = [];
    if (r) {
      console.log("RESPONSE: ", r.testResultCountsByDate);
      if(r.testResultCountsByDate) {
        labels.value = Object.keys(r.testResultCountsByDate).map((key) => { return key.toString().split('T')[0] });
        Object.keys(r.testResultCountsByDate).forEach((key) => {
          if(r.testResultCountsByDate) {
            if(dataSet.value == null) {
              dataSet.value = [];
            }
            dataSet.value.push(r.testResultCountsByDate[key]);
          }
        })
        
        chartData.value = {
          labels: labels,
          datasets: [{ label: "Count", backgroundColor: "#fc4c02", data: dataSet.value }]
        }
        console.log("chartData: ", chartData.value);
      }
    }
  });
}

watch(fromDate, async () => {
  fetchDataFromServiceAsync();
})

watch(toDate, async () => {
  fetchDataFromServiceAsync();
})


// const basicData = {
//   labels: [
//     "January",
//     "February",
//     "March",
//     "April",
//     "May",
//     "June",
//     "July",
//     "August",
//   ],
//   datasets: [
//     {
//       label: "My First dataset",
//       backgroundColor: "#42A5F5",
//       data: [65, 59, 80, 81, 56, 55, 40, 40],
//     },
//     {
//       label: "My Second dataset",
//       backgroundColor: "#9CCC65",
//       data: [28, 48, 40, 19, 86, 27, 90, 10],
//     },
//   ],
// };
</script>
<style>
.chart-card .p-card-body{
  width:100%;
}

</style>