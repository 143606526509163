<template>
    <Dialog v-if="dialogVisible" v-model:visible="dialogVisible" modal :draggable="false" header="Edit Rule" position="top"
        style="max-width: 80%; min-width: 80%; max-height: 80%;">
        <template #default>
            <div class="card flex justify-content-left mb-5">
                <div class="flex flex-column gap-3 w-full">
                    <div class="flex flex-row gap-5 w-full align-items-center">
                        <div class="w-4">
                            <label for="rulename">Rule Name</label>
                            <InputText id="rulename" v-model="editRule.Name" placeholder="Enter the Rule Name"
                                class="w-full" />
                        </div>
                        <div class="w-auto flex flex-column">
                            <label for="entity">Entity </label>
                            <Dropdown id="entity" v-model="editRule.Entity" :options="Object.values(ReleaseRuleEntity)"
                                placeholder="Select an Entity" class="w-full md:w-14rem" />
                        </div>

                        <div class="w-auto">
                            <label>Default </label>
                            <Checkbox v-model="editRule.DefaultTable" :binary="true" />
                        </div>
                    </div>
                    <div class="flex flex-row gap-5 w-full">
                        <div class="w-auto flex flex-column">
                            <label for="onapprove">On Approve Status</label>
                            <Dropdown id="onapprove" v-model="editRule.OnApprove" :options="Object.values(ReleaseStatus)"
                                showClear placeholder="Select Approve Status" class="w-full md:w-14rem" />
                        </div>
                        <div class="w-auto flex flex-column">
                            <label for="onreject">On Reject Status</label>
                            <Dropdown id="onreject" v-model="editRule.OnReject" :options="Object.values(ReleaseStatus)"
                                showClear placeholder="Select Reject Status" class="w-full md:w-14rem" />
                        </div>
                    </div>
                    <div class="flex flex-row gap-5 w-full">
                        <div class="w-auto flex flex-column">
                            <label for="onapprove">On Approve Rule</label>
                            <Dropdown id="onapprove" v-model="editRule.OnApproveRuleGuid" :options="existingRules"
                                option-label="name" option-value="guid" showClear placeholder="Select Approve Rule"
                                class="w-full md:w-14rem" />
                        </div>
                        <div class="w-auto flex flex-column">
                            <label for="onreject">On Reject Rule</label>
                            <Dropdown id="onreject" v-model="editRule.OnRejectRuleGuid" :options="existingRules"
                                option-label="name" option-value="guid" showClear placeholder="Select Reject Rule"
                                class="w-full md:w-14rem" />
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="editRule.Rules != null">
                <div v-for="(item, index) in editRule.Rules" :key="index" class="col-12">
                    <Card class="w-full mb-3" style="width: 25rem; overflow: hidden">
                        <!-- <template #title>
                            {{ `${item.name}` }}
                        </template> -->
                        <!-- <template #subtitle>
                            {{ item.type }}
                        </template> -->
                        <template #header>
                            <div class="flex flex-row justify-content-between align-items-center">
                                <p class="m-2">Rule {{ index + 1 }}</p>
                                <Button label="Delete Rule" class="m-2" icon="pi pi-trash" severity="danger" @click="deleteRule($event, index)" />
                            </div>
                        </template>
                        <template #content>
                            <div class="flex flex-column gap-3">
                                <div class="flex flex-row gap-3 align-items-center">
                                    <div class="w-4">
                                        <label :for="'rulename' + index">Rule Name</label>
                                        <InputText :id="'rulename' + index" v-model="editRule.Rules[index].name"
                                            placeholder="Enter Rule Name" class="w-full" />
                                    </div>
                                    <div class="w-auto flex flex-column">
                                        <label for="ruletype">Rule Type </label>
                                        <Dropdown id="ruletype" v-model="editRule.Rules[index].type"
                                            :options="Object.values(ReleaseRuleType)" placeholder="Select Rule Type"
                                            class="w-full md:w-14rem" @change="ruleTypeChanged($event, index)" />
                                    </div>
                                    <div class="w-auto">
                                        <label>Optional </label>
                                        <Checkbox v-model="editRule.Rules[index].optional" :binary="true" />
                                    </div>
                                </div>
                                <div v-if="item.type == ReleaseRuleType.DEPARTMENT && editRule.Rules[index].department"
                                    class="flex flex-row gap-3">
                                    <div class="w-6">
                                        <label :for="'departmentname' + index">Department Name</label>
                                        <InputText :id="'departmentname' + index"
                                            v-model="editRule.Rules[index].department.departmentName"
                                            placeholder="Enter the Department Name" class="w-full" />
                                    </div>
                                    <div class="w-auto">
                                        <label :for="'approvals' + index">Number of Approvals</label>
                                        <InputNumber :id="'approvals' + index" showButtons
                                            v-model="editRule.Rules[index].department.numberOfApprovals"
                                            placeholder="Enter the Department Name" class="w-full" />
                                    </div>
                                </div>
                                <div v-else-if="item.type == ReleaseRuleType.SPECIFIC_PERSON && editRule.Rules[index].person"
                                    class="flex flex-column gap-3">
                                    <div class="w-full">
                                        <label :for="'person' + index">Person</label>
                                        <AutoComplete :id="'person' + index" class="w-full"
                                            placeholder="Enter name or email" @change="personChange($event, index)"
                                            v-model="editRule.Rules[index].person.email" optionLabel="identifier"
                                            :suggestions="usersFound" @item-select="selectUser($event, index)" />
                                    </div>
                                </div>
                            </div>
                        </template>
                        <template #footer>

                        </template>
                    </Card>
                </div>
            </div>
            <div class="flex justify-content-center gap-3 mt-1">
                <div class="flex gap-3 mt-1">
                    <Button label="Add Rule" class="w-full" icon="pi pi-plus" @click="addRule" />
                </div>
            </div>
        </template>
        <template #footer>
            <Button class="p-button-text" label="Cancel" @click="() => { dialogVisible = false; }" />
            <Button class="p-button-primary" label="Save" :onClick="saveRule" />
        </template>
    </Dialog>
</template>
<script setup lang="ts">
import { ref, watch } from 'vue';
import {
    ReleaseService,
    type ReleaseRuleTableListRule,
    type ReleaseRuleTableListTable,
    ReleaseRuleEntity,
    ReleaseStatus,
    ReleaseRuleType,
    UserService,
    type ReleaseRuleTableUpdateBody,
    type ReleaseRuleTableUpdateRule
} from "@/apis/aready-api";
import Dialog from 'primevue/dialog'
import Card from 'primevue/card';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import Dropdown, { type DropdownChangeEvent } from 'primevue/dropdown';
import Checkbox from 'primevue/checkbox';
import { useDelayTimer, delay } from '@congatec/primevue-components-lib';
import AutoComplete from 'primevue/autocomplete';
import type { AutoCompleteChangeEvent, AutoCompleteItemSelectEvent } from 'primevue/autocomplete';
import InputNumber from 'primevue/inputnumber';

const usersFound = ref<any[]>([]);
const userQueryTimer = useDelayTimer();

const props = defineProps<{
    rule: ReleaseRuleTableListTable,
    existingRules: Array<ReleaseRuleTableListTable> | undefined,
    visible: boolean,
    onHide: (edited: boolean) => any
}>();

const editRule = ref({
    Name: '',
    Entity: ReleaseRuleEntity.NONE,
    DefaultTable: false,
    Rules: [] as Array<any> | null,
    OnApprove: ReleaseStatus.RELEASE as ReleaseStatus | undefined,
    OnReject: ReleaseStatus.REJECTED as ReleaseStatus | undefined,
    OnApproveRuleGuid: null as string | null | undefined,
    OnRejectRuleGuid: null as string | null | undefined
});

const dialogVisible = ref(false);
//const rules = ref(props.rule.rules);


watch(() => props.visible, (nv) => {
    console.log("VISIBLE: ", nv);
    if (nv) {
        dialogVisible.value = nv;
        if (props.rule) {
            editRule.value.Name = props.rule.name!;
            editRule.value.Entity = props.rule.entity!;
            editRule.value.DefaultTable = props.rule.defaultTable!;
            editRule.value.Rules = props.rule.rules!;
            editRule.value.OnApprove = props.rule.onApprove;
            editRule.value.OnReject = props.rule.onReject;
            editRule.value.OnApproveRuleGuid = props.rule.onApproveRuleGuid;
            editRule.value.OnRejectRuleGuid = props.rule.onRejectRuleGuid;
            editRule.value.OnApprove = props.rule.onApprove;
            editRule.value.OnReject = props.rule.onReject;
        }
    }
}, { deep: true, immediate: true });

watch(() => dialogVisible.value, (nv) => {
    if (nv != null && !nv) {
        props.onHide(true);
    }

})

const personChange = (event: AutoCompleteChangeEvent, index: number) => {
    delay(userQueryTimer, async () => {
        if (!editRule.value.Rules) {
            usersFound.value = [];
            return;
        }

        let query: string | null | undefined = "";
        if (editRule.value.Rules[index].person?.firstName) {
            query = editRule.value.Rules[index].person?.firstName!;
        } else if (editRule.value.Rules[index].person?.lastName) {
            query = editRule.value.Rules[index].person?.lastName!;
        } else if (editRule.value.Rules[index].person?.email) {
            query = editRule.value.Rules[index].person?.email!;
        }
        if(query) {

            usersFound.value = (await UserService.postApiV1UserFindUser(query)).users || [];
            
            for (let user of usersFound.value) {
                if (user.email) {
                    user.identifier = user.email;
                } else {
                    user.identifier = `${user.firstName} ${user.lastName}`
                }
            }
        }
    });

};

const selectUser = (event: AutoCompleteItemSelectEvent, index: number) => {
    console.log("Item:", event)
    if (!editRule.value.Rules || !editRule.value.Rules[index].person) {
        return;
    }
    editRule.value.Rules[index].person!.firstName = event.value.firstName,
        editRule.value.Rules[index].person!.lastName = event.value.lastName,
        editRule.value.Rules[index].person!.email = event.value.email,
        editRule.value.Rules[index].person.subjectId = event.value.subjectId;
    console.log("Person: ", editRule.value.Rules[index].person);
}

const ruleTypeChanged = (event: DropdownChangeEvent, index: number) => {
    console.log(event.value);
    if (event.value == ReleaseRuleType.SPECIFIC_PERSON && editRule.value.Rules![index].person == null) {
        editRule.value.Rules![index].person = { firstName: '', lastName: '', email: '', department: '' };
    }
    else if (event.value == ReleaseRuleType.DEPARTMENT && editRule.value.Rules![index].department == null) {
        editRule.value.Rules![index].department = { departmentName: '', numberOfApprovals: 1 };
    }
}

const addRule = () => {
    editRule.value.Rules!.push({ name: "", optional: false, type: ReleaseRuleType.ALL_RELEASED });
}

const deleteRule = (event: any, index: number) => {
    editRule.value.Rules!.splice(index, 1);
}

const saveRule = async () => {
    let requestRules: Array<ReleaseRuleTableUpdateRule> | undefined = editRule.value.Rules?.map(
        (x) => ({ name: x.name, optional: x.optional, type: x.type, person: { subjectId: x.person?.subjectId }, department: x.department } as ReleaseRuleTableUpdateRule));
    let request: ReleaseRuleTableUpdateBody = {
        name: editRule.value.Name, entity: editRule.value.Entity, defaultTable: editRule.value.DefaultTable,
        onApprove: editRule.value.OnApprove, onReject: editRule.value.OnReject,
        onApproveRuleGuid: editRule.value.OnApproveRuleGuid, onRejectRuleGuid: editRule.value.OnRejectRuleGuid, rules: requestRules
    }

    await ReleaseService.putApiV1ReleaseReleaseRules(props.rule.guid!, request);
    dialogVisible.value = false;
}

</script>