<template>
  <div>
    <ConfirmDialog />

    <ConPopupDialog :visible="showCreateDialog" title="Create Attachment" @onHide="showCreateDialog = false">
      <AttachmentInput @onSubmitted="() => { showCreateDialog = false; table.forceReload(); }"
        @onCancel="showCreateDialog = false" />
    </ConPopupDialog>

    <ConPopupDialog :visible="showEditDialog" title="Edit Attachment" @onHide="showEditDialog = false">
      <AttachmentInput :attachment="selection[0]" @onSubmitted="() => { showEditDialog = false; table.forceReload(); }"
        @onCancel="showEditDialog = false" />
    </ConPopupDialog>


    <Toolbar style="position:sticky !important">
      <template #start>
        <div class="flex flex-row gap-3">
          <ConKebabMenu :menuItems="attachmentMenuItems" buttonLabel="Attachments" buttonIcon="pi pi-paperclip" />
        </div>
      </template>
    </Toolbar>

    <ConDataTable ref="table" :columns="columns" :onReload="onReload" filterDisplay="menu"
      @onSelectionChanged="onSelectionChanged">
      <template #mimeType="col">
        <Column :field="col.field" :header="col.header">
          <template #body="field">
            <div v-if="mimeTypeMap[field.data.mimeType]">
              <i :class="mimeTypeMap[field.data.mimeType].icon" v-tooltip="mimeTypeMap[field.data.mimeType].name" />
              <label class="ml-1">{{ mimeTypeMap[field.data.mimeType].name }}</label>
            </div>
            <div v-else>
              <i class='pi pi-file' v-tooltip="'Generic File'" />
              <label class="ml-1">Unknown File Type</label>
            </div>
          </template>
        </Column>
      </template>
    </ConDataTable>
  </div>
</template>

<script setup lang="ts">
import { canThrowAsyncShowToast, ConDataTable, useFileDownloadStatusStore, FileStatusStoreStatusType } from '@congatec/primevue-components-lib';
import { EmailService, type ListEmailAttachmentResponse_Attachment } from '@/apis/neptune-api';
import Column from 'primevue/column';
import { ref } from 'vue';
import { ConKebabMenu } from '@congatec/primevue-components-lib';
import { ConPopupDialog } from '@congatec/primevue-components-lib';
import AttachmentInput from './AttachmentInput.vue';
import { useToast } from 'primevue/usetoast';
import { useConfirm } from "primevue/useconfirm";
import ConfirmDialog from 'primevue/confirmdialog';
import Toolbar from 'primevue/toolbar';
const mimeTypeMap: Record<string, { name: string, icon: string }> = {
  'application/pdf': { name: 'PDF Document', icon: 'pi pi-file-pdf' },
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': { name: 'Excel Spreadsheet', icon: 'pi pi-file-excel' },
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': { name: 'Word Document', icon: 'pi pi-file-word' },
  'image/bmp': { name: 'Bitmap Image', icon: 'pi pi-image' },
  'image/gif': { name: 'Gif Image', icon: 'pi pi-image' },
  'image/jpeg': { name: 'Jpeg Image', icon: 'pi pi-image' },
  'image/png': { name: 'Png Image', icon: 'pi pi-image' },
  'text/plain': { name: 'Text File', icon: 'pi pi-file-edit' },
}

const store = useFileDownloadStatusStore();
const table = ref();
const toast = useToast();
const showCreateDialog = ref(false);
const showEditDialog = ref(false);

const selection = ref();
const confirm = useConfirm();
const confirmDelete = () => {
  confirm.require({
    header: 'Are you sure?',
    icon: 'pi pi-info-circle',
    acceptClass: 'p-button-info',
    message: 'Are you sure you want to delete the selected Item(s)',
    accept: async () => {
      const ids = selection.value.map((x: any) => x.id) as number[];
      await EmailService.deleteApiV1EmailDeleteAttachment({ attachmentIds: ids });
      table.value.forceReload();
    }
  })
}

const disableDeleteAndDownload = ref(true);
const disableEdit = ref(true);
//const disableDelete = ref(true);

const attachmentMenuItems = ref([
  {
    label: 'Create',
    icon: 'pi pi-plus',
    command: () => {
      showCreateDialog.value = true;
    }
  },
  {
    label: 'Edit',
    icon: 'pi pi-pencil',
    disabled: disableEdit,
    command: () => {
      showEditDialog.value = true;
    }
  },
  {
    label: 'Download',
    icon: 'pi pi-download',
    disabled: disableDeleteAndDownload,
    command: async () => {
      selection.value.forEach(async (element: ListEmailAttachmentResponse_Attachment) => {
        let response = await EmailService.getApiV1EmailGetAttachment(element.id)
        const name = response.fileName || "Attachment" + element.id;
  
  
        store.add({
          id: name,
          name: name,
          url: "",
          status: FileStatusStoreStatusType.DOWNLOADING,
        });
  
        setFileFinished(
          name,
          response.content,
          response.mimeType || "text/plain"
        );
        
      });
    }

  },
  {
    label: 'Delete',
    icon: 'pi pi-trash',
    command: confirmDelete,
    disabled: disableDeleteAndDownload
  }
]);

const columns = [
  {
    selectionMode: 'multiple',
    field: 'any'
  },
  {
    field: "mimeType",
    header: "File Type",
    dataType: "text",
    isFiltered: true,
    sortable: true,
  },
  {
    field: "fileName",
    header: "File Name",
    dataType: "text",
    isFiltered: true,
    sortable: true,
  },
]

const onSelectionChanged = (newSelection: any) => {
  selection.value = newSelection;
  disableDeleteAndDownload.value = selection.value?.length <= 0
  disableEdit.value = selection.value?.length != 1
}


const onReload = async (filters: string, sorts: string, page: number, rows: number) => {
  let res = await canThrowAsyncShowToast(toast, async () => await EmailService.getApiV1EmailListAttachment(filters, sorts, page, rows));
  return {
    totalCount: res.totalCount || 0,
    data: res.attachments || []
  };
}

function setFileFinished(id: string, data: any, type: string) {
    if (data) {
    const byteCharacters = atob(data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);

    const url = window.URL.createObjectURL(
        new Blob([byteArray], { type: type })
    );

    store.update({
      id: id,
        changes: {
          url: url,
          status: FileStatusStoreStatusType.DOWNLOADED,
        },
    });


    // const link = document.createElement("a");
    // link.hidden = true;
    // link.href = url;
    // link.setAttribute("download", name);
    // document.body.appendChild(link);
    // link.click();
    }
}
</script>
