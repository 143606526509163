/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OpenAIDallERequest } from '../models/OpenAIDallERequest';
import type { OpenAIDallEResponse } from '../models/OpenAIDallEResponse';
import type { OpenAIGptChatRequest } from '../models/OpenAIGptChatRequest';
import type { OpenAIGptChatResponse } from '../models/OpenAIGptChatResponse';
import type { OpenAIGptSessionDeleteRequest } from '../models/OpenAIGptSessionDeleteRequest';
import type { OpenAIGptSessionDeleteResponse } from '../models/OpenAIGptSessionDeleteResponse';
import type { OpenAIGptSessionGetResponse } from '../models/OpenAIGptSessionGetResponse';
import type { OpenAIGptSessionListRequest } from '../models/OpenAIGptSessionListRequest';
import type { OpenAIGptSessionListResponse } from '../models/OpenAIGptSessionListResponse';
import type { OpenAIGptSessionUpdateRequest } from '../models/OpenAIGptSessionUpdateRequest';
import type { OpenAIGptSessionUpdateResponse } from '../models/OpenAIGptSessionUpdateResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class OpenAiService {

    /**
     * @param requestBody 
     * @returns OpenAIGptChatResponse OK
     * @throws ApiError
     */
    public static postApiV1OpenAiGptChats(
requestBody?: OpenAIGptChatRequest,
): CancelablePromise<OpenAIGptChatResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/open-ai/gpt/chats',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id 
     * @returns OpenAIGptSessionGetResponse OK
     * @throws ApiError
     */
    public static getApiV1OpenAiGptSessions(
id: number,
): CancelablePromise<OpenAIGptSessionGetResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/open-ai/gpt/sessions/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns OpenAIGptSessionDeleteResponse OK
     * @throws ApiError
     */
    public static deleteApiV1OpenAiGptSessions(
requestBody?: OpenAIGptSessionDeleteRequest,
): CancelablePromise<OpenAIGptSessionDeleteResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/open-ai/gpt/sessions',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param request 
     * @returns OpenAIGptSessionListResponse OK
     * @throws ApiError
     */
    public static getApiV1OpenAiGptSessions1(
request?: OpenAIGptSessionListRequest,
): CancelablePromise<OpenAIGptSessionListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/open-ai/gpt/sessions',
            query: {
                'request': request,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns OpenAIGptSessionUpdateResponse OK
     * @throws ApiError
     */
    public static putApiV1OpenAiGptSessions(
requestBody?: OpenAIGptSessionUpdateRequest,
): CancelablePromise<OpenAIGptSessionUpdateResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/open-ai/gpt/sessions',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns OpenAIDallEResponse OK
     * @throws ApiError
     */
    public static postApiV1OpenAiDalleImages(
requestBody?: OpenAIDallERequest,
): CancelablePromise<OpenAIDallEResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/open-ai/dalle/images',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
