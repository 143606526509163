/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ItemCreateRequest } from '../models/ItemCreateRequest';
import type { ItemCreateResponse } from '../models/ItemCreateResponse';
import type { ItemDeleteResponse } from '../models/ItemDeleteResponse';
import type { ItemGetResponse } from '../models/ItemGetResponse';
import type { ItemUpdateRequest } from '../models/ItemUpdateRequest';
import type { ItemUpdateResponse } from '../models/ItemUpdateResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ItemsService {

    /**
     * @param requestBody 
     * @returns ItemCreateResponse Success
     * @throws ApiError
     */
    public static postApiV1Items(
requestBody?: ItemCreateRequest,
): CancelablePromise<ItemCreateResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/Items',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id 
     * @returns ItemDeleteResponse Success
     * @throws ApiError
     */
    public static deleteApiV1Items(
id?: number,
): CancelablePromise<ItemDeleteResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/Items',
            query: {
                'Id': id,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns ItemUpdateResponse Success
     * @throws ApiError
     */
    public static putApiV1Items(
requestBody?: ItemUpdateRequest,
): CancelablePromise<ItemUpdateResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/Items',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id 
     * @returns ItemGetResponse Success
     * @throws ApiError
     */
    public static getApiV1Items(
id: number,
): CancelablePromise<ItemGetResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/Items/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id 
     * @returns any Success
     * @throws ApiError
     */
    public static getApiV1ItemsGetQr(
id: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/Items/{id}/GetQr',
            path: {
                'id': id,
            },
        });
    }

}
