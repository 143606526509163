/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PlacesListRequest } from '../models/PlacesListRequest';
import type { PlacesListResponse } from '../models/PlacesListResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PlacesService {

    /**
     * @param request 
     * @returns PlacesListResponse Success
     * @throws ApiError
     */
    public static getApiV1PlacesList(
request?: PlacesListRequest,
): CancelablePromise<PlacesListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/Places/List',
            query: {
                'request': request,
            },
        });
    }

}
