import { defineStore } from 'pinia'
import { useStorage } from '@vueuse/core'
import { type Ref } from 'vue';

const STORAGE_KEY = "jira-user-token";

export const useJiraTokenStore = defineStore("JiraTokenStore", () => {
  const token: Ref<string | undefined> = useStorage(STORAGE_KEY,  undefined);
  if(token.value=="undefined"){
    token.value = undefined;
  }
  const set = (newToken: string) => {
    token.value = newToken;
  }

  const clear = () =>[
    token.value = undefined
  ]
  return { token, set, clear}
});