/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CloneEmployeeCardRequest } from '../models/CloneEmployeeCardRequest';
import type { CloneEmployeeCardResponse } from '../models/CloneEmployeeCardResponse';
import type { CreateEmployeeCardRequest } from '../models/CreateEmployeeCardRequest';
import type { CreateEmployeeCardResponse } from '../models/CreateEmployeeCardResponse';
import type { DeleteEmployeeCardRequest } from '../models/DeleteEmployeeCardRequest';
import type { DeleteEmployeeCardResponse } from '../models/DeleteEmployeeCardResponse';
import type { InvalidateEmployeeCardRequest } from '../models/InvalidateEmployeeCardRequest';
import type { InvalidateEmployeeCardResponse } from '../models/InvalidateEmployeeCardResponse';
import type { ListEmployeeCardResponse } from '../models/ListEmployeeCardResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class EmployeeCardService {

    /**
     * @param requestBody 
     * @returns CreateEmployeeCardResponse Success
     * @throws ApiError
     */
    public static postApiV1EmployeeCardCreate(
requestBody?: CreateEmployeeCardRequest,
): CancelablePromise<CreateEmployeeCardResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/EmployeeCard/Create',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns CloneEmployeeCardResponse Success
     * @throws ApiError
     */
    public static postApiV1EmployeeCardClone(
requestBody?: CloneEmployeeCardRequest,
): CancelablePromise<CloneEmployeeCardResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/EmployeeCard/Clone',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns InvalidateEmployeeCardResponse Success
     * @throws ApiError
     */
    public static putApiV1EmployeeCardInvalidate(
requestBody?: InvalidateEmployeeCardRequest,
): CancelablePromise<InvalidateEmployeeCardResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/EmployeeCard/Invalidate',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns DeleteEmployeeCardResponse Success
     * @throws ApiError
     */
    public static deleteApiV1EmployeeCardDelete(
requestBody?: DeleteEmployeeCardRequest,
): CancelablePromise<DeleteEmployeeCardResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/EmployeeCard/Delete',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param sieveModelFilters 
     * @param sieveModelSorts 
     * @param sieveModelPage 
     * @param sieveModelPageSize 
     * @returns ListEmployeeCardResponse Success
     * @throws ApiError
     */
    public static getApiV1EmployeeCard(
sieveModelFilters?: string,
sieveModelSorts?: string,
sieveModelPage?: number,
sieveModelPageSize?: number,
): CancelablePromise<ListEmployeeCardResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/EmployeeCard',
            query: {
                'SieveModel.Filters': sieveModelFilters,
                'SieveModel.Sorts': sieveModelSorts,
                'SieveModel.Page': sieveModelPage,
                'SieveModel.PageSize': sieveModelPageSize,
            },
        });
    }

}
