<template>
  <RptTestDetailsComponent :id="selectedId" :visible="showDetailsDialog" :onHide="(onDetailsclosed: any) =>{
      showDetailsDialog = false;
    }">
  </RptTestDetailsComponent>
  <div class="flex flex-column w-full m-5 mt-6">
    <ConDataTable :columns="propMatching" :rows="25" filterDisplay="menu" :rowsPerPageOptions="[10, 25, 50]"
      :onReload="fetchDataFromServiceAsync" :router="router" :route="route" @onRowClick="onRowClick"
      :createActionMenu="createActionMenu">
    </ConDataTable>
  </div>
</template>

<script setup lang="ts">
import { defineComponent, ref } from "vue";
import { TestResultsService } from "@/apis/eris-api";
import { ConDataTable } from "@congatec/primevue-components-lib";
import { useRoute, useRouter } from "vue-router";
import {RptTestDetailsComponent} from "@/eris/components/rpt-testdetail-component";

const router = useRouter();
const route = useRoute();
const isLoading = ref(true);
const selectedId = ref(0);
const showDetailsDialog = ref<boolean>(false);
let propMatching = [
  {
    field: "serialNumber",
    header: "Serial Number",
    dataType: "numeric",
    isFiltered: true,
    sortable: true,
    showFilterOperator: true,
  },
  {
    field: "product",
    header: "Product",
    isFiltered: true,
    sortable: true,
  },
  {
    field: "testbed",
    header: "Testbed",
    isFiltered: true,
    sortable: true,
  },
  {
    field: "hardwareRevision",
    header: "Revision",
    dataType: "text",
    isFiltered: true,
    sortable: true,
    filterMeta: { operator: 'and', constraints: [{ value: [], matchMode: 'contains' }] },
  },
  {
    field: "productionOrder",
    header: "PO",
    dataType: "text",
    isFiltered: true,
    sortable: true,
    filterMeta: { operator: 'and', constraints: [{ value: [], matchMode: 'contains' }] },
  },
  {
    field: "manufacturedBiosRevision",
    header: "Bios",
    isFiltered: true,
    sortable: true,
  },
  {
    field: "testDate",
    header: "Test Date",
    isFiltered: true,
    sortable: true,
    dataType: "date",
    filterMeta: { operator: 'and', constraints: [{ value: [], matchMode: 'dateIs' }] }
  },
  {
    field: "result",
    header: "Result",
    dataType: "text",
    isFiltered: true,
    sortable: true,
    filterMeta: { operator: 'and', constraints: [{ value: [], matchMode: 'contains' }] }
  },
];

async function fetchDataFromServiceAsync(filters: string, sorts: string, page: number, rows: number): Promise<any> {
  isLoading.value = true;
  let r = await TestResultsService
    .getApiV1RptTestResultRpts(undefined, filters, sorts, page, rows);

  isLoading.value = false;
  return {
    totalCount: r.totalCount,
    data: r.details
  }
}

async function onRowClick(data: any) {
  //router.push(`/eris/rpt-test-details/${data.id}`);
  selectedId.value = data.id;
  showDetailsDialog.value = true;
}

function createActionMenu(row: any): any {
  return [{
  }];
}

</script>

<script lang="ts">
export default defineComponent({
  name: "RptTestDetailListComponent",
});
</script>

<style lang="css"></style>
