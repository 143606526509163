<template>
    <span class="p-float-label float-input">
        <InputText
            @input="onInput"
            :disabled="disable" 
            :id="$props.id" :type="$props.type" v-model="modelValueRef" :class="$props.inputClass" />
        <label :for="$props.id">{{$props.label}}</label>
    </span>
</template>

<script lang="ts">

export default defineComponent({
    name: "FloatingInput",
    components: { }
});
</script>

<script setup lang="ts">
import { defineComponent, toRef, ref, watch } from 'vue';
import InputText from 'primevue/inputtext';

    const emit = defineEmits(["update:modelValue", "input"]);

    let props = defineProps({
        modelValue: {default: ""},
        disable: {default: false},
        label: {type: String, default: ""},
        id: {type: String, default: ""},
        type: {type: String, defalt: "text"},
        inputClass: {default: (): any => ""}
    });

    const disable = props.disable;

    /**
     * I don't know why.
     * I should not have to care why.
     * I don't even want to know why,
     * but for some reason I cannot pass a toRef as a v-model 
     * to PrimeVue's InputText while retaining reactivity.
     * Instead we make a cloned ref and watch the original prop as a ref 
     * to update the clone's value.
     * Typical webdev experience right here...
     */
    let modelValueRef = ref(props.modelValue);
    let modelValueWatchRef = toRef(props, 'modelValue');

    watch(modelValueRef, (newValue) => {
        emit("update:modelValue", newValue);
    });

    watch(modelValueWatchRef, (newValue) => {
        modelValueRef.value = newValue;
    });

    const updateValue = (event: any) => {
        emit("update:modelValue", event.target.value);
    }

    const onInput = (event: any) => {
        emit("input", event);
    }
</script> 

<style> 
    .float-input {
        margin-top: 1.0em;
    }
</style>