<template>
  <Review @init="init" :guid="guid" :state="artifact?.state">
    <ProgressSpinner v-if="!artifact" />
    <div v-else>
      <ConDetailsItem title="Guid" :content="guid" />
      <ConDetailsItem title="Filename" :content="artifact.fileName" />
      <ConDetailsItem title="Identifier" :content="artifact.identifier" />
      <ConDetailsItem v-if="artifact.description" title="Description" :content="artifact.description" />
      <ConDetailsItem :title="artifact.hashAlgorithm" :content="artifact.fileHash" />
      <ConDetailsItem title="Status" :content="artifact.state" />
    </div>
  </Review>
</template>

<script setup lang="ts">
import { default as Review, type ReviewProps } from "../components/ReleaseReview.vue";

import { ConDetailsItem } from '@congatec/primevue-components-lib'
import { onMounted, ref } from 'vue';
import { ArtifactService } from '@/apis/aready-api'
import ProgressSpinner from 'primevue/progressspinner';

const props = defineProps<ReviewProps>();

const artifact = ref();

onMounted(async () => {
  await init();
})

const init = async () => {
  artifact.value = await ArtifactService.getApiV1Artifacts1(props.guid);
}

</script>
