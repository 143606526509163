/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SignOutCreateRequest } from '../models/SignOutCreateRequest';
import type { SignOutCreateResponse } from '../models/SignOutCreateResponse';
import type { SignOutGetResponse } from '../models/SignOutGetResponse';
import type { SignOutListResponse } from '../models/SignOutListResponse';
import type { SignOutReturnRequest } from '../models/SignOutReturnRequest';
import type { SignOutReturnResponse } from '../models/SignOutReturnResponse';
import type { SignOutStatusResponse } from '../models/SignOutStatusResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SignOutService {

    /**
     * @param requestBody 
     * @returns SignOutCreateResponse Success
     * @throws ApiError
     */
    public static postApiV1SignOut(
requestBody?: SignOutCreateRequest,
): CancelablePromise<SignOutCreateResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/SignOut',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param sieveModelFilters 
     * @param sieveModelSorts 
     * @param sieveModelPage 
     * @param sieveModelPageSize 
     * @returns SignOutListResponse Success
     * @throws ApiError
     */
    public static getApiV1SignOut(
sieveModelFilters?: string,
sieveModelSorts?: string,
sieveModelPage?: number,
sieveModelPageSize?: number,
): CancelablePromise<SignOutListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/SignOut',
            query: {
                'sieveModel.Filters': sieveModelFilters,
                'sieveModel.Sorts': sieveModelSorts,
                'sieveModel.Page': sieveModelPage,
                'sieveModel.PageSize': sieveModelPageSize,
            },
        });
    }

    /**
     * @param id 
     * @returns SignOutGetResponse Success
     * @throws ApiError
     */
    public static getApiV1SignOut1(
id: number,
): CancelablePromise<SignOutGetResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/SignOut/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param guid 
     * @returns SignOutStatusResponse Success
     * @throws ApiError
     */
    public static getApiV1SignOutStatus(
guid: string,
): CancelablePromise<SignOutStatusResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/SignOut/{guid}/status',
            path: {
                'guid': guid,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns SignOutReturnResponse Success
     * @throws ApiError
     */
    public static putApiV1SignOutReturn(
requestBody?: SignOutReturnRequest,
): CancelablePromise<SignOutReturnResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/SignOut/return',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
