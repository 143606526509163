/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AssetsCreateRequest } from '../models/AssetsCreateRequest';
import type { AssetsCreateResponse } from '../models/AssetsCreateResponse';
import type { AssetsDeleteResponse } from '../models/AssetsDeleteResponse';
import type { AssetsDetailsResponse } from '../models/AssetsDetailsResponse';
import type { AssetsListResponse } from '../models/AssetsListResponse';
import type { AssetsUpdateRequest } from '../models/AssetsUpdateRequest';
import type { AssetsUpdateResponse } from '../models/AssetsUpdateResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AssetsService {

    /**
     * @param requestBody 
     * @returns AssetsCreateResponse Success
     * @throws ApiError
     */
    public static postApiV1Assets(
requestBody?: AssetsCreateRequest,
): CancelablePromise<AssetsCreateResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/assets',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param sieveModelFilters 
     * @param sieveModelSorts 
     * @param sieveModelPage 
     * @param sieveModelPageSize 
     * @returns AssetsListResponse Success
     * @throws ApiError
     */
    public static getApiV1Assets(
sieveModelFilters?: string,
sieveModelSorts?: string,
sieveModelPage?: number,
sieveModelPageSize?: number,
): CancelablePromise<AssetsListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/assets',
            query: {
                'SieveModel.Filters': sieveModelFilters,
                'SieveModel.Sorts': sieveModelSorts,
                'SieveModel.Page': sieveModelPage,
                'SieveModel.PageSize': sieveModelPageSize,
            },
        });
    }

    /**
     * @param id 
     * @returns AssetsDetailsResponse Success
     * @throws ApiError
     */
    public static getApiV1Assets1(
id: number,
): CancelablePromise<AssetsDetailsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/assets/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns AssetsUpdateResponse Success
     * @throws ApiError
     */
    public static putApiV1Assets(
id: number,
requestBody?: AssetsUpdateRequest,
): CancelablePromise<AssetsUpdateResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/assets/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id 
     * @returns AssetsDeleteResponse Success
     * @throws ApiError
     */
    public static deleteApiV1Assets(
id: number,
): CancelablePromise<AssetsDeleteResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/assets/{id}',
            path: {
                'id': id,
            },
        });
    }

}
