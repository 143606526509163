/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LogEntriesDetailsResponse } from '../models/LogEntriesDetailsResponse';
import type { LogEntriesListFilterFieldsFilterStatement } from '../models/LogEntriesListFilterFieldsFilterStatement';
import type { LogEntriesListOrderFields } from '../models/LogEntriesListOrderFields';
import type { LogEntriesListOrderFieldsOrderingFieldsBase } from '../models/LogEntriesListOrderFieldsOrderingFieldsBase';
import type { LogEntriesListResponse } from '../models/LogEntriesListResponse';
import type { LogEntriesResolveResponse } from '../models/LogEntriesResolveResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class LogEntryService {

    /**
     * @param sieveModelFilters 
     * @param sieveModelSorts 
     * @param sieveModelPage 
     * @param sieveModelPageSize 
     * @param filterStatements 
     * @param pageNumber 
     * @param pageSize 
     * @param skip 
     * @param orderingFields 
     * @param availableOrderingFields 
     * @returns LogEntriesListResponse Success
     * @throws ApiError
     */
    public static getApiV1LogEntryLogEntriesList(
sieveModelFilters?: string,
sieveModelSorts?: string,
sieveModelPage?: number,
sieveModelPageSize?: number,
filterStatements?: Array<LogEntriesListFilterFieldsFilterStatement>,
pageNumber?: number,
pageSize?: number,
skip?: number,
orderingFields?: Array<LogEntriesListOrderFieldsOrderingFieldsBase>,
availableOrderingFields?: LogEntriesListOrderFields,
): CancelablePromise<LogEntriesListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/LogEntry/LogEntriesList',
            query: {
                'SieveModel.Filters': sieveModelFilters,
                'SieveModel.Sorts': sieveModelSorts,
                'SieveModel.Page': sieveModelPage,
                'SieveModel.PageSize': sieveModelPageSize,
                'FilterStatements': filterStatements,
                'PageNumber': pageNumber,
                'PageSize': pageSize,
                'Skip': skip,
                'OrderingFields': orderingFields,
                'AvailableOrderingFields': availableOrderingFields,
            },
        });
    }

    /**
     * @param id 
     * @returns LogEntriesDetailsResponse Success
     * @throws ApiError
     */
    public static getApiV1LogEntryLogEntryDetails(
id?: number,
): CancelablePromise<LogEntriesDetailsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/LogEntry/LogEntryDetails',
            query: {
                'Id': id,
            },
        });
    }

    /**
     * @param logEntryId 
     * @param aureaId 
     * @param hubspotId 
     * @returns LogEntriesResolveResponse Success
     * @throws ApiError
     */
    public static postApiV1LogEntryLogEntryResolve(
logEntryId?: number,
aureaId?: number,
hubspotId?: number,
): CancelablePromise<LogEntriesResolveResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/LogEntry/LogEntryResolve',
            query: {
                'LogEntryId': logEntryId,
                'AureaId': aureaId,
                'HubspotId': hubspotId,
            },
        });
    }

}
