<template>
  <Dialog v-if="dialogVisible" v-model:visible="dialogVisible" modal :draggable="false" :header="headerText"
    position="top" style="max-width: 80%; min-width: 80%; max-height: 80%">
    <template #default>
      <div>
        <div class="card flex justify-content-left">
          <div class="flex flex-column gap-5 w-full">
            <div class="flex flex-row gap-5 w-full">
            </div>
            <div class="flex flex-row gap-5 w-full">
              <div class="flex flex-column w-full">
                <label for="description">Description <span>*</span></label>
                <Textarea id="description" v-model="artifact.description"
                  placeholder="Enter the Description of the Artifact" autoResize rows="5" />
              </div>
            </div>
            <div class="flex flex-row gap-5 w-full">
              <div class="w-full">
                <label for="name">Plm Item <span>*</span></label>
                <InputText id="plmItem" v-model="artifact.plmItem" :value="artifact.plmItem"
                  placeholder="Enter the PLM Item for the Artifact" class="w-full" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="flex flex-row align-items-end justify-content-between">
        <Button class="p-button-text" :disabled="isLoading" label="Cancel" :onClick="() => {
          dialogVisible = false;
        }
          " />

        <Button class="p-button-primary" label="Save" :onClick="() => {
          submitArtifactUpdate();
        }
          " />
      </div>
    </template>
  </Dialog>
</template>

<script lang="ts" setup>
import Dialog from "primevue/dialog";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import {
  ReleaseStatus,
  ArtifactService,
  type ArtifactUpdateRequest,
} from "@/apis/aready-api";
import { ref, watch } from "vue";
import Textarea from "primevue/textarea";

const emit = defineEmits(['close'])
const dialogVisible = ref(false);
const isLoading = ref(false);
const isReleased = ref(false);
const artifact = ref<{description: string, plmItem: string}>(
  { description: '', plmItem: '' }
);

const props = defineProps<{
  visible?: boolean;
  guid?: string;
  description?: string;
  plmItem?: string;
  state?: ReleaseStatus;
}>();

watch(
  () => props.visible,
  (nv) => {
    if (nv) {
      dialogVisible.value = nv;
    }

    if (nv && props.guid) {
      isLoading.value = true;
      artifact.value.description = props.description || '';
      artifact.value.plmItem = props.plmItem || '';
      isReleased.value = props.state == ReleaseStatus.RELEASE;
    }
    isLoading.value = false;
  },
);

watch(dialogVisible, () => {
  if (!dialogVisible.value) {
    closeDialog();
  }
});

function closeDialog() {
  emit("close");
}

async function submitArtifactUpdate() {
  await ArtifactService.putApiV1Artifacts(props.guid!, artifact.value);
  dialogVisible.value = false;
}

let headerText = "Artifact Details";
</script>

<style lang="scss" scoped></style>
