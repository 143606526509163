<template>
  <div class="flex flex-column">
    <h1>401 Unauthorized</h1>
    <h2>If you want to request access
      <a href="mailto:helpdesk.it@congatec.com" rel="noopener noreferrer" class="link">Contact IT Helpdesk</a>
    </h2>
  </div>
</template>

<script setup lang="ts">
import { useGlobalRouterStore } from '@/stores/routerStore';
import globalRouter from '@/globalRouter';
import { useKeycloakStore } from '@congatec/authentication-lib';


const keyCloakStore = useKeycloakStore();
const globalRouterStore = useGlobalRouterStore()

keyCloakStore.$subscribe(()=>{
  if(globalRouterStore.lastRoute && keyCloakStore.token){

    globalRouter.push(globalRouterStore.lastRoute)
  }
})



</script>
