/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { IssueDeleteRequest } from '../models/IssueDeleteRequest';
import type { IssueDeleteResponse } from '../models/IssueDeleteResponse';
import type { TimeTrackingCommitRequest } from '../models/TimeTrackingCommitRequest';
import type { TimeTrackingCommitResponse } from '../models/TimeTrackingCommitResponse';
import type { TimeTrackingCreateRequest } from '../models/TimeTrackingCreateRequest';
import type { TimeTrackingCreateResponse } from '../models/TimeTrackingCreateResponse';
import type { TimeTrackingDeleteRequest } from '../models/TimeTrackingDeleteRequest';
import type { TimeTrackingDeleteResponse } from '../models/TimeTrackingDeleteResponse';
import type { TimeTrackingElapsedTimeResponse } from '../models/TimeTrackingElapsedTimeResponse';
import type { TimeTrackingListRequest } from '../models/TimeTrackingListRequest';
import type { TimeTrackingListResponse } from '../models/TimeTrackingListResponse';
import type { TimeTrackingPauseRequest } from '../models/TimeTrackingPauseRequest';
import type { TimeTrackingPauseResponse } from '../models/TimeTrackingPauseResponse';
import type { TimeTrackingStartRequest } from '../models/TimeTrackingStartRequest';
import type { TimeTrackingStartResponse } from '../models/TimeTrackingStartResponse';
import type { TimeTrackingStopRequest } from '../models/TimeTrackingStopRequest';
import type { TimeTrackingStopResponse } from '../models/TimeTrackingStopResponse';
import type { TimeTrackingSummarizeRequest } from '../models/TimeTrackingSummarizeRequest';
import type { TimeTrackingSummarizeResponse } from '../models/TimeTrackingSummarizeResponse';
import type { TimeTrackingUpdateRequest } from '../models/TimeTrackingUpdateRequest';
import type { TimeTrackingUpdateResponse } from '../models/TimeTrackingUpdateResponse';
import type { UserLoginResponse } from '../models/UserLoginResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class JiraTimeTrackingService {

    /**
     * @param jiraToken 
     * @returns UserLoginResponse OK
     * @throws ApiError
     */
    public static postApiV1JiraLogin(
jiraToken?: string,
): CancelablePromise<UserLoginResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/jira/login',
            query: {
                'JiraToken': jiraToken,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns TimeTrackingStartResponse OK
     * @throws ApiError
     */
    public static putApiV1JiraTimeTrackingsStart(
requestBody?: TimeTrackingStartRequest,
): CancelablePromise<TimeTrackingStartResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/jira/time-trackings/start',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns TimeTrackingPauseResponse OK
     * @throws ApiError
     */
    public static putApiV1JiraTimeTrackingsPause(
requestBody?: TimeTrackingPauseRequest,
): CancelablePromise<TimeTrackingPauseResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/jira/time-trackings/pause',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns TimeTrackingStopResponse OK
     * @throws ApiError
     */
    public static putApiV1JiraTimeTrackingsStop(
requestBody?: TimeTrackingStopRequest,
): CancelablePromise<TimeTrackingStopResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/jira/time-trackings/stop',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns TimeTrackingCommitResponse OK
     * @throws ApiError
     */
    public static putApiV1JiraTimeTrackingsCommit(
requestBody?: TimeTrackingCommitRequest,
): CancelablePromise<TimeTrackingCommitResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/jira/time-trackings/commit',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns TimeTrackingCreateResponse OK
     * @throws ApiError
     */
    public static postApiV1JiraTimeTrackings(
requestBody?: TimeTrackingCreateRequest,
): CancelablePromise<TimeTrackingCreateResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/jira/time-trackings',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param request 
     * @returns TimeTrackingListResponse OK
     * @throws ApiError
     */
    public static getApiV1JiraTimeTrackings(
request?: TimeTrackingListRequest,
): CancelablePromise<TimeTrackingListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/jira/time-trackings',
            query: {
                'request': request,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns TimeTrackingDeleteResponse OK
     * @throws ApiError
     */
    public static deleteApiV1JiraTimeTrackings(
requestBody?: TimeTrackingDeleteRequest,
): CancelablePromise<TimeTrackingDeleteResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/jira/time-trackings',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns TimeTrackingUpdateResponse OK
     * @throws ApiError
     */
    public static putApiV1JiraTimeTrackings(
requestBody?: TimeTrackingUpdateRequest,
): CancelablePromise<TimeTrackingUpdateResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/jira/time-trackings',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param jiraStoryKeys 
     * @returns TimeTrackingElapsedTimeResponse OK
     * @throws ApiError
     */
    public static getApiV1JiraTimeTrackingsElapsedTime(
jiraStoryKeys?: Array<string>,
): CancelablePromise<TimeTrackingElapsedTimeResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/jira/time-trackings/elapsed-time',
            query: {
                'JiraStoryKeys': jiraStoryKeys,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns IssueDeleteResponse OK
     * @throws ApiError
     */
    public static deleteApiV1JiraUserIssue(
requestBody?: IssueDeleteRequest,
): CancelablePromise<IssueDeleteResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/jira/user-issue',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns TimeTrackingSummarizeResponse OK
     * @throws ApiError
     */
    public static postApiV1JiraTimeTrackingsSummarize(
requestBody?: TimeTrackingSummarizeRequest,
): CancelablePromise<TimeTrackingSummarizeResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/jira/time-trackings/summarize',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
