<template>
  <Toast position="top-right" />
  <ConfirmDialog />

  <QuickInputVisitsDialog :visible="showQuickVisitDialog" :on-hide="() => {
    showQuickVisitDialog = false;
    reloadVisitTableData();
    }"
  />

  <InputVisitsDialog :visible="showEditVisitDialog" :visit="selectedItems[0]" :on-hide="(edited: any) => {
    showEditVisitDialog = false;
    selectedItems = selectedVisitBackup;
    reloadVisitTableData();
  }" />

  <div class="card">
    <div></div>
    <Toolbar>
      <template #start>
        <div class="flex flex-row gap-3">
          <Calendar class="w-14rem" selectionMode="range" dateFormat="dd.M.yy" v-model="dates"  />
          <!-- <label for="branchOffice">Branch Office</label> -->
          <Dropdown id="branchOffice" v-model="branchOffice" :options="branchOffices" optionValue="value"
            optionLabel="name" placeholder="Select Branch Office" class="w-14rem" />
          <Button label="Check In / Check Out" @click="checkInCheckOut()" icon="pi pi-briefcase">
          </Button>
          <Button label="One Time Visit" @click="showVisitDialog()" icon="pi pi-calendar-plus">
          </Button>
          <Button label="Delete Visit" @click="confirmDelete()" :disabled="deleteVisitDisabled" icon="pi pi-trash">
          </Button>
        </div>
      </template>
    </Toolbar>
  </div>
  <!-- <div class="mt-3"></div> -->
  <div class="m-5 mt-7">
    <ConDataTable tableId="receptionTable" title="Reception Dashboard" :columns="columns" filterDisplay="menu" :rowsPerPageOptions="[10, 20, 50]"
      :selection="selectedItems" :onReload="fetchVisitsReceptionDataAsync" @onSelectionChanged="handleSelectionChange"
      ref="receptionTableRef" exportable :createActionMenu="createVisitActionMenu" expansion-key="checkInsOuts" :expansion-columns="expansionColumns" globalFilter="visitorFirstName|visitorLastName|visitorEmail|visitorCompany|meetingRoom">
      <template #status="col">
        <Column :field="col.field" :header="col.header" :sortable="col.sortable" :isFiltered="col.isFiltered">
          <template #body="field">
            <div class="flex justify-content-between align-items-center w-7rem">
              <i v-if="field.data[col.field] == 'scheduled'" v-tooltip="'Visit is scheduled'" class="pi pi-calendar "
                style="color: rgb(107, 106, 112)" aria-label="visit scheduled"></i>
              <i v-if="field.data[col.field] == 'checkedIn'" v-tooltip="'Visitor is checked out'" class=" pi
              pi-check-circle " style=" color: rgb(23, 201, 100);" aria-label="check in success"></i>
              <i v-if="field.data[col.field] == 'checkedOut'" v-tooltip="'Visitor is checked out'"
                class="pi pi-minus-circle" style="color: rgb(255, 130, 0);" aria-label="check out success"></i>
              <i v-if="field.data[col.field] == 'cancelled'" v-tooltip="'This visit is cancelled'" class="pi pi-times"
                style="color: rgb(218, 50, 8)" aria-label="visit cancelled">
              </i>
              <span v-else>{{ field.data[col.field] }}</span>
            </div>
          </template>

        </Column>
      </template>
      <template #visitorScreeningStatus="col">
        <Column :field="col.field" :header="col.header">

          <template #body="field">
            <div class="flex justify-content-center">
              <i v-if="field.data[col.field] == 'notListed'" v-tooltip="'This visitor is not listed'"
                class="pi pi-thumbs-up-fill " style="color: rgb(23, 201, 100)" aria-label="screen success"></i>
              <i v-if="field.data[col.field] == 'listed'" v-tooltip="'This visitor needs to be checked by ECC department'" 
                class=" pi pi-thumbs-down-fill " style=" color: rgb(242, 19, 93)" aria-label="screen failed"></i>
              <i v-if="field.data[col.field] == 'inReview'" v-tooltip="'Review pending'" class="pi pi-search"
                style="color: rgb(255, 130, 0)" aria-label="screen pending"></i>
            </div>
          </template>
        </Column>

      </template>
      <template #responsibleEmployee="col">
        <Column :field="col.field" :header="col.header" :sortable="col.sortable" :isFiltered="col.isFiltered">
          <template #body="field">
            <a v-bind:href="'mailto:' + field.data[col.field]">{{ field.data[col.field] }}</a>
          </template>
        </Column>
      </template>
    </ConDataTable>
  </div>
</template>
   
<script setup lang="ts">
import Calendar from 'primevue/calendar';
import { ConDataTable, canThrowAsyncShowToast } from '@congatec/primevue-components-lib';
import { ref, type Ref, watch } from 'vue';
import { VisitorService, type CreateCheckInOutRequest } from '@/apis/neptune-api';
import { VisitService, CheckInOutService } from '@/apis/neptune-api';
import Column from 'primevue/column';
import Toast from 'primevue/toast';
import { useToast } from 'primevue/usetoast';
import Button from 'primevue/button';
import Toolbar from 'primevue/toolbar';
import ConfirmDialog from 'primevue/confirmdialog';
import { useConfirm } from 'primevue/useconfirm';
import InputVisitsDialog  from './../visits/InputVisitsDialog.vue';
import QuickInputVisitsDialog from '../visits/QuickInputVisitsDialog.vue';
import { useVisitStore } from '../visits/store/VisitStore';
import Dropdown from 'primevue/dropdown';


const visitStore = useVisitStore();
const receptionTableRef = ref<null | typeof ConDataTable>(null);
const dates: Ref<Array<Date>> = ref([new Date(), new Date(new Date().setDate(new Date().getDate() + 3))]);

const toast = useToast();
const selectedItems: Ref<any[]> = ref([]);
const showEditVisitDialog = ref<boolean>(false);
const showQuickVisitDialog = ref<boolean>(false);
let selectedVisitBackup: any[] = [];
const branchOffice = ref(visitStore.branchOffice);
const deleteVisitDisabled = ref(true);

let columns = [
  {
    expander: true,
    field: 'expansion',
    header: ''
  },
  {
    selectionMode: "multiple",
    field: "select",
  },
  {
    //selectionMode: undefined,
    field: 'visitorFirstName',
    header: 'First Name',
    isFiltered: true,
    sortable: true,
    toggle: true
  },
  {
    //selectionMode: undefined,
    field: 'visitorLastName',
    header: 'Last Name',
    isFiltered: true,
    sortable: true,
    toggle: true
  },
  {
    field: 'visitorEmail',
    header: 'Email',
    isFiltered: true,
    sortable: true,
    toggle: true
  },
  {
    field: 'visitorCompany',
    header: 'Company',
    isFiltered: true,
    sortable: true,
    toggle: true
  },
  {
    field: 'visitorFunction',
    header: 'Function',
    isFiltered: true,
    sortable: true,
    toggle: true
  },
  {
    field: 'status',
    header: 'Visitor Status',
    isFiltered: false,
    sortable: false,
    toggle: true
  },
  {
    field: 'visitorScreeningStatus',
    header: 'Screening Status',
    isFiltered: false,
    sortable: true,
    toggle: true
  },
  {
    field: 'scheduledCheckIn',
    header: 'Scheduled Check In',
    isFiltered: true,
    sortable: true,
    dataType: 'date',
    toggle: true
  },
  {
    field: 'scheduledCheckOut',
    header: 'Scheduled Check Out',
    isFiltered: true,
    sortable: true,
    dataType: 'date',
    toggle: true
  },
  {
    field: 'responsibleEmployee',
    header: 'Responsible Employee',
    isFiltered: false, // not filter-/sortable because Visit-Entity has no Foreign Key Constraint to User-Entity
    sortable: false,
    toggle: true
  },
  {
    field: 'branchOffice',
    header: 'Branch Office',
    isFiltered: true,
    sortable: true,
    toggle: true
  },
  {
    field: 'meetingRoom',
    header: 'Meeting Room',
    isFiltered: true,
    sortable: true,
    toggle: true
  },
  {
    field: "actionMenu",    
  }
];

let expansionColumns = [
  {
      field: "checkInDateTime",
      header: "Check In Date",
      dataType: "date",
      isFiltered: true,
      sortable: true,
  },
  {
      field: "checkOutDateTime",
      header: "Check Out Date",
      dataType: "date",
      isFiltered: true,
      sortable: true,
  },
];

const branchOffices = ref([
  { id: 1, name: "Deggendorf, CDE", value: "Deggendorf" },
  { id: 2, name: "Plzen, CCZ", value: "Plzen" },
  { id: 3, name: "Brno, CCZ", value: "Brno" },
  { id: 4, name: "Taipei City, CTW", value: "Taipei City" },
  { id: 5, name: "San Diego, CUS", value: "San Diego" },
  { id: 6, name: "West Burleigh, CAU", value: "West Burleigh" },
  { id: 7, name: "Tokyo, CJP", value: "Tokyo" },
  { id: 8, name: "Shanghai, CCN", value: "Shanghai" },
  { id: 9, name: "Seongnam, CKR", value: "Seongnam" },
  { id: 10, name: "London, CUK", value: "London" },
  { id: 11, name: "Paris, CFR", value: "Paris" },
  { id: 12, name: "Ravensburg, RTS", value: "Ravensburg" },
  { id: 13, name: "Wittlich, RTS", value: "Wittlich" }
]);

const selectedVisitIds = ref<number[]>([]);

watch(dates, async () => {
  receptionTableRef.value?.forceReload();
});

watch(branchOffice, async () => {
  visitStore.setBranchOffice(branchOffice.value);
  receptionTableRef.value?.forceReload();
});

watch(selectedItems, async (nv) => {
  console.log("Visit:", nv)

  if (nv.length > 0) {
    deleteVisitDisabled.value = false;
  } else {
    deleteVisitDisabled.value = true;
  }

})

async function handleSelectionChange(selectedRows: any) {
  console.log("handleSelection", selectedRows)
  selectedItems.value = selectedRows;
  selectedVisitIds.value = []
  selectedRows.forEach((visit: { id: number; }) => {
    selectedVisitIds.value.push(visit.id ? visit.id : 0)
  })
}

async function checkInCheckOut(): Promise<any> {
  const request: CreateCheckInOutRequest = {
    visitIds: selectedVisitIds.value
  }
  await CheckInOutService.putApiV1CheckInOutCreateOrEdit(request);
  toast.add({ severity: 'success', summary: 'Successful', detail: 'Successful Check In/Check Out', life: 3000 });
  // selectedVisitIds.value.length = 0;
  // selectedItems.value.length = 0;
  // console.log("selectedItems", selectedItems.value)
  receptionTableRef.value?.forceReload();
}

async function fetchVisitsReceptionDataAsync(filters?: string, sorts?: string, page?: number, rows?: number): Promise<any> {
  if (dates.value[1] == null) {
    return {
      totalCount: 0,
      data: [],
    }
  }
  let fromDate = dates.value[0]
  let toDate = dates.value[1]
  let res = await VisitService.getApiV1VisitReception(filters, sorts, page, rows, new Date(fromDate.setHours(0, 0, 0, 0)).toISOString(), (new Date(toDate.setHours(23, 59, 59, 999)).toISOString()), branchOffice.value);
  return {
    totalCount: res.totalCount,
    data: res.visits,
  }
}

function reloadVisitTableData() {
  receptionTableRef.value?.forceReload();
}

function createVisitActionMenu(row: any): any {


  return [
    {
      label: 'Edit',
      icon: 'pi pi-pencil',
      command: () => {
        selectedVisitBackup = selectedItems.value;
        selectedItems.value = [];
        selectedItems.value.push(row);
        showEditVisitDialog.value = true;
      }
    },
    {
      label: 'Delete',
      icon: 'pi pi-trash',
      command: () => deleteVisit(row)
    },
    {
    label: 'Screen',
    icon: 'pi pi-eye',
    command: () => {
      selectedVisitBackup = selectedItems.value;
      selectedItems.value = [];
      selectedItems.value.push(row);
      screenVisitors();
      selectedItems.value = selectedVisitBackup;
    }
    },
    // timing problem: items are only loaded in onKeycloaktoken
  // {
  //   label: 'Send Email',
  //   icon: 'pi pi-send',
  //   items: emailMenu.value.items?.forEach(async (item: { command: any; key: number | undefined; }) => { if(item) item.command = async () => await canThrowAsyncShowToast(toast, async () => await EmailService.putApiV1EmailSendEmail(row.id, item.key))}) || [],
  // }
  ];
}

async function screenVisitors() {
  for (let visit of selectedItems.value) {
    console.log("scanVisitorId: ", visit.visitorId);
    let status = await canThrowAsyncShowToast(toast, async () => await VisitorService.putApiV1VisitorScreen(visit.visitorId || 0));
    if (status) {
      visit.screeningStatus = status.status;
    }
  }
  receptionTableRef.value?.forceReload();
}

const confirm = useConfirm();
function deleteVisit(row: any) {
  confirm.require({
    header: 'Delete Confirmation',
    icon: 'pi pi-info-circle',
    acceptClass: 'p-button-info',
    //TODO: implement option to see a list of items that will be delted
    message: 'Are you sure you want to delete this Item',
    accept: async () => {
      try {
        await VisitService.deleteApiV1VisitDelete({ visitsIdList: [row.id] });
        toast.add({ severity: 'success', summary: 'Delete', detail: 'Item deleted', life: 3000 });
        reloadVisitTableData();
      } catch (ex: any) {
        toast.add({ severity: 'error', summary: 'Error!', detail: 'An error occured while deleting: ' + ex.message, life: 3000 });
      }
    }
  })
}

const showVisitDialog = () =>
{
  showQuickVisitDialog.value = true;
}

const confirmDelete = () => {
  confirm.require({
    header: 'Delete Confirmation',
    icon: 'pi pi-info-circle',
    acceptClass: 'p-button-info',
    //TODO: implement option to see a list of items that will be delted
    message: 'Are you sure you want to delete this Item(s)',
    accept: async () => {
      try {
        await VisitService.deleteApiV1VisitDelete({ visitsIdList: selectedVisitIds.value });
        toast.add({ severity: 'success', summary: 'Delete', detail: 'Item(s) deleted', life: 3000 });
        selectedItems.value = [];
        // selectedVisitor.value = [];
        
      } catch (ex: any) {
        toast.add({ severity: 'error', summary: 'Error!', detail: 'An error occured while deleting: ' + ex.message, life: 3000 });
      }
      finally {
        reloadVisitTableData();
      }
    }
  })
}
</script>






