<template>
  <div>
    <ConfirmDialog />
    <ConPopupDialog :visible="showCreateDialog" title="Create Template" @onHide="showCreateDialog = false">
      <TemplateInput @onSubmitted="() => { showCreateDialog = false; table.forceReload(); }"
        @onCancel="showCreateDialog = false" />
    </ConPopupDialog>

    <ConPopupDialog :visible="showEditDialog" title="Edit Template" @onHide="showEditDialog = false">
      <TemplateInput :template="selection[0]" @onSubmitted="() => { showEditDialog = false; table.forceReload(); }"
        @onCancel="showEditDialog = false" />
    </ConPopupDialog>

    <Toolbar>
      <template #start>
        <div class="flex flex-row gap-3">
          <ConKebabMenu :menuItems="templatesMenuItems" buttonLabel="Templates" buttonIcon="pi pi-send" />
        </div>
      </template>
    </Toolbar>

    <ConDataTable ref="table" :columns="columns" :onReload="onReload" filterDisplay="menu"
      @onSelectionChanged="onSelectionChanged">
      <template #defaultTemplate="col">
        <Column :field="col.field" :header="col.header" :sortable="col.sortable" dataType="boolean">
          <template #body="field">
            <i class="pi pi-check" v-if="field.data.defaultTemplate == true"></i>
            <i class="pi pi-times" v-else-if="field.data.defaultTemplate == false"></i>
          </template>
          <template #filter="{ filterModel }">
            <TriStateCheckbox :onChange="() => onFilterChanged(filterModel)" v-model="boolFilter" />
          </template>
        </Column>
      </template>
      <template #sendNotification="col">
        <Column :field="col.field" :header="col.header" :sortable="col.sortable" dataType="boolean">
          <template #body="field">
            <i class="pi pi-check" v-if="field.data.sendNotification == true"></i>
            <i class="pi pi-times" v-else-if="field.data.sendNotification == false"></i>
          </template>
        </Column>
      </template>
    </ConDataTable>
  </div>
</template>

<script setup lang="ts">
import { canThrowAsyncShowToast, ConDataTable } from '@congatec/primevue-components-lib';
import { EmailService } from '@/apis/neptune-api'
import { ref, type Ref } from 'vue';
import { ConKebabMenu } from '@congatec/primevue-components-lib';
import { ConPopupDialog } from '@congatec/primevue-components-lib';
import { useToast } from 'primevue/usetoast';
import { useConfirm } from "primevue/useconfirm";
import ConfirmDialog from 'primevue/confirmdialog';
import TemplateInput from './TemplateInput.vue';
import Toolbar from 'primevue/toolbar';
import Column from 'primevue/column';
import TriStateCheckbox from 'primevue/tristatecheckbox';

const table = ref();
const toast = useToast();
const showCreateDialog = ref(false);
const showEditDialog = ref(false);
const boolFilter: Ref<boolean | null> = ref(null);

const selection = ref();
const confirm = useConfirm();
const confirmDelete = () => {
  confirm.require({
    header: 'Are you sure?',
    icon: 'pi pi-info-circle',
    acceptClass: 'p-button-info',
    message: 'Are you sure you want to delete the selected Item(s)',
    accept: async () => {
      const ids = selection.value.map((x: any) => x.id) as number[];
      await EmailService.deleteApiV1EmailDeleteTemplate({ emailTemplateIds: ids });
      table.value.forceReload();
    }
  })
}

const disableDeleteAndDownload = ref(true);
const disableEdit = ref(true);

const templatesMenuItems = ref([
  {
    label: 'Create',
    icon: 'pi pi-plus',
    command: () => {
      showCreateDialog.value = true;
    }
  },
  {
    label: 'Edit',
    icon: 'pi pi-pencil',
    disabled: disableEdit,
    command: () => {
      showEditDialog.value = true;
    }
  },
  {
    label: 'Delete',
    icon: 'pi pi-trash',
    command: confirmDelete,
    disabled: disableDeleteAndDownload
  }
]);

const columns = [
  {
    selectionMode: 'multiple',
    field: 'any'
  },
  {
    field: "name",
    header: "Name",
    dataType: "text",
    isFiltered: true,
    sortable: true,
  },
  {
    field: "subject",
    header: "Subject",
    dataType: "text",
    isFiltered: true,
    sortable: true,
  },
  {
    field: "branchOffice",
    header: "Branch Office",
    dataType: "text",
    isFiltered: true,
    sortable: true,
  },
  {
    field: "defaultTemplate",
    header: "Default",
    dataType: "boolean",
    isFiltered: true,
    sortable: true,
  },
  {
    field: "sendNotification",
    header: "Send Notification",
    dataType: "boolean",
    isFiltered: false,
    sortable: false,
  },
  // {
  //   field: "notifySecondsBeforeVisit",
  //   header: "Notify Seconds Before Visit",
  //   dataType: "text",
  //   isFiltered: false,
  //   sortable: false,
  // },
]

const onSelectionChanged = (newSelection: any) => {
  selection.value = newSelection;
  disableDeleteAndDownload.value = selection.value?.length <= 0
  disableEdit.value = selection.value?.length != 1
}


const onReload = async (filters: string, sorts: string, page: number, rows: number) => {
  let res = await canThrowAsyncShowToast(toast, async () => await EmailService.getApiV1EmailListTemplate(filters, sorts, page, rows));
  return {
    totalCount: res.totalCount || 0,
    data: res.templates || []
  };
}

const onFilterChanged = (filterModel: any) => {
    filterModel.value = boolFilter.value?.toString() || ""; 
}

</script>
