/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ReleaseRuleEntity {
    NONE = 'none',
    BUNDLE = 'bundle',
    ARTIFACT = 'artifact',
}
