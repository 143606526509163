<template>
  <div v-if="isAdmin" class="w-full">
    <TabView class="w-full">

      <TabPanel header="rpt-extract-latest">
        <div class="flex justify-content-around">
          <Card style="width: 50%">
            <template #title>
              <span>Rpt Extract latest</span>
            </template>
            <template #content>
              <div class="flex justify-content-center">
                <!-- <div v-if="isLoading">
                  <ProgressSpinner indeterminate color="primary"> </ProgressSpinner>
                  <span>Execute Data</span>
                </div> -->
                <div class="w-full">
                  <span class="p-float-label">
                    <InputNumber v-model="amountInput" id="input" class="w-full" />
                    <label class="inputdiv" for="input">Amount</label>
                  </span>
                </div>
              </div>
            </template>
            <template #footer>
              <div class="flex justify-content-center">
                <Button @click="fetchResponseExtractLatest()" :loading="isLoading" icon="pi pi-play" label="Excecute">
                </Button>
              </div>
            </template>
          </Card>
        </div>
      </TabPanel>

      <TabPanel header="rpt-extract">
        <div class="flex justify-content-around">
          <Card style="width: 50%">
            <template #title>
              <span>Rpt Extract</span>
            </template>
            <template #content>
              <div class="flex flex-column justify-content-center">
                <!-- <div v-if="isLoading">
                  <ProgressSpinner indeterminate color="primary"> </ProgressSpinner>
                  <span>Execute Data</span>
                </div> -->
                <div>
                  <span class="p-float-label">
                    <InputNumber v-model="fromRevisionInput" class="w-full" />
                    <label class="inputdiv" for="input">From Revision</label>
                  </span>
                </div>
                <div>
                  <span class="p-float-label">
                    <InputNumber v-model="toRevisionInput" class="w-full" />
                    <label class="inputdiv" for="input">To Revision</label>
                  </span>
                </div>
              </div>
            </template>
            <template #footer>
              <div class="flex justify-content-center">
                <Button @click="fetchResponseExtract()" :loading="isLoading" icon="pi pi-play" label="Excecute">
                </Button>
              </div>
            </template>
          </Card>
        </div>
      </TabPanel>

      <TabPanel header="rpt-parse-latest">
        <div class="flex justify-content-around">
          <Card style="width: 50%">
            <template #title>
              <span>Rpt Parse Last</span>
            </template>
            <template #content>
              <!-- <div v-if="isLoading">
                <ProgressSpinner indeterminate color="primary"> </ProgressSpinner>
                <span>Execute Data</span>
              </div> -->
              <div class="flex flex-column justify-content-center">
                <span class="p-float-label">
                  <InputNumber v-model="amountInput" class="w-full" />
                  <label class="inputdiv" for="input">Amount</label>
                </span>
              </div>
            </template>
            <template #footer>
              <div class="flex justify-content-center">
                <Button @click="fetchResponseParseLast()" :loading="isLoading" icon="pi pi-play" label="Excecute">
                </Button>
              </div>
            </template>
          </Card>
        </div>
      </TabPanel>
      <TabPanel header="rpt-parse">
        <div class="flex justify-content-around">
          <Card style="width: 50%">
            <template #title>
              <span>Rpt Extract</span>
            </template>
            <template #content>
              <!-- <div v-if="isLoading">
                <ProgressSpinner indeterminate color="primary"> </ProgressSpinner>
                <span>Execute Data</span>
              </div> -->
              <div class="flex flex-column justify-content-center">
                <span class="p-float-label">
                  <InputNumber v-model="fromSysExtractAuditIdInput" class="w-full" />
                  <label class="inputdiv" for="input">Sys Extract Audit Id</label>
                </span>
              </div>
              <div>
                <span class="p-float-label">
                  <InputNumber v-model="toSysExtractAuditIdInput" class="w-full" />
                  <label class="inputdiv" for="input">To SysExtractAudit</label>
                </span>
              </div>
            </template>
            <template #footer>
              <div class="flex justify-content-center">
                <Button @click="fetchResponseParse()" :loading="isLoading" icon="pi pi-play" label="Excecute">
                </Button>
              </div>
            </template>
          </Card>
        </div>
      </TabPanel>
    </TabView>
  </div>
</template>
  
  
<script setup lang="ts">
import { defineComponent, ref } from "vue";

import Card from 'primevue/card';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import 'primeicons/primeicons.css';
import {
  SvnExtractionsService,
} from "@/apis/eris-api";
import { useToast } from "primevue/usetoast";
import InputNumber from 'primevue/inputnumber';
import Button from "primevue/button";
import ProgressSpinner from 'primevue/progressspinner';
import { onKeycloakToken } from "@congatec/authentication-lib";
import type { KeycloakService } from "@congatec/authentication-lib";

const isAdmin = ref(false);
onKeycloakToken(async (authenticationService: KeycloakService) => {
  isAdmin.value = authenticationService.hasRoles(["Admin", "SystemAdmin"],import.meta.env.VITE_KEYCLOAK_JUPITER_CLIENTID);

});

let amountInput: number;
const toast = useToast();
let fromRevisionInput: number;
let toRevisionInput: number;
let fromSysExtractAuditIdInput: number;
let toSysExtractAuditIdInput: number;
const isLoading = ref(false);


async function fetchResponseExtractLatest() {
  isLoading.value = true;
  SvnExtractionsService.postApiV1SvnExtractionRptFilesExtractLatest({ amount: amountInput })
    .then((response: any) => {
      if (response) {
        toast.add({
          severity: "success",
          summary: "Proccess successful Amount of Rpt Revisions",
          life: 10000,
        });
        console.log("response", response)
        isLoading.value = false;
      }
    }
    )
}

async function fetchResponseExtract() {
  isLoading.value = true;
  SvnExtractionsService.postApiV1SvnExtractionRptFilesExtract({ fromRevision: fromRevisionInput, toRevision: toRevisionInput })
    .then((response: any) => {
      if (response) {
        toast.add({
          severity: "success",
          summary: "Proccess successful Rpt to Revisions",
          life: 5000,
        });
        console.log("response", response)
        isLoading.value = false;
      }
    }
    )
}
async function fetchResponseParseLast() {
  isLoading.value = true;
  SvnExtractionsService.postApiV1SvnExtractionRptFilesParseLatest({ amount: amountInput })
    .then((response: any) => {
      if (response) {
        toast.add({
          severity: "success",
          summary: "Proccess successful amount of Rpt",
          life: 3000,
        });
        console.log("response", response)
        isLoading.value = false;
      }
    }
    )
}
async function fetchResponseParse() {
  isLoading.value = true;
  SvnExtractionsService.postApiV1SvnExtractionRptFilesParse({ fromSysExtractAuditId: fromSysExtractAuditIdInput, toSysExtractAuditId: toSysExtractAuditIdInput })
    .then((response: any) => {
      if (response) {
        toast.add({
          severity: "success",
          summary: "Proccess Rpt successful to SysExtract",
          life: 3000,
        });
        console.log("response", response)
        isLoading.value = false;
      }
    }
    )
}
</script>
  
<script lang="ts">
export default defineComponent({
  name: "AdminRevisionView",
});
</script>
  
<style lang="css"> .executeButton {
   display: flex;
 }

 .p-float-label {
   width: auto;
   margin-bottom: 2%;
 }

 .inputdiv {
   width: 95%;
 }

 .p-card {
   width: auto;
   display: inline-block;
   /* margin-top: 5%; */
   /* text-align: center; */
 }
</style>