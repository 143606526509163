import {
  HardwareDetails,
  HardwareList,
  HardwareInput,
  HardwareScan,
  HardwareSignout,
  LocationList,
  DepartmentList,
  SaturnHomeView,
  BranchOfficeList
} from "@/saturn/components";
import { to } from "@congatec/primevue-components-lib";


const saturnRoutes = [
  {
    path: "/saturn/home",
    name: "SaturnHome",
    component: SaturnHomeView,
    meta: {
      breadcrumb: [{ title: "Saturn", command:to('/saturn/home') }, { label: "Home" }],
    },
  },
  {
    path: "/saturn/hw-list",
    name: "SaturnHardwareList",
    component: HardwareList,
    meta: {
      breadcrumb: [{ label: "Saturn", command:to('/saturn/home') }, { label: "Hardware List" }],
    },
  },
  {
    path: "/saturn/hw-details/:id",
    name: "SaturnHardwareDetails",
    component: HardwareDetails,
    meta: {
      breadcrumb: [{ label: "Saturn", command:to('/saturn/home') }, { label: "Hardware Details" }],
    },
  },
  {
    path: "/saturn/hw-input",
    name: "SaturnHardwareInput",
    component: HardwareInput,
    meta: {
      breadcrumb: [{ label: "Saturn", command:to('/saturn/home') }, { label: "Hardware Inputs" }],
    },
  },
  {
    path: "/saturn/hw-scan",
    name: "SaturnHardwareScan",
    component: HardwareScan,
    meta: {
      breadcrumb: [{ label: "Saturn", command:to('/saturn/home') }, { label: "Hardware Scan" }],
    },
  },
  {
    path: '/saturn/hw-signout/:guid',
    name: 'SaturnHardwareSignout',
    component: HardwareSignout,
    props: true,
    meta: {
      breadcrumb: [{ label: "Saturn", command:to('/saturn/home') }, { label: "Hardware Signout" }],
    },
  },
  {
    path: '/saturn/locations',
    name: 'SaturnLocationList',
    component: LocationList,
    meta: {
      breadcrumb: [{ label: "Saturn", command:to('/saturn/home') }, { label: "Location List" }],
    },
  },
  {
    path: '/saturn/departments',
    name: 'SaturnDepartmentList',
    component: DepartmentList,
    meta: {
      breadcrumb: [{ label: "Saturn", command:to('/saturn/home') }, { label: "Department List" }],
    },
  },
  {
    path: '/saturn/branchoffices',
    name: 'SaturnBranchOfficeList',
    component: BranchOfficeList,
    meta: {
      breadcrumb: [{ label: "Saturn", command:to('/saturn/home') }, { label: "Branch Office List" }],
    },
  },
];

export default saturnRoutes;
