/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CalendarEventCreateRequest } from '../models/CalendarEventCreateRequest';
import type { CalendarEventCreateResponse } from '../models/CalendarEventCreateResponse';
import type { CalendarEventDeleteRequest } from '../models/CalendarEventDeleteRequest';
import type { CalendarEventDeleteResponse } from '../models/CalendarEventDeleteResponse';
import type { CalendarEventListRequest } from '../models/CalendarEventListRequest';
import type { CalendarEventListResponse } from '../models/CalendarEventListResponse';
import type { CalendarEventUpdateRequest } from '../models/CalendarEventUpdateRequest';
import type { CalendarEventUpdateResponse } from '../models/CalendarEventUpdateResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CalendarEventsService {

    /**
     * @param requestBody 
     * @returns CalendarEventCreateResponse Success
     * @throws ApiError
     */
    public static postApiV1CalendarEventsCreate(
requestBody?: CalendarEventCreateRequest,
): CancelablePromise<CalendarEventCreateResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/CalendarEvents/Create',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns CalendarEventUpdateResponse Success
     * @throws ApiError
     */
    public static putApiV1CalendarEventsUpdate(
requestBody?: CalendarEventUpdateRequest,
): CancelablePromise<CalendarEventUpdateResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/CalendarEvents/Update',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns CalendarEventDeleteResponse Success
     * @throws ApiError
     */
    public static deleteApiV1CalendarEventsDelete(
requestBody?: CalendarEventDeleteRequest,
): CancelablePromise<CalendarEventDeleteResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/CalendarEvents/Delete',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns CalendarEventListResponse Success
     * @throws ApiError
     */
    public static postApiV1CalendarEventsList(
requestBody?: CalendarEventListRequest,
): CancelablePromise<CalendarEventListResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/CalendarEvents/List',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
