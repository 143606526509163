<template>
  <div class="flex grid">
    <DynamicDialog />
    <Card class="col-6">
      <template #title>
        Sign out
      </template>
      <template #content>
        <form @submit.prevent="validateAndSubmit()" class="p-fluid">
          <div class="hw-signout-layout">
            <h5>Expected return date</h5>
            <Calendar v-model="inputData.expectedReturnDate" dateFormat="yy-mm-dd" class="full-size" />


            <Divider />
            <div>
              <h5>Remark</h5>
              <WrappedSkeleton :initialized="initialized">
                <Textarea v-model="inputData.remark" :autoResize="true" rows="5" cols="30" class="full-size" />
              </WrappedSkeleton>
            </div>
            <Divider />
            <Button @click="onUserScanClick" label="Scan User Token" />
            <Divider />
            <Button label="Submit" @click="validateAndSubmit()"></Button>
          </div>
        </form>
      </template>
    </Card>
  </div>
</template>


<script lang="ts">

export default defineComponent({
  name: "HardwareSignout",
  components: {},
});
</script>

<script setup lang="ts">
import { defineComponent } from "vue";
import { ref } from "vue";
import { SignOutService } from '@/apis/saturn-api';
import { AuthenticationObserver } from "@/base/AuthenticationObserver";
import { useKeycloak } from "@congatec/authentication-lib";
import { useToast } from 'primevue/usetoast';
import { useRouter, useRoute } from 'vue-router';
import { useDialog } from "primevue/usedialog";
import UserScan from "./UserScan.vue";
import { WrappedSkeleton } from '@/saturn/components';
import DynamicDialog from 'primevue/dynamicdialog';
import Calendar from 'primevue/calendar';
import Divider from 'primevue/divider';
import Button from 'primevue/button';
import Textarea from 'primevue/textarea';
import Card from 'primevue/card';

const toast = useToast();
const dialog = useDialog();
let authenticationService = useKeycloak();
let authObserver = new AuthenticationObserver(authenticationService);
let initialized = ref(true);
const router = useRouter();
const route = useRoute();
const guid = route.params['guid'].toString();
let user: any = undefined;
let employeeCardGuid: any = undefined;

class InputData {
  public remark: string = "";
  public expectedReturnDate: Date | undefined = new Date();
}

let inputData = ref(new InputData());

const validateAndSubmit = async () => {
  try {
    await SignOutService.postApiV1SignOut({
      guid: guid,
      remark: inputData.value.remark,
      expectedReturnDate: inputData.value.expectedReturnDate?.toISOString(),
      signOutAs: user,
      employeeCardGuid: employeeCardGuid
    });
    toast
      .add({
        severity: 'success',
        summary: 'Sample signed out!',
        life: 3000
      });
    setTimeout(() => { router.back() }, 3000)
  } catch (exception: any) {
    toast
      .add({
        severity: 'error',
        summary: 'Could not sign out sample! Has it already been signed out?',
        life: 3000
      });
  }
}

const onUserScanClick = () => {
  dialog.open(UserScan, {
    onClose: (newUser: any) => {
      if (newUser.Guid) {
        // haumea employee card was scanned
        employeeCardGuid = newUser.Guid;
      } else {
        // legacy saturn qr code was scanned 
        user = newUser.data;
      }
      console.log("Scanned ", user);
    }
  });
}

authObserver.onLoggedIn(async () => {
});


</script>

<style>
.hw-signout-view {
  margin: 5%;
}

.hw-signout-card {
  margin: 2%;
}

.hw-signout-layout {
  width: 100%
}

.full-size {
  width: 100%;
}</style>
