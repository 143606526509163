<template>
  <div class="w-full flex flex-column gap-3">
    <div class="w-full flex flex-column gap-2">
      <label for="name">Name<span>*</span></label>
      <InputText id="name" v-model="inputData.name" type="text" placeholder="insert name here"
        :class="{ 'input-invalid': v$.name.$invalid && v$.name.$error, 'h-3rem': true }" />
      <span v-if="v$.name.$error">{{
        v$.name.$errors[0].$message }} </span>
    </div>
    <div class="w-full flex flex-column gap-2">
      <label for="serialNumber">Serial Number</label>
      <InputText id="serialNumber" v-model="inputData.serialNumber" type="text" placeholder="insert Serial Number here" />
    </div>
    <div class="w-full flex flex-column gap-2">
      <label for="partNumber">Part Number<span>*</span></label>
      <InputText id="partNumber" v-model="inputData.partNumber" type="text" placeholder="insert Part Number here"
        :class="{ 'input-invalid': v$.partNumber.$invalid && v$.partNumber.$error, 'h-3rem': true }" />
      <span v-if="v$.partNumber.$error">{{
        v$.partNumber.$errors[0].$message }} </span>
    </div>
    <div class="w-full flex flex-column gap-2">
      <label for="lot">Lot</label>
      <InputText id="lot" v-model="inputData.lot" type="text" placeholder="insert Lot here" />
    </div>
    <div class="w-full flex flex-column gap-2">
      <label for="hwrev">Hardware Revision</label>
      <InputText id="hwrev" v-model="inputData.hardwareRevision" type="text"
        placeholder="insert Hardware Revision here" />
    </div>
    <div class="mt-2 flex flex-column gap-2">
      <h5 class="m-0">Department<span>*</span></h5>
      <Dropdown v-model="inputData.department" :options="departments" optionLabel="label"
        placeholder="Select a department" :filter="true" filterPlaceholder="Find department"
        :class="{ 'input-invalid': v$.department.$invalid && v$.department.$error, 'h-3rem': true }" />
      <span v-if="v$.department.$error">{{
        v$.department.$errors[0].$message }} </span>
    </div>
    <div class="mt-2 flex flex-column gap-2">
      <h5 class="m-0">Location<span>*</span></h5>
      <Dropdown v-model="inputData.location" :options="locations" optionLabel="label" placeholder="Select a location"
        :filter="true" filterPlaceholder="Find location"
        :class="{ 'input-invalid': v$.location.$invalid && v$.location.$error, 'h-3rem': true }" />
      <span v-if="v$.location.$error">{{
        v$.location.$errors[0].$message }} </span>
      <Divider />

    </div>
    <Panel class="mb-3 mt-2 flex flex-column" id="additionalPanel" header="Additional Information" toggleable
      :collapsed="true">
      <div class="w-full flex flex-column gap-2">
        <label for="po">Production Order</label>
        <InputText id="po" v-model="inputData.productuionOrder" type="text" placeholder="insert Production Order here" />
      </div>

      <div class="w-full flex flex-column gap-2">
        <label for="bios">BIOS</label>
        <InputText id="bios" v-model="inputData.biosFile" type="text" placeholder="insert Bios here" />
      </div>

      <div class="w-full flex flex-column gap-2">
        <label for="cmos">CMOS</label>
        <InputText id="cmos" v-model="inputData.cmos" type="text" placeholder="insert Cmos here" />
      </div>
      <div class="w-full flex flex-column gap-2">
        <label for="customerRev">Customer Revision</label>
        <InputText id="customerRev" v-model="inputData.customerRevision" type="text"
          placeholder="insert Customer Revision here" />
      </div>
      <div class="w-full flex flex-column gap-2">
        <label for="packageRev">Package Revision</label>
        <InputText id="packageRev" v-model="inputData.packageRevision" type="text"
          placeholder="insert Package Revision here" />
      </div>
      <div class="w-full flex flex-column gap-2">
        <label for="ean">Ean</label>
        <InputText id="ean" v-model="inputData.ean" type="text" placeholder="insert Ean here" />
      </div>
    </Panel>
    <div class="mt-2 gap-2 flex flex-column">
      <h5 class="m-0">Keep sample until</h5>
      <Calendar v-model="inputData.keepUntil" dateFormat="yy-mm-dd" class="w-full" />
    </div>
    <div class="mt-2 gap-2 flex flex-column">
      <h5 class="m-0">Quantity</h5>
      <InputNumber :min="0" v-model="inputData.quantity" />
    </div>
    <Divider />
    <div class=" gap-2 flex flex-column">
      <h5 class="m-0">Remark</h5>
      <Textarea v-model="inputData.remark" :autoResize="true" rows="5" cols="30" class="full-size" />
    </div>
    <div class="mt-4">
      <Button label="submit" @click="validateAndSubmit()"></Button>
    </div>
  </div>
</template>


<script lang="ts">

export type Props = {
  id?: number
}

export default defineComponent({
  name: "HardwareInput",
  components: {},
});
</script>

<script setup lang="ts">
import { defineComponent, watch } from "vue";
import { ref, inject, type Ref } from "vue";
import { DepartmentsService, HardwareSamplesService, ItemsService, LocationsService, ManufacturedHardwareSampleService } from '@/apis/saturn-api';
import { AuthenticationObserver } from "@/base/AuthenticationObserver";
import { useKeycloak } from "@congatec/authentication-lib";
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { useToast } from 'primevue/usetoast';
import InputText from "primevue/inputtext";
import Panel from "primevue/panel";
import Textarea from 'primevue/textarea';
import Button from 'primevue/button';
import Calendar from 'primevue/calendar';
import Divider from 'primevue/divider';
import Dropdown from 'primevue/dropdown';
import { useDelayTimer, delay } from "@congatec/primevue-components-lib";
import InputNumber from "primevue/inputnumber";

const toast = useToast();

const props = withDefaults(defineProps<Props>(), {});

let authenticationService = useKeycloak();
let authObserver = new AuthenticationObserver(authenticationService);

class InputData {
  public productuionOrder: string = "";
  public hardwareRevision: string = "";
  public biosFile: string = "";
  public cmos: string = "";
  public lot: string = "";
  public partNumber: string = "";
  public ean: string = "";
  public customerRevision: string = "";
  public packageRevision: string = "";
  public remark: string = "";
  public keepUntil: Date | undefined = new Date()
  public name: string = "";
  public quantity: number = 1;

  public serialNumber: Ref<string>;
  public location: Ref<any>;
  public department: Ref<any>;

  constructor(serialNumber: Ref<string>, department: Ref<any>, location: Ref<any>) {
    this.serialNumber = serialNumber;
    this.department = department;
    this.location = location;
  }

  clear() {
    this.productuionOrder = "";
    this.hardwareRevision = "";
    this.biosFile = "";
    this.ean = "";
    this.customerRevision = "";
    this.packageRevision = "";
    this.lot = "";
  }
}

enum InputFields {
  ProductionOrder,
  HardwareRevision,
  BiosFile,
  Cmos,
  Lot,
  PartNumber,
  Ean,
  CustomerRevision,
  PackageRevision,
  Name,
  Quantity,
}

const inputFields = [
  { label: "Production Order", key: InputFields.ProductionOrder },
  { label: "Hardware Revision", key: InputFields.HardwareRevision },
  { label: "Bios File", key: InputFields.BiosFile },
  { label: "Cmos", key: InputFields.Cmos },
  { label: "Lot", key: InputFields.Lot },
  { label: "Part Number", key: InputFields.PartNumber },
  { label: "Customer Revision", key: InputFields.CustomerRevision },
  { label: "Package Revision", key: InputFields.PackageRevision }];



let initialized = ref(false);
let departments: Ref<any> = ref([]);
const dialogRef: any = inject('dialogRef');
let locations: Ref<any> = ref([]);
let loading = ref(false);

let department = ref();
let location = ref();
let serialNumber = ref("");
let submitted = ref(false);
const snrDelayTimer = useDelayTimer();

let inputData = ref(new InputData(serialNumber, department, location));
const id = props.id || dialogRef.value.data?.id || 0;

const rules = {
  ean: {},
  department: { required },
  location: { required },
  name: { required },
  partNumber: { required }
};

const itemId = ref();
const hardwareSampleId = ref();

let skipImport = false;
let skipNextDepartmentChange = false;

const v$ = useVuelidate(rules, inputData)

const onImport = async () => {
  if (skipImport) {
    return;
  }

  let requestedSnr = inputData.value.serialNumber;
  console.log('Requesting ', requestedSnr);
  try {
    loading.value = true;
    let response = await ManufacturedHardwareSampleService
      .getApiV1ManufacturedHardwareSampleImport(inputData.value.serialNumber);
    // only actually import the data if the user has not 
    // changed the serial number since making the request! 
    // this avoids a data race
    if (requestedSnr == inputData.value.serialNumber) {
      console.log('Import', response);
      inputData.value.productuionOrder = response.productionOrder || "";
      inputData.value.hardwareRevision = response.hardwareRevision || "";
      inputData.value.biosFile = response.biosFile || "";
      inputData.value.lot = response.lot || "";
      inputData.value.cmos = response.cmos || "";
      inputData.value.partNumber = response.partNumber || "";
      inputData.value.ean = response.ean || "";
      inputData.value.customerRevision = response.customerRevision || "";
      inputData.value.packageRevision = response.packageRevision || "";
      inputData.value.serialNumber = requestedSnr;
      inputData.value.department = departments.value[0];
      inputData.value.location = locations.value[0];
    }
  } catch (ex: any) {
    console.error(ex);
    if (inputData.value.serialNumber == requestedSnr) {
      inputData.value.clear();
    }
  }
  loading.value = false;
};

const onDepartmentChanged = async () => {
  locations.value = (await LocationsService
    .getApiV1Locations(inputData.value.department.id)).data || []
  locations.value.forEach((x: any) => { 
    (x as any).label = `${x.officeName} - ${x.name}`;
    (x as any)._id = x.id;
  });
  console.log('Locations ', locations.value)
  inputData.value.location = locations.value[0];

  initialized.value = true;
}

const validateAndSubmit = async () => {
  submitted.value = true;
  await v$.value.$reset();
  await v$.value.$validate();
  if (v$.value.$error) {
    throw 'Vuelidate failed!';
  }
  console.log('Submitting ', inputData);

  try {
    let text = "Sample Created";
    if (id) {
      await submitUpdate();
      text = "Sample Updated";
    } else {
      await submitNewItem();
    }
    toast
      .add({
        severity: 'success',
        summary: text,
        life: 3000
      });
    inputData.value.clear();

    dialogRef.value.close();
  } catch (ex: any) {
    toast
      .add({
        severity: 'error',
        summary: 'Error',
        detail: 'Hardware sample creation failed! Does the sample already exist?',
        life: 3000
      });
  }
  submitted.value = false;
}

const submitNewItem = async () => {
  await ManufacturedHardwareSampleService
    .postApiV1ManufacturedHardwareSample(
      {
        serialNumber: inputData.value.serialNumber,
        productionOrder: inputData.value.productuionOrder,
        customerRevision: inputData.value.customerRevision,
        packageRevision: inputData.value.packageRevision,
        lot: inputData.value.lot,
        cmos: inputData.value.cmos,
        bios: inputData.value.biosFile,
        keepUntil: inputData.value.keepUntil?.toISOString(),
        itemCreateRequest: {
          departmentId: inputData.value.department.id,
          locationId: inputData.value.location.id,
          remark: inputData.value.remark,
          quantity: inputData.value.quantity,
          name: inputData.value.name
        },
        hardwareSampleCreateRequest: {
          partNumber: inputData.value.partNumber,
          hardwareRevision: inputData.value.hardwareRevision,
          ean: inputData.value.ean
        }
      }
    );
}

const submitUpdate = async () => {
  await ManufacturedHardwareSampleService
    .putApiV1ManufacturedHardwareSample(
      {
        id: id,
        serialNumber: inputData.value.serialNumber,
        productionOrder: inputData.value.productuionOrder,
        customerRevision: inputData.value.customerRevision,
        packageRevision: inputData.value.packageRevision,
        lot: inputData.value.lot,
        cmos: inputData.value.cmos,
        bios: inputData.value.biosFile,
        keepUntil: inputData.value.keepUntil?.toISOString(),
        hardwareSampleId: hardwareSampleId.value
      }
    );
  await ItemsService.putApiV1Items({
    id: itemId.value,
    departmentId: inputData.value.department.id,
    locationId: inputData.value.location.id,
    remark: inputData.value.remark,
    quantity: inputData.value.quantity,
    name: inputData.value.name
  });
  await HardwareSamplesService.putApiV1HardwareSamples({
    id: hardwareSampleId.value,
    partNumber: inputData.value.partNumber,
    hardwareRevision: inputData.value.hardwareRevision,
    ean: inputData.value.ean
  });
}

const fetchExistingData = async (id: number) => {
  const item = await ManufacturedHardwareSampleService.getApiV1ManufacturedHardwareSample1(id);
  // do not import data from eris...
  skipImport = true;
  skipNextDepartmentChange = true;
  // apply existing data to input buffer
  inputData.value.biosFile = item.bios || "";
  inputData.value.cmos = item.cmos || "";
  inputData.value.ean = item.hardwareSample?.ean || "";
  inputData.value.hardwareRevision = item.hardwareSample?.hardwareRevision || "";
  inputData.value.partNumber = item.hardwareSample?.partNumber || "";
  inputData.value.keepUntil = new Date(item.keepUntil || "") || Date.now();
  inputData.value.serialNumber = item.serialNumber || "";
  inputData.value.customerRevision = item.customerRevision || "";
  inputData.value.lot = item.lot || "";
  inputData.value.packageRevision = item.packageRevision || "";
  inputData.value.remark = item.item?.remark || "";
  inputData.value.name = item.item?.name || "";
  inputData.value.quantity = item.item?.quantity || 0;


  // lastly select the correct office and location in the dropdown menu
  for (let d of departments.value) {
    if (d.id == item.item?.department?.id) {
      inputData.value.department = d;
      break;
    }
  }
  await onDepartmentChanged();
  for (let l of locations.value) {
    if (l.id == item.item?.location?.id) {
      inputData.value.location = l;
      break;
    }
  }

  // store item and hardware sample id for the update call 
  hardwareSampleId.value = item.hardwareSample?.id;
  itemId.value = item.item?.id;
}

watch(serialNumber, async () => {
  delay(snrDelayTimer, onImport, 500);
});

watch(department, async () => {
  if (skipNextDepartmentChange) {
    skipNextDepartmentChange = false;
    return;
  }
  await onDepartmentChanged();
});

authObserver.onLoggedIn(async () => {
  // now we are logged in!
  departments.value = ((await DepartmentsService.getApiV1Departments()).data || []);
  departments.value.forEach((x: any) => {
    (x as any).label = `${x.officeName} - ${x.name}`;
    (x as any)._id = x.id;
  });

  inputData.value.department = departments.value[0];

  if (id) {
    await fetchExistingData(id);
  }
});


</script>

<style>
#additionalPanel .p-panel-content {
  display: flex;
  height: 100%;
  flex-direction: column;
  gap: 1.5rem;
}


</style>
<style scoped>
.input-invalid {
    border: 1px solid var(--primary-color);
}

.input-invalid:hover {
    border: 1px solid var(--primary-color) !important;
}
span {
    color: var(--primary-color);
    font-weight: 600;
}</style>
