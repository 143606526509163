<template>
    <ConfirmDialog />
    <EditReleaseRule :visible="showEditRuleDialog" :rule="selectedRule[0]" :existing-rules="existingRules" :on-hide="(edited: any) => {
        showEditRuleDialog = false,
            reloadRulesTable();
        selectedRule = selectedRuleBackup;
    }" />
    <div>
        <div class="m-5 mt-7">
            <ConDataTable tableId="ReleaseRulesList" :title="' '" :columns="rulesTableColumns" filterDisplay="menu"
                :rowsPerPageOptions="[10, 20, 50]" :onReload="fetchRulesDataAsync" :createActionMenu="createRuleActionMenu"
                ref="rulesTableRef">
                <template #defaultTable="col">
                    <Column :field="col.field" :header="col.header" dataType="boolean" class="text-center">
                        <template #body="field">
                            <i class="pi pi-check" style=" color: rgb(23, 201, 100)"
                                v-if="field.data.defaultTable == false"></i>
                            <i class="pi pi-times" style="color: rgb(218, 50, 8)"
                                v-if="field.data.defaultTable == true"></i>
                        </template>
                        <template #filter="{ filterModel, filterCallback }" v-if="col.isFiltered">
                            <TriStateCheckbox v-if="col.dataType == 'boolean'" v-model="filterModel.value[0]"
                                @input="filterCallback()" />
                        </template>
                    </Column>
                </template>
            </ConDataTable>
        </div>
    </div>
</template>
<script setup lang="ts">
import { ConDataTable } from "@congatec/primevue-components-lib";
import { useToast } from "primevue/usetoast";
import { ref } from "vue";
import { useConfirm } from "primevue/useconfirm";
import {
    ReleaseService,
    type ReleaseRuleTableListTable,
} from "@/apis/aready-api";
import ConfirmDialog from "primevue/confirmdialog";
import Column from 'primevue/column';
import EditReleaseRule from "./EditReleaseRule.vue";
import TriStateCheckbox from "primevue/tristatecheckbox";

const rulesTableRef = ref<null | typeof ConDataTable>(null);
const showEditRuleDialog = ref(false);
const selectedRule = ref<Array<ReleaseRuleTableListTable>>([]);
const selectedRuleBackup = ref<Array<ReleaseRuleTableListTable>>([]);
const confirm = useConfirm();
const toast = useToast();
const existingRules = ref<ReleaseRuleTableListTable[] | undefined>([]);

const rulesTableColumns = [
    {
        field: "name",
        header: "Name",
        dataType: "text",
        isFiltered: true,
        sortable: true,
    },
    {
        field: "entity",
        header: "Entity",
        dataType: "text",
        isFiltered: true,
        sortable: true,
    },
    {
        field: "defaultTable",
        header: "Default",
        dataType: "boolean",
        isFiltered: true,
        sortable: true,
    },
    {
        field: "actionMenu",
    },
];

function reloadRulesTable() {
    rulesTableRef.value?.forceReload();
}

function createRuleActionMenu(row: any): any {
    return [
        {
            label: "Edit",
            icon: "pi pi-pencil",
            command: () => {
                selectedRuleBackup.value = selectedRule.value;
                selectedRule.value = [];
                selectedRule.value.push(row);
                showEditRuleDialog.value = true;
            },
        },
        {
            label: "Delete",
            icon: "pi pi-trash",
            command: () => deleteRule(row),
        },
    ];
}

async function fetchRulesDataAsync(
    filters: string,
    sorts: string,
    page: number,
    rows: number,
): Promise<any> {
    let res = await ReleaseService.getApiV1ReleaseReleaseRules(
        filters,
        sorts,
        page,
        rows,
    );

    if(res.data)
        existingRules.value = res.data;

    return {
        totalCount: res.totalCount,
        data: res.data,
    };
}

function deleteRule(row: any) {
    confirm.require({
        header: "Delete Confirmation",
        icon: "pi pi-info-circle",
        acceptClass: "p-button-info",
        //TODO: implement option to see a list of items that will be delted
        message: "Are you sure you want to delete this Item",
        accept: async () => {
            try {
                await ReleaseService.deleteApiV1ReleaseReleaseRules(row.guid);
                toast.add({
                    severity: "success",
                    summary: "Delete",
                    detail: "Item deleted",
                    life: 3000,
                });

                reloadRulesTable();
            } catch (ex: any) {
                toast.add({
                    severity: "error",
                    summary: "Error!",
                    detail: "An error occured while deleting: " + ex.message,
                    life: 3000,
                });
            }
        },
    });
}
</script>