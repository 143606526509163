/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateCheckInOutRequest } from '../models/CreateCheckInOutRequest';
import type { CreateCheckInOutResponse } from '../models/CreateCheckInOutResponse';
import type { DeleteCheckInOutRequest } from '../models/DeleteCheckInOutRequest';
import type { DeleteCheckInOutResponse } from '../models/DeleteCheckInOutResponse';
import type { ListCheckInOutResponse } from '../models/ListCheckInOutResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CheckInOutService {

    /**
     * @param requestBody 
     * @returns CreateCheckInOutResponse Success
     * @throws ApiError
     */
    public static putApiV1CheckInOutCreateOrEdit(
requestBody?: CreateCheckInOutRequest,
): CancelablePromise<CreateCheckInOutResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/CheckInOut/CreateOrEdit',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns DeleteCheckInOutResponse Success
     * @throws ApiError
     */
    public static deleteApiV1CheckInOutDelete(
requestBody?: DeleteCheckInOutRequest,
): CancelablePromise<DeleteCheckInOutResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/CheckInOut/Delete',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param sieveModelFilters 
     * @param sieveModelSorts 
     * @param sieveModelPage 
     * @param sieveModelPageSize 
     * @returns ListCheckInOutResponse Success
     * @throws ApiError
     */
    public static getApiV1CheckInOut(
sieveModelFilters?: string,
sieveModelSorts?: string,
sieveModelPage?: number,
sieveModelPageSize?: number,
): CancelablePromise<ListCheckInOutResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/CheckInOut',
            query: {
                'sieveModel.Filters': sieveModelFilters,
                'sieveModel.Sorts': sieveModelSorts,
                'sieveModel.Page': sieveModelPage,
                'sieveModel.PageSize': sieveModelPageSize,
            },
        });
    }

}
