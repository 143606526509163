/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddDataQualityCheckRequest } from '../models/AddDataQualityCheckRequest';
import type { AddDataQualityCheckResponse } from '../models/AddDataQualityCheckResponse';
import type { DeleteDataQualityCheckResponse } from '../models/DeleteDataQualityCheckResponse';
import type { DetailsDataQualityCheckResponse } from '../models/DetailsDataQualityCheckResponse';
import type { ExecuteDataQualityCheckRequest } from '../models/ExecuteDataQualityCheckRequest';
import type { ExecuteDataQualityCheckResponse } from '../models/ExecuteDataQualityCheckResponse';
import type { FindDataQualityCheckByIdResponse } from '../models/FindDataQualityCheckByIdResponse';
import type { ListDataQualityCheckOrderFields } from '../models/ListDataQualityCheckOrderFields';
import type { ListDataQualityCheckOrderFieldsOrderingFieldsBase } from '../models/ListDataQualityCheckOrderFieldsOrderingFieldsBase';
import type { ListDataQualityCheckResponse } from '../models/ListDataQualityCheckResponse';
import type { UpdateDataQualityCheckRequest } from '../models/UpdateDataQualityCheckRequest';
import type { UpdateDataQualityCheckResponse } from '../models/UpdateDataQualityCheckResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DataQualityChecksService {

    /**
     * @param requestBody 
     * @returns AddDataQualityCheckResponse Success
     * @throws ApiError
     */
    public static postApiV1DataQualityChecks(
requestBody?: AddDataQualityCheckRequest,
): CancelablePromise<AddDataQualityCheckResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/DataQualityChecks',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns UpdateDataQualityCheckResponse Success
     * @throws ApiError
     */
    public static putApiV1DataQualityChecks(
requestBody?: UpdateDataQualityCheckRequest,
): CancelablePromise<UpdateDataQualityCheckResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/DataQualityChecks',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns ExecuteDataQualityCheckResponse Success
     * @throws ApiError
     */
    public static postApiV1DataQualityChecksExecute(
requestBody?: ExecuteDataQualityCheckRequest,
): CancelablePromise<ExecuteDataQualityCheckResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/DataQualityChecks/Execute',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id 
     * @returns DeleteDataQualityCheckResponse Success
     * @throws ApiError
     */
    public static deleteApiV1DataQualityChecksDeleteDataQualityCheck(
id?: number,
): CancelablePromise<DeleteDataQualityCheckResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/DataQualityChecks/DeleteDataQualityCheck',
            query: {
                'Id': id,
            },
        });
    }

    /**
     * @param sieveModelFilters 
     * @param sieveModelSorts 
     * @param sieveModelPage 
     * @param sieveModelPageSize 
     * @param pageNumber 
     * @param pageSize 
     * @param skip 
     * @param orderingFields 
     * @param availableOrderingFields 
     * @returns ListDataQualityCheckResponse Success
     * @throws ApiError
     */
    public static getApiV1DataQualityChecksList(
sieveModelFilters?: string,
sieveModelSorts?: string,
sieveModelPage?: number,
sieveModelPageSize?: number,
pageNumber?: number,
pageSize?: number,
skip?: number,
orderingFields?: Array<ListDataQualityCheckOrderFieldsOrderingFieldsBase>,
availableOrderingFields?: ListDataQualityCheckOrderFields,
): CancelablePromise<ListDataQualityCheckResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/DataQualityChecks/List',
            query: {
                'sieveModel.Filters': sieveModelFilters,
                'sieveModel.Sorts': sieveModelSorts,
                'sieveModel.Page': sieveModelPage,
                'sieveModel.PageSize': sieveModelPageSize,
                'PageNumber': pageNumber,
                'PageSize': pageSize,
                'Skip': skip,
                'OrderingFields': orderingFields,
                'AvailableOrderingFields': availableOrderingFields,
            },
        });
    }

    /**
     * @param id 
     * @param filters 
     * @param sorts 
     * @param page 
     * @param pageSize 
     * @returns DetailsDataQualityCheckResponse Success
     * @throws ApiError
     */
    public static getApiV1DataQualityChecks(
id: number,
filters?: string,
sorts?: string,
page?: number,
pageSize?: number,
): CancelablePromise<DetailsDataQualityCheckResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/DataQualityChecks/{id}',
            path: {
                'id': id,
            },
            query: {
                'Filters': filters,
                'Sorts': sorts,
                'Page': page,
                'PageSize': pageSize,
            },
        });
    }

    /**
     * @param id 
     * @returns FindDataQualityCheckByIdResponse Success
     * @throws ApiError
     */
    public static getApiV1DataQualityChecksFindById(
id: number,
): CancelablePromise<FindDataQualityCheckByIdResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/DataQualityChecks/FindById/{id}',
            path: {
                'id': id,
            },
        });
    }

}
