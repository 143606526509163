/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ArtifactCreateResponse } from '../models/ArtifactCreateResponse';
import type { ArtifactDeleteRequest } from '../models/ArtifactDeleteRequest';
import type { ArtifactDeleteResponse } from '../models/ArtifactDeleteResponse';
import type { ArtifactDetailsResponse } from '../models/ArtifactDetailsResponse';
import type { ArtifactGetUrlResponse } from '../models/ArtifactGetUrlResponse';
import type { ArtifactListResponse } from '../models/ArtifactListResponse';
import type { ArtifactUpdateRequest } from '../models/ArtifactUpdateRequest';
import type { ArtifactUpdateResponse } from '../models/ArtifactUpdateResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ArtifactService {

    /**
     * @param plmItem 
     * @param formData 
     * @returns ArtifactCreateResponse OK
     * @throws ApiError
     */
    public static postApiV1Artifacts(
plmItem?: string,
formData?: {
FileData?: Blob;
},
): CancelablePromise<ArtifactCreateResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/artifacts',
            query: {
                'PlmItem': plmItem,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param sieveModelFilters 
     * @param sieveModelSorts 
     * @param sieveModelPage 
     * @param sieveModelPageSize 
     * @returns ArtifactListResponse OK
     * @throws ApiError
     */
    public static getApiV1Artifacts(
sieveModelFilters?: string,
sieveModelSorts?: string,
sieveModelPage?: number,
sieveModelPageSize?: number,
): CancelablePromise<ArtifactListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/artifacts',
            query: {
                'SieveModel.Filters': sieveModelFilters,
                'SieveModel.Sorts': sieveModelSorts,
                'SieveModel.Page': sieveModelPage,
                'SieveModel.PageSize': sieveModelPageSize,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns ArtifactDeleteResponse OK
     * @throws ApiError
     */
    public static deleteApiV1Artifacts(
requestBody?: ArtifactDeleteRequest,
): CancelablePromise<ArtifactDeleteResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/artifacts',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param guid 
     * @returns ArtifactGetUrlResponse OK
     * @throws ApiError
     */
    public static getApiV1ArtifactsUrl(
guid: string,
): CancelablePromise<ArtifactGetUrlResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/artifacts/{guid}/url',
            path: {
                'guid': guid,
            },
        });
    }

    /**
     * @param guid 
     * @returns ArtifactDetailsResponse OK
     * @throws ApiError
     */
    public static getApiV1Artifacts1(
guid: string,
): CancelablePromise<ArtifactDetailsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/artifacts/{guid}',
            path: {
                'guid': guid,
            },
        });
    }

    /**
     * @param guid 
     * @param requestBody 
     * @returns ArtifactUpdateResponse OK
     * @throws ApiError
     */
    public static putApiV1Artifacts(
guid: string,
requestBody?: ArtifactUpdateRequest,
): CancelablePromise<ArtifactUpdateResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/artifacts/{guid}',
            path: {
                'guid': guid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
