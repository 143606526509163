import ParticipantsList  from "../components/ParticipantsList.vue"
import LapDetailsVue from "../components/LapDetails.vue";
import { to } from '@congatec/primevue-components-lib';

const title = "LapCounter"

const lapcounterRoutes = [
    {
      path: '/lapcounter/participants-list',
      name: 'ParticipantsList',
      
      component: ParticipantsList,
      meta: {
        breadcrumb: [{ title: title }, { label: "ParticipantsList" }],
        
      },
    },
    {
      path: '/lapcounter/lap-details/:participantId',
      name: 'LapDetails',
      
      component: LapDetailsVue,
      meta: {
        breadcrumb: [{ title: title,command:to('/lapcounter/participants-list') }, { label: "LapDetails" }],
        
      },
    },
  ];

  export default lapcounterRoutes;