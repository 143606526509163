
import { defineStore } from 'pinia'
import { ref, type Ref } from 'vue';
import { useStorage } from '@vueuse/core'
import { type CardsListResponse_Card as CardType, type CardsUpdateResponse_Card as CardUpdateType } from '@/apis/jupiter-api';

//const ORDER_DEFAULT = 9999;
const STORAGE_KEY = "card-store";

export const useCardStore = defineStore("CardStore", () => {
  const cards: Ref<CardType[]> = useStorage(STORAGE_KEY, ref([]));

  const set = (newItems: CardType[]) => {
    cards.value = newItems;
  }

  const add = (newItem: CardType) =>{
    cards.value.push(newItem);
  }

  const update = (newItem: CardUpdateType) => {
    const i = cards.value.findIndex(x => x.id === newItem.id)
    cards.value[i] = newItem
  }
  const clear = () =>[
    cards.value = []
  ]

  const sortCards = () => {
    
    
    // cards.value.sort((a: any, b: any) => 
    //   a.title.toLowerCase().localeCompare(b.title.toLowerCase()));

    cards.value =
    cards.value.sort((a: any, b: any) => {
      // Check if either title is null or undefined
      if (a.title == null || b.title == null) {
          // If a.title is null or undefined, sort by id
          if (a.title == null && b.title == null) {
              return a.id - b.id;
          }
          // If only a.title is null, put a after b
          if (a.title == null) {
              return 1;
          }
          // If only b.title is null, put b after a
          if (b.title == null) {
              return -1;
          }
      }
  
      // If both titles are defined, compare by title
      return a.title.toLowerCase().localeCompare(b.title.toLowerCase());
  });
    // menuItems.value = menuItems.value.sort((a: CardType, b: CardType) =>
    //   (a. || ORDER_DEFAULT) - (b.order || ORDER_DEFAULT));

    // menuItems.value = menuItems.value.sort((a: any, b: any) =>
    //   -(Number(a.isFavorite) - Number(b.isFavorite)));
  }

  const toggleFavorite = (key: number) => {
    const app = cards.value.find((x) => x.id == key);
    if (!app) {
      console.warn("App", key, "was not found!");
      return;
    }
    app.isFavorite = !app.isFavorite;
  }

  return { cards, set, add, clear, sortCards, toggleFavorite, update}
});