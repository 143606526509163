<template>
  <div class="flex flex-column">
    <DynamicDialog />
    <ConfirmDialog />
    <Toolbar id="toolbar">
      <template #start>
        <Button label="New" icon="pi pi-plus" @click="onNewClick" :disabled="newDisabled" />
      </template>
    </Toolbar>

    <ConDataTable class="m-3 w-auto" ref="dataTableRef" :columns="columns" filterDisplay="menu" :router="router"
      :route="route" title="Hardware Samples" :onReload="fetchDataFromServiceAsync" :createActionMenu="createActionMenu"
      :rowsPerPageOptions="[10, 25, 50]" :rows="25"
      :tableId="'saturn-hardware-list'" exportable>
      <template #isSignedOut="col">
        <Column :field="col.field" :header="col.header" dataType="boolean">
          <template #body="field">
            <i class="pi pi-check" v-if="field.data.item.status.signOutInformation != null"></i>
            <i class="pi pi-times" v-if="field.data.item.status.signOutInformation == null"></i>
          </template>
          <template #filter="{ filterModel }">
            <TriStateCheckbox v-model="filterModel.value" />
          </template>
        </Column>
      </template>
    </ConDataTable>
  </div>
</template>


<script setup lang="ts">
import { ref, onMounted } from 'vue';
import { defineComponent } from "vue";
import {
  ManufacturedHardwareSampleService
} from '@/apis/saturn-api';
import { useDialog } from 'primevue/usedialog';
import { HardwareInput } from '.';
import { useConfirm } from 'primevue/useconfirm';
import { useToast } from 'primevue/usetoast';
import { ConDataTable } from '@congatec/primevue-components-lib';
import Button from 'primevue/button';
import ConfirmDialog from 'primevue/confirmdialog';
import DynamicDialog from 'primevue/dynamicdialog';
import TriStateCheckbox from 'primevue/tristatecheckbox';
import Column from 'primevue/column';
import { useRoute, useRouter } from 'vue-router';
import Toolbar from 'primevue/toolbar';

const dialog = useDialog();
const newDisabled = ref(false);
const confirm = useConfirm();
const toast = useToast();

const dataTableRef = ref();

const route = useRoute();
const router = useRouter();

const columns = ref([
  {
    field: 'name',
    header: 'Name',
    isFiltered: true,
    sortable: true,
  },
  {
    field: 'serialNumber',
    header: 'Serial Number',
    isFiltered: true,
    sortable: true,
    filterMeta: { operator: 'and', constraints: [{ value: null, matchMode: 'contains' }] },
    toggle: true
  },
  {
    field: 'partNumber',
    header: 'Part Number',
    isFiltered: true,
    sortable: true,
    filterMeta: { operator: 'and', constraints: [{ value: null, matchMode: 'contains' }] },
    toggle: true
  },
  {
    field: 'productionOrder',
    header: 'ProductionOrder',
    isFiltered: true,
    sortable: true,
    filterMeta: { operator: 'and', constraints: [{ value: null, matchMode: 'contains' }] },
    toggle: true,
    inactive: true
  },
  {
    field: 'bios',
    header: 'Bios',
    isFiltered: true,
    sortable: true,
    filterMeta: { operator: 'and', constraints: [{ value: null, matchMode: 'contains' }] },
    toggle: true,
    inactive: true
  },
  {
    field: 'department',
    header: 'Department',
    isFiltered: true,
    sortable: true,
    filterMeta: { operator: 'and', constraints: [{ value: null, matchMode: 'contains' }] },
    toggle: true
  },
  {
    field: 'location',
    header: 'Location',
    isFiltered: true,
    sortable: true,
    filterMeta: { operator: 'and', constraints: [{ value: null, matchMode: 'contains' }] },
    toggle: true
  },
  {
    field: 'branchOffice',
    header: 'Branch Office',
    isFiltered: true,
    sortable: true,
    filterMeta: { operator: 'and', constraints: [{ value: null, matchMode: 'contains' }] },
    toggle: true
  },
  {
    field: 'quantity',
    header: 'Quantity',
    isFiltered: true,
    sortable: true,
    toggle: true
  },
  {
    field: 'packageRevision',
    header: 'Package Revision',
    isFiltered: true,
    sortable: true,
    toggle: true, 
    inactive: true
  },
  {
    field: 'remark',
    header: 'Remark',
    isFiltered: true,
    sortable: true,
    filterMeta: { operator: 'and', constraints: [{ value: null, matchMode: 'contains' }] },
    toggle: true,
    inactive: true
  },
  {
    field: 'isSignedOut',
    header: 'Signed Out',
    isFiltered: true,
    sortable: true,
    filterMeta: { operator: 'and', constraints: [{ value: null, matchMode: 'equals' }] },
    toggle: true
  },
  {
    field: 'actionMenu'
  }
]);

onMounted(() => {
});

async function reloadData() {
  dataTableRef.value.forceReload(1);
}

const onNewClick = () => {
  newDisabled.value = true;
  dialog.open(HardwareInput, {
    data: {
    },
    onClose: onNewClosed,
    props: {
      header: "New Hardware Sample",
      style: { width: '50vw' }
    },
  }
  );
}

const onNewClosed = async (_options: any) => {
  newDisabled.value = false;
  await reloadData();
}


const onDeleteClick = async (id: number) => {
  confirm.require({
    message: 'Are you sure?',
    header: 'Delete',
    icon: 'pi pi-exclamation-triangle',
    accept: async () => {
      await ManufacturedHardwareSampleService.deleteApiV1ManufacturedHardwareSample(
        id
      );
      toast.add({
        severity: 'success',
        summary: 'Sample deleted',
        detail: 'Sample was deleted successfully',
        life: 3000
      });
      await dataTableRef.value.forceReload(1);
    }
  });

}

const onEditClick = async (id: number) => {
  dialog.open(HardwareInput, {
    data: {
      id: id
    },
    onClose: onEditClosed,
    props: {
      header: "Edit Hardware Sample",
      style: { width: '50vw' }
    },
  }
  );
}

const onEditClosed = async (_options: any) => {
  await dataTableRef.value.forceReload(1);
}

async function fetchDataFromServiceAsync(filters: string, sorts: string, page: number, rows: number): Promise<any> {

  filters = filters.replace("isSignedOut==true", "isSignedOut!=null")
  filters = filters.replace("isSignedOut==false", "isSignedOut==null")
  const res = await ManufacturedHardwareSampleService.getApiV1ManufacturedHardwareSample(filters, sorts, page, rows);
  res.items?.forEach((x: any) => { x.remark = x.item?.remark || ''; x.name = x.item?.name || ''; x.quantity = x.item?.quantity || 0; });
  return {
    totalCount: res.totalCount,
    data: res.items
  };
}

function createActionMenu(row: any): any {
  return [
    {
      label: 'Edit',
      icon: 'pi pi-pencil',
      command: () => {
        onEditClick(row.id)
      }
    },
    {
      label: 'Details',
      icon: 'pi pi-eye',
      command: () => {
        router.push(`/saturn/hw-details/${row.id}`);
      }
    },
    {
      label: 'Delete',
      icon: 'pi pi-trash',
      command: () => {
        onDeleteClick(row.id)
      }
    },
  ];
}


</script>

<script lang="ts">

export default defineComponent({
  name: "HardwareList",
  components: {}
});
</script>

