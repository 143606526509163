import Chat from "../components/ChatComponent.vue";

const conAIRoutes = [
  {
    path: '/con-ai/chat',
    name: 'con-aiChat',
    component: Chat,
    meta: {
      breadcrumb: [{ title: "con-AI" }, { label: "Chat" }],
    },
  },
];


export default conAIRoutes;
