<template>
  <slot v-if="$props.initialized">
  </slot>
  <Skeleton :class="$props.skeletonClass" v-if="!$props.initialized" :shape="$props.shape || ''" />
</template>

<script lang="ts">

export default defineComponent({
  name: "WrappedSkeleton",
  components: {}
});
</script>

<script setup lang="ts">

import Skeleton from 'primevue/skeleton';

import { defineComponent } from 'vue';
let props = defineProps({
  skeletonClass: { default: () => "" },
  initialized: { default: false },
  shape: { default: (): any => "" }
});
</script>

<style> .float-input {
   margin-top: 1.0em;
 }
</style>
