/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApplyImageRequest } from '../models/ApplyImageRequest';
import type { ApplyImageResponse } from '../models/ApplyImageResponse';
import type { CreateImageTemplateRequest } from '../models/CreateImageTemplateRequest';
import type { CreateImageTemplateResponse } from '../models/CreateImageTemplateResponse';
import type { DeleteImageTemplateResponse } from '../models/DeleteImageTemplateResponse';
import type { GenerateEmployeeCardRequest } from '../models/GenerateEmployeeCardRequest';
import type { GenerateEmployeeCardResponse } from '../models/GenerateEmployeeCardResponse';
import type { GenerateEmployeeCardUserRequest } from '../models/GenerateEmployeeCardUserRequest';
import type { GenerateEmployeeCardUserResponse } from '../models/GenerateEmployeeCardUserResponse';
import type { ImageEditorCapabilitiesRequest } from '../models/ImageEditorCapabilitiesRequest';
import type { ImageEditorCapabilitiesResponse } from '../models/ImageEditorCapabilitiesResponse';
import type { InheritImageTemplateRequest } from '../models/InheritImageTemplateRequest';
import type { InheritImageTemplateResponse } from '../models/InheritImageTemplateResponse';
import type { ListImageTemplateResponse } from '../models/ListImageTemplateResponse';
import type { SetDrawCommandsRequest } from '../models/SetDrawCommandsRequest';
import type { SetDrawCommandsResponse } from '../models/SetDrawCommandsResponse';
import type { UpdateImageTemplateRequest } from '../models/UpdateImageTemplateRequest';
import type { UpdateImageTemplateResponse } from '../models/UpdateImageTemplateResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ImageTemplateService {

    /**
     * @param requestBody 
     * @returns CreateImageTemplateResponse Success
     * @throws ApiError
     */
    public static postApiV1ImageTemplate(
requestBody?: CreateImageTemplateRequest,
): CancelablePromise<CreateImageTemplateResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/ImageTemplate',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns UpdateImageTemplateResponse Success
     * @throws ApiError
     */
    public static putApiV1ImageTemplate(
requestBody?: UpdateImageTemplateRequest,
): CancelablePromise<UpdateImageTemplateResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/ImageTemplate',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id 
     * @returns DeleteImageTemplateResponse Success
     * @throws ApiError
     */
    public static deleteApiV1ImageTemplate(
id?: number,
): CancelablePromise<DeleteImageTemplateResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/ImageTemplate',
            query: {
                'Id': id,
            },
        });
    }

    /**
     * @param sieveModelFilters 
     * @param sieveModelSorts 
     * @param sieveModelPage 
     * @param sieveModelPageSize 
     * @param includeClones 
     * @returns ListImageTemplateResponse Success
     * @throws ApiError
     */
    public static getApiV1ImageTemplate(
sieveModelFilters?: string,
sieveModelSorts?: string,
sieveModelPage?: number,
sieveModelPageSize?: number,
includeClones?: boolean,
): CancelablePromise<ListImageTemplateResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/ImageTemplate',
            query: {
                'SieveModel.Filters': sieveModelFilters,
                'SieveModel.Sorts': sieveModelSorts,
                'SieveModel.Page': sieveModelPage,
                'SieveModel.PageSize': sieveModelPageSize,
                'IncludeClones': includeClones,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns ApplyImageResponse Success
     * @throws ApiError
     */
    public static postApiV1ImageTemplateApply(
requestBody?: ApplyImageRequest,
): CancelablePromise<ApplyImageResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/ImageTemplate/apply',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns GenerateEmployeeCardUserResponse Success
     * @throws ApiError
     */
    public static postApiV1ImageTemplateMakeId(
requestBody?: GenerateEmployeeCardUserRequest,
): CancelablePromise<GenerateEmployeeCardUserResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/ImageTemplate/make-id',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns GenerateEmployeeCardResponse Success
     * @throws ApiError
     */
    public static postApiV1ImageTemplateMakeIdAdmin(
requestBody?: GenerateEmployeeCardRequest,
): CancelablePromise<GenerateEmployeeCardResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/ImageTemplate/make-id-admin',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param request 
     * @returns ImageEditorCapabilitiesResponse Success
     * @throws ApiError
     */
    public static getApiV1ImageTemplateEditorCapabilities(
request?: ImageEditorCapabilitiesRequest,
): CancelablePromise<ImageEditorCapabilitiesResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/ImageTemplate/editor-capabilities',
            query: {
                'request': request,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns SetDrawCommandsResponse Success
     * @throws ApiError
     */
    public static putApiV1ImageTemplateDrawCommands(
requestBody?: SetDrawCommandsRequest,
): CancelablePromise<SetDrawCommandsResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/ImageTemplate/draw-commands',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns InheritImageTemplateResponse Success
     * @throws ApiError
     */
    public static putApiV1ImageTemplateClone(
requestBody?: InheritImageTemplateRequest,
): CancelablePromise<InheritImageTemplateResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/ImageTemplate/clone',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
