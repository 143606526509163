/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BundlesCreateRequest } from '../models/BundlesCreateRequest';
import type { BundlesCreateResponse } from '../models/BundlesCreateResponse';
import type { BundlesDeleteRequest } from '../models/BundlesDeleteRequest';
import type { BundlesDeleteResponse } from '../models/BundlesDeleteResponse';
import type { BundlesDetailsResponse } from '../models/BundlesDetailsResponse';
import type { BundlesListResponse } from '../models/BundlesListResponse';
import type { BundlesSetStateRequest } from '../models/BundlesSetStateRequest';
import type { BundlesSetStateResponse } from '../models/BundlesSetStateResponse';
import type { BundlesUpdateRequest } from '../models/BundlesUpdateRequest';
import type { BundlesUpdateResponse } from '../models/BundlesUpdateResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class BundleService {

    /**
     * @param guid 
     * @returns BundlesDetailsResponse OK
     * @throws ApiError
     */
    public static getApiV1SoftwareBundles(
guid: string,
): CancelablePromise<BundlesDetailsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/software-bundles/{guid}',
            path: {
                'guid': guid,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns BundlesCreateResponse OK
     * @throws ApiError
     */
    public static postApiV1SoftwareBundles(
requestBody?: BundlesCreateRequest,
): CancelablePromise<BundlesCreateResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/software-bundles',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns BundlesDeleteResponse OK
     * @throws ApiError
     */
    public static deleteApiV1SoftwareBundles(
requestBody?: BundlesDeleteRequest,
): CancelablePromise<BundlesDeleteResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/software-bundles',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns BundlesUpdateResponse OK
     * @throws ApiError
     */
    public static putApiV1SoftwareBundles(
requestBody?: BundlesUpdateRequest,
): CancelablePromise<BundlesUpdateResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/software-bundles',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param sieveModelFilters 
     * @param sieveModelSorts 
     * @param sieveModelPage 
     * @param sieveModelPageSize 
     * @returns BundlesListResponse OK
     * @throws ApiError
     */
    public static getApiV1SoftwareBundles1(
sieveModelFilters?: string,
sieveModelSorts?: string,
sieveModelPage?: number,
sieveModelPageSize?: number,
): CancelablePromise<BundlesListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/software-bundles',
            query: {
                'sieveModel.Filters': sieveModelFilters,
                'sieveModel.Sorts': sieveModelSorts,
                'sieveModel.Page': sieveModelPage,
                'sieveModel.PageSize': sieveModelPageSize,
            },
        });
    }

    /**
     * @param guid 
     * @param requestBody 
     * @returns BundlesSetStateResponse OK
     * @throws ApiError
     */
    public static putApiV1SoftwareBundlesSetState(
guid: string,
requestBody?: BundlesSetStateRequest,
): CancelablePromise<BundlesSetStateResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/software-bundles/{guid}/set-state',
            path: {
                'guid': guid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
