<!-- eslint-disable vue/require-v-for-key -->
<template>
  <div class="flex flex-column">
    <Dialog
      v-if="dialogVisible"
      v-model:visible="dialogVisible"
      modal
      :draggable="false"
      position="top"
      style="max-width: 50%; min-width: 50%; max-height: 100%"
      class="overflow-hidden"
    >
      <div class="grid">
        <Card class="col lg:col xl:col sm:col-12">
          <template #title>General Information</template>
          <template #subtitle>{{ "Manual " + area + " mapping" }}</template>
          <template #content>
            <ConDetailsItem
              title="Id"
              class="detailsItem"
              :content="logEntry?.id?.toString()"
            ></ConDetailsItem>
            <ConDetailsItem
              title="Area"
              class="detailsItem"
              :content="area"
              divider
            ></ConDetailsItem>
            <ConDetailsItem
              title="Resolved By"
              class="detailsItem"
              :content="logEntry?.resolvedBy?.toString()"
              divider
            ></ConDetailsItem>
            <ConDetailsItem
              title="Is Resolved"
              v-if="logEntry?.isResolved"
              class="detailsItem"
              content="Yes"
              divider
            ></ConDetailsItem>
            <ConDetailsItem
              title="Is Resolved"
              content="No"
              class="detailsItem"
              divider
              v-else
              >false</ConDetailsItem
            >
            <Divider
              style="margin-top: 1.25rem; margin-bottom: 1.25rem"
            ></Divider>
            <h4 style="margin-top: 18.62; margin-bottom: 18.62">Content</h4>
            <Textarea
              v-model="content"
              autoResize
              class="content mt-2"
              style="min-width: 100%"
            />
          </template>
        </Card>
      </div>
      <div class="grid">
        <Card class="col lg:col xl:col sm:col-12">
          <template #title>Resolve Log</template>
          <template #content>
            <div>
              <div class="resolveContainer">
                <div>
                  <h4>Hubspot Id´s</h4>
                  <div v-for="hubspotId in hubspotIds">
                    <RadioButton
                      :name="hubspotId.toString()"
                      :value="hubspotId"
                      :inputId="hubspotId.toString()"
                      v-model="selectedHubspotId"
                    ></RadioButton>
                    <a
                      v-if="isCompany"
                      :href="
                        'https://app-eu1.hubspot.com/contacts/139811559/company/' +
                        hubspotId
                      "
                    >
                      <label :for="hubspotId.toString()" class="ml-2">{{
                        "https://app-eu1.hubspot.com/contacts/139811559/company/" +
                        hubspotId
                      }}</label>
                    </a>
                    <a
                      v-else-if="isPerson"
                      :href="
                        'https://app-eu1.hubspot.com/contacts/139811559/contact/' +
                        hubspotId
                      "
                    >
                      <label :for="hubspotId.toString()" class="ml-2">{{
                        "https://app-eu1.hubspot.com/contacts/139811559/contact/" +
                        hubspotId +
                        isPerson
                      }}</label>
                    </a>
                  </div>
                </div>
                <div>
                  <h4>Aurea Crm Id´s</h4>
                  <div v-for="aureaId in aureaIds">
                    <RadioButton
                      :name="aureaId.toString()"
                      :value="aureaId"
                      :inputId="aureaId.toString()"
                      v-model="selectedAureaId"
                    ></RadioButton>
                    <a
                      v-if="isCompany"
                      :href="
                        'https://crmdev01.congatec.local/CRMweb/crm/tree?uid=FI%3Bx' +
                        aureaId.toString(16)
                      "
                    >
                      <label :for="aureaId.toString()" class="ml-2">{{
                        "https://crmdev01.congatec.local/CRMweb/crm/tree?uid=FI%3Bx" +
                        aureaId.toString(16)
                      }}</label>
                    </a>
                    <a
                      v-else-if="isPerson"
                      :href="
                        'https://crmdev01.congatec.local/CRMweb/crm/tree?uid=FI%3Bx' +
                        aureaId.toString(16)
                      "
                    >
                      <label :for="aureaId.toString()" class="ml-2">{{
                        "https://crmdev01.congatec.local/CRMweb/crm/tree?uid=FI%3Bx" +
                        aureaId.toString(16)
                      }}</label>
                    </a>
                  </div>
                </div>
              </div>
              <Button
                style="padding: 10 14 10 14"
                @click="resolveLog()"
                :disabled="selectedAureaId == null || selectedHubspotId == null"
                >Save</Button
              >
            </div>
          </template>
        </Card>
      </div>
    </Dialog>
  </div>
</template>

<script setup lang="ts">
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import Divider from "primevue/divider";
import RadioButton from "primevue/radiobutton";
import Card from "primevue/card";
import Textarea from "primevue/textarea";
import { ConDetailsItem } from "@congatec/primevue-components-lib";
import { type Ref, ref, watch } from "vue";
import {
  LogEntryService,
  type LogEntriesDetailsResponse,
} from "@/apis/hubspotgateway-api";
import { EntityMappingAreaEnum } from "@/apis/hubspotgateway-api/models/EntityMappingAreaEnum";

const props = defineProps<{
  id: any;
  visible: boolean;
  onHide: (onDetailsClosed: boolean) => any;
}>();

const dialogVisible = ref<boolean>(false);
let logEntry: Ref<LogEntriesDetailsResponse | null> = ref(null);
let entryId = ref(0);
let loading = ref(false);
let content: Ref<string> = ref("");
let hubspotIds: Ref<Array<number> | null | undefined> = ref([]);
let aureaIds: Ref<Array<number> | null | undefined> = ref([]);
let selectedHubspotId = ref(0);
let selectedAureaId = ref(0);
let area: Ref<EntityMappingAreaEnum> = ref(EntityMappingAreaEnum.COMPANY);
let isCompany = ref(false);
let isPerson = ref(false);

const fetchdetails = async (id: number) => {
  loading.value = true;
  logEntry.value = await LogEntryService.getApiV1LogEntryLogEntryDetails(id);

  loading.value = false;
  content.value = logEntry.value.content || "";

  hubspotIds.value = logEntry.value.hubspotIds;
  aureaIds.value = logEntry.value.aureaCrmIds;

  if (hubspotIds.value) selectedHubspotId.value = hubspotIds.value[0];
  if (aureaIds.value) selectedAureaId.value = aureaIds.value[0];
  if (logEntry.value.area) area.value = logEntry.value.area;

  isCompany.value = area.value === EntityMappingAreaEnum.COMPANY;
  isPerson.value = area.value === EntityMappingAreaEnum.PERSON;
};

watch(
  () => dialogVisible.value,
  (nv) => {
    if (nv != null && !nv) {
      props.onHide(true);
      entryId.value = props.id;
      fetchdetails(entryId.value ?? 0);
    }
  },
);

watch(
  () => props.visible,
  (nv) => {
    if (nv) {
      dialogVisible.value = nv;

      entryId.value = props.id;
      fetchdetails(entryId.value ?? 0);
    }
  },
  { deep: true, immediate: true },
);

watch(
  () => props.id,
  async (nv) => {
    if (nv != undefined) {
      entryId.value = props.id;
      await fetchdetails(entryId.value ?? 0);
    }
  },
);

function resolveLog() {
  LogEntryService.postApiV1LogEntryLogEntryResolve(
    props.id,
    selectedAureaId.value,
    selectedHubspotId.value,
  );
}
</script>

<style scoped lang="scss">
.resolveContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
</style>
