<template>
  <div>
    <Toast />
    <div class="card h-full">
      <Card class="h-full w-full">
        <template #title>
          <div>Behavior</div>
          <div style="display: flex; justify-content: space-between">
            <div style="display: flex">
              <span>
                <Dropdown
                  v-model="Table"
                  :options="tables"
                  optionValue="value"
                  optionLabel="label"
                  @change="onChangeTable($event)"
                  placeholder="Select a table"
                  :filter="true"
                  :loading="tablesLoading"
                  filterPlaceholder="Filter..."
                  class="full-size"
                />
              </span>
              <span class="p-float-label" style="margin-left: 1rem">
                <Calendar
                  inputId="FromDate"
                  v-model="FromDate"
                  :showIcon="true"
                  :showButtonBar="true"
                  dateFormat="dd.mm.yy"
                />
                <label for="Approvaldate">From Date</label>
              </span>
              <span class="p-float-label" style="margin-left: 1rem">
                <Calendar
                  inputId="ToDate"
                  v-model="ToDate"
                  :showIcon="true"
                  :showButtonBar="true"
                  dateFormat="dd.mm.yy"
                />
                <label for="Approvaldate">To Date</label>
              </span>
            </div>
          </div>
        </template>
        <template #content>
          <Chart type="line" :data="chartData" ref="linechart" />
        </template>
      </Card>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, type Ref, onMounted, watch } from "vue";
import Chart from "primevue/chart";
import Card from "primevue/card";
import Calendar from "primevue/calendar";
import Dropdown from "primevue/dropdown";
import Toast from "primevue/toast";
import { useToast } from "primevue/usetoast";
import {
  DwhDatabaseService,
  type DWHDatabaseBehaviorResponse,
} from "@/apis/uranus-api";
import { DwhDbStore } from "@/uranus/components/dataqualitychecks/store/selectedDwhDb";
const toast = useToast();
const dwhDbStore = DwhDbStore();
const chartData = ref();
const FromDate: Ref<Date> = ref(new Date());
const ToDate: Ref<Date> = ref(new Date());
const Table = ref("");
const linechart = ref();
let labels: Ref<Array<string | undefined>> = ref([]);
let dataSets: Ref<Array<number | undefined> | null> = ref([]);
const data: Ref<DWHDatabaseBehaviorResponse> = ref({});
const tables: Ref<Array<{ label: string; value: string }>> = ref([]);
const tablesLoading = ref(true);

onMounted(async () => {
  const now = new Date();
  if (dwhDbStore.id) await loadTables(dwhDbStore.id);
  chartData.value = fetchData();
  FromDate.value = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate() - 7
  );
});

const packagechange = (event: any) => {
  Table.value = event.target.value;
  chartData.value = fetchData();
};

watch(FromDate, async () => {
  chartData.value = fetchData();
});

watch(ToDate, async () => {
  chartData.value = fetchData();
});

const fetchData = async () => {
  const documentStyle = getComputedStyle(document.documentElement);
  dataSets.value = [];
  if (!Table.value) return;
  DwhDatabaseService.getApiV1DwhDatabaseDwhDatabaseBehavior(
    Table.value,
    dwhDbStore.id,
    FromDate.value.toLocaleDateString("sv"),
    ToDate.value.toLocaleDateString("sv")
  )
    .then(async (r) => {
      if (r.dWHDatabaseBehaviorResponse_Objects?.map((x) => x.validFrom)) {
        console.log(
          r.dWHDatabaseBehaviorResponse_Objects?.map((x) => x.validFrom)
        );
        (labels.value = r.dWHDatabaseBehaviorResponse_Objects?.map((x) => {
          if (x.validFrom) return new Date(x.validFrom).toLocaleString("de-DE");
          else return "";
        })),
          (dataSets.value = r.dWHDatabaseBehaviorResponse_Objects?.map(
            (x) => x.count
          )),
          (chartData.value = {
            labels: labels,
            datasets: [
              {
                label: "Count of Records",
                backgroundColor: "#fc4c02",
                data: dataSets.value,
                borderColor: documentStyle.getPropertyValue("--primary-color"),
              },
            ],
          });
      }
    })
    .catch((err) => {
      toast.add({
        severity: "error",
        summary: "Behavior data fetch failed!",
        detail: "This Table does not have a ValidFrom or ValidTo Column.",
        life: 3000,
      });
    });
};

const onChangeTable = (event: any) => {
  Table.value = event.value;
  console.log("Table changed to: ", Table.value);
  chartData.value = fetchData();
};

const loadTables = async (id: number | null) => {
  if (!id) {
    return;
  }

  // load all tables that the server has for the current db
  tablesLoading.value = true;
  try {
    tables.value = [];
    let tableNames =
      (await DwhDatabaseService.getApiV1DwhDatabaseDwhDatabaseTables(id))
        .tables || [];
    tableNames.forEach((x) => tables.value.push({ label: x, value: x }));
    tablesLoading.value = false;
    console.log("Fetched new tables: ", tables.value);
  } catch (ex: any) {
    console.log(ex);
  }
};
</script>

<style scoped>
/* .card {
  margin-left: 1rem;
  margin-right: 1rem;
} */

.full-size {
  width: 100%;

}
</style>
