<template>
    <div class="flex flex-column w-full h-full surface-card border-2 border-primary" style="z-index: 10;">
        <div class="flex flex-row align-items-center justify-content-between h-3rem w-full">
            <div class="w-4"></div>
            <div class="flex justify-content-center w-4">
                <h3 class="m-0">Jira Time Tracking</h3>
            </div>
            <div class="flex flex-row justify-content-end w-4 pr-2">
                <Button v-tooltip.bottom="'Logout from Jira'" text rounded icon="pi pi-sign-out"
                    @click="(e) => jiraTokenStore.clear()" />
                <Button text rounded icon="pi pi-times" @click="closeTimeTracker()"
                    v-tooltip.bottom="'Close Timetracker'"></Button>

            </div>
        </div>
        <div class="flex flex-column w-full h-13rem mt-3 gap-4" v-if="!tokenActive">
            <div id="trackerLoggedOutHeader"
                class="flex flex-column gap-2 w-full justify-content-center align-items-center border-bottom-2 surface-border">
                <h2>No Token!</h2>
                <a href="https://one.congatec.local:8445/secure/ViewProfile.jspa" class="text-primary"
                    target="_blank">Get one here: Jira!</a>
            </div>
            <div class="flex flex-row w-full  justify-content-center align-items-center">
                <InputText placeholder="Enter Jira Token" v-model="jiraToken" class="border-noround-right w-9"
                    @keydown.enter.exact="addJiraToken">
                </InputText>
                <Button class="border-noround-left h-full" icon="pi pi-check" @click="addJiraToken" />
            </div>
        </div>
        <div v-else class="flex flex-column w-full h-full pt-3 gap-2">
            <div id="trackerLoggedInHeader" class="flex flex-column w-full h-4rem">

                <div class="flex flex-row w-full  justify-content-center align-items-center">
                    <InputText placeholder="Enter Issue Key eq: ITDODS-1234" v-model="taskNumber"
                        class="border-noround-right w-9" @keydown.enter.exact="addTask">
                    </InputText>
                    <Button class="border-noround-left h-full" icon="pi pi-check" @click="addTask" />
                </div>
            </div>
            <ScrollPanel style="width: 100%; height: 15rem;" :dt="{
                bar: {
                    background: '{primary.color}'
                }
            }">
                <div class="flex flex-row w-full h-5rem px-2" v-for="issue in issues" :key="issue.taskNumber!">
                    <div class="flex flex-column w-5 pl-2 justify-content-center gap-2">
                        <h3 class="m-0">
                            <a :href="jUrl.VITE_JIRA_BROWSE_URL + issue.taskNumber" target="”_blank”">{{
                                issue.taskNumber }}</a>
                        </h3>
                        <p class="m-0">{{ issue.taskName }}</p>
                    </div>
                    <div class="flex flex-column w-3 align-items-center justify-content-center">
                        <span>{{
                            formatTime(
                                response.jiraTicketsElapsedTime
                                    ? response.jiraTicketsElapsedTime[issue.taskNumber!] || 0
                                    : 0
                            )
                        }}</span>
                    </div>
                    <div class="flex flex-row w-4  align-items-center surface-card">
                        <div class="flex flex-row h-4rem w-full justify-content-between ">
                            <Button v-if="issue.paused" text icon="pi pi-play" @click="startClick(issue)" />
                            <Button v-if="issue.paused" text icon="pi pi-calendar-plus" @click="commitClick(issue)" />
                            <Button v-if="!issue.paused" text icon="pi pi-stop" @click="stopClick(issue)" />
                            <Button v-if="!issue.paused" text icon="pi pi-pause" @click="pauseClick(issue)" />
                            <Button text icon="pi pi-trash" @click="confirmDelete(issue)" />
                        </div>
                    </div>
                </div>
            </ScrollPanel>
        </div>
    </div>

</template>
<script setup lang="ts">
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import ScrollPanel from 'primevue/scrollpanel';
import { onMounted, onUnmounted, ref, type Ref } from 'vue';
import { JiraTimeTrackingService, type ListTrackingResponse_UserIssue, type TimeTrackingCreateResponse, type TimeTrackingElapsedTimeResponse } from '@/apis/jupiter-api';
import { useJiraTokenStore } from '@/jupiter/store/jiraTokenStore';
import { useConfirm } from 'primevue/useconfirm';
import { useToast } from 'primevue/usetoast'
const jUrl = import.meta.env;
const issues = ref<ListTrackingResponse_UserIssue[]>([]);
const toast = useToast();
onMounted(async () => {
    issues.value = (await JiraTimeTrackingService.getApiV1JiraTimeTrackings()).issues || [];
    issues.value = issues.value.reverse();
    if (issues.value) {
        issues.value.forEach((element) => {
            if (!element.paused) {
                if (element.taskNumber) request.value.jiraStoryKeys?.push(element.taskNumber);
                console.log("Story Keys on mounted", request.value.jiraStoryKeys);
            }
        });
        loadTimer();
    }
    let checkLoginResponse = await dispatchCheckJiraLogin(jiraToken.value);
    if (checkLoginResponse == false) {
        jiraTokenStore.clear();
    }
});

const confirm = useConfirm();
const request: Ref<{ jiraStoryKeys: string[] }> = ref({ jiraStoryKeys: [] });
const response: Ref<TimeTrackingElapsedTimeResponse> = ref({});

const emits = defineEmits<{
    (e: 'commitJiraClick', jiraIssue: ListTrackingResponse_UserIssue): void,
    (e: 'closeDialog'): void
}>()
const jiraTokenStore = useJiraTokenStore();
const taskFailed = ref(false);
const loginFailed = ref(false);

const minuteInterval = setInterval(async () => {
    if (request.value.jiraStoryKeys != null && request.value != null) {
        loadTimer()
    }
}, 60000);

const secondInterval = setInterval(async () => {
    if (request.value.jiraStoryKeys.length > 0 && request.value != null) {
        request.value.jiraStoryKeys.forEach(storyKey => {
            if (response.value.jiraTicketsElapsedTime != undefined) {
                if (response.value.jiraTicketsElapsedTime[storyKey] != null) {
                    (response.value.jiraTicketsElapsedTime[storyKey] as number) += 1;
                }
            }
        });
    }
}, 1000);

const checkLoginInterval = setInterval(async () => {
    let checkLoginResponse = await dispatchCheckJiraLogin(jiraToken.value);
    if (checkLoginResponse == false) {
        jiraTokenStore.clear();
    }

}, 600000);

let tokenActive = ref(false);
let checkedLogin: any;
let checkedTask: TimeTrackingCreateResponse;
let taskNumber = ref("");
let jiraToken = ref(jiraTokenStore.token);

if (jiraToken.value !== undefined) {
    tokenActive.value = true;
}


onUnmounted(() => {
    clearInterval(minuteInterval);
    clearInterval(secondInterval);
    clearInterval(checkLoginInterval)
});
jiraTokenStore.$subscribe(() => {
    if (jiraTokenStore.token !== "undefined") {
        setTokenActive();
    } else {
        resetTokenActive();
    }
});


async function dispatchCheckJiraLogin(jiraToken: any) {
    return (
        await JiraTimeTrackingService.postApiV1JiraLogin(jiraToken)
    ).jiraSuccess;
}

async function dispatchCheckJiraTasks(jiraTaskNumber: any, jiraToken: any) {
    return await JiraTimeTrackingService.postApiV1JiraTimeTrackings({
        jiraToken: jiraToken,
        taskNumber: jiraTaskNumber,
    });
}

function resetTokenActive() {
    tokenActive.value = false;
}

function setTokenActive() {
    tokenActive.value = true;
}

async function startClick(issue: ListTrackingResponse_UserIssue) {
    await JiraTimeTrackingService.putApiV1JiraTimeTrackingsStart({
        taskNumber: issue.taskNumber,
    });
    issue.paused = false;
    let pauseIndex = issues?.value.findIndex(x => x.taskNumber === issue.taskNumber) || 0;
    if (issues.value) {
        issues.value[pauseIndex].paused = false;
    }
    issue.paused = false;
    request.value.jiraStoryKeys?.push(issue.taskNumber!);
    loadTimer()
}

async function loadTimer() {
    response.value = await JiraTimeTrackingService.getApiV1JiraTimeTrackingsElapsedTime(
        request.value.jiraStoryKeys
    );
}

async function stopClick(issue: ListTrackingResponse_UserIssue) {
    await JiraTimeTrackingService.putApiV1JiraTimeTrackingsStop({ taskNumber: issue.taskNumber, stopTaskTracking: true });
    issue.paused = true;
    let pauseIndex = issues?.value.findIndex(x => x.taskNumber === issue.taskNumber) || 0;
    if (issues.value) {
        issues.value[pauseIndex].paused = true;
    }
    var index = request.value.jiraStoryKeys?.findIndex(() => issue.taskNumber);
    if (index != undefined)
        request.value.jiraStoryKeys = request.value.jiraStoryKeys?.splice(1, index);
    forceReload()
}

async function pauseClick(issue: ListTrackingResponse_UserIssue) {
    await JiraTimeTrackingService.putApiV1JiraTimeTrackingsPause({ issueKey: issue.taskNumber });
    issue.paused = true;
    let pauseIndex = issues?.value.findIndex(x => x.taskNumber === issue.taskNumber) || 0;
    if (issues.value) {
        issues.value[pauseIndex].paused = true;
    }

    var index = request.value.jiraStoryKeys?.findIndex(() => issue.taskNumber);
    if (index != undefined)
        request.value.jiraStoryKeys = request.value.jiraStoryKeys?.splice(1, index);
    forceReload()
}

async function commitClick(issue: ListTrackingResponse_UserIssue) {
    emits("commitJiraClick", issue)
}

function formatTime(elapsedTime: number) {
    const totalMinutes = Math.floor(elapsedTime / 60);
    const seconds = `0${elapsedTime % 60}`.slice(-2);
    const hours = `0${Math.floor(totalMinutes / 60)}`.slice(-2);
    const minutes = `0${totalMinutes % 60}`.slice(-2);

    return `${hours}:${minutes}:${seconds}`;
}

const confirmDelete = (item: ListTrackingResponse_UserIssue) => {
    confirm.require({
        message: 'No time trackings will be commited to Jira, but they are available again when the same task is added to the time tracking widget.',
        header: 'Remove Task',
        icon: 'pi pi-info-circle',
        acceptClass: 'p-button-info',
        accept: async () => {
            try {
                await JiraTimeTrackingService.deleteApiV1JiraUserIssue({
                    issueId: item.issueId
                });
                removeItem(item)
                toast.add({ severity: 'success', summary: 'Success', detail: `Deleted Tracking: ${item.taskNumber}-${item.taskName}`, life: 3000 });
            }
            catch (ex: any) {
                toast.add({ severity: 'error', summary: 'Error', detail: 'An error occured: ' + ex.message, life: 3000 });
            }
            
        },
        reject: async () => {
            // toast.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
        }
    });
}

async function removeItem(issue: ListTrackingResponse_UserIssue) {
    const index = issues?.value.findIndex(x => x.taskNumber === issue.taskNumber) || 0;
    if (index !== undefined && index > -1) {
        issues?.value.splice(index, 1);
    }
}

const addJiraToken = async () => {
    checkedLogin = await dispatchCheckJiraLogin(jiraToken.value);
    if (checkedLogin === true) {
        jiraTokenStore.set(jiraToken.value || "");
    } else {
        loginFailed.value = true;
    }
};

const addTask = async () => {
    taskNumber.value = taskNumber.value.trim();
    checkedTask = await dispatchCheckJiraTasks(
        taskNumber.value,
        jiraTokenStore.token
    );
    if (taskNumber.value.length !== 0 && checkedTask.taskExists) {
        if (issues?.value === undefined) {
            issues.value = [];
        }
        issues?.value.push({
            issueId: checkedTask.issueId,
            taskNumber: taskNumber.value,
            paused: true,
            taskName: checkedTask.taskSummary || "",
            trackings: checkedTask.trackingsList
        });
        console.log("issues after create:", issues.value)
        issues.value = issues.value.reverse();
        taskNumber.value = "";
    } else {
        taskFailed.value = true;
    }
};

jiraTokenStore.$subscribe(() => {
    if (jiraTokenStore.token == undefined) {
        console.log("JiraLogout!")
        tokenActive.value = false;
        jiraToken.value = undefined
    }
})

async function forceReload() {
    issues.value = (await JiraTimeTrackingService.getApiV1JiraTimeTrackings()).issues || [];
    issues.value = issues.value.reverse();
}

function closeTimeTracker() {
    emits('closeDialog');
}

defineExpose({ forceReload })
</script>