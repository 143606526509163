<template>
    <span class="p-float-label chip-input">
        <Chips :id="$props.id" v-model="modelValueRef" :separator="$props.separator" />
        <label :for="$props.id">{{$props.label}}</label>
    </span>
</template>

<script lang="ts">

export default defineComponent({
    name: "FloatingInput",
    components: { }
});
</script>

<script setup lang="ts">
import { defineComponent, ref, watch } from 'vue';

    const emit = defineEmits(["update:modelValue"]);

    let props = defineProps({
        modelValue: {default(): Array<any> {return []}},
        label: {type: String, default: ""},
        id: {type: String, default: ""},
        separator: {type: String, default: ","}
    });

    let modelValueRef = ref(props.modelValue);

    watch(modelValueRef, (newValue) => {
        emit("update:modelValue", newValue);
    });
</script>

<style> 
    .chip-input {
        margin-top: 1.0em;
        max-width: 100%;
    }
    .p-chips-multiple-container {
        width: 100%;
    }
</style>