<template>
  <Review @init="init" :guid="guid" :state="bundle?.releaseStatus">
    <ProgressSpinner v-if="!bundle" />
    <div v-else>
      <ConDetailsItem title="Guid" :content="guid" />
      <ConDetailsItem title="Identifier" :content="bundle.name" />
      <ConDetailsItem title="Status" :content="bundle.releaseStatus" />
    </div>
  </Review>
</template>

<script setup lang="ts">
import { default as Review, type ReviewProps } from "../components/ReleaseReview.vue";

import { ConDetailsItem } from '@congatec/primevue-components-lib'
import { onMounted, ref } from 'vue';
import { BundleService } from '@/apis/aready-api'
import ProgressSpinner from 'primevue/progressspinner';

const props = defineProps<ReviewProps>();

const bundle = ref();

onMounted(async () => {
  await init();
})

const init = async () => {
  bundle.value = await BundleService.getApiV1SoftwareBundles(props.guid);
}

</script>
