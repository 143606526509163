
import { addAuthToGuards } from "./global-guards";
import { type AuthenticationService } from "@congatec/authentication-lib";
import { type Ref, ref } from "vue";
import { CalendarComponent } from "@/Mercury/components/calendar-component/index";
import { to } from "@congatec/primevue-components-lib";

const _authenticationService: Ref<AuthenticationService | undefined | null> =
  ref(null);

export function addAuthToRouter(authenticationService: AuthenticationService) {
  _authenticationService.value = authenticationService;
  addAuthToGuards(_authenticationService.value);
}

const routes = [
  {
    path: "/mercury/home",
    component: CalendarComponent,
    props: true,
    meta: {
      breadcrumb: [{ title: "Mercury", command:to('/mercury/home') }, { label: "Home" }],
    },
  },
];

export default routes;
