/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateTemplateRequest } from '../models/CreateTemplateRequest';
import type { CreateTemplateResponse } from '../models/CreateTemplateResponse';
import type { DeleteTemplateResponse } from '../models/DeleteTemplateResponse';
import type { ListTemplateResponse } from '../models/ListTemplateResponse';
import type { UpdateTemplateRequest } from '../models/UpdateTemplateRequest';
import type { UpdateTemplateResponse } from '../models/UpdateTemplateResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TemplateService {

    /**
     * @param requestBody 
     * @returns CreateTemplateResponse Success
     * @throws ApiError
     */
    public static postApiV1Template(
requestBody?: CreateTemplateRequest,
): CancelablePromise<CreateTemplateResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/Template',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns UpdateTemplateResponse Success
     * @throws ApiError
     */
    public static putApiV1Template(
requestBody?: UpdateTemplateRequest,
): CancelablePromise<UpdateTemplateResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/Template',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id 
     * @returns DeleteTemplateResponse Success
     * @throws ApiError
     */
    public static deleteApiV1Template(
id?: number,
): CancelablePromise<DeleteTemplateResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/Template',
            query: {
                'Id': id,
            },
        });
    }

    /**
     * @param sieveModelFilters 
     * @param sieveModelSorts 
     * @param sieveModelPage 
     * @param sieveModelPageSize 
     * @param alwaysIncludeTemplateId 
     * @returns ListTemplateResponse Success
     * @throws ApiError
     */
    public static getApiV1Template(
sieveModelFilters?: string,
sieveModelSorts?: string,
sieveModelPage?: number,
sieveModelPageSize?: number,
alwaysIncludeTemplateId?: number,
): CancelablePromise<ListTemplateResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/Template',
            query: {
                'SieveModel.Filters': sieveModelFilters,
                'SieveModel.Sorts': sieveModelSorts,
                'SieveModel.Page': sieveModelPage,
                'SieveModel.PageSize': sieveModelPageSize,
                'AlwaysIncludeTemplateId': alwaysIncludeTemplateId,
            },
        });
    }

}
