<template>
  <ConfirmDialog />
  <Toast position="center" group="center" />
  <TagsCreate
    :visible="showCreateDialog"
    :onHide="onCreateClose"
    :tag="selectedTag"
  >
  </TagsCreate>
  <Toolbar class="tool-bar">
    <template #start>
      <Button
        label="Create Tag"
        icon="pi pi-plus-circle"
        @click="createTag()"
      />
    </template>
  </Toolbar>
  <div class="m-5 mt-7">
    <ConDataTable
      tableId="Tagslist"
      title="Tags"
      :columns="tagTableColumns"
      filterDisplay="menu"
      :rowsPerPageOptions="[10, 20, 50]"
      :onReload="fetchDataAsync"
      ref="tagTableRef"
      :createActionMenu="createActionMenu"
      globalFilter="make|tagName|typeName|locationName"
    >
    </ConDataTable>
  </div>
</template>
<script setup lang="ts">
import { TagsService } from "@/apis/luna-api/services/TagsService";
import { ref } from "vue";
import { ConDataTable } from "@congatec/primevue-components-lib";
import Toast from "primevue/toast";
import ConfirmDialog from "primevue/confirmdialog";
import Toolbar from "primevue/toolbar";
import Button from "primevue/button";
import { useConfirm } from "primevue/useconfirm";
import TagsCreate from "./TagsCreate.vue";
import { useToast } from "primevue/usetoast";
import router from "@/globalRouter";
import { id } from "date-fns/locale";

const tagTableRef = ref<null | typeof ConDataTable>(null);
const confirm = useConfirm();
const showCreateDialog = ref(false);
const toast = useToast();
const selectedTag = ref({});

const tagTableColumns = [
  {
    field: "id",
    header: "ID",
    dataType: "text",
    isFiltered: false,
    sortable: false,
  },
  {
    field: "name",
    header: "Tag Name",
    dataType: "text",
    isFiltered: false,
    sortable: false,
  },
  {
    field: "description",
    header: "Description",
    dataType: "text",
    isFiltered: false,
    sortable: false,
  },
  {
    field: "actionMenu",
  },
];

async function fetchDataAsync(
  filters: string,
  sorts: string,
  page: number,
  rows: number,
): Promise<any> {
  let res = await TagsService.getApiV1Tags(filters, sorts, page, rows);
  return {
    totalCount: res.totalCount,
    data: res.tags,
  };
}

function createActionMenu(row: any): any {
  return [
    {
      label: "Edit",
      icon: "pi pi-pencil",
      command: () => {
        selectedTag.value = row;
        showCreateDialog.value = true;
      },
    },
    {
      label: "Delete",
      icon: "pi pi-trash",
      command: () => deleteTag(row),
    },
  ];
}

function createTag() {
  showCreateDialog.value = true;
}

function deleteTag(row: any) {
  confirm.require({
    header: "Delete Tag",
    icon: "pi pi-question-circle",
    acceptClass: "p-button-info",
    message: "Are you sure you want to delete this Item?",
    accept: async () => {
      try {
        await TagsService.deleteApiV1Tags(row.id);
        toast.add({
          severity: "success",
          summary: "Delete",
          detail: "Item deleted",
          life: 3000,
        });

        reloadTableData();
      } catch (ex: any) {
        toast.add({
          severity: "error",
          summary: "Error!",
          detail: "An error occured while deleting: " + ex.message,
          life: 3000,
        });
      }
    },
  });
}
const onCreateClose = () => {
  showCreateDialog.value = false;
  selectedTag.value = {};
  reloadTableData();
};

function reloadTableData() {
  tagTableRef.value?.forceReload();
}
</script>

<style scoped lang="scss">
.tool-bar {
  background: none;
}
</style>
