/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CheckoutLogRevisionAmountRequest } from '../models/CheckoutLogRevisionAmountRequest';
import type { CheckoutLogRevisionAmountResponse } from '../models/CheckoutLogRevisionAmountResponse';
import type { CheckoutLogToRevisionRequest } from '../models/CheckoutLogToRevisionRequest';
import type { CheckoutLogToRevisionResponse } from '../models/CheckoutLogToRevisionResponse';
import type { CheckoutRevisionStatusRequest } from '../models/CheckoutRevisionStatusRequest';
import type { CheckoutRevisionStatusResponse } from '../models/CheckoutRevisionStatusResponse';
import type { CheckoutRptRevisionAmountRequest } from '../models/CheckoutRptRevisionAmountRequest';
import type { CheckoutRptRevisionAmountResponse } from '../models/CheckoutRptRevisionAmountResponse';
import type { CheckoutRptRevisionRequest } from '../models/CheckoutRptRevisionRequest';
import type { CheckoutRptRevisionResponse } from '../models/CheckoutRptRevisionResponse';
import type { CheckoutRptToRevisionRequest } from '../models/CheckoutRptToRevisionRequest';
import type { CheckoutRptToRevisionResponse } from '../models/CheckoutRptToRevisionResponse';
import type { ProcessLogAmountRequest } from '../models/ProcessLogAmountRequest';
import type { ProcessLogAmountResponse } from '../models/ProcessLogAmountResponse';
import type { ProcessLogToSysExtractRequest } from '../models/ProcessLogToSysExtractRequest';
import type { ProcessLogToSysExtractResponse } from '../models/ProcessLogToSysExtractResponse';
import type { ProcessRptAmountRequest } from '../models/ProcessRptAmountRequest';
import type { ProcessRptAmountResponse } from '../models/ProcessRptAmountResponse';
import type { ProcessRptRevisionsRequest } from '../models/ProcessRptRevisionsRequest';
import type { ProcessRptRevisionsResponse } from '../models/ProcessRptRevisionsResponse';
import type { ProcessRptSysExtractRequest } from '../models/ProcessRptSysExtractRequest';
import type { ProcessRptSysExtractResponse } from '../models/ProcessRptSysExtractResponse';
import type { ProcessRptToSysExtractRequest } from '../models/ProcessRptToSysExtractRequest';
import type { ProcessRptToSysExtractResponse } from '../models/ProcessRptToSysExtractResponse';
import type { PruneContentRequest } from '../models/PruneContentRequest';
import type { PruneContentResponse } from '../models/PruneContentResponse';
import type { VerifyProductRequest } from '../models/VerifyProductRequest';
import type { VerifyProductResponse } from '../models/VerifyProductResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SvnExtractionsService {

    /**
     * @param request 
     * @returns CheckoutRevisionStatusResponse Success
     * @throws ApiError
     */
    public static getApiV1SvnExtractionStatus(
request?: CheckoutRevisionStatusRequest,
): CancelablePromise<CheckoutRevisionStatusResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/svn-extraction/Status',
            query: {
                'request': request,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns CheckoutRptRevisionAmountResponse Success
     * @throws ApiError
     */
    public static postApiV1SvnExtractionRptFilesExtractLatest(
requestBody?: CheckoutRptRevisionAmountRequest,
): CancelablePromise<CheckoutRptRevisionAmountResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/svn-extraction/rpt-files/extract-latest',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns CheckoutRptToRevisionResponse Success
     * @throws ApiError
     */
    public static postApiV1SvnExtractionRptFilesExtract(
requestBody?: CheckoutRptToRevisionRequest,
): CancelablePromise<CheckoutRptToRevisionResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/svn-extraction/rpt-files/extract',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns CheckoutRptRevisionResponse Success
     * @throws ApiError
     */
    public static postApiV1SvnExtractionRptFilesExtractRevisions(
requestBody?: CheckoutRptRevisionRequest,
): CancelablePromise<CheckoutRptRevisionResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/svn-extraction/rpt-files/extract/revisions',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns CheckoutLogRevisionAmountResponse Success
     * @throws ApiError
     */
    public static postApiV1SvnExtractionLogFilesExtractLatest(
requestBody?: CheckoutLogRevisionAmountRequest,
): CancelablePromise<CheckoutLogRevisionAmountResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/svn-extraction/log-files/extract-latest',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns CheckoutLogToRevisionResponse Success
     * @throws ApiError
     */
    public static postApiV1SvnExtractionLogFilesExtract(
requestBody?: CheckoutLogToRevisionRequest,
): CancelablePromise<CheckoutLogToRevisionResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/svn-extraction/log-files/extract',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns ProcessRptAmountResponse Success
     * @throws ApiError
     */
    public static postApiV1SvnExtractionRptFilesParseLatest(
requestBody?: ProcessRptAmountRequest,
): CancelablePromise<ProcessRptAmountResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/svn-extraction/rpt-files/parse-latest',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns ProcessLogAmountResponse Success
     * @throws ApiError
     */
    public static postApiV1SvnExtractionLogFilesParseLatest(
requestBody?: ProcessLogAmountRequest,
): CancelablePromise<ProcessLogAmountResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/svn-extraction/log-files/parse-latest',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns ProcessRptToSysExtractResponse Success
     * @throws ApiError
     */
    public static postApiV1SvnExtractionRptFilesParse(
requestBody?: ProcessRptToSysExtractRequest,
): CancelablePromise<ProcessRptToSysExtractResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/svn-extraction/rpt-files/parse',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns ProcessRptSysExtractResponse Success
     * @throws ApiError
     */
    public static postApiV1SvnExtractionRptFilesParseIds(
requestBody?: ProcessRptSysExtractRequest,
): CancelablePromise<ProcessRptSysExtractResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/svn-extraction/rpt-files/parse/ids',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns ProcessRptRevisionsResponse Success
     * @throws ApiError
     */
    public static postApiV1SvnExtractionRptFilesParseRevisions(
requestBody?: ProcessRptRevisionsRequest,
): CancelablePromise<ProcessRptRevisionsResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/svn-extraction/rpt-files/parse/revisions',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns VerifyProductResponse Success
     * @throws ApiError
     */
    public static postApiV1SvnExtractionRptFilesVerifyProducts(
requestBody?: VerifyProductRequest,
): CancelablePromise<VerifyProductResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/svn-extraction/rpt-files/verify-products',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns ProcessLogToSysExtractResponse Success
     * @throws ApiError
     */
    public static postApiV1SvnExtractionLogFilesParse(
requestBody?: ProcessLogToSysExtractRequest,
): CancelablePromise<ProcessLogToSysExtractResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/svn-extraction/log-files/parse',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns PruneContentResponse Success
     * @throws ApiError
     */
    public static putApiV1SvnExtractionRptFilesPrune(
requestBody?: PruneContentRequest,
): CancelablePromise<PruneContentResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/svn-extraction/rpt-files/prune',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
