<template>
    <div>
    </div>
</template>


<script lang="ts">

export default defineComponent({
    name: "HardwareReturn",
    components: { },
});
</script>

<script setup lang="ts">
import { defineComponent } from "vue";
import { AuthenticationObserver } from "@/base/AuthenticationObserver";
import { useKeycloak } from "@congatec/authentication-lib";


let authenticationService = useKeycloak();
let authObserver = new AuthenticationObserver(authenticationService);

authObserver.onLoggedIn(async () => {
});


</script>

<style>
    
</style>