import { to } from "@congatec/primevue-components-lib";

export const scheduledExecutionRoutes = [
  {
    path: "/uranus/scheduledexecutions/:executionId/",
    component: () =>
      import(
        "@/uranus/components/scheduledexecutions/details/ScheduledexecutionDetailsView.vue"
      ),
    props: (route: { params: { executionId: any } }) => ({
      propDetailCheckId: route.params.executionId,
    }),
    meta: {
      breadcrumb: [
        { label: "Scheduled Executions", command:to("/uranus/scheduledexecutions") },
        { label: "Details" },
      ],
    },
  },
  {
    path: "/uranus/scheduledexecutions",
    component: () =>
      import(
        "@/uranus/components/scheduledexecutions/list/ScheduledExecutionListView.vue"
      ),
    meta: {
      breadcrumb: [
        { label: "Uranus", command:to("/uranus/home")},
        { label: "Scheduled Executions", command:to("/uranus/scheduledexecutions") },
      ],
    },
  },
  {
    path: "/uranus/scheduledexecutions/:scheduledexecutionId/input/",
    component: () =>
      import(
        "@/uranus/components/scheduledexecutions/input/ScheduledExecutionsInputView.vue"
      ),
    props: (route: { params: { scheduledexecutionId: any } }) => ({
      propDetailCheckId: route.params.scheduledexecutionId,
    }),
    meta: {
      breadcrumb: [
        { label: "Uranus", command:to("/uranus/home")},
        { label: "Scheduled Executions", command:to("/uranus/scheduledexecutions") },
        { label: "Edit" },
      ],
    },
  },
];
