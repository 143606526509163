import { defineStore } from 'pinia'
import { useStorage } from '@vueuse/core'
import { type Ref } from 'vue';

const STORAGE_KEY = "globalRouter-route-token";

export const useGlobalRouterStore = defineStore("GlobalRouterStore", () => {
  const lastRoute: Ref<string | undefined> = useStorage(STORAGE_KEY,  undefined);

  const set = (newRoute: string) => {
    lastRoute.value = newRoute;
  }

  const clear = () =>[
    lastRoute.value = undefined
  ]
  return { lastRoute, set, clear}
});