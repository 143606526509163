<template>
  <div>
    <ProgressSpinner v-if="loading" />
    <div v-else class="m-3 flex flex-column flex-1 justify-content-between">
      <div>
        <span class="p-float-label mt-2 w-full">
          <Input icon="pi pi-file" id="fileName" v-model="data.fileName"
            :class="{ 'p-invalid': v$.fileName.$invalid && v$.fileName.$error, 'w-full': true }" />
          <label for="fileName">File Name</label>
        </span>
      </div>

      <div>
          <FileUpload name="demo[]" @select="onFileSelect" :maxFileSize="100000000" ref="fileUpload" :multiple="false"
            :showUploadButton="false" :showCancelButton="false"
            accept="application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/*" >
            <template #empty>
              <p>Drag and drop files here to upload.</p>
            </template>
          </FileUpload>
      </div>
      <div class="flex flex-row mt-5 justify-content-between">
        <Button class="p-button-text" label="Cancel" :onClick="onCancel" />
        <Button :disabled="v$.$invalid" class="p-button-primary" :label="submitLabel" :onClick="onSubmit" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { AttachmentType, EmailService } from '@/apis/neptune-api';
import { reactive, ref, type Ref } from 'vue';
import Button from 'primevue/button';
import Input from 'primevue/inputtext';
import ProgressSpinner from 'primevue/progressspinner';
import { useToast } from 'primevue/usetoast';
import { canThrowAsyncShowToast, showError } from '@congatec/primevue-components-lib';
import { useVuelidate, type Validation } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import FileUpload from 'primevue/fileupload';

interface Attachment {
  id?: number;
  mimeType?: string;
  type?: AttachmentType;
  fileName?: string;
  content?: string;
}

const fileUpload = ref();
const toast = useToast();
const props = defineProps<{
  attachment?: Attachment
}>()
const emit = defineEmits<{ (e: 'onSubmitted', attachment: Attachment): void, (e: 'onCancel'): void }>();
const loading = ref(false);
let data = reactive<Attachment>({});

const validationsCreate = {
  content: { required },
  fileName: { required },
}

const validationsUpdate = {
  content: {},
  fileName: { required }
}

let v$: Ref<Validation>;
let submitLabel = ref('Create');

if (props.attachment) {
  data = props.attachment;
  v$ = useVuelidate(validationsUpdate, data as any);
  submitLabel.value = 'Update';
} else {
  v$ = useVuelidate(validationsCreate, data as any);
}

const onCancel = () => {
  emit('onCancel');
}

const onFileSelect = (e: any) => {
  
  const file = e.files[0];
  if (!file) {
    return;
  }

  if (!data.fileName) {
    data.fileName = file.name;
  }
  data.mimeType = file.type;

  readFile(file);
}

const readFile = async (file: File) =>  {

  const arrayBuffer = await file.arrayBuffer();
  const uint8Array = new Uint8Array(arrayBuffer);
  const byteArray = Array.from(uint8Array);
  var binary = '';
  var len = byteArray.length;
  for (var i = 0; i < len; i++) {
      binary += String.fromCharCode( byteArray[ i ] );
  }

  
  data.content = btoa(binary);

  fileUpload.value.uploadedFiles = [];
  fileUpload.value.uploadedFiles.push(file);
  fileUpload.value.files = [];
}

const onSubmit = async () => {
  if (props.attachment) {
    await submitUpdate();
  } else {
    await submitCreate();
  }
  emit('onSubmitted', data);
}

const submitUpdate = async () => {
  let formCorrect = await v$.value.$validate();
  if (!formCorrect) {
    return;
  }

  await canThrowAsyncShowToast(toast, async () => await EmailService.putApiV1EmailUpdateAttachment({
    attachmentId: data.id,
    content: data.content,
    fileName: data.fileName,
    mimeType: data.mimeType,
    type: AttachmentType.FILE
  }));
}

const submitCreate = async () => {
  let formCorrect = await v$.value.$validate();
  if (!formCorrect) {
    return;
  }

  await canThrowAsyncShowToast(toast, async () => await EmailService.postApiV1EmailCreateAttachment({
    content: data.content,
    fileName: data.fileName,
    mimeType: data.mimeType,
    type: AttachmentType.FILE
  }));
}

</script>
