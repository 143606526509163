import { addAuthToGuards } from "./global-guards";
import { type AuthenticationService } from "@congatec/authentication-lib";
import { type Ref, ref } from "vue";
import { executionRoutes } from "@/uranus/components/executions/router/ExecutionsRoutes";
import { dataqualitycheckRoutes } from "@/uranus/components/dataqualitychecks/router/DataqualityCheckRoutes";
import { dwhDatabaseRoutes } from "@/uranus/components/dwhdatabases/router/DwhDatabaseRoutes";
import { scheduledExecutionRoutes } from "@/uranus/components/scheduledexecutions/router/ScheduledExecutionRoutes";
import { UranusHomeView } from "@/uranus/components/home";

const _authenticationService: Ref<AuthenticationService | undefined | null> =
  ref(null);

export function addAuthToRouter(authenticationService: AuthenticationService) {
  _authenticationService.value = authenticationService;
  addAuthToGuards(_authenticationService.value);
}

const uranusRoutes = [
  {
    path: "/uranus/home",
    name: "uranusHome",
    component: UranusHomeView,
    meta: {
      breadcrumb: [
        { title: "Uranus" },
        { label: "Home"}
      ],
    },
  },
  ...executionRoutes,
  ...dataqualitycheckRoutes,
  ...dwhDatabaseRoutes,
  ...scheduledExecutionRoutes,
];

export default uranusRoutes;
