<template>
  <div class="flex flex-column">
    <div class="mt-3 grid">
      <Card class="col lg:col xl:col sm:col-12 m-3">
        <template #title>General</template>
        <template #content>
          <div>
            <div class="container">
              <ConDetailsItem
                title="Name"
                icon="pi pi-info-circle"
                :content="`${bundle.name}`"
              />
              <ConDetailsItem
                title="Version"
                icon="pi pi-history"
                :content="bundle.version"
              />
              <ConDetailsItem
                title="PLM Item"
                icon="pi pi-box"
                :content="bundle.plmItem"
              />
              <ConDetailsItem
                title="Release State"
                icon="pi pi-wave-pulse"
                :content="bundle.releaseState"
              />
              <ConDetailsItem
                title="Description"
                icon="pi pi-pen-to-square"
                :content="bundle.description"
              />
            </div>
            <Divider></Divider>
            <ConDataTable
              tableId="ArtifactList"
              title="Bundle includes these Artifacts"
              :columns="artifactTableColumns"
              :onReload="fetchArtifactDataAsync"
              :tableData="bundle.artifacts"
              :createActionMenu="BundlesDetailsActionMenu"
            >
            </ConDataTable>
          </div>
        </template>
      </Card>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ConDetailsItem } from "@congatec/primevue-components-lib";
import Card from "primevue/card";
import Divider from "primevue/divider";
import { ArtifactService, BundleService } from "@/apis/aready-api";
import { onMounted, ref, watch } from "vue";
import { ConDataTable } from "@congatec/primevue-components-lib";

const bundle = ref({
  name: "",
  version: "",
  plmItem: "",
  description: "",
  releaseState: "",
  artifacts: [{}],
});

const artifactTableColumns = [
  {
    field: "filePath",
    header: "File Path",
    dataType: "text",
    isFiltered: true,
    sortable: true,
  },
  {
    field: "fileSize",
    header: "File Size",
    dataType: "text",
    isFiltered: true,
    onFormat: formatBytesToMegaBytes,
    sortable: true,
  },
  {
    field: "identifier",
    header: "Identifier",
    dataType: "text",
    isFiltered: true,
    sortable: true,
  },
  {
    field: "plmItem",
    header: "PLM Item",
    dataType: "text",
    isFiltered: true,
    sortable: true,
  },
  {
    field: "actionMenu",
  },
];

function BundlesDetailsActionMenu(row: any): any {
  return [
    {
      label: "Download",
      icon: "pi pi-download",
      command: () => {
        ArtifactDownload(row);
      },
    },
  ];
}

onMounted(async () => {
  fetchDataAsync();
});

var props = defineProps({
  guid: String,
});

watch(
  () => props.guid,
  (nv) => {
    if (nv) {
      fetchDataAsync();
    }
  },
);

async function fetchArtifactDataAsync(): Promise<any> {
  return {
    totalCount: bundle.value.artifacts.length,
    data: bundle.value.artifacts,
  };
}

const fetchDataAsync = async () => {
  var res = await BundleService.getApiV1SoftwareBundles(props.guid!);
  bundle.value.description = res.description ? res.description : "";
  bundle.value.name = res.name ? res.name : "";
  bundle.value.plmItem = res.plmItem ? res.plmItem : "";
  bundle.value.releaseState = res.releaseStatus ? res.releaseStatus : "";
  bundle.value.version = res.version ? res.version : "";
  bundle.value.artifacts = res.artifacts ? res.artifacts : [{}];
};

function formatBytesToMegaBytes(bytes: number): string {
  var megaBytes = Math.round(bytes / 1000);

  return megaBytes + " MB";
}

async function ArtifactDownload(row: any) {
  let result = await ArtifactService.getApiV1ArtifactsUrl(row.guid);
  window.open(result.objectUrl!);
}
</script>

<style scoped lang="scss">
.container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
}
</style>
