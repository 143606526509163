<template>
  <div v-if="!loading" class="w-full h-full flex flex-column justify-content-center m-3">
    <div class="flex flex-column align-items-center justify-content-center">
      <div class="flex flex-column align-items-center justify-content-center">
        <div class="flex flex align-self-end align-items-center justify-content-center mb-3">
          <Button @click="startScan" icon="pi pi-camera" >
          </Button>
        </div>
        <div style="background: #1e1e1e; min-width: 75vw; min-height: 75vh !important;">
          <qrcode-stream v-if="isStartScan" class="d-flex align-start justify-start" style="max-width: 75vw; max-height: 75vh;"
          :track="scanLocked == false ? drawOutline : () => { }" @init="onInit" @decode="onDecode" @detect="onDetect"
            :camera="camera">
          </qrcode-stream>
        </div>
      </div>
    </div>
  </div>
</template>


<script lang="ts">

export default defineComponent({
  name: "VisitorQrScan",
  components: {
  }
});
</script>

<script setup lang="ts">
import { onMounted } from 'vue';
import { defineComponent, ref } from "vue";
import { useKeycloak } from '@congatec/authentication-lib';
import { AuthenticationObserver } from '@/base/AuthenticationObserver';
import { useToast } from 'primevue/usetoast';
import { CheckInOutService, VisitorStatus } from "@/apis/neptune-api";
import { useRouter } from 'vue-router';
import { useBreadCrumbStore } from '@congatec/primevue-components-lib';
import Button from 'primevue/button';

const toast = useToast();
const router = useRouter();
const isStartScan = ref(false);

// const store = useBreadCrumbStore();
// onMounted(() => {
//   store.$reset();
// });

enum DecodingState {
  Unloaded,
  Idle,
  Failed,
  Processing,
  Validating
}

interface DecodedGuidObject {
  VisitorId: number,
  VisitId: number,
}

let authenticationService = useKeycloak();
let authObserver = new AuthenticationObserver(authenticationService);
let loading = ref(true);
let camera = ref('auto');
let scanLocked = false;
let eventStatusResponse = "";
let isCameraDisabled = false;
let decodingState = ref(DecodingState.Unloaded)
let timeoutIdValidationReset: any = undefined;

authObserver.onLoggedIn(async () => {
  loading.value = false;
});

const onDecode = (decoded: any) => {
  if (
    decodingState.value ==
    DecodingState.Processing &&
    !scanLocked
  ) {
    scanLocked = true;
    decodingState.value = DecodingState.Validating;

    camera.value = "off";
    validate(decoded);
  }
}

const validate = async (decodedJsonString: any) => {
  timeoutIdValidationReset = window.setTimeout(resetScanner, 5000);

  try {
    let decodedObject: DecodedGuidObject = JSON.parse(decodedJsonString);
    console.log(decodedObject);
    var res = await CheckInOutService.putApiV1CheckInOutCreateOrEdit({
      visitIds: [decodedObject.VisitId]
    });

    console.log(res);
    if (res.status && res.status[decodedObject.VisitId] != VisitorStatus.CHECKED_OUT) {
      // TODO push to sign out ui
      router.push(`/hw-signout/${decodedObject.VisitorId}`);
    } else {
      // TODO return signed out hardware
    }
    toast.add({
      severity: 'success',
      summary: '',
      life: 3000
    });
    
  } catch (error: any) {
    toast
      .add({
        severity: 'error',
        summary: 'Invalid QR Code',
        life: 3000
      });
  } finally {
    isStartScan.value = false;
  }
}

const onInit = async (promise: any) => {
  if (decodingState.value != DecodingState.Unloaded) {
    return;
  }
  loading.value = true;
  try {
    const { capabilities } = await promise;
    // successfully initialized
    decodingState.value = DecodingState.Idle;
    console.log("Camera initialized!");
  } catch (error: any) {
    decodingState.value = DecodingState.Failed;
    isCameraDisabled = true;
    toast
      .add({
        severity: 'error',
        summary: 'Camera unavailable',
        life: 3000
      });

    if (error.name === "NotAllowedError") {
      // user denied camera access permisson
      console.log(error);
    } else if (error.name === "NotFoundError") {
      // no suitable camera device installed
      console.log(error);
    } else if (error.name === "NotSupportedError") {
      // page is not served over HTTPS (or localhost)
      console.log(error);
    } else if (error.name === "NotReadableError") {
      // maybe camera is already in use
      console.log(error);
    } else if (error.name === "OverconstrainedError") {
      // did you requested the front camera although there is none?
      console.log(error);
    } else if (error.name === "StreamApiNotSupportedError") {
      // browser seems to be lacking features
      console.log(error);
    }
  } finally {
    // hide loading indicator
  }
  loading.value = false;
}

const onDetect = (stream: string) => {

}

const drawOutline = (detectedCodes: any, ctx: any) => {
  if (decodingState.value != DecodingState.Processing &&
    !scanLocked) {
    decodingState.value = DecodingState.Processing;
  }

  isCameraDisabled = false;

  if (!scanLocked) {
    for (const detectedCode of detectedCodes) {
      const [firstPoint, ...otherPoints] = detectedCode.cornerPoints;

      ctx.strokeStyle = "#fc4c02";

      ctx.beginPath();
      ctx.moveTo(firstPoint.x, firstPoint.y);
      for (const { x, y } of otherPoints) {
        ctx.lineTo(x, y);
      }
      ctx.lineTo(firstPoint.x, firstPoint.y);
      ctx.lineWidth = 5;
      ctx.closePath();
      ctx.stroke();
    }
  }
}

const resetScanner = () => {
  eventStatusResponse = "";
  decodingState.value = DecodingState.Idle;
  scanLocked = false;
  camera.value = "auto";
}

var timeoutId: number | NodeJS.Timeout | undefined = undefined;
const startScan = () => {
  isStartScan.value = !isStartScan.value;
  if(isStartScan.value == true) {
    // console.log("setTimeout");
    var millisecondsToWait = 30000;
    timeoutId = setTimeout(function() {
      isStartScan.value = false;
      timeoutId = undefined;
      // console.log("Scan Timeout ende");
    }, millisecondsToWait);
  } else {
    //console.log("clearTimeout");
    clearTimeout(timeoutId);
    timeoutId = undefined;
  }
}

</script>

<style scoped></style>
