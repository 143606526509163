/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DepartmentCreateRequest } from '../models/DepartmentCreateRequest';
import type { DepartmentCreateResponse } from '../models/DepartmentCreateResponse';
import type { DepartmentDeleteResponse } from '../models/DepartmentDeleteResponse';
import type { DepartmentGetResponse } from '../models/DepartmentGetResponse';
import type { DepartmentListResponse } from '../models/DepartmentListResponse';
import type { DepartmentUpdateRequest } from '../models/DepartmentUpdateRequest';
import type { DepartmentUpdateResponse } from '../models/DepartmentUpdateResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DepartmentsService {

    /**
     * @param requestBody 
     * @returns DepartmentCreateResponse Success
     * @throws ApiError
     */
    public static postApiV1Departments(
requestBody?: DepartmentCreateRequest,
): CancelablePromise<DepartmentCreateResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/Departments',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param sieveModelFilters 
     * @param sieveModelSorts 
     * @param sieveModelPage 
     * @param sieveModelPageSize 
     * @returns DepartmentListResponse Success
     * @throws ApiError
     */
    public static getApiV1Departments(
sieveModelFilters?: string,
sieveModelSorts?: string,
sieveModelPage?: number,
sieveModelPageSize?: number,
): CancelablePromise<DepartmentListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/Departments',
            query: {
                'SieveModel.Filters': sieveModelFilters,
                'SieveModel.Sorts': sieveModelSorts,
                'SieveModel.Page': sieveModelPage,
                'SieveModel.PageSize': sieveModelPageSize,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns DepartmentUpdateResponse Success
     * @throws ApiError
     */
    public static putApiV1Departments(
requestBody?: DepartmentUpdateRequest,
): CancelablePromise<DepartmentUpdateResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/Departments',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id 
     * @returns DepartmentDeleteResponse Success
     * @throws ApiError
     */
    public static deleteApiV1Departments(
id?: number,
): CancelablePromise<DepartmentDeleteResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/Departments',
            query: {
                'Id': id,
            },
        });
    }

    /**
     * @param id 
     * @returns DepartmentGetResponse Success
     * @throws ApiError
     */
    public static getApiV1Departments1(
id: number,
): CancelablePromise<DepartmentGetResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/Departments/{id}',
            path: {
                'id': id,
            },
        });
    }

}
