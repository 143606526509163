/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CastVoteRequestBody } from '../models/CastVoteRequestBody';
import type { CastVoteResponse } from '../models/CastVoteResponse';
import type { ChangeReleaseStatusRequestBody } from '../models/ChangeReleaseStatusRequestBody';
import type { ChangeReleaseStatusResponse } from '../models/ChangeReleaseStatusResponse';
import type { CheckReleaseStatusResponse } from '../models/CheckReleaseStatusResponse';
import type { ListVoteResponse } from '../models/ListVoteResponse';
import type { ReleaseRuleTableCreateRequest } from '../models/ReleaseRuleTableCreateRequest';
import type { ReleaseRuleTableCreateResponse } from '../models/ReleaseRuleTableCreateResponse';
import type { ReleaseRuleTableDeleteResponse } from '../models/ReleaseRuleTableDeleteResponse';
import type { ReleaseRuleTableListResponse } from '../models/ReleaseRuleTableListResponse';
import type { ReleaseRuleTableUpdateBody } from '../models/ReleaseRuleTableUpdateBody';
import type { ReleaseRuleTableUpdateResponse } from '../models/ReleaseRuleTableUpdateResponse';
import type { VoteStatus } from '../models/VoteStatus';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ReleaseService {

    /**
     * @param requestBody 
     * @returns ReleaseRuleTableCreateResponse OK
     * @throws ApiError
     */
    public static postApiV1ReleaseReleaseRules(
requestBody?: ReleaseRuleTableCreateRequest,
): CancelablePromise<ReleaseRuleTableCreateResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/release/release-rules',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param sieveModelFilters 
     * @param sieveModelSorts 
     * @param sieveModelPage 
     * @param sieveModelPageSize 
     * @returns ReleaseRuleTableListResponse OK
     * @throws ApiError
     */
    public static getApiV1ReleaseReleaseRules(
sieveModelFilters?: string,
sieveModelSorts?: string,
sieveModelPage?: number,
sieveModelPageSize?: number,
): CancelablePromise<ReleaseRuleTableListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/release/release-rules',
            query: {
                'SieveModel.Filters': sieveModelFilters,
                'SieveModel.Sorts': sieveModelSorts,
                'SieveModel.Page': sieveModelPage,
                'SieveModel.PageSize': sieveModelPageSize,
            },
        });
    }

    /**
     * @param guid 
     * @returns ReleaseRuleTableDeleteResponse OK
     * @throws ApiError
     */
    public static deleteApiV1ReleaseReleaseRules(
guid: string,
): CancelablePromise<ReleaseRuleTableDeleteResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/release/release-rules/{Guid}',
            path: {
                'Guid': guid,
            },
        });
    }

    /**
     * @param guid 
     * @param requestBody 
     * @returns ReleaseRuleTableUpdateResponse OK
     * @throws ApiError
     */
    public static putApiV1ReleaseReleaseRules(
guid: string,
requestBody?: ReleaseRuleTableUpdateBody,
): CancelablePromise<ReleaseRuleTableUpdateResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/release/release-rules/{Guid}',
            path: {
                'Guid': guid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param guid 
     * @param requestBody 
     * @returns CastVoteResponse OK
     * @throws ApiError
     */
    public static putApiV1ReleaseVote(
guid: string,
requestBody?: CastVoteRequestBody,
): CancelablePromise<CastVoteResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/release/{Guid}/vote',
            path: {
                'Guid': guid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param guid 
     * @returns ListVoteResponse OK
     * @throws ApiError
     */
    public static getApiV1ReleaseVote(
guid: string,
): CancelablePromise<ListVoteResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/release/{Guid}/vote',
            path: {
                'Guid': guid,
            },
        });
    }

    /**
     * @param guid 
     * @param requestBody 
     * @returns ChangeReleaseStatusResponse OK
     * @throws ApiError
     */
    public static putApiV1ReleaseChangeStatus(
guid: string,
requestBody?: ChangeReleaseStatusRequestBody,
): CancelablePromise<ChangeReleaseStatusResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/release/{Guid}/change-status',
            path: {
                'Guid': guid,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param guid 
     * @param bodyNewStatus 
     * @returns CheckReleaseStatusResponse OK
     * @throws ApiError
     */
    public static getApiV1ReleaseCheckStatus(
guid: string,
bodyNewStatus?: VoteStatus,
): CancelablePromise<CheckReleaseStatusResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/release/{Guid}/check-status',
            path: {
                'Guid': guid,
            },
            query: {
                'Body.NewStatus': bodyNewStatus,
            },
        });
    }

}
