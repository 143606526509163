/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ReleaseRuleType {
    DEPARTMENT = 'department',
    SPECIFIC_PERSON = 'specificPerson',
    ALL_RELEASED = 'allReleased',
}
