<template style="height: 100%">
  <select v-model="options.select" @change="fetchCalendarEvents()">
    <option selected disabled>Select A Room</option>
    <option v-for="room in rooms" v-bind:key="room.id!" :value="room.emailAdress">{{ room.displayName }}</option>
  </select>
  <div class="calendar-app">
    <div class="calendar-app-main">
      <!-- <span v-if="options?.events">EVENTS: {{ JSON.stringify(options?.events) }}</span> -->
      <FullCalendar ref="fullCalendar" id="calendar" class="calendar-app-calendar" :options="options" >
        <!-- <template v-slot:eventContent="arg">
          <b>{{ arg.timeText }}</b>
          <i>{{ arg.event.title }}</i>
        </template> -->
      </FullCalendar>
    </div>
  </div> 
</template>
<script setup lang="ts">
import { defineComponent, onMounted, type Ref, ref } from "vue";
import "@fullcalendar/core/vdom"; // solve problem with Vite
import { type DateInput } from "@fullcalendar/core";
import FullCalendar, {
  type EventClickArg,
  type EventInput
} from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import { previousSunday } from 'date-fns'
import interactionPlugin from "@fullcalendar/interaction";
import {
  type CalendarEventListResponse,
  CalendarEventsService,
PlacesService,
type Room
} from "@/apis/mercury-api";

onMounted(() => {

fetchCalendarEvents();
fetchRooms();

});

let allEvents: EventInput[] = [];

var fullCalendar : any = ref(null);

let rooms: Ref<Room[] | null | undefined> = ref([]);
// const rooms = [
//   { id: 1, name: "Elbe" },
//   { id: 2, name: "Donau" },
//   { id: 3, name: "Isar" },
//   { id: 4, name: "Main" },
//   { id: 5, name: "Lech" },
//   { id: 6, name: "Regen" },
//   { id: 7, name: "Vils" },
//   { id: 8, name: "Alz" },
//   { id: 9, name: "Inn" },
//   { id: 10, name: "Laber" },
//   { id: 11, name: "Alishan" },
//   { id: 12, name: "Hehuanshan" },
//   { id: 13, name: "OldTown" },
//   { id: 14, name: "Yangmingshan" },
//   { id: 15, name: "Yushan" },
// ];

const options: Ref<any> = ref({
  plugins: [
    dayGridPlugin,
    timeGridPlugin,
    interactionPlugin, // needed for dateClick
  ],
  headerToolbar: {
    left: "prev,next today",
    center: "title",
    right: "dayGridMonth,timeGridWeek,timeGridDay",
  },
  initialView: "dayGridMonth",
  locale: "de",
  //initialEvents: this.fetchCalendarEvents,
  editable: true,
  selectable: true,
  selectMirror: true,
  dayMaxEvents: true,
  weekends: true,
  select: "Elbe",
  eventClick: handleEventClick,
  events: allEvents,
  customButtons: {
        prev: {
          text: '<',
          click: function() {
              const api = fullCalendar.value.getApi().currentData.viewApi;
              const api2 = fullCalendar.value.getApi();
              let firstDay = api.activeStart;
            
            if(api.type == "dayGridMonth")
            {
              let firstDay2 = api.currentStart;
              const date3 = subtractMonths(firstDay2);
              console.log(date3);
              api2.changeView("dayGridMonth", date3);
              fetchCalendarEvents();

            }
            else if(api.type == "timeGridWeek")
            {
  
                const date3 = subtracWeeks(firstDay);
                console.log("test");
                api2.changeView("timeGridWeek", date3);
                fetchCalendarEvents();
            }
            else if(api.type == "timeGridDay")
            {
  
                const date3 = subtracDays(firstDay);
                console.log(date3);
                api2.changeView("timeGridDay", date3);
                fetchCalendarEvents();
            }

          }
        },
        next: {
          text: '>',
          click: function() {
              const api = fullCalendar.value.getApi().currentData.viewApi;
              const api2 = fullCalendar.value.getApi();
              let firstDay = api.activeStart;


            if(api.type == "dayGridMonth")
            {
              let firstDay2 = api.currentStart;
              const date3 = addMonths(firstDay2);
              api2.changeView("dayGridMonth", date3);
              fetchCalendarEvents();

            }
            else if(api.type == "timeGridWeek")
            {
                let firstDay2 = api.currentStart;
                const date3 = addWeeks(firstDay2);
                api2.changeView("timeGridWeek", date3);
                fetchCalendarEvents();
            }
            else if(api.type == "timeGridDay")
            {
                const date3 = addDays(firstDay);
                api2.changeView("timeGridDay", date3);
                fetchCalendarEvents();
            }

          }
        },
        today: {
          text: 'today',
          click: function() {
              const api = fullCalendar.value.getApi().currentData.viewApi;
              const api2 = fullCalendar.value.getApi();
              var date = new Date();
              var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);

            if(api.type == "dayGridMonth")
            {

              api2.changeView("dayGridMonth", firstDay);
              fetchCalendarEvents();

            }
            else if(api.type == "timeGridWeek")
            {
                api2.changeView("timeGridWeek", previousSunday(date));
                fetchCalendarEvents();
            }
            else if(api.type == "timeGridDay")
            {
                api2.changeView("timeGridDay", date);
                fetchCalendarEvents();
            }

          }
        },
        timeGridWeek: {
          text: "week",
          click: function() {
            const api = fullCalendar.value.getApi().currentData.viewApi;
            const api2 = fullCalendar.value.getApi();
            let firstDay = api.activeStart;
            api2.changeView("timeGridWeek", previousSunday(firstDay));
            fetchCalendarEvents();
          }
        },
        dayGridMonth: {
          text: "month",
          click: function() {
            const api = fullCalendar.value.getApi().currentData.viewApi;
            const api2 = fullCalendar.value.getApi();
            let month = api.activeStart.getMonth();
            let year =  api.activeStart.getFullYear();
            var firstDay = new Date(year, month, 1);
            api2.changeView("dayGridMonth", firstDay);
            fetchCalendarEvents();
          }
        },
        timeGridDay: {
          text: "day",
          click: function() {
            const api = fullCalendar.value.getApi().currentData.viewApi;
            const api2 = fullCalendar.value.getApi();
            let firstDay = api.activeStart;
            api2.changeView("timeGridDay", firstDay);
            fetchCalendarEvents();
          }
        }
        },
});


function subtractMonths(date : Date) {
  var tomorrow = new Date(date);
  tomorrow.setMonth(date.getMonth() - 1);
  return tomorrow;
}

function addMonths(date : Date) {
  var tomorrow = new Date(date);
  tomorrow.setMonth(date.getMonth() + 1);
  return tomorrow;
}

function subtracWeeks(date : Date) {
  var tomorrow = new Date(date);
  tomorrow.setDate(date.getDate()-7);
  return tomorrow;
}

function addWeeks(date : Date) {
  var tomorrow = new Date(date);
  tomorrow.setDate(date.getDate()+7);
  return tomorrow;
}

function subtracDays(date : Date) {
  var tomorrow = new Date(date);
  tomorrow.setDate(date.getDate()-1);
  return tomorrow;
}

function addDays(date : Date) {
    var tomorrow = new Date(date);
    tomorrow.setDate(date.getDate()+1);
  return tomorrow;
}


function handleEventClick(clickInfo: EventClickArg) {
  if (
    confirm(
      `Would you like to contact the organizer? '${clickInfo.event.title}'`
    )
  ) {
      const mail = clickInfo.event.title.replace(" ", ".") + "@congatec.com";
      window.location.href = "mailto:" + mail + "?subject=Meeting room change request on " + clickInfo.event._instance?.range.start.toUTCString() + "&body=Bitte antworten Sie auf diese E-Mail. \n Mit freundlichen Grüßen / Best regards, \n Mercury";

  }
}

async function fetchRooms() {
  let response = await PlacesService.getApiV1PlacesList();
  rooms.value = response.rooms;
}


async function fetchCalendarEvents() {
  
  const api = fullCalendar.value.getApi().currentData.viewApi;
  console.log(api.activeStart);
  let firstDay = api.activeStart;
  let lastDay = api.activeEnd;
  const request = {
    from: firstDay,
    to: lastDay,
    roomEmail:  options.value.select?.toString(),
  };

  console.log("Req", request);

  var r = await CalendarEventsService.postApiV1CalendarEventsList(request);
  let response: CalendarEventListResponse = r;

  let maped = response.events?.map(
    (e) =>
    ({
      title: e.organizer?.emailAddress?.name,
      start: convertToTimeZone(e.start!.toString()),
      end: convertToTimeZone(e.end!.toString()),
      allDay: false,
    } as EventInput)
  ) as EventInput[];

  options.value.events = maped;

  console.log("events", maped);
}

function convertToTimeZone(fullDate: string) {
  const date = fullDate.substring(0,10);
  const time = fullDate.substring(11, 19).split(":");
  const tz = fullDate.substring(20,26).split(":");

  var x = Number(time[0]).toString();

  if(x.length == 1) 
  {
    x = "0" + x
  }

  var t = (date + "T" + x + ":" + time[1] + "+00:00");

  return t as DateInput
}


</script>
<script lang='ts'>
  export default defineComponent({
    name: "CalendarView",
    components: {
      FullCalendar
    }
  });
</script>
     
<style scoped>
ul {
  margin: 0;
  padding: 0 0 0 1.5em;
}
</style>
      
<style lang='css'>
h2 {
  margin: 0;
  font-size: 16px;
}

select {
  display: block;
  margin: 0 auto;
  margin-top: 1em;
  outline-style: auto;
  height: 2rem;
}


li {
  margin: 1.5em 0;
  padding: 0;
}

b {
  /* used for event dates/times */
  margin-right: 3px;
}

.calendar-app {
  display: flex;
  min-height: 100%;
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 14px;
}

.calendar-app-sidebar {
  width: 300px;
  line-height: 1.5;
  background: #eaf9ff;
  border-right: 1px solid #d3e2e8;
}

.calendar-app-sidebar-section {
  padding: 2em;
}

.calendar-app-main {
  flex-grow: 1;
  padding: 3em;
}

.fc {
  /* the calendar root */
  max-width: 90%;
  max-height: 70%;
  margin: 0 auto;
}

.container {
  display: flex;
  flex-direction: row;
  align-content: flex-end;
}

.partial {
  flex: 0 0 5%;
  margin-top: 1em;
}

select {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8nwYAAmoBZ0eMiB8AAAAASUVORK5CYII=) repeat;
  border: none;
  border-radius: 3px;
  box-shadow: 2px 2px 5px 1px rgba(0,0,0,.3);
  color: rgb(0, 0, 0);
  cursor: pointer;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  height: 40px;
  outline: none;
  padding-left: 10px;
  width: 86%;
}

</style>