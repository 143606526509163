<template>
  <Dialog
    v-if="dialogVisible"
    v-model:visible="dialogVisible"
    modal
    :draggable="false"
    :header="headerText"
    position="top"
    style="max-width: 50%; min-width: 50%; max-height: 80%"
  >
    <template #default>
      <div class="flex flex-column gap-5 w-full">
        <div class="flex flex-row w-full gap-2">
          <div class="flex flex-column w-6">
            <label>Tag Name*</label>
            <InputText
              type="text"
              v-model="name"
              placeholder="Name of the tag"
            />
          </div>
          <div class="flex flex-column w-6">
            <label> Description</label>
            <InputText
              type="text"
              v-model="description"
              placeholder="Description"
              @keyup.enter="onSave"
            />
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="flex flex-row align-items-end justify-content-between">
        <Button
          class="p-button-text"
          label="Cancel"
          :onClick="
            () => {
              dialogVisible = false;
            }
          "
        />
        <Button
          class="p-button-primary"
          :disabled="!name"
          label="Save"
          :onClick="onSave"
        />
      </div>
    </template>
  </Dialog>
</template>
<script setup lang="ts">
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import { useToast } from "primevue/usetoast";
import { ref, watch } from "vue";
import {
  type TagsCreateRequest,
  TagsService,
  type TagsUpdateRequest,
} from "@/apis/luna-api";

const dialogVisible = ref(false);
const toast = useToast();
const description = ref("");
const name = ref("");
const headerText = ref("Create new Tag");

const props = defineProps<{
  visible: boolean;
  onHide: (edited: boolean) => any;
  tag?: any;
}>();

watch(
  () => props.visible,
  (nv: any) => {
    console.log("VISIBLE: ", nv);
    if (nv) {
      dialogVisible.value = nv;
    }

    if (nv && props.tag && props.tag.name) {
      headerText.value = "Edit Tag";
      name.value = props.tag.name;
      description.value = props.tag.description;
    }
  },
);
watch(
  () => dialogVisible.value,
  (nv: any) => {
    if (nv != null && !nv) {
      console.log("TRIGGER");
      headerText.value = "Create new Tag";
      name.value = "";
      description.value = "";
      props.onHide(true);
    }
  },
);

const onSave = async () => {
  if (props.tag && props.tag.name) {
    console.log("LOCATION: ", props.tag);
    console.log("LOCATION NAME: ", props.tag.name);
    await dispatchEditTagAsync({
      id: props.tag.id,
      description: description.value,
      name: name.value,
    } as TagsUpdateRequest);
    return;
  }

  await dispatchCreateTagAsync({
    description: description.value,
    name: name.value,
  } as TagsCreateRequest);
};

async function dispatchCreateTagAsync(tagsCreateRequest: TagsCreateRequest) {
  await TagsService.postApiV1Tags(tagsCreateRequest);
  toast.add({
    severity: "success",
    summary: "Tag created",
    detail: "Tag created successfully",
    life: 3000,
  });
  dialogVisible.value = false;
}

async function dispatchEditTagAsync(tagsUpdateRequest: TagsUpdateRequest) {
  await TagsService.putApiV1Tags(props.tag.id, tagsUpdateRequest);
  dialogVisible.value = false;
}
</script>
