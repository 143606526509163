<template>
  <div class="m-3">
    <Dialog v-model:visible="commentDialogVisible" modal header="Comment" :style="{ width: '25rem' }">
      <Textarea v-model="comment" rows="5" cols="30" />
      <Button label="Submit" @click="() => castVote(voteStatus, comment)" class="mt-3 w-full" />
    </Dialog>

    <h3>Votes for {{ guid }}</h3>
    <div class="flex">
      <SplitButton class="m-2" icon="pi pi-check" label="Approve" severity="success"
        @click="() => showCommentDialog(VoteStatus.APPROVED)" :model="voteButtonItems" />

      <span v-tooltip="acceptTooltip">
        <Button @click="accept" :disabled="!canAccept" class="m-2" label="Accept" severity="success" />
      </span>
      <span v-tooltip="rejectTooltip">
        <Button @click="reject" :disabled="!canReject" class="m-2" label="Reject" severity="danger" />
      </span>
    </div>

    <Timeline :value="statusList" layout="horizontal" align="top">
      <template #marker="slotProps">
        <span class="flex w-2rem h-2rem align-items-center justify-content-center text-white border-circle z-1 shadow-1"
          :style="{ backgroundColor: slotProps.item.color }">
          <i :class="slotProps.item.icon"></i>
        </span>

      </template>
      <template #content="slotProps">
        <div :class="{ 'text-primary': props.state == slotProps.item.state }">
          {{ slotProps.item.title }}
        </div>
      </template>
    </Timeline>

    <div class="grid">
      <div class="col-4">

        <ProgressSpinner v-if="!votes" />
        <div v-else class="mt-3 grid grid-nogutter">
          <div v-if="votes.length == 0">
            <Card class="mb-3" style="width: 25rem; overflow: hidden">
              <template #title>
                No votes
              </template>
            </Card>
          </div>

          <div v-for="(item, index) in votes" :key="index" class="col-12">
            <Card class="mb-3" style="width: 25rem; overflow: hidden">
              <template #title>
                {{ `${item.status!.charAt(0).toUpperCase()}${item.status!.slice(1)}` }}
              </template>
              <template #subtitle>
                {{ item.firstName }}
                {{ item.lastName }}
              </template>
              <template #content>
                <p>{{ item.comment }}</p>
              </template>
              <template #footer>
                <div class="flex gap-3 mt-1">
                  <a :href="`mailto:${item.email}`" target="_blank" rel="noopener" class="w-full">
                    <Button label="Contact" class="w-full" icon="pi pi-envelope" />
                  </a>
                </div>
              </template>
            </Card>
          </div>
        </div>
      </div>

      <div class="col-8">
        <slot>
          Captain Placeholer
          - Releasable Details -
        </slot>
      </div>

    </div>

  </div>
</template>

<script setup lang="ts">

import { onMounted, ref } from 'vue';
import { type ListVoteResponseVote, ReleaseService, VoteStatus, type ListVoteResponse, type ChangeReleaseStatusRequestBody, type CheckReleaseStatusResponse } from '@/apis/aready-api'
import SplitButton from 'primevue/splitbutton';
import Card from 'primevue/card';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import { useToast } from 'primevue/usetoast';
import Textarea from 'primevue/textarea';
import ProgressSpinner from 'primevue/progressspinner';
import Timeline from 'primevue/timeline';
import Tooltip from 'primevue/tooltip';

const statusList = [
  { title: "Rejected", state: "rejected", icon: 'pi pi-times-circle', color: 'red' },
  { title: "Draft", state: 'draft', icon: 'pi pi-file-o', color: 'orange' },
  { title: "Review", state: 'review', icon: 'pi pi-flag-fill', color: 'teal' },
  { title: "Released", state: 'release', icon: 'pi pi-verified', color: 'green' }];

const votes = ref<ListVoteResponseVote[]>([]);
const toast = useToast();
const comment = ref('');

const commentDialogVisible = ref(false);
const voteStatus = ref();

const canAccept = ref(false);
const acceptTooltip = ref('');
const canReject = ref(false);
const rejectTooltip = ref('');

const emit = defineEmits(["init"]);

onMounted(async () => {
  await init();
});

export type ReviewProps = {
  guid: string,
  state?: string,

  checkStatusReq?: (guid: string, newStatus?: VoteStatus) => Promise<CheckReleaseStatusResponse>,
  changeStatusReq?: (guid: string, body?: ChangeReleaseStatusRequestBody) => Promise<{}>,

  castVoteReq?: (guid: string, body: {
    status?: VoteStatus;
    comment?: string | null;
  }) => Promise<{}>;

  fetchVotesReq?: (guid: string) => Promise<ListVoteResponse>
};
const props = withDefaults(defineProps<ReviewProps>(),
  {
    castVoteReq: ReleaseService.putApiV1ReleaseVote,
    fetchVotesReq: ReleaseService.getApiV1ReleaseVote,
    checkStatusReq: ReleaseService.getApiV1ReleaseCheckStatus,
    changeStatusReq: ReleaseService.putApiV1ReleaseChangeStatus
  });

const voteButtonItems = [
  {
    label: 'Reject',
    command: () => {
      showCommentDialog(VoteStatus.REJECTED)
    }
  },
]

const init = async () => {
  emit("init");
  const res = await props.fetchVotesReq(props.guid);
  votes.value = res.data || [];

  try {
    const res = await props.checkStatusReq(props.guid, VoteStatus.APPROVED);
    canAccept.value = res.ok || false;
    acceptTooltip.value = res.info || ''
  }
  catch {
    canAccept.value = false;
  }

  try {
    const res = await props.checkStatusReq(props.guid, VoteStatus.REJECTED);
    canReject.value = res.ok || false;
    rejectTooltip.value = res.info || ''
  }
  catch {
    canReject.value = false;
  }
}

const showCommentDialog = (status: VoteStatus) => {
  comment.value = '';
  commentDialogVisible.value = true;
  voteStatus.value = status;
}

const castVote = async (status: VoteStatus, comment?: string) => {
  commentDialogVisible.value = false;
  await props.castVoteReq(props.guid, { status, comment });
  toast.add({ severity: 'info', summary: 'Info', detail: 'Vote submitted', life: 3000 });
  await init();
}

const accept = async () => {
  await props.changeStatusReq(props.guid, { newStatus: VoteStatus.APPROVED });
  toast.add({ severity: 'info', summary: 'Info', detail: 'Approved', life: 3000 });
  await init();
}

const reject = async () => {
  await props.changeStatusReq(props.guid, { newStatus: VoteStatus.REJECTED });
  toast.add({ severity: 'info', summary: 'Info', detail: 'Rejected', life: 3000 });
  await init();
}

</script>
