<template>
  <Dialog v-if="dialogVisible" v-model:visible="dialogVisible" modal :draggable="false" position="top"
    style="max-width: 80%; min-width: 80%; max-height: 80%;">
    <template #default>
      <div class="flex flex-column">
        <Toolbar v-if="!isLoading">
          <template #start>
            <Button label="Reparse" @click="reparseRpt" :loading="reparseInProgress" />
          </template>
        </Toolbar>
        <div class="flex flex-row m-5 mt-7 align-items-between">
          <Card class="vertical-align-top inline-block w-full">
            <template #content>
              <div class="flex justify-content-around">
                <div class="flex flex-column justify-content-around">
                  <ConDetails :contentItems="contentItems" title="Manufactured Product" :subTitle="subtitle">
                    <template #item="{ content }">
                      <div v-for="c in content" :key="c">
                        <div class="flex justify-content-between">
                          <div>
                            <span class="details-label">{{ (c as any).title }}:</span>
                          </div>
                          <div>
                            {{ (c as any).content }}
                          </div>
                        </div>
                        <Divider />
                      </div>
                    </template>
                    <template #footer>
                      <ConDataTable class="w-full" :total-items="totalItemsMacAddress" :table-data="dataMacAddress"
                        :columns="propMatchingMacAddress" v-model:rows="tableOptionsMacAddress.itemsPerPage"
                        @on-page="onMacAddressPage">
                      </ConDataTable>
                    </template>
                  </ConDetails>
                </div>

                <ConDataTable class="w-full" :total-items="totalItemsTestDetails" :table-data="dataTestDetails"
                  :columns="propMatchingTestDetails" v-model:rows="tableOptionsTestDetails.itemsPerPage"
                  @on-page="onTestDetailsPage">
                  <template #result="col">
                    <Column :field="col.field" :header="col.header">
                      <template #body="testResult">
                        <i large :class="`${testResult.data.result == 'PASSED' ? 'pi pi-check' : 'pi pi-times'
                          }`" :style="`color: ${testResult.data.result == 'PASSED' ? 'green' : 'red'}`"></i>
                      </template>
                    </Column>
                  </template>
                </ConDataTable>
              </div>
            </template>
          </Card>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="w-full flex flex-row align-items-end justify-content-between">
        <Button class="p-button-text" label="Close" icon="pi pi-times" :onClick="() => {
          dialogVisible = false;
        }" />
      </div>
    </template>
  </Dialog>
</template>


<script setup lang="ts">
import { type Ref, defineComponent, ref, watch } from "vue";
import { ConDataTable, ConDetails } from "@congatec/primevue-components-lib";
import {
  ManufacturedProductsService,
  type ManufacturedProductsDetailsResponse,
  type ManufacturedProductsDetailsResponse_Interface,
  TestResultsService,
  type TestResultRptListResponse_Details,
  type TestResultRptListResponse,
  ProductsService,
  SvnExtractionsService
} from "@/apis/eris-api";
import 'primeicons/primeicons.css';
import Card from "primevue/card";
import Column from "primevue/column";
import Divider from "primevue/divider";
import Button from "primevue/button";
import { useToast } from "primevue/usetoast";
import Toolbar from 'primevue/toolbar';
import Dialog from 'primevue/dialog';
const dataMacAddress: Ref<Array<ManufacturedProductsDetailsResponse_Interface> | null> = ref(new Array<ManufacturedProductsDetailsResponse_Interface>());
const dataTestDetails: Ref<TestResultRptListResponse_Details[] | null | undefined> = ref([]);
const subtitle: Ref<string | undefined> = ref("");
let contentItems: Ref<any> = ref();
const totalItemsMacAddress = ref(1);
const totalItemsTestDetails = ref(1);
const serialNumber = ref(0);
const toast = useToast();
const dialogVisible = ref<boolean>(false);

const tableOptionsMacAddress = ref<{
  page: number;
  sortBy: Array<string>;
  sortDesc: Array<boolean>;
  groupBy: Array<string>;
  groupDesc: Array<string>;
  multiSort: boolean;
  mustSort: boolean;
  itemsPerPage: number;
}>({
  page: 1,
  sortBy: ["id"],
  sortDesc: [true],
  groupBy: [],
  groupDesc: [],
  multiSort: true,
  mustSort: false,
  itemsPerPage: 10,
});
const tableOptionsTestDetails = ref<{
  page: number;
  sortBy: Array<string>;
  sortDesc: Array<boolean>;
  groupBy: Array<string>;
  groupDesc: Array<string>;
  multiSort: boolean;
  mustSort: boolean;
  itemsPerPage: number;
}>({
  page: 1,
  sortBy: ["id"],
  sortDesc: [true],
  groupBy: [],
  groupDesc: [],
  multiSort: true,
  mustSort: false,
  itemsPerPage: 10,
});

let propMatchingMacAddress = [
  {
    field: "macAddress",
    header: "MAC Adress",
  },
];

let propMatchingTestDetails = [
  {
    field: "logfileRevision",
    header: "Log File Revision",
  },
  {
    field: 'testDate',
    header: 'Test Date'
  },
  {
    field: 'result',
    header: 'Test Result'
  }
];

const onTestDetailsPage = async (event: any) => {
  if (typeof event === "number") {
    if (event <= 0) {
      event = 1;
    }
    tableOptionsTestDetails.value.page = event;
  }
  else {
    tableOptionsTestDetails.value.itemsPerPage = event.rows;
    tableOptionsTestDetails.value.page = event.page + 1;
  }

  await _fetchTestDetailList(serialNumber.value);
}

const onMacAddressPage = async (event: any) => {
  if (typeof event === "number") {
    if (event <= 0) {
      event = 1;
    }
    tableOptionsMacAddress.value.page = event;
  }
  else {
    tableOptionsMacAddress.value.itemsPerPage = event.rows;
    tableOptionsMacAddress.value.page = event.page + 1;
  }

  await _fetchManufacturedProductDetails(testDetailId.value ?? 0);
}

const props = defineProps<{
  id: any,
  visible: boolean,
  onHide: (onDetailsClosed: boolean) => any
}>();

const testDetailId: Ref<number | null> = ref(null);
const ManufacturedProductsDetails: Ref<ManufacturedProductsDetailsResponse | null> = ref(null);
const TestResultList: Ref<TestResultRptListResponse | null> = ref(null);
const isLoading = ref(false);
const reparseInProgress = ref(false);
let svnRevisions: Ref<number[]> = ref([]);

if (props && props.id) {
  testDetailId.value = parseInt(
    props.id.toString()
  );
}

// onMounted(async () => {

//   if (testDetailId.value) {
//     await _fetchManufacturedProductDetails(testDetailId.value ?? 0);
//     await _fetchTestDetailList(serialNumber.value);
//     isLoading.value = false;
//   }
// })

async function _fetchTestDetailList(serialNumber: number) {
  if (serialNumber == 0) {
    return;
  }
  isLoading.value = false;

  let response = await TestResultsService.getApiV1RptTestResultRpts(
    [serialNumber],
    undefined, undefined,
    tableOptionsTestDetails.value.page,
    tableOptionsTestDetails.value.itemsPerPage);
  if (response) {
    TestResultList.value = response;
    dataTestDetails.value = response.details;
    totalItemsTestDetails.value = response.totalCount || 0;
  }
  console.log(tableOptionsTestDetails, response);
}



async function _fetchManufacturedProductDetails(id: number) {
  isLoading.value = false;

  let response = await ManufacturedProductsService.getApiV1ManufacturedProducts(id)
  svnRevisions.value = response.svnRevisions || [];
  if (response) {
    ManufacturedProductsDetails.value = response;
    dataMacAddress.value = response.productInterfaces ?? [];
    totalItemsMacAddress.value = ManufacturedProductsDetails.value!.productInterfaces!.length;
    if (ManufacturedProductsDetails.value) {
      let productDetails = await ProductsService.getApiV1Products(response.productID || 0);

      subtitle.value = ManufacturedProductsDetails.value.serialnumber?.toString();
      serialNumber.value = response.serialnumber || 0;

      contentItems.value = [
        {
          title: 'Details', content: [
            {
              title: "ID",
              content: ManufacturedProductsDetails.value.id,
            },
            {
              title: "Serial Number",
              content: ManufacturedProductsDetails.value.serialnumber?.toString(),
            },
            {
              title: "Production Order",
              content: ManufacturedProductsDetails.value.productionOrder,
            },
            {
              title: "Manufacturer ID",
              content: ManufacturedProductsDetails.value.manufacturerID?.toString(),
            },
            {
              title: "Package Revision",
              content: ManufacturedProductsDetails.value.packageRevision?.toString(),
            },
            {
              title: "Lot",
              content: ManufacturedProductsDetails.value.lot,
            },
            {
              title: "AmproCgf Flag",
              content: ManufacturedProductsDetails.value.amproCgfFlag,
            },
            {
              title: "CMOS",
              content: ManufacturedProductsDetails.value.cmos,
            },
            {
              title: "Bios File",
              content: ManufacturedProductsDetails.value.biosFile,
            },
            {
              title: "CgBcFw File",
              content: ManufacturedProductsDetails.value.cgBcFwFile,
            },
            {
              title: 'Part Number',
              content: productDetails.partNumber
            },
            {
              title: 'Hardware Revision',
              content: productDetails.hardwareRevision
            },
            {
              title: 'EAN',
              content: productDetails.ean
            }
          ]
        }];
    }

  }
}


const reparseRpt = async () => {
  reparseInProgress.value = true;
  try {
    await SvnExtractionsService.postApiV1SvnExtractionRptFilesParseRevisions({ revisions: svnRevisions.value })

    toast.add({
      severity: 'success',
      summary: "Logfile was reparsed",
      life: 3000
    });
  } catch (e: any) {
    toast.add({
      severity: 'error',
      summary: "Error",
      detail: e,
      life: 3000
    });
  }
  reparseInProgress.value = false;
}

watch(() => dialogVisible.value, (nv) => {
  if (nv != null && !nv) {
    props.onHide(true);
  }
})

watch(() => props.id, async (nv) => {
  if (nv != undefined) {
    testDetailId.value = props.id;
    await _fetchManufacturedProductDetails(testDetailId.value ?? 0);
    await _fetchTestDetailList(serialNumber.value);
    isLoading.value = false;
  }
})

watch(() => props.visible, (nv) => {
  console.log("VISIBLE: ", nv);
  if (nv) {
    dialogVisible.value = nv;
  }
}, { deep: true, immediate: true });
</script>

<script lang="ts">
export default defineComponent({
  name: "HardwareDetailsComponent",
});
</script>

<style scoped lang="css">
.details-label {
  text-decoration: underline;
}
</style>