<template>
  <div class="w-full h-full flex flex-column">
    <Toast position="top-right" />
  <ConfirmDialog />
  <!-- <InputVisitorDialog :visible="showCreateVisitorDialog" :on-hide="(created: any) => {
    showCreateVisitorDialog = false;
    reloadVisitorTableData();
  }" /> -->
  <QuickInputVisitsDialog :visible="showQuickVisitDialog" :on-hide="() => {
    showQuickVisitDialog = false;
    reloadVisitorTableData();
    reloadVisitTableData();
    }"
  />

  <InputVisitorDialog :visible="showVisitorDialog" :copy="visitorCopy" :visitor="selectedVisitor[0]" :on-hide="(edited: any) => {
    showVisitorDialog = false,
    visitorCopy = false,
    reloadVisitorTableData();
    selectedVisitor = selectedVisitorBackup;
  }" @visitorEdited="reloadVisitorTableData"/>


<InputVisitsDialog :visible="showCreateVisitsDialog" :visitors="selectedVisitor" :on-hide="(created: any) => {
    showCreateVisitsDialog = false;
    reloadVisitTableData();
  }"  />

  <InputVisitsDialog :visible="showEditVisitDialog" :visit="selectedVisit[0]" :on-hide="(edited: any) => {
    showEditVisitDialog = false;
    selectedVisit = selectedVisitBackup;
    reloadVisitTableData();
  }" />
  <!-- <div class="card"> -->
    <Toolbar>
      <template #start>
        <div class="flex flex-row gap-3">
          <Button icon="pi pi-user" label="Visitors" class="p-button-sm " @click="(e: any) => { toggleVisitorsMenu(e); }"
            aria-haspopup="true" aria-controls="overlay_menu" />
          <TieredMenu ref="visitorsMenu" id="overlay_menu" :model="visitorsMenuItems" :popup="true" />
          <Button icon="pi pi-calendar-minus" label="Visits" class="p-button-sm " @click="(e: any) => { toggleVisitsMenu(e); }"
            aria-haspopup="true" aria-controls="overlay_menu" />
          <TieredMenu ref="visitsMenu" id="overlay_menu" :model="visitsMenuItems" :popup="true" />
          <Button label="Company Visit" @click="showVisitDialog()" icon="pi pi-calendar-plus">
          </Button>
        </div>

      </template>
    </Toolbar>
  <!-- </div> -->

  <div class="m-5 mt-7">
    <VisitorTable table-id="visitorTable" ref="visitorTableRef" title="Visitor List" :create-visitor-action-menu="createVisitorActionMenu" :fetch-visitor-data-async="fetchVisitorDataAsync" :handle-visitor-selection-change="handleVisitorSelectionChange" ></VisitorTable>
  </div>
  <div class="m-5" >
    <ConDataTable v-show="showVisitsTable" v-model:selectedItems="selectedVisit" tableId="VisitsList" title="Visits List" globalFilter="visitorFirstName|visitorLastName|branchOffice|meetingRoom"
      :columns="visitsTableColumns" filterDisplay="menu" :rowsPerPageOptions="[10, 20, 50]" 
      :onReload="fetchVistsDataAsync" @onSelectionChanged="handleVisitsSelectionChange" ref="visitTableRef" :createActionMenu="createVisitActionMenu" expansion-key="checkInsOuts" :expansion-columns="expansionColumns">
      <template #status="col">
        <Column :field="col.field" :header="col.header" :sortable="col.sortable" :isFiltered="col.isFiltered">
          <template #body="field">
            <div class="flex justify-content-between align-items-center w-7rem">
              <i v-if="field.data[col.field] == 'scheduled'" v-tooltip="'Visit is scheduled'" class="pi pi-calendar "
                style="color: rgb(107, 106, 112)" aria-label="visit scheduled"></i>
              <i v-if="field.data[col.field] == 'checkedIn'" v-tooltip="'Visitor is checked out'" class=" pi
              pi-check-circle " style=" color: rgb(23, 201, 100);" aria-label="check in success"></i>
              <i v-if="field.data[col.field] == 'checkedOut'" v-tooltip="'Visitor is checked out'"
                class="pi pi-minus-circle" style="color: rgb(255, 130, 0);" aria-label="check out success"></i>
              <i v-if="field.data[col.field] == 'cancelled'" v-tooltip="'This visit is cancelled'" class="pi pi-times"
                style="color: rgb(218, 50, 8)" aria-label="visit cancelled">
              </i>
              <span v-else>{{ field.data[col.field] }}</span>
            </div>
          </template>

        </Column>
      </template>
    </ConDataTable>
  </div>
  </div>
  
</template>
<script setup lang="ts">
import { ConDataTable, showSuccess } from '@congatec/primevue-components-lib';
import InputVisitorDialog from './InputVisitorDialog.vue';
import InputVisitsDialog  from './../visits/InputVisitsDialog.vue';
import Button from 'primevue/button';
import Toolbar from 'primevue/toolbar';
import TieredMenu from 'primevue/tieredmenu';
import Column from 'primevue/column';
import { ref, watch, onMounted, } from 'vue';
import { VisitorService, VisitService, type ListVisitorResponse_Visitors, type ListVisitResponse_Visits, EmailService } from '@/apis/neptune-api'
import ConfirmDialog from 'primevue/confirmdialog';
import { useConfirm } from "primevue/useconfirm";
import Toast from 'primevue/toast';
import { useToast } from 'primevue/usetoast';
import { canThrowAsyncShowToast } from '@congatec/primevue-components-lib';
import { onKeycloakToken } from '@congatec/authentication-lib';
import { useDelayTimer } from '@congatec/primevue-components-lib';
import { delay } from '@congatec/primevue-components-lib';
import QuickInputVisitsDialog from '../visits/QuickInputVisitsDialog.vue';
import VisitorTable from './VisitorTable.vue';

// Dataelements (constants / variables) area
//Visitor:
// const showCreateVisitorDialog = ref<boolean>(false);
const showVisitorDialog = ref<boolean>(false);
const showQuickVisitDialog = ref<boolean>(false);
const visitorIdList = ref<number[]>([]);
const editVisitorDisabled = ref<boolean>(true);
const deleteVisitorDisabled = ref<boolean>(true);
const visitorTableRef = ref(); //ref<null | typeof VisitorTable>(null);
const selectedVisitor = ref<Array<ListVisitorResponse_Visitors>>([]);
const  visitorCopy = ref<boolean>(false);
let selectedVisitorBackup: any[] = [];



const visitorsMenu = ref();
const visitorsMenuItems = ref([
  {
    label: 'Create',
    icon: 'pi pi-plus',
    command: () => {
      selectedVisitorBackup = selectedVisitor.value;
      selectedVisitor.value = [];
      showVisitorDialog.value = true;
    }
  },
  {
    label: 'Copy',
    icon: 'pi pi-copy',
    disabled: editVisitorDisabled,
    command: () => {
      showVisitorDialog.value = true;
      visitorCopy.value = true;
      
    }
  },
  // {
  //   label: 'Edit',
  //   icon: 'pi pi-pencil',
  //   disabled: editVisitorDisabled,
  //   command: () => {
  //     selectedVisitorBackup = selectedVisitor.value;
  //     showEditVisitorDialog.value = true;
  //   }
  // },
  {
    label: 'Screen',
    icon: 'pi pi-eye',
    disabled: editVisitorDisabled,
    command: () => {
      screenVisitors();
    }
  },
  {
    label: 'Delete',
    icon: 'pi pi-trash',
    disabled: deleteVisitorDisabled,
    command: () => {
      confirmDelete("visitor")
    }
  }
]);


//Visit
const showCreateVisitsDialog = ref<boolean>(false);
const showEditVisitDialog = ref<boolean>(false);
const showVisitsTable = ref<boolean>(false);
const editVisitDisabled = ref<boolean>(true);
const createVisitDisabled = ref<boolean>(true);
const visitTableRef = ref<null | typeof ConDataTable>(null);
const selectedVisit = ref<Array<ListVisitResponse_Visits>>([]);
let selectedVisitBackup: any[] = [];
const visitsIdList = ref<number[]>([]);
const deleteVisitDisabled = ref<boolean>(true);
const emailMenu = ref();
const visitsMenu = ref();
const visitsMenuItems = ref([
  {
    label: 'Create',
    icon: 'pi pi-plus',
    disabled: createVisitDisabled,
    command: () => {
      showCreateVisitsDialog.value = true;
    }
  },
  // {
  //   label: 'Edit',
  //   icon: 'pi pi-pencil',
  //   disabled: editVisitDisabled,
  //   command: () => {
  //     selectedVisitBackup = selectedVisit.value;
  //     showEditVisitDialog.value = true;
  //   }
  // },
  {
    label: 'Delete',
    icon: 'pi pi-trash',
    disabled: deleteVisitDisabled,
    command: () => {
      confirmDelete('Visit')
    }
  },
  {
    label: 'Send Email',
    icon: 'pi pi-send',
    disabled: deleteVisitDisabled,
    items: [],
  }
])
const visitsTableColumns = [
  {
    expander: true,
    field: 'expansion',
    header: ''
  },
  {
    selectionMode: 'multiple',
    field: 'any'
  },
  {
    field: "visitorFirstName",
    header: "First Name",
    dataType: "text",
    isFiltered: true,
    sortable: true,
  },
  {
    field: "visitorLastName",
    header: "Last Name",
    dataType: "text",
    isFiltered: true,
    sortable: true,
  },
  {
    field: "scheduledCheckIn",
    header: "Scheduled Check in",
    dataType: "date",
    isFiltered: true,
    sortable: true,
  },
  {
    field: "scheduledCheckOut",
    header: "Scheduled Check out",
    dataType: "date",
    isFiltered: true,
    sortable: true,
  },
  {
    field: "status",
    header: "Visitor Status",
    dataType: "text",
    isFiltered: true,
    sortable: true,
  },
  {
    field: 'responsibleEmployee',
    header: 'Responsible Employee',
    isFiltered: true,
    sortable: true
  },
  {
    field: "branchOffice",
    header: "Branch Office",
    dataType: "text",
    isFiltered: true,
    sortable: true,
  },
  {
    field: "meetingRoom",
    header: "Meetingroom",
    dataType: "text",
    isFiltered: true,
    sortable: true,
  },
  {
    field: "actionMenu",    
  }
]

let expansionColumns = [
  {
      field: "checkInDateTime",
      header: "Check In Date",
      dataType: "date",
  },
  {
      field: "checkOutDateTime",
      header: "Check Out Date",
      dataType: "date",
  },
];

//other
const confirm = useConfirm();
const confirmDelete = (selectedDeleteMode: string) => {
  confirm.require({
    header: 'Delete Confirmation',
    icon: 'pi pi-info-circle',
    acceptClass: 'p-button-info',
    //TODO: implement option to see a list of items that will be delted
    message: 'Are you sure you want to delete this Item(s)',
    accept: async () => {
      try {
        if (selectedDeleteMode.toUpperCase() == 'VISITOR') {
          await VisitorService.deleteApiV1VisitorDelete({ visitorIdsList: visitorIdList.value });
        } else {
          await VisitService.deleteApiV1VisitDelete({ visitsIdList: visitsIdList.value });
        }
        toast.add({ severity: 'success', summary: 'Delete', detail: 'Item(s) deleted', life: 3000 });
        selectedVisit.value = [];
        // selectedVisitor.value = [];
        
      } catch (ex: any) {
        toast.add({ severity: 'error', summary: 'Error!', detail: 'An error occured while deleting: ' + ex.message, life: 3000 });
      }
      finally {
        reloadVisitorTableData();
        reloadVisitTableData();
      }
    }
  })
}
const toast = useToast();
const delayTimer = useDelayTimer();

onMounted(() => {
})

onKeycloakToken(async () => {
  // var res = await EmailService.getApiV1EmailListTemplate("", "", 1, 10000);
  // let emailMenu: any = visitsMenuItems.value.find((x: any) => x.label == 'Send Email');
  // if (!emailMenu) {
  //   console.error("There does not appear to be an email menu!");
  //   return;
  // }
  // emailMenu.items = [];

  // for (let template of res.templates || []) {
  //   console.log(template);
  //   let innerTemplate = template;
  //   emailMenu.items.push({
  //     label: template.name || "Unnamed",
  //     command: async () => {
  //       for (let visit of selectedVisit.value) {
  //         await canThrowAsyncShowToast(toast, async () => await EmailService.putApiV1EmailSendEmail(visit.id, innerTemplate.id));
  //       }
  //       showSuccess(toast, "Success", "Emails sent!");
  //     }
  //   });
  // }
});

//watch area
watch(() => selectedVisitor.value, (nv) => {
  console.log("Visitors:", nv.length)
  if (nv.length > 0) {
    showVisitsTable.value = true;
    deleteVisitorDisabled.value = false;
    createVisitDisabled.value = false;
    delay(delayTimer, async () => { reloadVisitTableData() }, 400);

    if (nv.length == 1) {
      editVisitorDisabled.value = false;
    } else {
      editVisitorDisabled.value = true;
    }
  } else {
    showVisitsTable.value = false;
    editVisitorDisabled.value = true;
    createVisitDisabled.value = true;
    deleteVisitorDisabled.value = true;
  }
})

watch(() => selectedVisit.value, (nv) => {
  console.log("Visit:", nv)

  if (nv.length > 0) {
    deleteVisitDisabled.value = false;
    if (nv.length == 1) {
      editVisitDisabled.value = false;
    } else {
      editVisitDisabled.value = true;
    }
  } else {
    deleteVisitDisabled.value = true;
    editVisitDisabled.value = true;
  }

})

//functions area
function handleVisitorSelectionChange(selectedRows: any) {
  console.log("handleSel", selectedRows)
  console.log("visitorIdList: ", visitorIdList)
  if(selectedRows.length < selectedVisitor.value.length) {
    selectedVisit.value = selectedVisit.value.filter((visit) => (selectedRows.map((x: { id: any; }) => x.id)).includes(visit.visitorId))
  }
  selectedVisitor.value = selectedRows

  console.log("SelectedVisitorLength:", selectedVisitor.value.length)
  visitorIdList.value = [];
  selectedVisitor.value.forEach(visitor => {
    visitorIdList.value.push(visitor.id ? visitor.id : 0)
  });
}

async function handleVisitsSelectionChange(selectedRows: any) {
  selectedVisit.value = selectedRows
  visitsIdList.value = []
  selectedVisit.value.forEach(visit => {
    visitsIdList.value.push(visit.id ? visit.id : 0)
  });

  let templateFilter = "defaultTemplate==true";
  let branchOffice = selectedVisit.value[0]?.branchOffice;
  if(branchOffice) {
    templateFilter = "branchOffice@=" + selectedVisit.value[0].branchOffice!.split(",")[0];
  }
  var res = await EmailService.getApiV1EmailListTemplate(templateFilter, "", 1, 10000);
  emailMenu.value = visitsMenuItems.value.find((x: any) => x.label == 'Send Email');
  if (!emailMenu.value) {
    console.error("There does not appear to be an email menu!");
    return;
  }
  emailMenu.value.items = [];

  for (let template of res.templates || []) {
    console.log(template);
    let innerTemplate = template;
    emailMenu.value.items.push({
      label: template.name || "Unnamed",
      command: async () => {
        for (let visit of selectedVisit.value) {
          await canThrowAsyncShowToast(toast, async () => await EmailService.putApiV1EmailSendEmail(visit.id, innerTemplate.id));
        }
        showSuccess(toast, "Success", "Emails sent!");
      }
    });
  }

}

function toggleVisitorsMenu(e: any) {
  visitorsMenu.value.toggle(e);
}

function toggleVisitsMenu(e: any) {
  visitsMenu.value.toggle(e);
}

async function screenVisitors() {
  for (let visitor of selectedVisitor.value) {
    console.log(visitor.id);
    let status = await canThrowAsyncShowToast(toast, async () => await VisitorService.putApiV1VisitorScreen(visitor.id || 0));
    if (status) {
      visitor.screeningStatus = status.status;
    }
  }
  visitorTableRef.value?.forceReload();
}

async function fetchVisitorDataAsync(filters: string, sorts: string, page: number, rows: number): Promise<any> {

  let res = await VisitorService.getApiV1VisitorList(filters, sorts, page, rows);
  console.log("Resolution: ", res)
  return {
    totalCount: res.totalCount,
    data: res.visitors,
  }
}

async function fetchVistsDataAsync(filters?: string, sorts?: string, page?: number, rows?: number): Promise<any> {
  let res = await VisitService.getApiV1VisitList(filters, sorts, page, rows, visitorIdList.value)
  console.log("Res:", res)
  return {
    totalCount: res.totalCount,
    data: res.visits,
  }
}

function reloadVisitorTableData() {
  visitorTableRef.value?.forceReload();
}

function reloadVisitTableData() {
  visitTableRef.value?.forceReload();
}

function createVisitorActionMenu(row: any): any {


  return [
    {
      label: 'Edit',
      icon: 'pi pi-pencil',
      command: () => {
        selectedVisitorBackup = selectedVisitor.value;
        selectedVisitor.value = [];
        selectedVisitor.value.push(row);
        showVisitorDialog.value = true;
      }
    },
    {
      label: 'Copy',
      icon: 'pi pi-copy',
      command: () => {
        selectedVisitor.value = [];
        selectedVisitor.value.push(row);
        showVisitorDialog.value = true;
        visitorCopy.value = true;
      }
    },
    {
    label: 'Screen',
    icon: 'pi pi-eye',
    command: () => {
      selectedVisitorBackup = selectedVisitor.value;
      selectedVisitor.value = [];
      selectedVisitor.value.push(row);
      screenVisitors();
      selectedVisitor.value = selectedVisitorBackup;
    }
    },
    {
      label: 'Delete',
      icon: 'pi pi-trash',
      command: () => deleteVisitor(row)
    }, 
  ];
}

function deleteVisitor(row: any) {
  //console.log('VisitorId: ', row.id);
  confirm.require({
    header: 'Delete Confirmation',
    icon: 'pi pi-info-circle',
    acceptClass: 'p-button-info',
    //TODO: implement option to see a list of items that will be delted
    message: 'Are you sure you want to delete this Item',
    accept: async () => {
      try {
        await VisitorService.deleteApiV1VisitorDelete({ visitorIdsList: [row.id] });
        toast.add({ severity: 'success', summary: 'Delete', detail: 'Item deleted', life: 3000 });
       
        reloadVisitorTableData();
        reloadVisitTableData();
      } catch (ex: any) {
        toast.add({ severity: 'error', summary: 'Error!', detail: 'An error occured while deleting: ' + ex.message, life: 3000 });
      }
    }
  })
}

function createVisitActionMenu(row: any): any {


return [
    {
      label: 'Edit',
      icon: 'pi pi-pencil',
      command: () => {
        selectedVisitBackup = selectedVisit.value;
        selectedVisit.value = [];
        selectedVisit.value.push(row);
        showEditVisitDialog.value = true;
      }
    },
    {
      label: 'Delete',
      icon: 'pi pi-trash',
      command: () => deleteVisit(row)
    },
    // timing problem: items are only loaded in onKeycloaktoken
  // {
  //   label: 'Send Email',
  //   icon: 'pi pi-send',
  //   items: emailMenu.value.items?.forEach(async (item: { command: any; key: number | undefined; }) => { if(item) item.command = async () => await canThrowAsyncShowToast(toast, async () => await EmailService.putApiV1EmailSendEmail(row.id, item.key))}) || [],
  // }
  ];
}

function deleteVisit(row: any) {
  confirm.require({
    header: 'Delete Confirmation',
    icon: 'pi pi-info-circle',
    acceptClass: 'p-button-info',
    //TODO: implement option to see a list of items that will be delted
    message: 'Are you sure you want to delete this Item',
    accept: async () => {
      try {
        await VisitService.deleteApiV1VisitDelete({ visitsIdList: [row.id] });
        toast.add({ severity: 'success', summary: 'Delete', detail: 'Item deleted', life: 3000 });
        reloadVisitorTableData();
        reloadVisitTableData();
      } catch (ex: any) {
        toast.add({ severity: 'error', summary: 'Error!', detail: 'An error occured while deleting: ' + ex.message, life: 3000 });
      }
    }
  })
}

const showVisitDialog = () =>
{
  showQuickVisitDialog.value = true;
}

</script>
