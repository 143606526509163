/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MacAddressSerialNumberResponse } from '../models/MacAddressSerialNumberResponse';
import type { ManufacturedProductListFilterFieldsFilterStatement } from '../models/ManufacturedProductListFilterFieldsFilterStatement';
import type { ManufacturedProductListOrderFields } from '../models/ManufacturedProductListOrderFields';
import type { ManufacturedProductListOrderFieldsOrderingFieldsBase } from '../models/ManufacturedProductListOrderFieldsOrderingFieldsBase';
import type { ManufacturedProductsDetailsResponse } from '../models/ManufacturedProductsDetailsResponse';
import type { ManufacturedProductsListResponse } from '../models/ManufacturedProductsListResponse';
import type { TestDetailExportDetailXlsxRequest } from '../models/TestDetailExportDetailXlsxRequest';
import type { TestDetailExportDetailXlsxResponse } from '../models/TestDetailExportDetailXlsxResponse';
import type { TestDetailExportResultXlsxRequest } from '../models/TestDetailExportResultXlsxRequest';
import type { TestDetailExportResultXlsxResponse } from '../models/TestDetailExportResultXlsxResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ManufacturedProductsService {

    /**
     * @param id 
     * @returns ManufacturedProductsDetailsResponse Success
     * @throws ApiError
     */
    public static getApiV1ManufacturedProducts(
id: number,
): CancelablePromise<ManufacturedProductsDetailsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/manufactured-products/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param sieveModelFilters 
     * @param sieveModelSorts 
     * @param sieveModelPage 
     * @param sieveModelPageSize 
     * @param filterStatements 
     * @param pageNumber 
     * @param pageSize 
     * @param skip 
     * @param orderingFields 
     * @param availableOrderingFields 
     * @returns ManufacturedProductsListResponse Success
     * @throws ApiError
     */
    public static getApiV1ManufacturedProducts1(
sieveModelFilters?: string,
sieveModelSorts?: string,
sieveModelPage?: number,
sieveModelPageSize?: number,
filterStatements?: Array<ManufacturedProductListFilterFieldsFilterStatement>,
pageNumber?: number,
pageSize?: number,
skip?: number,
orderingFields?: Array<ManufacturedProductListOrderFieldsOrderingFieldsBase>,
availableOrderingFields?: ManufacturedProductListOrderFields,
): CancelablePromise<ManufacturedProductsListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/manufactured-products',
            query: {
                'sieveModel.Filters': sieveModelFilters,
                'sieveModel.Sorts': sieveModelSorts,
                'sieveModel.Page': sieveModelPage,
                'sieveModel.PageSize': sieveModelPageSize,
                'FilterStatements': filterStatements,
                'PageNumber': pageNumber,
                'PageSize': pageSize,
                'Skip': skip,
                'OrderingFields': orderingFields,
                'AvailableOrderingFields': availableOrderingFields,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns TestDetailExportResultXlsxResponse Success
     * @throws ApiError
     */
    public static postApiV1ManufacturedProductsExcelExportResult(
requestBody?: TestDetailExportResultXlsxRequest,
): CancelablePromise<TestDetailExportResultXlsxResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/manufactured-products/excel-export-result',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns TestDetailExportDetailXlsxResponse Success
     * @throws ApiError
     */
    public static postApiV1ManufacturedProductsExcelExportDetail(
requestBody?: TestDetailExportDetailXlsxRequest,
): CancelablePromise<TestDetailExportDetailXlsxResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/manufactured-products/excel-export-detail',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param serialNumbers 
     * @returns MacAddressSerialNumberResponse Success
     * @throws ApiError
     */
    public static getApiV1ManufacturedProductsMacs(
serialNumbers?: Array<number>,
): CancelablePromise<MacAddressSerialNumberResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/manufactured-products/macs',
            query: {
                'SerialNumbers': serialNumbers,
            },
        });
    }

    /**
     * @param serialNumber 
     * @returns MacAddressSerialNumberResponse Success
     * @throws ApiError
     */
    public static getApiV1ManufacturedProductsMacs1(
serialNumber: number,
): CancelablePromise<MacAddressSerialNumberResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/manufactured-products/{serialNumber}/macs',
            path: {
                'serialNumber': serialNumber,
            },
        });
    }

}
