<template>
  <div class="flex flex-column justify-content-center align-items-center w-full mt-7">
    <Card style="width: 50%">
      <template #title>
        <span>Export manufactured Products</span>
      </template>
      <template #content>
        <div class="p-fluid grid formgrid">
          <span class="p-float-label" style="width: 100%">
            <Chips v-model="serialNumbersInput" separator="," id="input" />
            <label for="input">Serialnumbers</label>
          </span>
        </div>
      </template>
      <template #footer>
        <div class="flex flex-row w-full justify-content-start gap-5">
          <Button v-on:click="_exportResultXlsx()" icon="pi pi-download" label="Download Test Results" style>
          </Button>
          <Button v-on:click="_exportDetailXlsx()" icon="pi pi-download" label="Download Product Details">
          </Button>

          <FileUpload mode="basic" name="demo[]" :showUploadButton="false" :show-cancel-button="false"
            @select="onSerialNumberListSelect" customUpload @uploader="() => { }" :multiple="false" accept="text/*"
            :maxFileSize="1000000">

            <Button @click="clear" label="Clear Input" />
          </FileUpload>
        </div>

      </template>
    </Card>
  </div>
</template>

<script setup lang="ts">
import { onMounted, defineComponent, ref, type Ref, watch } from "vue";
import Chips from "primevue/chips";
import Card from "primevue/card";
import Button from "primevue/button";
import {
  ManufacturedProductsService,
  type TestDetailExportDetailXlsxResponse,
  type TestDetailExportDetailXlsxRequest,
  type TestDetailExportResultXlsxRequest,
  type TestDetailExportResultXlsxResponse,
} from "@/apis/eris-api";
//import { useFileDownloadStatusStore } from "../file-download-status/store/FileDownloadStatusStore";
//import { FileStatusStoreStatusType } from "../file-download-status/store/FileDownloadStatusStore";
import { useFileDownloadStatusStore, FileStatusStoreStatusType } from "@congatec/primevue-components-lib";
import FileUpload from 'primevue/fileupload';
import { removeDuplicates } from "./helpers";

const requestResult: Ref<TestDetailExportResultXlsxRequest> = ref({});
const requestDetail: Ref<TestDetailExportDetailXlsxRequest> = ref({});
const serialNumbersInput: Ref<string[]> = ref([]);
const store = useFileDownloadStatusStore();

onMounted(() => { });

// primevue chips do not allow new line as a separator,
// so we simply transform new lines to , when the input changes...
watch(serialNumbersInput, () => {
  let toRemove: string[] = [];
  let newValues: string[] = [];
  for (let s of serialNumbersInput.value) {
    if (s.includes('\n')) {
      toRemove.push(s);
      newValues = newValues.concat(s.split('\n'));
    }
  }
  if (toRemove.length > 0) {
    serialNumbersInput.value = serialNumbersInput.value.filter(x => !toRemove.includes(x))
  }

  if (newValues.length > 0) {
    for (let s of newValues) {
      s = s.trim();
    }
    newValues = newValues.filter(x => x.replace(/\s/g, '').length);
    serialNumbersInput.value = serialNumbersInput.value.concat(newValues);
  }
});

const trimSerialNumbers = (snrs: string[]) => {
  return snrs.map(x => x.trim());
}

async function _exportResultXlsx() {
  serialNumbersInput.value = removeDuplicates(serialNumbersInput.value);
  serialNumbersInput.value = trimSerialNumbers(serialNumbersInput.value);
  requestResult.value = { serialNumbers: serialNumbersInput.value as any };

  store.add({
    id: serialNumbersInput.value.toString(),
    name: `Eris-Export-Result-${Date.now()}.xlsx`,
    url: "",
    status: FileStatusStoreStatusType.DOWNLOADING,
  });

  ManufacturedProductsService.postApiV1ManufacturedProductsExcelExportResult(
    requestResult.value
  ).then((response: TestDetailExportResultXlsxResponse) => {
    console.log(typeof response);
    if (requestResult.value.serialNumbers) {
      /* let data = new Uint8Array(response.length);
        for (let i = 0; i < response.length; i++) {
          data[i] = response[i].charCodeAt(0);
        } */
      if (response.workbook) {
        forceFileDownload(serialNumbersInput.value.toString(),
          Uint8Array.from(atob(response.workbook), (c) => c.charCodeAt(0)),
          `Eris-Export-Result-${Date.now()}.xlsx`
        );
      }
    }
  },
    () => {
      store.update({
        id: serialNumbersInput.value.toString(),
        changes: {
          url: "",
          status: FileStatusStoreStatusType.ERROR,
        },
      });
    });
}

async function _exportDetailXlsx() {
  serialNumbersInput.value = removeDuplicates(serialNumbersInput.value);
  serialNumbersInput.value = trimSerialNumbers(serialNumbersInput.value);
  requestDetail.value = { serialNumbers: serialNumbersInput.value as any };

  store.add({
    id: serialNumbersInput.value.toString(),
    name: `Eris-Export-Detail-${Date.now()}.xlsx`,
    url: "",
    status: FileStatusStoreStatusType.DOWNLOADING,
  });

  ManufacturedProductsService.postApiV1ManufacturedProductsExcelExportDetail(
    requestDetail.value
  ).then((response: TestDetailExportDetailXlsxResponse) => {
    console.log(typeof response);
    if (requestDetail.value.serialNumbers) {
      /* let data = new Uint8Array(response.length);
        for (let i = 0; i < response.length; i++) {
          data[i] = response[i].charCodeAt(0);
        } */
      if (response.workbook)
        forceFileDownload(serialNumbersInput.value.toString(),
          Uint8Array.from(atob(response.workbook), (c) => c.charCodeAt(0)),
          `Eris-Export-Detail-${Date.now()}.xlsx`
        );
    }
  },
    () => {
      store.update({
        id: serialNumbersInput.value.toString(),
        changes: {
          url: "",
          status: FileStatusStoreStatusType.ERROR,
        },
      });
    });
}

function forceFileDownload(id: string, data: Uint8Array, name: string) {
  if (data) {
    console.log(data);
    const url = window.URL.createObjectURL(
      new Blob([data], {
        endings: "transparent",
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      })
    );

    store.update({
      id: id,
      changes: {
        url: url,
        status: FileStatusStoreStatusType.DOWNLOADED,
      },
    });

    // let fileLink = document.createElement("a");
    // fileLink.href = url;
    // fileLink.download = name;
    // fileLink.click();
  }
}

const onSerialNumberListSelect = async (event: any) => {
  const file = event.files[0];
  const reader = new FileReader();
  reader.readAsBinaryString(file);
  reader.onloadend = function () {
    let lines = (reader.result as string).split(/\r?\n/);
    fillSelectionFromLines(lines);
  };
}

const fillSelectionFromLines = (lines: string[]) => {
  serialNumbersInput.value = serialNumbersInput.value.concat(lines);
  serialNumbersInput.value = removeDuplicates(serialNumbersInput.value);
}

const clear = () => {
  serialNumbersInput.value = [];
}

</script>

<script lang="ts">
export default defineComponent({
  name: "ManufacturedProductExportComponent",
});
</script>

<style lang="css"></style>
