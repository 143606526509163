<template>
  <div class="flex flex-column">
    <ConfirmPopup></ConfirmPopup>
    <Dialog v-model:visible="dialogVisible" :header="template?.id ? 'Edit Template' : 'New Template'"
      :style="{ width: '32rem' }">
      <div class="flex flex-column gap-3">
        <div class="w-full">
          <InputText class="w-full" v-model="template.name" placeholder="Template Name" />
        </div>
        <label for="cardType">Card Type</label>
        <Dropdown id="cardType" v-model="selectedCadType" :options="cardTypes" optionValue="name"
          optionLabel="name" class="w-full" />

        <!-- <label for="frontImage">Front Image</label> -->
        <Dropdown id="frontImage" v-model="selectedFrontImageId" :options="cardTemplates" optionValue="id"
          @change="onFrontImageChange" optionLabel="fileName" placeholder="Select Front Image" class="w-full" />
        <!-- <label for="backImage">Back Image</label> -->
        <Dropdown id="backImage" v-model="selectedBackImageId" :options="cardTemplates" optionValue="id"
          @change="onBackImageChange" optionLabel="fileName" placeholder="Select Back Image" class="w-full" />

        <div v-if="template.id" class="flex align-items-center">
          <Checkbox v-model="template.isDisabled" inputId="isDisabled" :binary="true" />
        <label for="isDisabled" class="ml-2"> Disabled </label>
    </div>
      </div>
      <Button class="mt-3 mb-3" icon="pi pi-save" label="Save" @click="onSaveTemplate"></Button>
    </Dialog>

    <Toolbar>
      <template #start>
        <Button icon="pi pi-upload" label="New Template" @click="onNewTemplate" />
      </template>
    </Toolbar>

    <DataView class="m-3 w-full" :value="templates" dataKey="id">
      <template #list="slotProps">
        <div class="grid grid-nogutter">
          <div v-for="(item, index) in slotProps.items" :key="item.id" class="col-12">
            <div class="flex flex-column sm:flex-row sm:align-items-center p-4 gap-3"
              :class="{ 'border-top-1 surface-border': index !== 0 }">
              <div class="md:w-10rem relative">
                <img class="block xl:block mx-auto border-round w-full" :src="itemToImgSrc(item.frontImageTemplate)"
                  :alt="item.frontImageTemplate.fileName" />
              </div>
              <div class="md:w-10rem relative">
                <img class="block xl:block mx-auto border-round w-full" :src="itemToImgSrc(item.backImageTemplate)"
                  :alt="item.backImageTemplate.fileName" />
              </div>
              <div class="flex flex-column md:flex-row justify-content-between md:align-items-center flex-1 gap-4">
                <div class="flex flex-row md:flex-column justify-content-between align-items-start gap-2">
                  <div>
                    <div class="text-lg font-medium text-900 mt-1">Name: {{ item.name }}</div>
                  </div>
                </div>
                <div class="flex flex-row gap-1">
                  <div class="text-lg font-medium text-900 mt-1">Active:</div>
                  <i class="pi pi-check w-3" style="font-size: 1.5rem; color: rgb(23, 201, 100)" v-if="item.isDisabled == false"></i>
                  <i class="pi pi-times" style="font-size: 1.5rem;color: rgb(218, 50, 8)" v-if="item.isDisabled == true"></i>
                </div>
                <div class="flex flex-column md:align-items-end gap-5">
                  <div class="flex flex-row-reverse md:flex-row gap-2">
                    <Button icon="pi pi-trash" severity="danger" outlined
                      @click="onDeleteTemplate($event, item)"></Button>
                    <Button icon="pi pi-pencil" label="Edit" class="flex-auto md:flex-initial white-space-nowrap"
                      @click="onEditTemplate(item)"></Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </DataView>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, type Ref } from 'vue';
import DataView from 'primevue/dataview';
import { TemplateService, type ListTemplateResponse_Template, type ListImageTemplateResponse_ImageTemplate, ImageTemplateService } from '@/apis/haumea-api';
import Button from 'primevue/button'
import Toolbar from 'primevue/toolbar'
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import { useToast } from 'primevue/usetoast';
import { showError } from '@congatec/primevue-components-lib';
import ConfirmPopup from 'primevue/confirmpopup';
import { useConfirm } from "primevue/useconfirm";
import Dropdown from 'primevue/dropdown';
import Checkbox from 'primevue/checkbox';

const confirm = useConfirm();

const toast = useToast();
const templates: Ref<ListTemplateResponse_Template[]> = ref([]);
const dialogVisible = ref(false);
const template: Ref<ListTemplateResponse_Template> = ref({ name: "" });
const cardTemplates: Ref<any> = ref([]);
const selectedFrontImageId: Ref<any> = ref();
//const selectedFrontImage: Ref<any> = ref("");
const selectedBackImageId: Ref<any> = ref();
//const selectedBackImage: Ref<any> = ref("");

const cardTypes = [
  { name: "Employee" },
  { name: "Extern" }
];
const selectedCadType: Ref<string> = ref(cardTypes[0].name);

onMounted(async () => {
  await fetchData();
});

const fetchData = async () => {
  templates.value = (await TemplateService.getApiV1Template("", "", 1, 1000)).templates || [];
}



const itemToImgSrc = (item: ListImageTemplateResponse_ImageTemplate) => {
  return `data:${item.mimeType};base64,${item.content}`;
}

const onNewTemplate = async () => {
  const result = await ImageTemplateService.getApiV1ImageTemplate("", "", 1, 1000);
  cardTemplates.value = result.imageTemplates;
  dialogVisible.value = true;
  template.value = { name: "" };
}

const onEditTemplate = async (item: ListTemplateResponse_Template) => {
  const result = await ImageTemplateService.getApiV1ImageTemplate("", "", 1, 1000);
  cardTemplates.value = result.imageTemplates;
  dialogVisible.value = true;
  // shallow copy item, so it doest get changed
  template.value = Object.assign({}, item);
  selectedBackImageId.value = template.value.backImageTemplate?.id;
  selectedFrontImageId.value = template.value.frontImageTemplate?.id;
}

const onDeleteTemplate = (event: any, item: ListTemplateResponse_Template) => {
  confirm.require({
    target: event.currentTarget,
    message: 'Do you want to delete this record?',
    icon: 'pi pi-info-circle',
    rejectClass: 'p-button-secondary p-button-outlined p-button-sm',
    acceptClass: 'p-button-danger p-button-sm',
    rejectLabel: 'Cancel',
    acceptLabel: 'Delete',
    accept: async () => {
      console.info(`Deleting template ${item.id}`);
      await TemplateService.deleteApiV1Template(item.id);
      await fetchData();
    },
  });
}

const onFrontImageChange = (event: any) => {
  console.log("newTemplate: ", event.value);
  console.log("selectedTemplate.value: ", selectedFrontImageId.value)
  template.value.frontImageTemplate = cardTemplates.value.filter((temp: { id: any; }) => { return temp.id === event.value })

}

const onBackImageChange = (event: any) => {
  console.log("newTemplate: ", event.value);
  console.log("selectedTemplate.value: ", selectedBackImageId.value)
  template.value.backImageTemplate = cardTemplates.value.filter((temp: { id: any; }) => { return temp.id === event.value })


}

const onSaveTemplate = async () => {
  // create or update
  if (template.value.id) {
    await TemplateService.putApiV1Template({ id: template.value.id, name: template.value.name, frontImageTemplateId: selectedFrontImageId.value, backImageTemplateId: selectedBackImageId.value, isDisabled: template.value.isDisabled, cardType: selectedCadType.value });
  } else {
    await TemplateService.postApiV1Template({ name: template.value.name, frontTemplateImageId: selectedFrontImageId.value, backTemplateImageId: selectedBackImageId.value, cardType: selectedCadType.value });
  }
  await fetchData();

  dialogVisible.value = false;
}

</script>
