/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LapCreateRequest } from '../models/LapCreateRequest';
import type { LapCreateResponse } from '../models/LapCreateResponse';
import type { LapDeleteRequest } from '../models/LapDeleteRequest';
import type { LapDeleteResponse } from '../models/LapDeleteResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class LapsService {

    /**
     * @param requestBody 
     * @returns LapCreateResponse Success
     * @throws ApiError
     */
    public static postApiV1Laps(
requestBody?: LapCreateRequest,
): CancelablePromise<LapCreateResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/Laps',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns LapDeleteResponse Success
     * @throws ApiError
     */
    public static deleteApiV1Laps(
requestBody?: LapDeleteRequest,
): CancelablePromise<LapDeleteResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/Laps',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
