/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ParticipantCreateRequest } from '../models/ParticipantCreateRequest';
import type { ParticipantCreateResponse } from '../models/ParticipantCreateResponse';
import type { ParticipantDeleteRequest } from '../models/ParticipantDeleteRequest';
import type { ParticipantDeleteResponse } from '../models/ParticipantDeleteResponse';
import type { ParticipantEditRequest } from '../models/ParticipantEditRequest';
import type { ParticipantEditResponse } from '../models/ParticipantEditResponse';
import type { ParticipantFindByIdResponse } from '../models/ParticipantFindByIdResponse';
import type { ParticipantFindByRfidResponse } from '../models/ParticipantFindByRfidResponse';
import type { ParticipantListResponse } from '../models/ParticipantListResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ParticipantsService {

    /**
     * @param requestBody 
     * @returns ParticipantCreateResponse Success
     * @throws ApiError
     */
    public static postApiV1Participants(
requestBody?: ParticipantCreateRequest,
): CancelablePromise<ParticipantCreateResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/Participants',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns ParticipantEditResponse Success
     * @throws ApiError
     */
    public static putApiV1Participants(
requestBody?: ParticipantEditRequest,
): CancelablePromise<ParticipantEditResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/Participants',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns ParticipantDeleteResponse Success
     * @throws ApiError
     */
    public static deleteApiV1Participants(
requestBody?: ParticipantDeleteRequest,
): CancelablePromise<ParticipantDeleteResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/Participants',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param sieveModelFilters 
     * @param sieveModelSorts 
     * @param sieveModelPage 
     * @param sieveModelPageSize 
     * @returns ParticipantListResponse Success
     * @throws ApiError
     */
    public static getApiV1Participants(
sieveModelFilters?: string,
sieveModelSorts?: string,
sieveModelPage?: number,
sieveModelPageSize?: number,
): CancelablePromise<ParticipantListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/Participants',
            query: {
                'sieveModel.Filters': sieveModelFilters,
                'sieveModel.Sorts': sieveModelSorts,
                'sieveModel.Page': sieveModelPage,
                'sieveModel.PageSize': sieveModelPageSize,
            },
        });
    }

    /**
     * @param rfidCode 
     * @returns ParticipantFindByRfidResponse Success
     * @throws ApiError
     */
    public static getApiV1ParticipantsRfid(
rfidCode?: string,
): CancelablePromise<ParticipantFindByRfidResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/Participants/rfid',
            query: {
                'RfidCode': rfidCode,
            },
        });
    }

    /**
     * @param id 
     * @returns ParticipantFindByIdResponse Success
     * @throws ApiError
     */
    public static getApiV1ParticipantsId(
id?: number,
): CancelablePromise<ParticipantFindByIdResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/Participants/id',
            query: {
                'Id': id,
            },
        });
    }

}
