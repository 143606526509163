import { type KeycloakService } from "@congatec/authentication-lib";

export class AuthenticationObserver {
    _service: KeycloakService | undefined;
    constructor(service: KeycloakService | undefined) {
        this._service = service
    }

    onLoggedIn(callback: () => void, interval=1) {
        setTimeout(() => {
            if (this._service?.isSignedIn) {
                callback();
            } else {
                this.onLoggedIn(callback, interval);
            }
        }, interval);
    }
}