import { defineStore } from "pinia";
import { useStorage } from "@vueuse/core";

const COUNTRY_STORAGE_KEY = "Visit-Country-Storage";
const BRANCHOFFICE_STORAGE_KEY = "Visit-BranchOffice-Storage";

export const useVisitStore = defineStore("VisitStorage", () => {
    const country = useStorage(COUNTRY_STORAGE_KEY, "DE");
    const branchOffice = useStorage(BRANCHOFFICE_STORAGE_KEY, "Deggendorf");
  
    const setCountry = (newToken: string) => {
      country.value = newToken;
    };

    const setBranchOffice = (newToken: string) => {
      branchOffice.value = newToken;
    };
  
    const clear = () => {
        country.value = "DE";
        branchOffice.value = "Deggendorf";
    }
  
    return { country, setCountry, branchOffice, setBranchOffice, clear };
  });