<template>
    <div class="card">
        <div class="mt-3"></div>
        <Toolbar>
            <template #start>
                <div class="flex flex-row gap-3">
                    <Button label="Check In / Check Out" @click="checkInCheckOut()" icon="pi pi-briefcase">
                    </Button>
                </div>
            </template>
        </Toolbar>
    </div>
    <div class="mt-3"></div>
    <div>
        <ConDataTable tableId="CheckInOutList" title="Check In / Check Out List" :columns="checkInOutTableColumns"
            filterDisplay="menu" :rowsPerPageOptions="[10, 20, 50]" :onReload="fetchVisitorDataAsync"
            @onSelectionChanged="handleVisitorSelectionChange" ref="checkInOutTableRef">
            <template #checkOutDateTime="col" >
                <Column :columnKey="col.field" :selectionMode="col.selectionMode" :field="col.field" :header="col.header"
                    :data-type="col.dataType" :sortable="col.sortable">
                    <template #body="{ data }">{{ nullCheck(data[col.field]) }}</template>
                </Column>
            </template>
            <!-- <template #screeningStatus="col">
        <Column :field="col.field" :header="col.header">

          <template #body="field">
            <div class="flex justify-content-center">
              <i v-if="field.data[col.field] == 'notListed'" v-tooltip="'This visitor is not listed'"
                class="pi pi-thumbs-up-fill " style="color: rgb(23, 201, 100)" aria-label="screen success"></i>
              <i v-if="field.data[col.field] == 'listed'" v-tooltip="'This visitor is listed!'" class=" pi
              pi-thumbs-down-fill " style=" color: rgb(242, 19, 93)" aria-label="screen failed"></i>
              <i v-if="field.data[col.field] == 'inReview'" v-tooltip="'Review pending'" class="pi pi-spinner pi-spin"
                style="color: rgb(255, 130, 0)" aria-label="screen pending"></i>
            </div>
          </template>
        </Column>

      </template> -->
        </ConDataTable>
    </div>
</template>
<script setup lang="ts">
import { ConDataTable, ConDateTimeHelper } from '@congatec/primevue-components-lib';
import { CheckInOutService, type CreateCheckInOutRequest, type ListCheckInOutResponse_CheckInsOuts } from '@/apis/neptune-api';
import { ref } from 'vue';
import { useToast } from 'primevue/usetoast';
import Button from 'primevue/button';
import Toolbar from 'primevue/toolbar';
import Column from "primevue/column";

const toast = useToast();
const selectedCheckInOut = ref<ListCheckInOutResponse_CheckInsOuts[]>([]);
const visitIdList = ref<number[]>([]);
const checkInOutTableRef = ref<null | typeof ConDataTable>(null);


const checkInOutTableColumns = [
    {
        selectionMode: 'multiple',
        field: 'any'
    },
    //   {
    //     field: "Id",
    //     header: "Visitor Id",
    //     dataType: "text",
    //     isFiltered: true,
    //     sortable: true,
    //   },
    {
        field: "visitorFirstName",
        header: "First Name",
        dataType: "text",
        isFiltered: true,
        sortable: true,
    },
    {
        field: "visitorLastName",
        header: "Last Name",
        dataType: "text",
        isFiltered: true,
        sortable: true,
    },
    {
        field: "visitorEmail",
        header: "E-Mail",
        dataType: "text",
        isFiltered: true,
        sortable: true,
    },
    //   {
    //     field: "visitorstatus",
    //     header: "Visitor Status",
    //     dataType: "text",
    //     isFiltered: true,
    //     sortable: true,
    //   },
    {
        field: "visitorCompany",
        header: "Company",
        dataType: "text",
        isFiltered: true,
        sortable: true,
    },
    {
        field: "checkInDateTime",
        header: "Check In Date",
        dataType: "date",
        isFiltered: true,
        sortable: true,
    },
    {
        field: "checkOutDateTime",
        header: "Check Out Date",
        dataType: "date",
        isFiltered: true,
        sortable: true,
    },
]

function handleVisitorSelectionChange(selectedRows: any) {
    console.log("handleSel", selectedRows)
    console.log("visitorIdList: ", visitIdList)
    selectedCheckInOut.value = selectedRows;
    visitIdList.value = [];
    selectedCheckInOut.value.forEach(checkInOut => {
        visitIdList.value.push(checkInOut.visitId ? checkInOut.visitId : 0)
    });

}

async function fetchVisitorDataAsync(filters: string, sorts: string, page: number, rows: number): Promise<any> {

    let res = await CheckInOutService.getApiV1CheckInOut(filters, sorts, page, rows);
    console.log("Resolution: ", res)
    return {
        totalCount: res.totalCount,
        data: res.checkInsOuts,
    }
}

async function checkInCheckOut(): Promise<any> {
  const request: CreateCheckInOutRequest = {
    visitIds: visitIdList.value
  }
  await CheckInOutService.putApiV1CheckInOutCreateOrEdit(request);
  toast.add({ severity: 'success', summary: 'Successful', detail: 'Successful Check In/Check Out', life: 3000 });
  //visitIdList.value = [];
  //selectedCheckInOut.value = [];
  console.log("selectedItems", selectedCheckInOut.value)
  checkInOutTableRef.value?.forceReload();
}


const nullCheck = (value: string) => {
    if(value)
        return ConDateTimeHelper.utcToLocaleString(value);
    else
        return "N/A";
};

</script>