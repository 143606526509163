<template>
  <div >
    <Image imageStyle="border: 3px solid; border-color: var(--primary-color)" width="450px" height="650px" :src="previewImageUrl" id="image" alt="Preview Image" /> 

  </div>
        
</template>

<script setup lang="ts">
import { ImageTemplateService, ImageFormat } from '@/apis/haumea-api';
import { showError } from '@congatec/primevue-components-lib';
import Image from 'primevue/image';
import { useToast } from 'primevue/usetoast';
import { watch, ref,onMounted } from 'vue';

const props = defineProps<{
    state : UserState,
    selectedTemplateId: any;
}>()

const previewImageUrl = ref("");
const toast = useToast();
export type UserState = {
  Id: number | undefined,
  Firstname: string,
  Lastname: string,
  Email: string,
  BranchOffice: string,
  ValidUntil: Date
  SelectedTemplateId: any,
  EmployeeImageBase64: any,
  CardType: any
}

onMounted(()=>{
  updatePreview();
})

watch((props.state || props.selectedTemplateId),(nv)=>{
  if(nv != undefined){
    updatePreview()
  }
})

const updatePreview = (async ()=>{
  try {
    var prev = await ImageTemplateService.postApiV1ImageTemplateApply({

      cards: [{
        imageTemplateIds: [props.selectedTemplateId],
        photo: props.state.EmployeeImageBase64,
        qrCode: props.state.EmployeeImageBase64,
        fullName: `${props.state.Firstname} ${props.state.Lastname}`,
        branchOffice: props.state.BranchOffice ?? "",
        country: "",
        cardType: props.state.CardType ?? "",
      }],
      imageFormat: ImageFormat.PNG,

    });
    previewImageUrl.value = `data:${prev.mimeType};base64,${prev.content}`
  } catch (ex: any) {
    showError(toast, "Error", ex);
  }
})

</script>

