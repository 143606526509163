/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ImageFormat {
    PNG = 'png',
    JPEG = 'jpeg',
    PDF = 'pdf',
    SVG = 'svg',
}
