/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ReleaseStatus {
    DRAFT = 'draft',
    REVIEW = 'review',
    RELEASE = 'release',
    REJECTED = 'rejected',
}
