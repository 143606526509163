<script setup lang="ts">
import { ConPortalCard } from "@congatec/primevue-components-lib";
import { onBeforeMount, ref, type Ref } from "vue";
import Button from "primevue/button";
import DwhDatabaseBehaviorView from "@/uranus/components/dwhdatabases/behavior/DwhDatabaseBehaviorView.vue";
import DwhDatabasePerformanceView from "../../dwhdatabases/performance/DwhDatabasePerformanceView.vue";
import OrderList from "primevue/orderlist";
import Card from "primevue/card";
import {
  DataQualityChecksService,
  type ListDataQualityCheckResponse,
} from "@/apis/uranus-api";
import { type DwhDatabaseListResponse_DwhDatabase } from "@/apis/uranus-api";
import VirtualScroller from "primevue/virtualscroller";

let data: Ref<Array<DwhDatabaseListResponse_DwhDatabase>> = ref([{}]);
let selectedItem: Ref<DwhDatabaseListResponse_DwhDatabase> = ref({});
let dialogRef: any = null;
const isLoading = ref(true);
const totalItems = ref(1);
let sieveFilterStatements = "";
let sieveSortStatements = "";

const tableOptions = ref<{
  page: number;
  sortBy: Array<string>;
  sortDesc: Array<boolean>;
  groupBy: Array<string>;
  groupDesc: Array<string>;
  multiSort: boolean;
  mustSort: boolean;
  itemsPerPage: number;
}>({
  page: 1,
  sortBy: ["id"],
  sortDesc: [true],
  groupBy: [],
  groupDesc: [],
  multiSort: true,
  mustSort: false,
  itemsPerPage: 25,
});

onBeforeMount(() => {
  fetchDataFromServiceAsync();
  tableOptions.value.page = 1;
});

async function fetchDataFromServiceAsync() {
  console.log("tableOptions page: ", tableOptions.value.page);
  const { page, itemsPerPage } = tableOptions.value;
  const request = {
    sieveModelFilters: "failedChecksFilter==1",
    sieveModelSorts: sieveSortStatements,
    sieveModelPage: page,
    sieveModelPageSize: itemsPerPage,
    pageNumber: page,
    pageSize: itemsPerPage,
  };
  if (request && tableOptions.value !== undefined) {
    isLoading.value = true;
    DataQualityChecksService.getApiV1DataQualityChecksList(
      request.sieveModelFilters,
      request.sieveModelSorts,
      request.sieveModelPage,
      request.sieveModelPageSize,
      request.pageNumber,
      request.pageSize,
      0,
    ).then((r) => {
      if (r?.dataQualityChecks && r.totalCount != undefined) {
        data.value = r.dataQualityChecks;
        totalItems.value = r.totalCount;
      }
      console.log(data.value);
      isLoading.value = false;
    });
  }
}
</script>

<template>
  <div class="flex flex-column gap-4 m-5 mt-7">
    <!-- landing-container -->
    <div class="flex justify-content-between w-full gap-4">
      <div class="flex justify-content-between w-6 gap-4">
        <ConPortalCard
          class="landing-item"
          key="Data Quality Checks"
          title="Data Quality Checks"
          route="/uranus/dataqualitychecks"
          description="See the list of all Data Quality Checks"
          cardIcon=""
          :tags="[]"
          :isFavorite="false"
          :isAllowed="true"
          :isExternal="false"
          :isAdmin="false"
          :disable-menu="true"
          :disable-icon="true"
        >
        </ConPortalCard>
        <ConPortalCard
          class="landing-item"
          title="Executions"
          description="See the List of all Executions"
          cardIcon=""
          key="Executions"
          route="/uranus/executions"
          :disable-menu="true"
          :disable-icon="true"
          :isAllowed="true"
          :isFavorite="false"
          :isExternal="false"
          :isAdmin="false"
          :tags="[]"
        >
        </ConPortalCard>
      </div>
      <div class="flex w-6 justify-content-between gap-4">
        <ConPortalCard
          class="landing-item"
          title="DWH Databases"
          description="See the List of all Data Warehouse Databases"
          key="DWH Databases"
          route="/uranus/executions"
          cardIcon=""
          :disable-menu="true"
          :disable-icon="true"
          :isAllowed="true"
          :isFavorite="false"
          :isExternal="false"
          :isAdmin="false"
          :tags="[]"
        >
        </ConPortalCard>
        <ConPortalCard
          class="landing-item"
          title="Scheduled Executions"
          description="See the List of all Scheduled Executions"
          key="ScheduledExecutions"
          route="/uranus/executions"
          :disable-menu="true"
          cardIcon=""
          :disable-icon="true"
          :isFavorite="false"
          :isAllowed="true"
          :isExternal="false"
          :isAdmin="false"
          :tags="[]"
        >
        </ConPortalCard>
      </div>
    </div>
    <div class="flex justify-content-between gap-3 mb-3 w-full">
      <DwhDatabasePerformanceView class="w-5"></DwhDatabasePerformanceView>

      <DwhDatabaseBehaviorView class="w-5"></DwhDatabaseBehaviorView>

      <Card class="w-2">
        <template #title>Failed Data Quality Checks</template>
        <template #content>
          <!-- <OrderList v-model="data" listStyle="height:auto" dataKey="id">
            <template #item="element">
              <router-link :to="`/uranus/dataqualitychecks/${element.item.id}`">
                <div class="flex-1 flex flex-column gap-2">
                  <span class="font-bold">{{ element.item.table }}</span>
                  <div class="flex align-items-center gap-2">
                    <span>{{ element.item.description }}</span>
                  </div>
                </div>
              </router-link>
            </template>
          </OrderList> -->
          <VirtualScroller :items="data">
            <template v-slot:item="{ item }">
              <div style="height: 55px">
                <router-link :to="`/uranus/dataqualitychecks/${item.id}`">
                  <div class="flex-1 flex flex-column gap-2">
                    <span class="font-bold">{{ item.table }}</span>
                    <div class="flex align-items-center gap-2">
                      <span>{{ item.description }}</span>
                    </div>
                  </div>
                </router-link>
              </div>
            </template>
          </VirtualScroller>
        </template>
      </Card>
    </div>
  </div>
</template>

<style scoped>
.landing-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: 1fr;
  margin: 1rem !important;
  grid-column-gap: 1rem;
}

.landing-item {
  height: 100%;
  width: 50%;
  text-align: center;
  @media screen and (min-width: 1500px) {
    .ConPortalCard-title {
      font-size: 1.5rem;
    }
    .ConPortalCard-description {
      font-size: 1.25rem;
    }
  }

  @media screen and (max-width: 1500px) {
    .ConPortalCard-title {
      font-size: 1.125rem;
    }
    .ConPortalCard-description {
      font-size: 1rem;
    }
  }

  @media screen and (max-width: 900px) {
    .ConPortalCard-title {
      font-size: 1rem;
    }
    .ConPortalCard-description {
      font-size: 0.75rem;
    }
  }

  @media (min-width: 1200px) and (max-width: 1399px) {
    .ConPortalCard-title-container {
      flex-direction: column !important;
      justify-content: center;
      align-items: center;
      text-align: left;
      gap: 0rem;
    }
    .ConPortalCard-title {
      text-align: center;
    }

    .ConPortalCard-description {
      text-align: center;
    }
  }

  @media (min-width: 1400px) {
    .ConPortalCard-title-container {
      flex-direction: row !important;
      justify-content: center; /* Center items horizontally */
      align-items: center; /* Center items vertically */
      text-align: left; /* Align text to the left */
      gap: 0.75rem;
    }
    .ConPortalCard-title {
      text-align: left;
    }

    .ConPortalCard-description {
      text-align: left;
    }
  }
}

.landing-button {
  display: flex;
  width: 100%;
  justify-content: center;
}

.p-virtualscroller {
  height: 400px;
}
</style>
