/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum AccessLevel {
    DEPARTMENT = 'department',
    PUBLIC = 'public',
    PRIVATE = 'private',
}
