<template>
  <div class="container">
    <Card class="card" @click="globalRouter.push('/hubspot-gateway/logs')">
      <template #header>
        <div style="background-color: var(--primary-color); height: 3em"></div>
      </template>
      <template #title> Log Entries </template>
      <template #subtitle> View and resolve mapping errors </template>
    </Card>
  </div>
</template>

<script setup lang="ts">
import Card from "primevue/card";
import { useRouter } from "vue-router";

const globalRouter = useRouter();
</script>

<style scoped lang="scss">
* {
  margin: 0;
  padding: 0;
}

.card {
  cursor: pointer;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
