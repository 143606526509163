/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum VisitorStatus {
    SCHEDULED = 'scheduled',
    CHECKED_IN = 'checkedIn',
    CHECKED_OUT = 'checkedOut',
    CANCELLED = 'cancelled',
}
