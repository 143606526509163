import { VisitorList, ManageAttachments, ManageTemplates, CheckInOutList, ScreenedVisitorList } from '@/neptune/components'
import { to } from '@congatec/primevue-components-lib';
import { ReceptionDashboard, VisitorQRScan, } from "@/neptune/components"

const title = "con-VISIT"


const neptuneRoutes = [
  {
    path: '/neptune/visitor-list',
    name: 'VisitorList',
    
    component: VisitorList,
    meta: {
      breadcrumb: [{ title: title }, { label: "visitor-list" }],
      
    },
  },
  {
    path: '/neptune/reception-dashboard',
    name: 'ReceptionsDashboard',
    component: ReceptionDashboard,
    meta: {
      breadcrumb: [{ title: title,command:to('/neptune/visitor-list') }, { label: "ReceptionDashboard" }],
    },
  },
  {
    path: '/neptune/vs-scan',
    name: 'VisitorQrScan',
    component: VisitorQRScan,
    meta: {
      breadcrumb: [{ title: title,command:to('/neptune/visitor-list') }, { label: "VS-Scan" }],
    },
  },
  {
    path: '/neptune/email/manage-attachments',
    name: 'ManageAttachments',
    component: ManageAttachments,
    meta: {
      breadcrumb: [{ title: title,command:to('/neptune/visitor-list') }, { label: "Manage-Attachments" }],
    },
  },
  {
    path: '/neptune/email/manage-templates',
    name: 'ManageTemplates',
    component: ManageTemplates,
    meta: {
      breadcrumb: [{ title: title,command:to('/neptune/visitor-list') }, { label: "Manage-Templates" }],
    },
  },
  {
    path: '/neptune/checkinout-list',
    name: 'CheckInOutList',
    component: CheckInOutList,
    meta: {
      breadcrumb: [{ title: title ,command:to('/neptune/visitor-list') }, { label: "CheckInOutList" }],
    },
  },
  {
    path: '/neptune/screenedvisitor-list',
    name: 'ScreenedVisitorList',
    
    component: ScreenedVisitorList,
    meta: {
      breadcrumb: [{ title: title }, { label: "ecc-dashboard" }],
      
    },
  },
];


export default neptuneRoutes;

