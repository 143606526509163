/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateVisitRequest } from '../models/CreateVisitRequest';
import type { CreateVisitResponse } from '../models/CreateVisitResponse';
import type { DeleteVisitRequest } from '../models/DeleteVisitRequest';
import type { DeleteVisitResponse } from '../models/DeleteVisitResponse';
import type { EditVisitRequest } from '../models/EditVisitRequest';
import type { EditVisitResponse } from '../models/EditVisitResponse';
import type { ListVisitResponse } from '../models/ListVisitResponse';
import type { QuickCreateVisitRequest } from '../models/QuickCreateVisitRequest';
import type { QuickCreateVisitResponse } from '../models/QuickCreateVisitResponse';
import type { ReceptionVisitResponse } from '../models/ReceptionVisitResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class VisitService {

    /**
     * @param requestBody 
     * @returns CreateVisitResponse Success
     * @throws ApiError
     */
    public static postApiV1Visit(
requestBody?: CreateVisitRequest,
): CancelablePromise<CreateVisitResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/Visit',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns QuickCreateVisitResponse Success
     * @throws ApiError
     */
    public static postApiV1VisitQuickCreate(
requestBody?: QuickCreateVisitRequest,
): CancelablePromise<QuickCreateVisitResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/Visit/QuickCreate',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns EditVisitResponse Success
     * @throws ApiError
     */
    public static putApiV1VisitEdit(
requestBody?: EditVisitRequest,
): CancelablePromise<EditVisitResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/Visit/Edit',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns DeleteVisitResponse Success
     * @throws ApiError
     */
    public static deleteApiV1VisitDelete(
requestBody?: DeleteVisitRequest,
): CancelablePromise<DeleteVisitResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/Visit/Delete',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param sieveModelFilters 
     * @param sieveModelSorts 
     * @param sieveModelPage 
     * @param sieveModelPageSize 
     * @param visitorsIdList 
     * @returns ListVisitResponse Success
     * @throws ApiError
     */
    public static getApiV1VisitList(
sieveModelFilters?: string,
sieveModelSorts?: string,
sieveModelPage?: number,
sieveModelPageSize?: number,
visitorsIdList?: Array<number>,
): CancelablePromise<ListVisitResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/Visit/List',
            query: {
                'sieveModel.Filters': sieveModelFilters,
                'sieveModel.Sorts': sieveModelSorts,
                'sieveModel.Page': sieveModelPage,
                'sieveModel.PageSize': sieveModelPageSize,
                'VisitorsIdList': visitorsIdList,
            },
        });
    }

    /**
     * @param sieveModelFilters 
     * @param sieveModelSorts 
     * @param sieveModelPage 
     * @param sieveModelPageSize 
     * @param fromDate 
     * @param toDate 
     * @param branchOffice 
     * @returns ReceptionVisitResponse Success
     * @throws ApiError
     */
    public static getApiV1VisitReception(
sieveModelFilters?: string,
sieveModelSorts?: string,
sieveModelPage?: number,
sieveModelPageSize?: number,
fromDate?: string,
toDate?: string,
branchOffice?: string,
): CancelablePromise<ReceptionVisitResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/Visit/reception',
            query: {
                'sieveModel.Filters': sieveModelFilters,
                'sieveModel.Sorts': sieveModelSorts,
                'sieveModel.Page': sieveModelPage,
                'sieveModel.PageSize': sieveModelPageSize,
                'FromDate': fromDate,
                'ToDate': toDate,
                'BranchOffice': branchOffice,
            },
        });
    }

}
