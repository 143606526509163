/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateAttachmentRequest } from '../models/CreateAttachmentRequest';
import type { CreateAttachmentResponse } from '../models/CreateAttachmentResponse';
import type { CreateEmailTemplateRequest } from '../models/CreateEmailTemplateRequest';
import type { CreateEmailTemplateResponse } from '../models/CreateEmailTemplateResponse';
import type { DeleteAttachmentRequest } from '../models/DeleteAttachmentRequest';
import type { DeleteAttachmentResponse } from '../models/DeleteAttachmentResponse';
import type { DeleteEmailTemplateRequest } from '../models/DeleteEmailTemplateRequest';
import type { DeleteEmailTemplateResponse } from '../models/DeleteEmailTemplateResponse';
import type { EditAttachmentRequest } from '../models/EditAttachmentRequest';
import type { EditAttachmentResponse } from '../models/EditAttachmentResponse';
import type { EditEmailTemplateRequest } from '../models/EditEmailTemplateRequest';
import type { EditEmailTemplateResponse } from '../models/EditEmailTemplateResponse';
import type { EmailNotificationRequest } from '../models/EmailNotificationRequest';
import type { EmailNotificationResponse } from '../models/EmailNotificationResponse';
import type { FormatEmailResponse } from '../models/FormatEmailResponse';
import type { FormatEmailVariableResponse } from '../models/FormatEmailVariableResponse';
import type { FormatEmailVariablesRequest } from '../models/FormatEmailVariablesRequest';
import type { GetAttachmentResponse } from '../models/GetAttachmentResponse';
import type { GetEmailTemplateResponse } from '../models/GetEmailTemplateResponse';
import type { ListEmailAttachmentsResponse } from '../models/ListEmailAttachmentsResponse';
import type { ListEmailTemplateResponse } from '../models/ListEmailTemplateResponse';
import type { SendEmailResponse } from '../models/SendEmailResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class EmailService {

    /**
     * @param requestBody 
     * @returns CreateAttachmentResponse Success
     * @throws ApiError
     */
    public static postApiV1EmailCreateAttachment(
requestBody?: CreateAttachmentRequest,
): CancelablePromise<CreateAttachmentResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/Email/CreateAttachment',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns EditAttachmentResponse Success
     * @throws ApiError
     */
    public static putApiV1EmailUpdateAttachment(
requestBody?: EditAttachmentRequest,
): CancelablePromise<EditAttachmentResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/Email/UpdateAttachment',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param sieveModelFilters 
     * @param sieveModelSorts 
     * @param sieveModelPage 
     * @param sieveModelPageSize 
     * @returns ListEmailAttachmentsResponse Success
     * @throws ApiError
     */
    public static getApiV1EmailListAttachment(
sieveModelFilters?: string,
sieveModelSorts?: string,
sieveModelPage?: number,
sieveModelPageSize?: number,
): CancelablePromise<ListEmailAttachmentsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/Email/ListAttachment',
            query: {
                'SieveModel.Filters': sieveModelFilters,
                'SieveModel.Sorts': sieveModelSorts,
                'SieveModel.Page': sieveModelPage,
                'SieveModel.PageSize': sieveModelPageSize,
            },
        });
    }

    /**
     * @param attachmentId 
     * @returns GetAttachmentResponse Success
     * @throws ApiError
     */
    public static getApiV1EmailGetAttachment(
attachmentId?: number,
): CancelablePromise<GetAttachmentResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/Email/GetAttachment',
            query: {
                'AttachmentId': attachmentId,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns DeleteAttachmentResponse Success
     * @throws ApiError
     */
    public static deleteApiV1EmailDeleteAttachment(
requestBody?: DeleteAttachmentRequest,
): CancelablePromise<DeleteAttachmentResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/Email/DeleteAttachment',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns CreateEmailTemplateResponse Success
     * @throws ApiError
     */
    public static postApiV1EmailCreateTemplate(
requestBody?: CreateEmailTemplateRequest,
): CancelablePromise<CreateEmailTemplateResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/Email/CreateTemplate',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns EditEmailTemplateResponse Success
     * @throws ApiError
     */
    public static putApiV1EmailUpdateTemplate(
requestBody?: EditEmailTemplateRequest,
): CancelablePromise<EditEmailTemplateResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/Email/UpdateTemplate',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param sieveModelFilters 
     * @param sieveModelSorts 
     * @param sieveModelPage 
     * @param sieveModelPageSize 
     * @returns ListEmailTemplateResponse Success
     * @throws ApiError
     */
    public static getApiV1EmailListTemplate(
sieveModelFilters?: string,
sieveModelSorts?: string,
sieveModelPage?: number,
sieveModelPageSize?: number,
): CancelablePromise<ListEmailTemplateResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/Email/ListTemplate',
            query: {
                'SieveModel.Filters': sieveModelFilters,
                'SieveModel.Sorts': sieveModelSorts,
                'SieveModel.Page': sieveModelPage,
                'SieveModel.PageSize': sieveModelPageSize,
            },
        });
    }

    /**
     * @param templateId 
     * @returns GetEmailTemplateResponse Success
     * @throws ApiError
     */
    public static getApiV1EmailGetTemplate(
templateId?: number,
): CancelablePromise<GetEmailTemplateResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/Email/GetTemplate',
            query: {
                'TemplateId': templateId,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns DeleteEmailTemplateResponse Success
     * @throws ApiError
     */
    public static deleteApiV1EmailDeleteTemplate(
requestBody?: DeleteEmailTemplateRequest,
): CancelablePromise<DeleteEmailTemplateResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/Email/DeleteTemplate',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param emailText 
     * @param visitId 
     * @param sampleVisit 
     * @returns FormatEmailResponse Success
     * @throws ApiError
     */
    public static getApiV1EmailFormatEmail(
emailText?: string,
visitId?: number,
sampleVisit?: boolean,
): CancelablePromise<FormatEmailResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/Email/FormatEmail',
            query: {
                'EmailText': emailText,
                'VisitId': visitId,
                'SampleVisit': sampleVisit,
            },
        });
    }

    /**
     * @param request 
     * @returns FormatEmailVariableResponse Success
     * @throws ApiError
     */
    public static getApiV1EmailTemplateVariablesList(
request?: FormatEmailVariablesRequest,
): CancelablePromise<FormatEmailVariableResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/Email/TemplateVariablesList',
            query: {
                'request': request,
            },
        });
    }

    /**
     * @param visitId 
     * @param emailTemplateId 
     * @returns SendEmailResponse Success
     * @throws ApiError
     */
    public static putApiV1EmailSendEmail(
visitId?: number,
emailTemplateId?: number,
): CancelablePromise<SendEmailResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/Email/SendEmail',
            query: {
                'VisitId': visitId,
                'EmailTemplateId': emailTemplateId,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns EmailNotificationResponse Success
     * @throws ApiError
     */
    public static postApiV1EmailSendAutomaticEmails(
requestBody?: EmailNotificationRequest,
): CancelablePromise<EmailNotificationResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/Email/SendAutomaticEmails',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
