/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LocationCreateRequest } from '../models/LocationCreateRequest';
import type { LocationCreateResponse } from '../models/LocationCreateResponse';
import type { LocationDeleteResponse } from '../models/LocationDeleteResponse';
import type { LocationGetResponse } from '../models/LocationGetResponse';
import type { LocationListResponse } from '../models/LocationListResponse';
import type { LocationUpdateRequest } from '../models/LocationUpdateRequest';
import type { LocationUpdateResponse } from '../models/LocationUpdateResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class LocationsService {

    /**
     * @param requestBody 
     * @returns LocationCreateResponse Success
     * @throws ApiError
     */
    public static postApiV1Locations(
requestBody?: LocationCreateRequest,
): CancelablePromise<LocationCreateResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/Locations',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param ids 
     * @returns LocationDeleteResponse Success
     * @throws ApiError
     */
    public static deleteApiV1Locations(
ids?: Array<number>,
): CancelablePromise<LocationDeleteResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/Locations',
            query: {
                'Ids': ids,
            },
        });
    }

    /**
     * @param sieveModelFilters 
     * @param sieveModelSorts 
     * @param sieveModelPage 
     * @param sieveModelPageSize 
     * @returns LocationListResponse Success
     * @throws ApiError
     */
    public static getApiV1Locations(
sieveModelFilters?: string,
sieveModelSorts?: string,
sieveModelPage?: number,
sieveModelPageSize?: number,
): CancelablePromise<LocationListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/Locations',
            query: {
                'SieveModel.Filters': sieveModelFilters,
                'SieveModel.Sorts': sieveModelSorts,
                'SieveModel.Page': sieveModelPage,
                'SieveModel.PageSize': sieveModelPageSize,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns LocationUpdateResponse Success
     * @throws ApiError
     */
    public static putApiV1Locations(
requestBody?: LocationUpdateRequest,
): CancelablePromise<LocationUpdateResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/Locations',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id 
     * @returns LocationGetResponse Success
     * @throws ApiError
     */
    public static getApiV1Locations1(
id: number,
): CancelablePromise<LocationGetResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/Locations/{id}',
            path: {
                'id': id,
            },
        });
    }

}
