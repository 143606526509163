import { to } from "@congatec/primevue-components-lib";
import AssetsList from "../components/assets/AssetsList.vue";
import AssetTypesList from "../components/assetTypes/AssetTypesList.vue";
import AssetsDetails from "../components/assets/AssetsDetails.vue";
import LocationsList from "../components/locations/LocationsList.vue";
import LocationsDetails from "../components/locations/LocationsDetails.vue";
import TagsList from "../components/tags/TagsList.vue";
import AssetTypesDetails from "../components/assetTypes/AssetTypesDetails.vue";

const title = "Luna";

const lunaRoutes = [
  {
    path: "/luna/asset-list",
    name: "AssetsList",

    component: AssetsList,
    meta: {
      breadcrumb: [{ title: title }, { label: "Assets" }],
    },
  },
  {
    path: "/luna/assettype-list",
    name: "AssetTypesList",

    component: AssetTypesList,
    meta: {
      breadcrumb: [{ title: title }, { label: "Assets Types" }],
    },
  },
  {
    path: "/luna/tag-list",
    name: "TagsList",

    component: TagsList,
    meta: {
      breadcrumb: [{ title: title }, { label: "Tags" }],
    },
  },
  {
    path: "/luna/location-list",
    name: "LocationsList",

    component: LocationsList,
    meta: {
      breadcrumb: [{ title: title }, { label: "Locations" }],
    },
  },
  {
    path: "/luna/asset-details/:id",
    name: "Asset Details",

    component: AssetsDetails,
    props: (route: any) => ({
      id: route.params.id,
    }),
    meta: {
      breadcrumb: [
        { title: title, command: to("/luna/asset-list") },
        { label: "Asset Details" },
      ],
    },
  },
  {
    path: "/luna/location-details/:id",
    name: "Location Details",

    component: LocationsDetails,
    props: (route: any) => ({
      id: route.params.id,
    }),
    meta: {
      breadcrumb: [
        { title: title, command: to("/luna/location-list") },
        { label: "Location Details" },
      ],
    },
  },
  {
    path: "/luna/assettype-details/:id",
    name: "AssetType Details",

    component: AssetTypesDetails,
    props: (route: any) => ({
      id: route.params.id,
    }),
    meta: {
      breadcrumb: [
        { title: title, command: to("/luna/assettype-list") },
        { label: "Assettype Details" },
      ],
    },
  },
];

export default lunaRoutes;
