import { defineStore } from "pinia";
import { useStorage } from "@vueuse/core";
import type { Ref } from "vue";

const STORAGE_KEY = "Selected-DwhDb";

export const DwhDbStore = defineStore("DwhDbStorage", () => {
  const id: Ref<number | undefined> = useStorage(STORAGE_KEY, undefined);

  const set = (newToken: number) => {
    id.value = newToken;
  };

  return { id, set };
});
