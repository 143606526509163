/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FindUserResponse } from '../models/FindUserResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UserService {

    /**
     * @param query 
     * @returns FindUserResponse Success
     * @throws ApiError
     */
    public static postApiV1UserFindUser(
query?: string,
): CancelablePromise<FindUserResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/User/FindUser',
            query: {
                'Query': query,
            },
        });
    }

}
