export const printBlob = (file: any) => {
  const iframe = document.createElement('iframe');
  
  const name = crypto.randomUUID();

  iframe.src = window.URL.createObjectURL(file);
  iframe.name = name;

  iframe.onload = () => {
    const docFrame = window.frames[name as any];
    docFrame.focus();
    docFrame.print();

    // FIXME: it appears to not be possible to hook 
    // into onafterprint for iframes and therefore we cannot
    // remove the frame after pritning is done 
    // this leaks the iframe as long as the current page is open!
    // too bad...
  }
  iframe.setAttribute("hidden", "hidden");
  document.body.appendChild(iframe);
}
