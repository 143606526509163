<template>
    <ConfirmDialog />
    <div class="m-5 mt-7">
        <ConDataTable tableId="Lapdetails" :title="'Lap Details ' + participantName" :columns="lapTableColumns" filterDisplay="menu"
            :rowsPerPageOptions="[10, 20, 50]" :onReload="fetchDataAsync" ref="lapTableRef"
            :createActionMenu="createActionMenu">

        </ConDataTable>
    </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';
import { ConDataTable } from '@congatec/primevue-components-lib';
import { ParticipantsService } from '@/apis/lapcounter-api/services/ParticipantsService';
import { useToast } from 'primevue/usetoast';
import { LapsService } from '@/apis/lapcounter-api/services/LapsService';
import ConfirmDialog from 'primevue/confirmdialog';
import { useConfirm } from "primevue/useconfirm";
import { useRoute } from 'vue-router'

const toast = useToast();
const confirm = useConfirm();
const selectedParticipants = ref<Array<any>>([]);
const lapTableRef = ref<null | typeof ConDataTable>(null);
const route = useRoute();
const participantId = ref<number>();
const participantName = ref("");

const lapTableColumns = [
    // {
    //   selectionMode: 'multiple',
    //   field: 'any'
    // },
    {
        field: "id",
        header: "ID",
        dataType: "text",
        isFiltered: false, // not filter-/sortable because not yet implemented in Data-Lib
        sortable: false,
    },
    {
        field: "lapNumber",
        header: "Lap Number",
        dataType: "numeric",
        isFiltered: false,
        sortable: false,
    },
    {
        field: "startTime",
        header: "Start Time",
        dataType: "date",
        isFiltered: false,
        sortable: false,
    },
    {
        field: "endTime",
        header: "End Time",
        dataType: "date",
        isFiltered: false,
        sortable: false,
    },
    {
        field: "lapTime",
        header: "Lap Time",
        dataType: "text",
        isFiltered: false,
        sortable: false,
    },
    {
        field: "actionMenu",
    }
]

if (route.params && route.params["participantId"]) {
    participantId.value = parseInt(
        route.params["participantId"].toString()
    );
}

interface Lap {
    id: number,
    lapNumber: number,
    startTime: string | undefined,
    endTime: string | undefined,
    lapTime: string | undefined
}

async function fetchDataAsync(filters: string, sorts: string, page: number, rows: number): Promise<any> {
    if (participantId.value) {

        let res = await ParticipantsService.getApiV1ParticipantsId(participantId.value);
        console.log("Resolution: ", res)
        if(res) {
            if(res.firstName && res.lastName) {
                participantName.value = res.firstName + " " + res.lastName;
            }

            if (res.laps != null && res.laps != undefined) {
                let data: Array<Lap> = [];
                res.laps.forEach((entry, index) => {
                    if(res.laps![index+1] && res.laps![index+1].startTime) {
                        let endTime = res.laps![index+1].startTime!;
                        let lapTime = Math.abs(new Date(endTime).getTime() - new Date(entry.startTime!).getTime());
                        let lapTimeMs = Math.floor( lapTime % (1000));
                        let lapTimeS = Math.floor(lapTime / 1000 % 60);
                        let lapTimeM = Math.floor(lapTime / (1000*60) % 60);
                        let lapTimeH = Math.floor(lapTime / (1000*60*60) % 60);
                        let lapTimeString = `${lapTimeH.toString()}h ${lapTimeM.toString()}m ${lapTimeS.toString()}s ${lapTimeMs.toString()}ms`
                        let lap: Lap = { id: entry.id!, lapNumber: index+1, startTime: entry.startTime, endTime: endTime, lapTime: lapTimeString };
                        data.push(lap);
                    }
                    // else {
                    //     let lap: Lap = { id: entry.id!, lapNumber: 0, startTime: entry.startTime, endTime: undefined, lapTime: "End of run" };
                    //     data.push(lap);
                    // }
                });

                return {
                    totalCount: res.laps?.length-1,
                    data: data.slice((page-1)*rows, (page*rows)+rows),
                }
            }
        }
    }
    else {
        return {
                totalCount: 0,
                data: null,
            }
    }
}

function createActionMenu(row: any): any {
    return [
        {
            label: 'Delete',
            icon: 'pi pi-trash',
            command: () => deleteLap(row)
        },
    ];
}

function deleteLap(row: any) {
    //console.log('VisitorId: ', row.id);
    confirm.require({
        header: 'Delete Confirmation',
        icon: 'pi pi-info-circle',
        acceptClass: 'p-button-info',
        //TODO: implement option to see a list of items that will be delted
        message: 'Are you sure you want to delete this Item',
        accept: async () => {
            try {
                //await ParticipantsService.deleteApiV1Participants({ id: row.id });
                await LapsService.deleteApiV1Laps({ id: row.id });
                toast.add({ severity: 'success', summary: 'Delete', detail: 'Item deleted', life: 3000 });

                reloadTableData();
            } catch (ex: any) {
                toast.add({ severity: 'error', summary: 'Error!', detail: 'An error occured while deleting: ' + ex.message, life: 3000 });
            }
        }
    })
}

function reloadTableData() {
    lapTableRef.value?.forceReload();
}

</script>