<template>
  <div class="flex flex-column">
    <ConfirmPopup></ConfirmPopup>
    <Dialog v-model:visible="dialogVisible" :header="template?.id ? 'Edit Template' : 'New Template'">
      <div class="w-full mt-3 mb-3">
        <InputText class="w-full" v-model="template.fileName" placeholder="Template Name" />
      </div>

      <FileUpload name="demo[]" url="/api/upload" accept="image/*" customUpload :maxFileSize="1000000"
        @uploader="submitImage" @upload="() => { }" @select="onFileSelect">
        <template #empty>
          <p>Drag and drop files to here to upload.</p>
        </template>
      </FileUpload>
    </Dialog>

    <Toolbar>
      <template #start>
        <Button icon="pi pi-upload" label="New Template" @click="onNewTemplate" />
      </template>
    </Toolbar>

    <DataView class="m-3 w-full" :value="templates" dataKey="id">
      <template #list="slotProps">
        <div class="grid grid-nogutter">
          <div v-for="(item, index) in slotProps.items" :key="item.id" class="col-12">
            <div class="flex flex-column sm:flex-row sm:align-items-center p-4 gap-3"
              :class="{ 'border-top-1 surface-border': index !== 0 }">
              <div class="md:w-10rem relative">
                <img class="block xl:block mx-auto border-round w-full" :src="itemToImgSrc(item)" :alt="item.fileName" />
              </div>
              <div class="flex flex-column md:flex-row justify-content-between md:align-items-center flex-1 gap-4">
                <div class="flex flex-row md:flex-column justify-content-between align-items-start gap-2">
                  <div>
                    <div class="text-lg font-medium text-900 mt-2">{{ item.fileName }}</div>
                  </div>
                </div>
                <div class="flex flex-column md:align-items-end gap-5">
                  <div class="flex flex-row-reverse md:flex-row gap-2">
                    <Button icon="pi pi-trash" severity="danger" outlined
                      @click="onDeleteTemplate($event, item)"></Button>
                    <Button icon="pi pi-pencil" label="Upload" class="flex-auto md:flex-initial white-space-nowrap"
                      @click="onEditTemplate(item)"></Button>
                    <Button icon="pi pi-pencil" label="Edit" class="flex-auto md:flex-initial white-space-nowrap"
                      @click="onOpenEditor(item)"></Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </DataView>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, watch, type Ref } from 'vue';
import FileUpload from 'primevue/fileupload';
import DataView from 'primevue/dataview';
import { ImageTemplateService, type ListImageTemplateResponse_ImageTemplate } from '@/apis/haumea-api';
import Button from 'primevue/button'
import Toolbar from 'primevue/toolbar'
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import { useToast } from 'primevue/usetoast';
import { showError } from '@congatec/primevue-components-lib';
import ConfirmPopup from 'primevue/confirmpopup';
import { useConfirm } from "primevue/useconfirm";
import { useRouter } from 'vue-router';

const confirm = useConfirm();
const router = useRouter();
const toast = useToast();
const templates: Ref<ListImageTemplateResponse_ImageTemplate[]> = ref([]);
const dialogVisible = ref(false);
const template: Ref<ListImageTemplateResponse_ImageTemplate> = ref({ fileName: "", mimeType: "" });

onMounted(async () => {
  await fetchData();
});

const fetchData = async () => {
  templates.value = (await ImageTemplateService.getApiV1ImageTemplate("", "", 1, 1000)).imageTemplates || [];
}

const onFileSelect = async (event: any) => {
  const file = event.files[0];
  if (!template.value.fileName || template.value.fileName == "") {
    template.value.fileName = file.name;
  }
}

const submitImage = async (event: any) => {
  const file = event.files[0];
  const reader = new FileReader();
  let blob = await fetch(file.objectURL).then((r) => r.blob()); //blob:url


  reader.readAsDataURL(blob);

  reader.onloadend = async function () {
    const base64data = reader.result as any as string;
    let base64ContentArray = base64data.split(",")

    let mimeTypeArray = base64ContentArray[0]?.match(/[^:\s*]\w+\/[\w-+\d.]+(?=[;| ])/);

    if (mimeTypeArray) {
      template.value.mimeType = mimeTypeArray[0];
    }

    // base64 encoded data - pure
    template.value.content = base64ContentArray[1];

    if (!template.value.fileName || !template.value.mimeType || !template.value.content) {
      showError(toast, "File Upload Failed", "");
    }

    dialogVisible.value = false;

    // create or update
    if (template.value.id) {
      await ImageTemplateService.putApiV1ImageTemplate({ id: template.value.id, mimeType: template.value.mimeType, fileName: template.value.fileName, base64Data: template.value.content });
    } else {
      await ImageTemplateService.postApiV1ImageTemplate({ mimeType: template.value.mimeType, fileName: template.value.fileName, base64Data: template.value.content });
    }
    await fetchData();
  };
};

const itemToImgSrc = (item: ListImageTemplateResponse_ImageTemplate) => {
  return `data:${item.mimeType};base64,${item.content}`;
}

const onNewTemplate = () => {
  dialogVisible.value = true;
  template.value = { fileName: "", mimeType: "" };
}

const onEditTemplate = (item: ListImageTemplateResponse_ImageTemplate) => {
  dialogVisible.value = true;
  template.value = item;
}

const onOpenEditor = (item: ListImageTemplateResponse_ImageTemplate) => {
  router.push({ name: "ImageTemplateEditor", params: { templateId: item.id || 0 } });
}

const onDeleteTemplate = (event: any, item: ListImageTemplateResponse_ImageTemplate) => {
  confirm.require({
    target: event.currentTarget,
    message: 'Do you want to delete this record?',
    icon: 'pi pi-info-circle',
    rejectClass: 'p-button-secondary p-button-outlined p-button-sm',
    acceptClass: 'p-button-danger p-button-sm',
    rejectLabel: 'Cancel',
    acceptLabel: 'Delete',
    accept: async () => {
      console.info(`Deleting template ${item.id}`);
      await ImageTemplateService.deleteApiV1ImageTemplate(item.id);
      await fetchData();
    },
  });
}

watch(dialogVisible, () => {
  if (!dialogVisible.value) {
    fetchData();
  }
});

</script>
